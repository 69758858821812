import React, { memo, useCallback } from "react";
import styled from "styled-components";
import { LabelText } from "..";

export interface TextareaProps {
  label?: string;
  defaultValue?: string;
  placeholder?: string;
  name: string;
  className?: string;
  onChange: (text: string, name: string) => void;
  maxLength?: number;
}

export const Textarea = memo<TextareaProps>(
  ({ label, defaultValue, onChange, name, placeholder,maxLength, className }) => {
    const onChangeCallback = useCallback(
      (event: any) => {
        const {
          nativeEvent: {
            target: { name, value },
          },
        } = event;
        onChange(value, name);
      },
      [onChange]
    );
    return (
      <Container   className={className}>
      <LabelContainer> {label && <LabelText as="label" isValid={true}>{label}</LabelText>}</LabelContainer>
      <StyledTextarea
        className={className}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChangeCallback}
        name={name}
        maxLength={maxLength ? maxLength : undefined}
      />
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: 10px 0px;
`;
const LabelContainer = styled.div`
  margin: 10px 0px;
`;
const StyledTextarea = styled.textarea`
  display: flex;
  width: 100%;
  height: 132px;
  padding: 15px 19px;
  border: 2px solid ${({ theme: { colors } }) => colors.labelLight};
  font-family: ${({ theme: { fonts } }) => fonts.primary};
  font-size: 13px;
  outline: none;

  &::placeholder {
    color: ${({ theme: { colors } }) => colors.label};
  }
`;
