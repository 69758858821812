import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconCalendar = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
      <g transform="translate(5.469 6.25)">
        <rect
          fill={props.color}
          width="14.063"
          height="1.519"
          transform="translate(12.5 4.688)"
        />
        <path
          fill={props.color}
          d="M38.25,14v1.519H44.5v7.075H8.563V15.519h6.25V14H7V45.25H46.063V14ZM17.938,31.188H25.75v5.469H17.938Zm-1.562,5.469H8.563V31.188h7.813Zm9.375-7.031H17.938V24.156H25.75Zm1.563-5.469h7.813v5.469H27.313ZM25.75,38.219v5.469H17.938V38.219Zm1.563,0h7.813v5.469H27.313Zm0-1.562V31.188h7.813v5.469Zm9.375-5.469H44.5v5.469H36.688Zm0-1.562V24.156H44.5v5.469ZM16.375,24.156v5.469H8.563V24.156ZM8.563,38.219h7.813v5.469H8.563Zm28.125,5.469V38.219H44.5v5.469Z"
          transform="translate(-7 -9.313)"
        />
        <rect
          fill={props.color}
          width="1.563"
          height="8.594"
          transform="translate(9.375)"
        />
        <rect
          fill={props.color}
          width="1.563"
          height="8.594"
          transform="translate(28.125)"
        />
      </g>
    </Svg>
  );
});
