import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconTag = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 46 46" {...props}>
        <rect fill="none" width="46" height="46"/>
        <g transform="translate(-774 -1690)">
        <path 
        fill={props.color}  
        d="M1411.586,410.441,1431,391h9.044a7,7,0,0,1,6.954,6.953V407l-19.445,19.4Zm1.89,0,14.079,14.091,18.119-18.086v-8.494a5.675,5.675,0,0,0-5.628-5.626h-8.5Zm23.579-12.481a2.984,2.984,0,1,1,2.984,2.983A2.987,2.987,0,0,1,1437.055,397.96Zm1.326,0a1.658,1.658,0,1,0,1.658-1.657A1.66,1.66,0,0,0,1438.381,397.96Z" 
        transform="translate(-632.293 1304.304)"/>
        <rect fill="none" width="46" height="46" transform="translate(774 1690)"/>
      </g>
    </Svg>
  );
});