import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconClose = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <path d="M17.9977 1.27507L16.7243 0L8.99885 7.72723L1.27491 0L0 1.27507L7.72624 9L0 16.7265L1.27491 18L9.00115 10.2728L16.7274 18L18 16.7272L10.2738 9L17.9977 1.27507Z" fill="white"/>
    </Svg>
  );
});


