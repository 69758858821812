import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconEmailStroke = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 39.063 40.616" {...props}>
      <g transform="translate(-5.469 -3.134)">
      <g transform="translate(5.469 3.134)">
      <path fill={props.color} d="M41.375,14.235V7.909H33.567L30.466,5.4a6.28,6.28,0,0,0-7.869,0L19.5,7.909H11.688v6.326L7,18.032V44.627H46.063V18.032ZM23.58,6.61a4.712,4.712,0,0,1,5.9,0l1.6,1.3H21.978ZM11.688,16.246v3.566l1.563,1.3V9.471H39.813V21.147l1.563-1.3v-3.6L44.5,18.778v.484l-13.564,11.3H22.127L8.563,19.262v-.484ZM44.5,21.3v19.7L32.514,31.283ZM20.547,31.283,8.563,40.993V21.3ZM8.563,43.065V43L21.986,32.127h9.088L44.5,43v.062Z" transform="translate(-7 -4.011)"/>
      <rect fill={props.color} width="6.25" height="1.563" transform="translate(10.938 10.929)"/>
      <rect fill={props.color} width="17.188" height="1.563" transform="translate(10.938 15.616)"/>
      <rect fill={props.color} width="17.188" height="1.563" transform="translate(10.938 18.741)"/>
      </g>
      </g>
    </Svg>
  );
});
