import React, { memo, useMemo, useState } from "react";
import styled from "styled-components";
import Label from "../typography/label";
import { ErrorLabel } from "../error-label";
import defaultTheme from "../../theme";
import { IconEmail, IconLock, IconEye } from "../icons";

const IconTypes = {
  email: <IconEmail />,
  password: <IconLock />,
};

export enum InputTypes {
  EMAIL = "email",
  PASSWORD = "password",
  TEXT = "text",
  PHONE = 'number',
  NUMBER = 'number'
}

export interface InputProps {
  label?: string;
  isValid?: boolean;
  errorMessage?: string;
  type?: InputTypes;
  value?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent) => void;
  autoFocus?: boolean;
  className?: string;
  name?: string;
  required?: boolean;
  maxLength?: number;
  disabled?: boolean;
  onFocus?:(event: React.ChangeEvent) => void;
}

export const Input = memo<InputProps>(
  ({
    label,
    errorMessage,
    type = InputTypes.TEXT,
    isValid = true,
    placeholder,
    value,
    onChange,
    autoFocus,
    className,
    name,
    required,
    maxLength,
    disabled,
    onFocus
  }): JSX.Element => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const leftIconMemo = useMemo(() => {
      const iconTypes = [InputTypes.EMAIL, InputTypes.PASSWORD];
      const hasIcon = iconTypes.includes(type);
      const icon = IconTypes[type];
      return {
        hasIcon,
        icon,
      };
    }, [type]);

    const isPassword = useMemo(() => {
      return type === InputTypes.PASSWORD;
    }, [type]);

    return (
      <InputContainer className={className}>
        {errorMessage && (
          <ErrorLabel message={errorMessage} visible={!isValid} />
        )}
        {label && <LabelText as="label" isValid={isValid}  >{label}</LabelText>}
        {leftIconMemo.hasIcon && (
          <InputIconLeft>{leftIconMemo.icon} </InputIconLeft>
        )}
        <StyledInput
          isValid={isValid}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          hasIcon={leftIconMemo.hasIcon}
          type={passwordVisible ? InputTypes.TEXT : type}
          autoFocus={autoFocus}
          maxLength={maxLength ? maxLength : undefined}
          name={name}
          required
          disabled={disabled || false}
          autoComplete="none"
          autoCorrect="off" // Set unique values for autoCorrect and autoCapitalize
          autoCapitalize="off"
          onFocus={onFocus}
        />
        {isPassword && (
          <InputIconRightButton
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            <IconEye color={defaultTheme.colors.primaryDark} />
          </InputIconRightButton>
        )}
      </InputContainer>
    );
  }
);

interface IInputContainerProps {
  label?: string;
}

export const InputContainer = styled.div<IInputContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.colors.labelLight};
`;

interface ILabelTextProp {
  isValid?: boolean;
}

export const LabelText = styled(Label) <ILabelTextProp>` 
  color: ${({ theme, isValid }) => (isValid && isValid === true ? theme.colors.labelDark : theme.colors.accent)};
  margin-bottom: 8px;
`;

interface IStyledInputProps {
  isValid?: boolean;
  hasIcon?: boolean;
}

export const StyledInput = styled.input<IStyledInputProps>`
  height: 50px;
  border: 2px solid
    ${({ theme, isValid }) =>
    isValid ? theme.colors.labelLight : theme.colors.accent};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.black};
  font-size: 13px;
  padding-right: 20px;
  padding-left: ${({ hasIcon }) => (hasIcon ? 56 : 20)}px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.label};
  }
`;

export const InputIconLeft = styled.div`
  position: absolute;
  bottom: 10px;
  left: 16.5px;
`;

export const InputIconRightButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 12px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
`;
