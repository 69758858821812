import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconPlaneTakeoff = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(1.822 11.719)">
        <rect width="42.188" height="1.563" transform="translate(2.084 32.812)"/>
        <path 
        fill={props.color}          
        d="M48.454,17.319a4.294,4.294,0,0,0-5.793-1.835l-11.945,6.2-15.1-6.247-4.834,2.509L21.21,26.614,13.047,30.85l-6.7-2.684L2.333,30.248l7.31,7.371,15.672-3.453,21.3-11.055A4.3,4.3,0,0,0,48.454,17.319ZM45.9,21.725,24.777,32.685,10.147,35.908,4.938,30.657l1.491-.774,6.7,2.684,10.888-5.65L13.59,18.246l2.1-1.089,15.1,6.247,12.59-6.533A2.734,2.734,0,1,1,45.9,21.725Z" 
        transform="translate(-2.333 -15)"/>
      </g>
    </Svg>
  );
});