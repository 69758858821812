import moment from "moment";
import { useState } from "react";
import { IsVaidNumber } from "../../helpers/validation-helper";
import { IFamily, IFrequentFlyer, ILookUp, IPhone } from "../../models";

export interface IPartyDetailEdit {
    // contactId: number;
    contactId: string;
    // familyId: number;
    familyId: string;
    isNewParty: boolean;
    assignedToPartyId: number;
}
export interface IFormField<T> {
    field: T;
    isValid?: boolean;
    required?: boolean;
}
export interface IChangeForm {
    event?: React.ChangeEvent<Element>;
    field?: string | null;
    value?: any | null;
    key?: any | null;
    index?: any | null;
}

export interface IPartyDetailForm {
    title: IFormField<ILookUp>;
    firstName: IFormField<string>;
    lastName: IFormField<string>;
    dob: IFormField<any>;
    gender: IFormField<string>;
    passportNumber: IFormField<string>;
    passportIssueDate: IFormField<any>;
    passportExpiryDate: IFormField<any>;
    nationality: IFormField<ILookUp>;
    countryOfIssue: IFormField<ILookUp>;
    frequentFlyer: IFormField<IFrequentFlyer[]>;
    seatPreference: IFormField<ILookUp>;
    dietaryRequirement: IFormField<string>;
    flagMedicalRequest: IFormField<boolean>;
    roomBeddingRequest: IFormField<string>;
    insuranceCompany: IFormField<string>;
    insuranceContactNumber: IFormField<IPhone>;
    insurancePolicyNumber: IFormField<string>;
    emergencyContactName: IFormField<string>;
    emergencyPhone: IFormField<IPhone>;
    relationship: IFormField<ILookUp>;
    // insuranceCopyFamilyIDs: IFormField<number[]>;
    // emergencyContactCopyFamilyIDs: IFormField<number[]>;
    insuranceCopyFamilyIDs: IFormField<string[]>;
    emergencyContactCopyFamilyIDs: IFormField<string[]>;
    flagCopyEmergencyDetails: IFormField<boolean>;
    flagCopyInsuranceDetails: IFormField<boolean>;
}

export const isStringNullOrEmpty = (val: any): boolean => {
    return val === undefined || val === null || val === "";
}

export const getPartyDetailFormDefaultState = (props: IPartyDetailEdit, family: IFamily): IPartyDetailForm => {
    return props.isNewParty ?
        {
            title: { field: { id: 0, title: "" }, required: true, isValid: false },
            firstName: { field: "", required: true, isValid: false },
            lastName: { field: "", required: true, isValid: false },
            dob: { field: null, required: true, isValid: false },
            passportNumber: { field: "", required: false, isValid: true },
            passportIssueDate: { field: null, required: false, isValid: true },
            passportExpiryDate: { field: null, required: false, isValid: true },
            nationality: { field: { id: 0, title: "" }, required: false, isValid: true },
            countryOfIssue: { field: { id: 0, title: "" }, required: false, isValid: true },
            frequentFlyer: { field: [], required: false, isValid: true },
            seatPreference: { field: { id: 0, title: "" }, required: false, isValid: true },
            dietaryRequirement: { field: "", required: false, isValid: true },
            flagMedicalRequest: { field: false, required: false, isValid: true },
            roomBeddingRequest: { field: "", required: false, isValid: true },
            insuranceCompany: { field: "", required: false, isValid: true },
            insuranceContactNumber: { field: { phoneId: 0, phone: "", dialingCode: { id: 0, title: "" } }, required: false, isValid: true },
            insurancePolicyNumber: { field: "", required: false, isValid: true },
            emergencyContactName: { field: "", required: false, isValid: true },
            relationship: { field: { id: 0, title: "" }, required: true, isValid: false },
            gender: { field: "", required: true, isValid: false },
            emergencyPhone: { field: { phoneId: 0, phone: "", dialingCode: { id: 0, title: "" } }, required: false, isValid: true },
            insuranceCopyFamilyIDs: { field: [], required: false, isValid: true },
            emergencyContactCopyFamilyIDs: { field: [], required: false, isValid: true },
            flagCopyEmergencyDetails: { field: false, required: false, isValid: true },
            flagCopyInsuranceDetails: { field: false, required: false, isValid: true },
        }
        :
        {
            title: { field: { id: family.salutation?.id, title: family.salutation?.title }, required: true, isValid: !isStringNullOrEmpty(family.salutation) },
            firstName: { field: family.firstName, required: true, isValid: !isStringNullOrEmpty(family.firstName) },
            lastName: { field: family.lastName, required: true, isValid: !isStringNullOrEmpty(family.lastName) },
            relationship: { field: { title: family.relationship?.title, id: family.relationship?.id }, required: true, isValid: true },
            dob: { field: family.dob == null ? null : family.dob, required: true, isValid: !isStringNullOrEmpty(family.dob) },
            gender: { field: family.sex, required: true, isValid: true },
            passportNumber: { field: family?.passportNumber, required: false, isValid: true },
            passportExpiryDate: { field: family.passportExpiryDate == null ? null : family.passportExpiryDate, required: false, isValid: true },
            passportIssueDate: { field: family.passportIssueDate == null ? null : family.passportIssueDate, required: false, isValid: true },
            nationality: { field: { id: family.nationality?.id, title: family.nationality?.title }, required: false, isValid: true },
            countryOfIssue: { field: { id: family.countryOfIssue?.id, title: family.countryOfIssue?.title }, required: false, isValid: true },
            frequentFlyer: { field: family.frequentFlyer, required: false, isValid: true },
            seatPreference: { field: { id: family.seatPreference?.id, title: family.seatPreference?.title }, required: false, isValid: true },
            dietaryRequirement: { field: family.dietaryRequirement?.profile, required: false, isValid: true },
            flagMedicalRequest: { field: family.flagMedicalRequest, required: false, isValid: true },
            roomBeddingRequest: { field: family.roomBeddingRequest?.profile, required: false, isValid: true },
            insuranceCompany: { field: family.insurance?.insuranceCompany, required: false, isValid: true },
            insuranceContactNumber: { field: family.insurance?.insuranceContactNumber, required: false, isValid: true },
            insurancePolicyNumber: { field: family.insurance?.insurancePolicyNumber, required: false, isValid: true },
            emergencyContactName: { field: family.emergencyContactName != null ? family.emergencyContactName : "", required: false, isValid: true },
            emergencyPhone: { field: family.emergencyPhone, required: false, isValid: true },
            insuranceCopyFamilyIDs: { field: [], required: false, isValid: true },
            emergencyContactCopyFamilyIDs: { field: [], required: false, isValid: true },
            flagCopyEmergencyDetails: { field: false, required: false, isValid: true },
            flagCopyInsuranceDetails: { field: false, required: false, isValid: true },
        };
}

export const useForm = (initialState: IPartyDetailForm) => {
    const [formData, setFormData] = useState<IPartyDetailForm>(initialState)
    const handleInputChange = (e: IChangeForm): void => {
        if ((e.event === undefined || e.event == null) && e.field !== null) {
            setFormData({
                ...formData, [e.field ?? ""]:
                {
                    field: e.value,
                    isValid: e.value !== "" || e.value !== null
                }
            })
        } else {
            const target = e.event?.target as HTMLTextAreaElement;

            setFormData({
                ...formData, [target.name]:
                    { field: target.value, isValid: target.value !== "" }
            })
        }
    }

    const passportDatesValidation = (): boolean => {

        if ((formData.passportIssueDate.field as Date) === null || (formData.passportExpiryDate.field as Date) === null) return true;
        if (formData.passportIssueDate.field === undefined || formData.passportExpiryDate.field === undefined) return true;

        const issueDate = moment((formData.passportIssueDate.field as Date)).toDate();
        const expiryDate = moment((formData.passportExpiryDate.field as Date)).toDate();
        const isValidDates: boolean = moment(issueDate).isBefore(moment(expiryDate));

        return isValidDates;
    }

    const setEmergencyPhoneValidation = (): boolean => {
        if (formData.emergencyPhone.field !== undefined) {
            let isValidPhone = true;
            if (!isStringNullOrEmpty(formData.emergencyPhone?.field?.phone)) {
                // phone number entered by not a valid length
                if (formData.emergencyPhone.field.phone.trim().length > 0 && formData.emergencyPhone.field.phone.length < 6) {
                    isValidPhone = false;
                }
                // valid phone number enterd but not selected dialing code
                else if (formData.emergencyPhone.field.phone.trim().length > 5 &&
                    ((formData.emergencyPhone.field.dialingCode === undefined || formData.emergencyPhone.field.dialingCode === null) ||
                        (formData.emergencyPhone.field.dialingCode && formData.emergencyPhone.field.dialingCode.id === 0)
                    )
                ) {
                    isValidPhone = false;
                }
                // country code selected but no phone number entered
                else if (formData.emergencyPhone.field.dialingCode && formData.emergencyPhone.field.dialingCode.id > 0 && formData.emergencyPhone.field.phone.trim().length === 0) {
                    isValidPhone = false;
                }

            }
            else if (!isStringNullOrEmpty(formData.emergencyPhone?.field?.dialingCode?.title)) {
                if (formData.emergencyPhone.field.dialingCode.id > 0 && (formData.emergencyPhone.field.phone === null || formData.emergencyPhone.field.phone.trim().length === 0)) {
                    isValidPhone = false;
                }
            }
            // else if (isStringNullOrEmpty(formData.emergencyPhone?.field?.dialingCode) || isStringNullOrEmpty(formData.emergencyPhone?.field?.phone)) {
            //     isValidPhone = false;
            // }

            setFormData({
                ...formData, ['emergencyPhone']: { field: { ...formData.emergencyPhone.field }, isValid: isValidPhone }
            })
            return isValidPhone;
        }
        return true;
    }

    const handleEmergencyPhoneChange = (e: IChangeForm): void => {
        if (formData.emergencyPhone.field === undefined) {
            let phone: IPhone = {
                phoneId: 0,
                phone: e.key === "phoneNumber" && IsVaidNumber(e.value) ? e.value : "",
                dialingCode: e.key === "dialingCode" ? e.value : {
                    id: 0,
                    title: ''
                }
            }
            setFormData({
                ...formData, [e.field ?? ""]:
                    { field: phone, isValid: true }
            })
        }
        else {
            let emergencyPhone = formData.emergencyPhone.field;
            if (e.key === "phoneNumber" && IsVaidNumber(e.value)) {
                emergencyPhone = {
                    ...formData.emergencyPhone.field,
                    phone: e.value
                }
            }
            if (e.key === "dialingCode"){
                emergencyPhone = {
                    ...formData.emergencyPhone.field,
                    dialingCode: e.value
                }
            }
            setFormData({
                ...formData, [e.field ?? ""]: { field: emergencyPhone, isValid: true }
            })
        }
    }

    const handleFrequentFlyerChange = (e: IChangeForm): void => {
        //grab it from state//create obj push else update.
        if (formData.frequentFlyer.field.length > 0) {
            let airline = formData.frequentFlyer.field.map(i => {
                return i.id === e.index ? {
                    ...i,
                    [e.key]: e.value,
                } : { ...i }
            });
            setFormData({
                ...formData, [e.field ?? ""]:
                    { field: airline, isValid: true }
            });
        } else {
            const flight: Array<IFrequentFlyer> = [
                {
                    id: e.index,
                    cardNumber: e.key === "cardNumber" ? e.value : null,
                    cardType: e.key === "cardType" ? e.value : null
                }];
            setFormData({
                ...formData, [e.field ?? ""]:
                    { field: flight, isValid: true }
            });
        }
    }

    const handleDropdownChange = (e: IChangeForm): void => {
        if ((e.event === undefined || e.event == null) && e.field !== null && e.key != null && e.value != null) {
            setFormData({
                ...formData, [e.field ?? ""]:
                {
                    field: { id: e.key, title: e.value },
                    isValid: e.value !== "" || e.value !== null
                }
            })
        }
    }

    const onSelectHandleInsurance = (selectedList: any, selectedItem: any): void => {
        const updatedSelectedKeys = selectedList.map((item: any) => item.id);
        // Update the insuranceCopyFamilyIDs in your form state
        setFormData({
            ...formData,
            ['insuranceCopyFamilyIDs']: { field: updatedSelectedKeys, isValid: true }
        })
    };

    const onSelectHandleEmergencyContact = (selectedList: any, selectedItem: any): void => {
        const updatedSelectedKeys = selectedList.map((item: any) => item.id);
        // Update the insuranceCopyFamilyIDs in your form state
        setFormData({
            ...formData,
            ['emergencyContactCopyFamilyIDs']: { field: updatedSelectedKeys, isValid: true }
        })
    };

    const handleCopyInsuranceChk = (e: boolean): void => {
        setFormData({
            ...formData,
            ['insuranceCopyFamilyIDs']: { field: [], isValid: true },
            ['flagCopyInsuranceDetails']: {field: e, isValid: true}
        })
    }

    const handleCopyEmergencyContactChk = (e: boolean): void => {
        setFormData({
            ...formData,
            ['emergencyContactCopyFamilyIDs']: { field: [], isValid: true },
            ['flagCopyEmergencyDetails']: {field: e, isValid: true}
        })
    }

    const setInsurancePhoneValidation = (): boolean => {
        
        if (formData.insuranceContactNumber.field !== undefined) {
            let isValidPhone = true;
            if (!isStringNullOrEmpty(formData.insuranceContactNumber?.field?.phone)) {
                // phone number entered by not a valid length
                if (formData.insuranceContactNumber.field.phone.trim().length > 0 && formData.insuranceContactNumber.field.phone.length < 6) {
                    isValidPhone = false;
                }
                // valid phone number enterd but not selected dialing code
                else if (formData.insuranceContactNumber.field.phone.trim().length > 5 &&
                    ((formData.insuranceContactNumber.field.dialingCode === undefined || formData.insuranceContactNumber.field.dialingCode === null) ||
                        (formData.insuranceContactNumber.field.dialingCode && formData.insuranceContactNumber.field.dialingCode.id === 0)
                    )
                ) {
                    isValidPhone = false;
                }
                // country code selected but no phone number entered
                else if (formData.insuranceContactNumber.field.dialingCode && formData.insuranceContactNumber.field.dialingCode.id > 0 && formData.insuranceContactNumber.field.phone.trim().length === 0) {
                    isValidPhone = false;
                }

            }
            else if (!isStringNullOrEmpty(formData.insuranceContactNumber?.field?.dialingCode?.title)) {
                if (formData.insuranceContactNumber.field.dialingCode.id > 0 && (formData.insuranceContactNumber.field.phone === null || formData.insuranceContactNumber.field.phone.trim().length === 0)) {
                    isValidPhone = false;
                }
            }
            // else if (isStringNullOrEmpty(formData.insuranceContactNumber?.field?.dialingCode) || isStringNullOrEmpty(formData.insuranceContactNumber?.field?.phone)) {
            //     isValidPhone = false;
            // }

            setFormData({
                ...formData, ['insuranceContactNumber']: { field: { ...formData.insuranceContactNumber.field }, isValid: isValidPhone }
            })
            return isValidPhone;
        }
        return true;
    }

    const handleInsurancePhoneChange = (e: IChangeForm): void => {
        if (formData.insuranceContactNumber.field === undefined) {
            let phone: IPhone = {
                phoneId: 0,
                phone: e.key === "phoneNumber" && IsVaidNumber(e.value) ? e.value : "",
                dialingCode: e.key === "dialingCode" ? e.value : {
                    id: 0,
                    title: ''
                }
            }
            setFormData({
                ...formData, [e.field ?? ""]:
                    { field: phone, isValid: true }
            })
        }
        else {
            let insuranceContactNumber = formData.insuranceContactNumber.field;
            if (e.key === "phoneNumber" && IsVaidNumber(e.value)) {
                insuranceContactNumber = {
                    ...formData.insuranceContactNumber.field,
                    phone: e.value
                }
            }
            if (e.key === "dialingCode"){
                insuranceContactNumber = {
                    ...formData.insuranceContactNumber.field,
                    dialingCode: e.value
                }
            }
            setFormData({
                ...formData, [e.field ?? ""]: { field: insuranceContactNumber, isValid: true }
            })
        }
    }

    return { 
        formData,
        handleInputChange,
        handleFrequentFlyerChange,
        handleDropdownChange,
        handleEmergencyPhoneChange,
        setEmergencyPhoneValidation,
        passportDatesValidation,
        onSelectHandleInsurance,
        onSelectHandleEmergencyContact,
        handleCopyEmergencyContactChk,
        handleCopyInsuranceChk,
        setInsurancePhoneValidation,
        handleInsurancePhoneChange
    };
}

export const GetDays = (selectedMonth: string) => {
    const dayCollection: Array<string> = [];
    const daysInMonth = moment().month(selectedMonth).daysInMonth();
    for (var i = 1; i < daysInMonth + 1; i++) {
        dayCollection.push(i.toString())
    }
    return dayCollection;
}

export const GetYears = (maxYear: number, futureYears: boolean) => {
    const yearCollection: Array<string> = [];
    for (var i = 0; i < maxYear; i++) {
        if (futureYears === true) {
            yearCollection.push((moment().year() + i).toString());
        } else {
            yearCollection.push((moment().year() - i).toString());
        }
    }
    return yearCollection;
}