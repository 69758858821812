import React, {
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import styled, { ThemeContext } from "styled-components";
  import { useWindowSize } from "../../helpers/useWindowSize";
  
  export interface PagedCarouselProps {
    children: JSX.Element[];
    autoplay?: boolean;
    autoplayTime?: number;
  }
  
  export const PagedCarousel = memo<PagedCarouselProps>(
    ({ children, autoplay, autoplayTime = 1000 }) => {
      const [scrollIdx, setScrollIdx] = useState(0);
      const { breakpoints } = useContext(ThemeContext);
      const windowWidth = useWindowSize().width;
      const scrollRef = useRef(null);
      const intervalRef = useRef<any>();
  
      const isMobile = useMemo(() => {
        return windowWidth <= breakpoints.tablet;
      }, [windowWidth, breakpoints]);
  
      const isEnd = useMemo(() => {
          return !(scrollIdx < children.length);
      }, [scrollIdx, children]);
  
      useEffect(() => {
        if (autoplay) {
          intervalRef.current = setInterval(() => {
            setScrollIdx((prev) => prev + 1);
          }, autoplayTime);
        }
        return () => {
          clearInterval(intervalRef.current);
        };
      }, [autoplay, autoplayTime]);
  
      useEffect(() => {
        if (isEnd && intervalRef.current) {
            setScrollIdx(0);
        }
      }, [isEnd]);
  
      const renderItemsCallback = useCallback(
        (item: JSX.Element, idx: number): JSX.Element =>
          React.cloneElement(item, { key: idx, isMobile }),
        [isMobile]
      );
  
      return (
        <Container>
          <StyledCarousel
            ref={scrollRef}
            scrollIdx={scrollIdx}
            isMobile={isMobile}
          >
            {children.map(renderItemsCallback)}
          </StyledCarousel>
          <DiamondContainer>
          {[...Array(children.length)].map((e, i) => 
            <Diamond scrollIdx={scrollIdx} i={i} key={i}>♦</Diamond>)
          }
          </DiamondContainer>
        </Container>
      );
    }
  );
  
  const Container = styled.div`
    position: relative;
    width: 100%;
  `;

const DiamondContainer = styled.div`
    text-align: center;
`;

interface IDiamondProps {
    scrollIdx: number;
    i: number;
}

const Diamond = styled.span<IDiamondProps>`
    color: ${({ scrollIdx, i, theme }) => scrollIdx === i ? theme.colors.accentLight : theme.colors.primary};
`;
  
interface StyledCarouselProps {
    scrollIdx: number;
    isMobile: boolean;
}
  
  const StyledCarousel = styled.ul<StyledCarouselProps>`
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    height: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
    transform: translateX(
      ${({ scrollIdx, isMobile }) => -scrollIdx * (isMobile ? 100 : 100)}%
    );
    transition: transform 300ms;
  `;
   
  interface CarouselPageProps {
    isMobile?: boolean;
  }
  
  export const CarouselPage = styled.li<CarouselPageProps>`
    display: block;
    height: 100%;
    width: ${({ isMobile }) => (isMobile ? 100 : 100)}%;
    min-width: ${({ isMobile }) => (isMobile ? 100 : 100)}%;
  `;
  
  