
import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import {  fetchHolidayError, fetchHolidays, holidayStateActions } from './holiday-actions';

export const deleteRetrieveHolidayEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.DELETE_RETRIEVE_HOLIDAY),
        mergeMap((action) => {
            let { bookingId, contactId}  = action.payload;
            return api.delete(endPointKeys.api_travel, `Booking/MSD/DeleteHolidaySharing/${contactId}/${bookingId}`).pipe(
                map(res => res.data),
                mergeMap((m : any) => { 
                    return of(fetchHolidays(contactId));
                }),
                catchError((error) => {
                    const msg = error.response && error.response.data ? error.response.data.message: "Failed to delete holiday";
                    const appError: IAppError = { error, message:msg };
                    sendToast(appError.message, ToastType.Error);
                    return of(fetchHolidayError(appError));
                }))
        })
    )