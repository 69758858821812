import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconConcierge = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
    <rect fill="none" width="50" height="50"/>
    <g transform="translate(6.25 3.906)">
        <path 
        fill={props.color}
        d="M15.906,49a3.906,3.906,0,1,0,3.906,3.906A3.911,3.911,0,0,0,15.906,49Zm0,6.25a2.344,2.344,0,1,1,2.344-2.344A2.346,2.346,0,0,1,15.906,55.25Z" 
        transform="translate(-8.875 -14.625)"/>
        <path 
        fill={props.color}
        d="M45.906,49a3.906,3.906,0,1,0,3.906,3.906A3.911,3.911,0,0,0,45.906,49Zm0,6.25a2.344,2.344,0,1,1,2.344-2.344A2.346,2.346,0,0,1,45.906,55.25Z" 
        transform="translate(-15.438 -14.625)"/>
        <path 
        fill={props.color}
        d="M42.375,36.25V22.969c0-8.03-4.38-12.441-13-13.163a3.125,3.125,0,1,0-5.252,0c-8.619.722-13,5.133-13,13.163V36.25H8v1.563H45.5V36.25ZM25.188,8.125A1.563,1.563,0,1,1,26.75,9.688,1.564,1.564,0,0,1,25.188,8.125ZM12.688,36.25V22.969c0-7.885,4.6-11.719,14.063-11.719s14.063,3.833,14.063,11.719V36.25Z" 
        transform="translate(-8 -5)"/>
        <path 
        fill={props.color}
        d="M36.625,26.125H33.5V24.563A1.564,1.564,0,0,0,31.938,23H27.25a1.564,1.564,0,0,0-1.562,1.563v1.563H22.563A1.564,1.564,0,0,0,21,27.688v9.375a1.564,1.564,0,0,0,1.563,1.563H36.625a1.564,1.564,0,0,0,1.563-1.562V27.688A1.564,1.564,0,0,0,36.625,26.125ZM27.25,24.563h4.688v1.563H27.25Zm-4.687,12.5V27.688H36.625v9.375Z" 
transform="translate(-10.844 -8.938)"/>
</g>
    </Svg>
  );
});