

export const USDictionary = [
{Key:"queries", Value: "questions"},
{Key:"minimise", Value: "minimize"},
{Key:"Caramelise", Value: "Caramelize"},
{Key:"caramelise", Value: "caramelize"},
{Key:"customise", Value: "customize"},
{Key:"harmonise", Value: "harmonize"},
{Key:"tailormade", Value: "customized"},
{Key:"mediaeval", Value: "medieval"},
{Key:"Colour", Value: "Color"},
{Key:"yoghurt", Value: "yogurt"},
{Key:"speciality", Value: "specialty"},
{Key:"Bank Holidays", Value: "Bank Holidays"},
{Key:"appetiser", Value: "appetizer"},
{Key:"snorkelling", Value: "snorkeling"},
{Key:"harbour", Value: "harbor"},
{Key:"amphitheatre", Value: "amphitheater"},
{Key:"Customised", Value: "Customized"},
{Key:"customised", Value: "customized"},
{Key:"artefact", Value: "artifact"},
{Key:"BodyVacation", Value: "BodyHoliday"},
{Key:"prioritise", Value: "prioritize"},
{Key:"labourer", Value: "laborer"},
{Key:"programme", Value: "program"},
{Key:"capitalise", Value: "capitalize"},
{Key:"bank holidays", Value: "bank holidays"},
{Key:"learnt", Value: "learned"},
{Key:"civilisation", Value: "civilization"},
{Key:"specialities", Value: "specialties"},
{Key:"coloured", Value: "colored"},
{Key:"traveller", Value: "traveler"},
{Key:"doughnut", Value: "donut"},
{Key:"jewellery", Value: "jewelry"},
{Key:"specialises", Value: "specializes"},
{Key:"flavour", Value: "flavor"},
{Key:"specialising", Value: "specializing"},
{Key:"mesmerise", Value: "mesmerize"},
{Key:"personalised", Value: "personalized"},
{Key:"humour", Value: "humor"},
{Key:"authorise", Value: "authorize"},
{Key:"travelled", Value: "traveled"},
{Key:"metre", Value: "meter"},
{Key:"Savour", Value: "Savor"},
{Key:"neighbour", Value: "neighbor"},
{Key:"favourite", Value: "favorite"},
{Key:"Favourite", Value: "Favorite"},
{Key:"organise", Value: "organize"},
{Key:"colour", Value: "color"},
{Key:"savoury", Value: "savory"},
{Key:"specialise", Value: "specialize"},
{Key:"programmes", Value: "programs"},
{Key:"theatre", Value: "theater"},
{Key:"query", Value: "question"},
{Key:"holiday", Value: "vacation"},
{Key:"marvellous", Value: "marvelous"},
{Key:"tailoring", Value: "customizing"},
{Key:"tailor-made", Value: "customized"},
{Key:"Motorise", Value: "Motorize"},
{Key:"utilise", Value: "utilize"},
{Key:"epitomise", Value: "epitomize"},
{Key:"visualise", Value: "visualize"},
{Key:"characterise", Value: "characterize"},
{Key:"motorise", Value: "motorize"},
{Key:"travelling", Value: "traveling"},
{Key:"optimise", Value: ""},
{Key:"spoilt", Value: "spoiled"},
{Key:"Query", Value: "Question"},
{Key:"memorise", Value: "memorize"},
{Key:"Travelling", Value: "Traveling"},
{Key:"Holiday", Value: "Vacation"},
{Key:"personalise", Value: "personalize"},
{Key:"honourable", Value: "honorable"},
{Key:"Humour", Value: "Humor"},
{Key:"maximise", Value: "maximize"},
{Key:"civilise", Value: "civilize"},
{Key:"organisation", Value: "organization"},
{Key:"Queries", Value: "Questions"},
{Key:"Father Christmas", Value: "Santa"},
{Key:"Autumn", Value: "Fall"},
{Key:"aeroplane", Value: "airplane"},
{Key:"socialise", Value: "socialize"},
{Key:"Specialising", Value: "Specializing"},
{Key:"Civilisation", Value: "Civilization"},
{Key:"Traveller", Value: "Traveler"},
{Key:"Colours", Value: "Colors"},
{Key:"petrol", Value: "gas"},
{Key:"colours", Value: "colors"},
{Key:"Honourable", Value: "Honorable"},
{Key:"Centre", Value: "Center"},
{Key:"centre", Value: "center"},
{Key:"Personalise", Value: "Personalize"}
];
