import React from "react";
import styled from "styled-components";

interface INumericStepperProps{
  title : string;
  count : number;
  className?: string;
  onIncrement(): void;
  onDecrement(): void;
}

export const NumericStepper = (props: INumericStepperProps) =>{
    return (
        <Container className={props.className}>
            <TextWrapepr>{props.title}</TextWrapepr>
            <IconWrapepr onClick={props.onDecrement}>
               -
            </IconWrapepr>            
            <Count>{props.count}</Count>
            <IconWrapepr onClick={props.onIncrement}>
           +
            </IconWrapepr>
        </Container>
    )
}

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  overflow: hidden;
  margin: 15px 0px;
`;

const TextWrapepr = styled.div`
  display: flex;
  cursor: pointer;
  width : 35%;
`;

const Count = styled.div`
display: flex;
padding: 2px 0px;
`;

const IconWrapepr = styled.div`
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 3px solid ${({ theme: { colors } }) => colors.labelLight};
  margin: 0px 10px;
  align-items: center; 
  justify-content: center;
`;