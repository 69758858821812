import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Carousel, CarouselItem, ConsultantBox, InfoLockup, PartyAssign } from "../..";
import { GetCloudinaryImage } from "../../../helpers/getCloudinaryImages";
import { IHoliday } from "../../../models";
import routes from "../../../routing/routes";
import { routeChange } from "../../../store/session";
import { IconTypes } from "../../icons/icon-component";
import { Checkbox, CheckboxType } from "../../checkbox";
import { Body2 } from "../../typography";
import { updateBookGDPR } from "../../../store/holiday";
import { IAppState } from "../../../store/app-state";

export interface IHolidayOverviewProps {
    holiday: IHoliday;
}

export const HolidayOverview = ({ holiday }: IHolidayOverviewProps) => {

    const [isGDPRChecked, setisGDPRChecked] = useState<boolean>(holiday.flagGDPR);
    // const leadContact: number = useSelector((state: IAppState) => state.session.appUser ? state.session.appUser.id : 0);
    // const isHolidayLead: boolean = holiday.contactId === leadContact;
    const leadContact: string = useSelector((state: IAppState) => state.session.appUser ? state.session.appUser.id : "");
    const isHolidayLead: boolean = holiday.contactId === leadContact;

    const dispatch = useDispatch();
    const onRequestAmendment = () => {
        dispatch(routeChange(routes.getInTouchHolidayRequest('Amend', holiday && holiday.bookId ? holiday.bookId.toString() : '')));
    }

    const onCancelHoliday = () => {
        dispatch(routeChange(routes.getInTouchHolidayRequest('Cancel', holiday && holiday.bookId ? holiday.bookId.toString() : '')));
    }

    return (
        <React.Fragment>
            <CarouselContainer>
                <Carousel autoplayTime={500}>
                    {holiday.images.map(image =>
                        <StyledCarouselItem url={GetCloudinaryImage(image.toString(), true)} key={'image-' + image.toString()} />
                    )}
                </Carousel>
            </CarouselContainer>
            <TextWrapper dangerouslySetInnerHTML={{ __html:holiday.primaryCountrySynopsis}}></TextWrapper>
            <OverviewLayout>
                <LockupContainer>
                    <StyledInfoLockup icon={IconTypes.IconMap} label="Destination" text={holiday.bookName} />
                    <StyledInfoLockup icon={IconTypes.IconCalendar} label="Departing" text={moment(holiday.startDate, "DD/MM/YYYY").format("ddd, DD MMM YYYY")} />
                    <StyledInfoLockup icon={IconTypes.IconCalendar} label="Returning" text={moment(holiday.endDate, "DD/MM/YYYY").format("ddd, DD MMM YYYY")
                        + ` (${holiday.nights} night${Number(holiday.nights) > 1 ? 's' : ''})`} />
                    <StyledInfoLockup icon={IconTypes.IconFemaleUser} label="Party Leader" text={holiday.partyLeader} />
                    <PartyAssign />
                    <StyledInfoLockup icon={IconTypes.IconAirplaneTicket} label="Reference" text={holiday.bookId.toString()} />
                </LockupContainer>
                <ConsultantLayout>
                    <ConsultantBox
                        profileImage={GetCloudinaryImage(holiday.consultant.imageId, false, 120, 120)}
                        name={holiday.consultant.name}
                        onAmendmentCallback={onRequestAmendment}
                        onCancelHolidayCallback={onCancelHoliday}
                        downloadText="Download booking Pdf"
                    />
                    {isHolidayLead === true &&
                        <GDPRContainer>
                            <CheckboxContainer>
                                <Checkbox type={CheckboxType.SECONDARY} isChecked={isGDPRChecked} onClick={
                                    (e) => {
                                        setisGDPRChecked(e);
                                        dispatch(updateBookGDPR({
                                            bookId: holiday.bookId,
                                            flagGDPR: e,
                                        }))
                                    }}></Checkbox>
                            </CheckboxContainer>
                            <StyledBody2>By ticking this box you’re confirming you give consent for any listed passengers travelling on the holiday to discuss or make amendments.</StyledBody2>
                        </GDPRContainer>
                    }
                </ConsultantLayout>
            </OverviewLayout>
        </React.Fragment>
    )
}

declare interface ICarouselItem {
    url: string;
}

const CarouselContainer = styled.div`
    margin-right: 15px;
`;

const StyledCarouselItem = styled(CarouselItem) <ICarouselItem>`
    background-image: url(${({ url }) => url});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: 200px;
`;

const OverviewLayout = styled.div`
    display: flex;
    flex-flow: column nowrap;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        flex-flow: row nowrap;
    }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 10%;
  padding-left: 8px;
  align-items: center;
`;

const GDPRContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;


const StyledBody2 = styled(Body2)`
  color: ${({ theme }) => theme.colors.dark};
  font-size: 12px;
  padding-right: 12px;
  padding-left: 4px;
  width: 90%;
`;

const LockupContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-left: 20px;
    margin-right: 15px;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: 50%;
    }
`;

const StyledInfoLockup = styled(InfoLockup)`
    margin-bottom: 10px;
    width: 100%;
`;

const ConsultantLayout = styled.div`
    margin-bottom: 10px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: 50%;
    }
`;

const TextWrapper = styled.div`
    padding: 20px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-character;
    font-size: 14px;
`;