import { IAppUser } from "../../models";

export interface ISessionState {
  appUser: IAppUser | null;
  isMobileMenuOpen: boolean;
  resetLogin: boolean;
  isProcessing: boolean;
  updatePasswordSuccess: boolean;
  processingLoginType: string;
  tabs: {
    quote: number;
    booking: number;
    quotes: number;
    bookings: number;
  }
  isUserAlreadyExist: boolean;
}

  export const defaultAppUserState: IAppUser | null = null;
  
  const defaultState : ISessionState = {
    appUser: defaultAppUserState,
    isMobileMenuOpen: false,
    resetLogin: false,
    isProcessing: false,
    updatePasswordSuccess: false,
    processingLoginType: '',
    tabs: { 
      quote: 0,
      booking: 0,
      quotes: 0,
      bookings: 0
    },
    isUserAlreadyExist: false
  };
  
  export const getDefaultSessionState = (options?: any) => {
    return {
      ...defaultState,
      ...options
    };
  };