import * as React from "react";
import styled, { ThemeContext } from "styled-components";
import { QuoteTileBadgeTypes, Tab } from "../components";
import { ImageBackground } from "../components/image-background/image-background";
import { Body2, Headline3 } from "../components/typography";
import TextComponent from "../components/typography/text/text";
import { useContext } from "react";
import { IAppUser, IHolidaySummary } from "../models";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../store/app-state";
import { BookedBookingSubStatus } from "../helpers/datasource-helper";
import { isFutureDate } from "../helpers/date-helper";
import { HolidayTiles } from "../components/holidays";
import { Loader } from "../components/loader";
import { selectTab } from "../store/session";
import { useHistory } from "react-router-dom";
import { fetchHolidays } from "../store/holiday";
import { RetrieveHolidayPage } from "../components/retrieve-holiday/retrive-holiday-page";
import { SDPImageBackground } from "../components/image-background/image-sdp-background";

export const MyBookingsPage = () => {
  const { colors } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );
  const holidays: IHolidaySummary[] = useSelector(
    (state: IAppState) => state.holidays.holidays
  );
  const isProcessing: boolean = useSelector(
    (state: IAppState) => state.holidays.isProcessing
  );

  const bookings: IHolidaySummary[] = holidays
    ? holidays.filter((x) =>
        BookedBookingSubStatus().includes(x.bookSubStatusId)
      )
    : [];
  const futureBookings: IHolidaySummary[] = bookings
    ? bookings.filter((x) => isFutureDate(x.endDate, "DD/MM/YYYY"))
    : [];
  const pastBookings: IHolidaySummary[] = bookings
    ? bookings.filter((x) => !isFutureDate(x.endDate, "DD/MM/YYYY"))
    : [];

  React.useEffect(() => {
    if (appUser) {
      dispatch(fetchHolidays(appUser.id));
    }
  }, [appUser, dispatch]);
  const setTab = React.useCallback(
    (tab: number) => {
      dispatch(selectTab("bookings", tab));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (history.action !== "POP") {
      setTab(0);
    }
  }, [setTab, history]);

  const tabsSelected: any = useSelector(
    (state: IAppState) => state.session.tabs
  );

  const getTab = () => {
    const tabSelected = tabsSelected.bookings;
    return tabSelected ? tabSelected : 0;
  };

  return (
    <PageWrapper>
      {appUser?.isSDP ? (
        <SDPImageBackground url="/images/SDPBackgroundImagePattern.png" />
      ) : (
        <ImageBackground url="/images/backgroundImagePattern.gif" />
      )}
      <ContentLayout>
        <StyledHeadline3>My Bookings</StyledHeadline3>
        <StyledTextComponent>
          Here you can view all your bookings past and present.
        </StyledTextComponent>
        <TabContainer>
          <Tab
            initialIndex={getTab()}
            elements={[
              "Live Bookings",
              "Previous Bookings",
              "Retrieve Booking",
            ]}
            color={colors.white}
            size={14}
            onChange={(idx: number) => setTab(idx)}
          />
        </TabContainer>
        {isProcessing ? (
          getTab() !== 2 && <Loader />
        ) : (
          <>
            {getTab() === 0 && (
              <HolidayTiles
                holidays={futureBookings}
                badgeType={QuoteTileBadgeTypes.BOOK}
                isLiveHoliday={true}
                isQuote={false}
              />
            )}
            {getTab() === 1 && (
              <HolidayTiles
                holidays={pastBookings}
                badgeType={QuoteTileBadgeTypes.BOOK}
                isLiveHoliday={false}
                isQuote={false}
              />
            )}
            {getTab() === 2 && (
              <>
                {" "}
                <RetrieveHolidayPage isBooking />
              </>
            )}
            {getTab() === 0 && futureBookings.length > 0 && (
              <StyledBody2>
                Total live bookings ({futureBookings.length})
              </StyledBody2>
            )}
            {getTab() === 1 && pastBookings.length > 0 && (
              <StyledBody2>
                Total previous bookings ({pastBookings.length})
              </StyledBody2>
            )}
          </>
        )}
      </ContentLayout>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: calc(100% - 10px);
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 800px;
    max-width: inherit;
  }
`;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
`;

const StyledTextComponent = styled(TextComponent)`
  color: ${({ theme }) => theme.colors.white};
`;

const TabContainer = styled.div`
  top: 20px;
  bottom: 50px;
  max-width: 425px;
`;
const StyledBody2 = styled(Body2)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 10px;
  margin-left: 10px;
`;
