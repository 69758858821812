import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconFoot = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
    <g transform="translate(-182 -1690)">
        <rect fill="none"  width="50" height="50" transform="translate(182 1690)"/>
        <path 
        fill={props.color}  
        d="M3.53,50a1.582,1.582,0,0,1-1.276-.741,5.747,5.747,0,0,1,.053-6.047l3.6-5.634,1.12-4.7.01.013a11.816,11.816,0,0,1,1.223,1.731l-.872,3.659-3.754,5.88a3.962,3.962,0,0,0-.045,4.16l6.429-8.744.363-1.117,1.081,1.876-.02.06-6.6,8.945A1.57,1.57,0,0,1,3.575,50Zm13.62-2.965c-1.056-1.917-2.619-4.625-4-7.014-1.567-2.714-2.92-5.058-3.244-5.743a11.723,11.723,0,0,0-1.659-2.489,6.872,6.872,0,0,1-1.97-4.944,8.872,8.872,0,0,1-3.145.264C1,26.8-.161,25.135.018,22.647A14.334,14.334,0,0,1,4.385,14.6c2.338-1.989,7.682-4.079,7.908-4.167l.009.026a4.627,4.627,0,0,1,2.2-.3,4.192,4.192,0,0,1,3.719,4.063c.15,1.563.406,5.83.513,6.486a80.114,80.114,0,0,0,3.085,7.643.869.869,0,0,1-.383,1.128.781.781,0,0,1-1.063-.406,78.189,78.189,0,0,1-3.215-8.076c-.208-1.284-.27-3.948-.527-6.6a2.593,2.593,0,0,0-2.3-2.552,2.934,2.934,0,0,0-3.158,1.987c-1.19,3.829-2.643,8.963-2.99,10.566-.806,3.722.022,4.745,1.276,6.294a13.262,13.262,0,0,1,1.877,2.834c.293.621,1.695,3.047,3.177,5.617,1.384,2.4,2.952,5.112,4.017,7.043A4.015,4.015,0,0,0,22.02,48.3L13.233,27.194c.722-2.381,1.487-4.919,2.2-7.311a9.137,9.137,0,0,0,.714,3.227c-.532,1.774-1.008,3.347-1.2,3.99l8.564,20.581a1.805,1.805,0,0,1-.181,1.6,1.573,1.573,0,0,1-1.3.715A5.606,5.606,0,0,1,17.151,47.034ZM5.386,15.917a12.7,12.7,0,0,0-3.773,6.86c-.115,1.6.453,2.467,1.735,2.65a8.4,8.4,0,0,0,3.088-.371c.363-2.336,2.516-9.484,3.18-11.627A21.4,21.4,0,0,0,5.386,15.917ZM11.928,4.67A4.551,4.551,0,0,1,16.339,0,4.551,4.551,0,0,1,20.75,4.67a4.551,4.551,0,0,1-4.411,4.669A4.551,4.551,0,0,1,11.928,4.67Zm1.6,0a2.9,2.9,0,0,0,2.812,2.974A2.9,2.9,0,0,0,19.151,4.67,2.9,2.9,0,0,0,16.339,1.7,2.9,2.9,0,0,0,13.527,4.67Z" 
        transform="translate(195.194 1690)"/>
      </g>
    </Svg>
  );
});