import React from "react";
import styled from "styled-components";
import { IconAirplaneTicket } from "../icons";
import TextComponent from '../../components/typography/text/text';
import Button, { ButtonType } from "../button";
import { useDispatch } from "react-redux";
import { resetSuccessMessage } from "../../store/get-in-touch";

export const GetInTouchSuccess = () => {
    const dispatch = useDispatch();
    const OnSubmit = () =>{
        dispatch(resetSuccessMessage());
    }

    return (
        <Container>
            <StyledIcon size={80}/>
           <StyledTextComponent> Success! Your enquiry has been sent to the Scott Dunn team and a member of the team will be in touch shortly.
           </StyledTextComponent>

           <StyledButton type={ButtonType.MAIN} text="New Enquiry" onClick={OnSubmit}  />
        </Container>
    );
}

const Container = styled.div`
display: flex;
flex-flow: column;
height:300px;
background-color: ${({ theme }) => theme.colors.white};
justify-content: center;
`;

const StyledIcon = styled(IconAirplaneTicket)`
color: ${({ theme }) => theme.colors.primary};
margin: 20px auto;
`;

const StyledTextComponent = styled(TextComponent)`
color: ${({ theme }) => theme.colors.primaryDark};
text-align: center;
font-size: 15px;
`;

const StyledButton = styled(Button)`
width: 150px;
height: 40px;
margin: 10px auto;
`;