import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { GetPartyStatusId } from '../../helpers/party-helper';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { IApiError, IBookParty, IEpicDependency, IFamily } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { contactStateActions, fetchContactFamilyDetails, showDialogBox, updateFamilyDetailsError, updateFamilyDetailsSuccess,assignNewFamilyToBookParty } from '../contact/contact-actions';
import { updateBookParty } from '../holiday';



export const updateFamilyDetailsEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(contactStateActions.UPDATE_FAMILY_DETAILS),
        mergeMap(action => {
            const familyPayload: IFamily = action.payload.family;

            const assignFamilyToBookParty = state$.value.contact.assignNewFamilyToBookParty;

            var data = JSON.stringify(familyPayload);
            return api.post(endPointKeys.api_contact, 'family/savefamily', data).pipe(
                map(result => result.data),
                mergeMap((familyId: string) => {
                    if (assignFamilyToBookParty === true) {

                        const assignedToPartyId = action.payload.partyId;
                        const holiday = state$.value.holidays.holiday;
                        const contactId = state$.value.contact.contact?.contactId;

                        let updatedBookParty: IBookParty = {
                            bookId: holiday ? holiday.bookId : 0,
                            contactId: contactId,
                            partyId: assignedToPartyId,
                            familyId: familyId,
                            partyName: familyPayload?.familyName,
                            partyStatusId: GetPartyStatusId(familyPayload.dob, holiday?.endDate)
                        };
                        return of(
                            updateFamilyDetailsSuccess(familyId),
                            fetchContactFamilyDetails(familyPayload.contactId),
                            updateBookParty(updatedBookParty),
                            assignNewFamilyToBookParty(false),
                            showDialogBox(false));
                    }
                    else {
                        return of(
                            updateFamilyDetailsSuccess(familyId),
                            fetchContactFamilyDetails(familyPayload.contactId),
                            showDialogBox(false));
                    }
                }),
                catchError(error => {
                    sendToast("Error while saving family", ToastType.Error);
                    const res: Array<IApiError> = error.response && error.response.data && error.response.data.validationErrors !== null ? error.response.data.validationErrors : [];
                    return of(updateFamilyDetailsError(res));
                }))
        })
    );

