import * as React from 'react';
import styled,{ThemeContext} from 'styled-components';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Icon,IconTypes } from '../icons/icon-component';
import { useWindowSize } from "../../helpers/useWindowSize";
export interface IDialogBoxProp {
    children: JSX.Element;
    isOpen: boolean;
    hide: () => void;
    size?: DialogSize;
    withoutBoundaries?: boolean;
    noPadding?: boolean;
}

export const DialogBox = (props: IDialogBoxProp) => {
    const { colors,breakpoints } = React.useContext(ThemeContext);
    const size = useWindowSize();
    const isTablet = size.width >= breakpoints.tablet;
    const isLaptop = size.width >= breakpoints.laptop;
    const focusRef = React.useRef<any>(false)
    const modalStyle = {
        margin: isLaptop ? "5em" : isTablet ? "1em" : 0,
        overflowX: 'hidden' as "hidden",
        overflowY: isTablet ? "hidden" as "hidden": "auto" as "auto",
    } ;
    const closeIcon = (
        <IconClose>
            <Icon name={IconTypes.IconClose} size={35} color={"black"} />
        </IconClose>
    )
    const buttonStyle = {
        margin:"0",
        height:"40px",
        width:"40px",
        backgroundColor: colors.primary,
        top:0,
        right:0
    }
    return (props.isOpen === true ?
        (
            <>
            <Modal open={true} onClose={props.hide} closeOnOverlayClick={false} showCloseIcon={true} closeIcon={closeIcon}
                styles={{ modal: modalStyle  , closeButton: buttonStyle} }>
                <Container size={props.size} withoutBoundaries={props.withoutBoundaries} noPadding={props.noPadding}>
                    <FocusButton ref={focusRef}></FocusButton>
                    {props.children}
                </Container>
            </Modal>
            </>
        ) : null);
}
export enum DialogSize {
    NORMAL,
    SMALL
}
interface IContainer {
    size?: DialogSize;
    withoutBoundaries?: boolean;
    noPadding?: boolean
};

const Container = styled.div<IContainer>`
width:320px;
// overflow-x: hidden;
height:${({ size }) => (size !== undefined && size === DialogSize.NORMAL) ? "calc(100vh - 110px)" : "auto"} ;
@media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin:${({ withoutBoundaries }) => withoutBoundaries ? "-20px" :  "0 auto"};
    width:340px;
}
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:${({ size, withoutBoundaries }) => (size !== undefined && size === DialogSize.NORMAL) ? "100%" : withoutBoundaries ? "573px" : "auto"} ;
    margin:${({ withoutBoundaries, noPadding }) => withoutBoundaries ? "-20px" : noPadding ?" 10px auto 0" : "40px auto 0"};
    // padding: 30px;
    padding:${({ withoutBoundaries, noPadding }) => withoutBoundaries ? "0" : noPadding ? "0" : "30px"};
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
    min-width:${({ withoutBoundaries }) => withoutBoundaries ? "573px" :  "720px"};
`;
const IconClose = styled.div<any>`
height: 20px;
width: 20px;
svg{
    transform: scale(1.5);
    position: relative;
    top: 19px;
    left: 20px;
}
`;
const FocusButton = styled.button`
border: none;
background: transparent;
color: transparent;
z-index: 99;
position: absolute;
`