import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { contactStateActions, deleteFamilyMemberError, fetchContactFamilyDetails } from '../contact/contact-actions';

export const deleteFamilyEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(contactStateActions.DELETE_FAMILY_MEMBER),
        mergeMap(action => {
            return api.delete(endPointKeys.api_contact, 'family/' + action.payload.familyId).pipe(
                mergeMap(() => {
                    return of(fetchContactFamilyDetails(action.payload.contactId));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed to delete party" };
                    sendToast(appError.message, ToastType.Error);
                    return of(deleteFamilyMemberError(appError));
                }))
        })
    );
