import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError, IUpdateRetrieveHoliday } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import {  holidayStateActions, retriveHolidayError, retriveHolidaySuccess, routeChange } from './holiday-actions';
import routes from '../../routing/routes';

export const retrieveUpdateHolidayEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.UPDATE_RETRIEVE_HOLIDAY),
        mergeMap((action) => {
            let holiday: IUpdateRetrieveHoliday  = action.payload.holiday;
            var data = JSON.stringify(holiday);
            return api.post(endPointKeys.api_travel, `Booking/MSD/UpdateHolidaySharing`, data).pipe(
                map(res => res.data),
                mergeMap((m : any) => { 
                    return of(retriveHolidaySuccess(),  routeChange(action.payload.isBooking ? routes.booking(holiday.BookId.toString()): routes.quote(holiday.BookId.toString())))
                }),
                catchError((error) => {
                    const appError : IAppError = { error: error, message: 'Error while updating shared booking.'};
                    return of(retriveHolidayError(appError))
                }))
        })
    )