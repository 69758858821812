import { IAppAction } from '../app-action';
import { IAppUser, IAppError, IContactIdLookUp } from '../../models';
import { history } from '../../app-history';

export const sessionStateActions = {
  ROUTE_CHANGE: 'ROUTE_CHANGE',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  LOGIN_SOCIAL_MEDIA: 'LOGIN_SOCIAL_MEDIA',
  LOGIN_SOCIAL_MEDIA_SUCCESS: 'LOGIN_SOCIAL_MEDIA_SUCCESS',
  LOGIN_SOCIAL_MEDIA_ERROR: 'LOGIN_SOCIAL_MEDIA_ERROR',
  SIGNUP_EMAIL: 'SIGNUP_EMAIL',
  SIGNUP_EMAIL_SUCCESS: 'SIGNUP_EMAIL_SUCCESS',
  SIGNUP_EMAIL_ERROR: 'SIGNUP_EMAIL_ERROR',
  SIGNUP_SOCIAL: 'SIGNUP_SOCIAL',
  SIGNUP_SOCIAL_SUCCESS: 'SIGNUP_SOCIAL_SUCCESS',
  SIGNUP_SOCIAL_ERROR: 'SIGNUP_SOCIAL_ERROR',
  APP_ERROR: 'APP_ERROR',
  FETCH_X: 'FETCH_X',
  FETCH_X_SUCCESS: 'FETCH_X_SUCCESS',
  FETCH_X_ERROR: 'FETCH_X_ERROR',
  TOGGLE_MOBILE_MENU: 'TOGGLE_MOBILE_MENU',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  SAVE_NEW_PASSWORD: 'SAVE_NEW_PASSWORD',
  SAVE_NEW_PASSWORD_SUCCESS: 'SAVE_NEW_PASSWORD_SUCCESS',
  SAVE_NEW_PASSWORD_ERROR: 'SAVE_NEW_PASSWORD_ERROR',
  UPDATE_PASSWORD : 'UPDATE_PASSWORD',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_ERROR: 'UPDATE_PASSWORD_ERROR',
  RESET_UPDATE_PASSWORD_SUCCESS: 'RESET_UPDATE_PASSWORD_SUCCESS',
  UPDATE_APP_USER_DETAILS: 'UPDATE_APP_USER_DETAILS',
  SELECT_TAB: 'SELECT_TAB',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',
  USER_ALREADY_EXISTS : 'USER_ALREADY_EXISTS',
};

interface ISaveNewPasswordPayload {
  password: string;
  resetCode: string;
}

export const routeChange = (url: string) => {
  history.push(url);
   return {
     type: `${sessionStateActions.ROUTE_CHANGE}_${(url.toUpperCase())}`,
     payload: { url: `${url}`}
   }
 }

export const login = (email: string, password: string): IAppAction => ({
  type: sessionStateActions.LOGIN,
  payload: { email, password },
  meta: { appInsights: { trackPayload: true}}
});

export const loginSuccess = (appUser: IAppUser): IAppAction => ({
  type: sessionStateActions.LOGIN_SUCCESS,
  payload: appUser
});

export const loginError = (error: IAppError): IAppAction => ({
  type: sessionStateActions.LOGIN_ERROR,
  payload: error,
  meta: { appInsights: { trackPayload: true}}
});

export const loginSocialMedia = (type: string, accessToken: string): IAppAction => ({
  type: sessionStateActions.LOGIN_SOCIAL_MEDIA,
  payload: { type, accessToken },
  meta: { appInsights: { trackPayload: true}}
});

export const loginSocialMediaSuccess = (appUser: IAppUser): IAppAction => ({
  type: sessionStateActions.LOGIN_SOCIAL_MEDIA_SUCCESS,
  payload: appUser
});

export const loginSocialMediaError = (error: IAppError): IAppAction => ({
  type: sessionStateActions.LOGIN_SOCIAL_MEDIA_ERROR,
  payload: error,
  meta: { appInsights: { trackPayload: true}}
});

export const signUpEmail = (firstName: string, lastName: string, email: string, password: string,
  captchaToken: string, termsApproved: boolean): IAppAction => ({
  type: sessionStateActions.SIGNUP_EMAIL,
  payload: { firstName, lastName, email, password, captchaToken, termsApproved },
  meta: { appInsights: { trackPayload: true}}
});

export const signUpEmailSuccess = (appUser: IAppUser): IAppAction => ({
  type: sessionStateActions.SIGNUP_EMAIL_SUCCESS,
  payload: appUser
});

export const signUpEmailError = (error: IAppError): IAppAction => ({
  type: sessionStateActions.SIGNUP_EMAIL_ERROR,
  payload: error,
  meta: { appInsights: { trackPayload: true}}
});

export const signUpSocial = (type: string, accessToken: string): IAppAction => ({
  type: sessionStateActions.SIGNUP_SOCIAL,
  payload: { type, accessToken },
  meta: { appInsights: { trackPayload: true}}
});

export const signUpSocialSuccess = (appUser: IAppUser): IAppAction => ({
  type: sessionStateActions.SIGNUP_SOCIAL_SUCCESS,
  payload: appUser
});

export const signUpSocialError = (error: IAppError): IAppAction => ({
  type: sessionStateActions.SIGNUP_SOCIAL_ERROR,
  payload: error,
  meta: { appInsights: { trackPayload: true}}
});

export const logOut = (): IAppAction => ({
  type: sessionStateActions.LOGOUT
});

export const logOutSuccess = (): IAppAction => ({
  type: sessionStateActions.LOGOUT_SUCCESS
});

export const logOutError = (error: IAppError): IAppAction => ({
  type: sessionStateActions.LOGOUT_ERROR,
  payload: error
});

export const fetchX = (): IAppAction => ({
  type: sessionStateActions.FETCH_X
});

export const fetchXSuccess = (user: IAppUser): IAppAction => ({
  type: sessionStateActions.FETCH_X_SUCCESS,
  payload: user
});

export const fetchXError = (error: IAppError): IAppAction => ({
  type: sessionStateActions.FETCH_X_ERROR,
  payload: error
});

export const toggleMobileMenu = (isOpen: boolean): IAppAction => ({
  type: sessionStateActions.TOGGLE_MOBILE_MENU,
  payload: isOpen
});

export const resetPassword = (email: string): IAppAction => ({
  type: sessionStateActions.RESET_PASSWORD,
  payload: email
});

export const userAlreadyExists = (flag: boolean): IAppAction => ({
  type: sessionStateActions.USER_ALREADY_EXISTS,
  payload: flag
});

export const resetPasswordSuccess = (): IAppAction => ({
  type: sessionStateActions.RESET_PASSWORD_SUCCESS
});

export const resetPasswordError = (): IAppAction => ({
  type: sessionStateActions.RESET_PASSWORD_ERROR
});

export const saveNewPassword = (payload: ISaveNewPasswordPayload): IAppAction => ({
  type: sessionStateActions.SAVE_NEW_PASSWORD,
  payload: payload
});

export const saveNewPasswordSuccess = (): IAppAction => ({
  type: sessionStateActions.SAVE_NEW_PASSWORD_SUCCESS
});

export const saveNewPasswordError = (): IAppAction => ({
  type: sessionStateActions.SAVE_NEW_PASSWORD_ERROR
});

// export const updatePassword = (payload: ILookUp): IAppAction => ({
//   type: sessionStateActions.UPDATE_PASSWORD,
//   payload: payload
// });

export const updatePassword = (payload: IContactIdLookUp): IAppAction => ({
  type: sessionStateActions.UPDATE_PASSWORD,
  payload: payload
});

export const updatePasswordSuccess = (): IAppAction => ({
  type: sessionStateActions.UPDATE_PASSWORD_SUCCESS
});

export const updatePasswordError = (): IAppAction => ({
  type: sessionStateActions.UPDATE_PASSWORD_ERROR
});

export const resetPasswordSuccessFlag = (): IAppAction => ({
  type: sessionStateActions.RESET_UPDATE_PASSWORD_SUCCESS
});

export const updateAppUserDetails = (user: IAppUser) : IAppAction => ({
  type: sessionStateActions.UPDATE_APP_USER_DETAILS,
  payload: user
})

export const selectTab = (pageName: string, tab: number) : IAppAction => ({
  type: sessionStateActions.SELECT_TAB,
  payload: {pageName, tab}
})

export const refreshToken = (): IAppAction => ({
  type: sessionStateActions.REFRESH_TOKEN
});

export const refreshTokenSuccess = (token : string): IAppAction => ({
  type: sessionStateActions.REFRESH_TOKEN_SUCCESS,
  payload: token
});

export const refreshTokenError = (error: IAppError): IAppAction => ({
  type: sessionStateActions.REFRESH_TOKEN_ERROR
});