import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { Body1, Body2 } from "../../../components/typography";
import { getCurrencyCodeAndSymbol } from "../../../helpers/getCurrencySymbol";
import { EnumHolidayType, IInvoice } from "../../../models";

export declare interface IHolidayInvoiceProps {
    invoice: IInvoice;
    holidayType: EnumHolidayType;
    quoteExpiryDate?: Date;
}

export const HolidayInvoice = ({ invoice, holidayType, quoteExpiryDate }: IHolidayInvoiceProps) => {
    const currencySymbols = getCurrencyCodeAndSymbol(invoice.currency);
    return (
        <Container>
            {invoice && <div>
                {invoice.partyCosts.map((party, i) =>
                    <div key={"partyCost" + i}>
                        <PartyMemberLineContainer>
                            <StyledBody1>{party.partyName.toUpperCase()}</StyledBody1>
                            <StyledBody1><NumberFormat value={party.amount} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={currencySymbols} /></StyledBody1>
                        </PartyMemberLineContainer>
                        <StyledBody2>{party.partyStatus}</StyledBody2>
                        <UnderlineDiv />
                    </div>)}
                {invoice.supplementCosts.length > 0 &&
                    <div><StyledBody1><strong>Surcharges</strong></StyledBody1>
                        {invoice.supplementCosts.map((supplement, i) =>
                            <div key={"supplementCost" + i}>
                                <PartyMemberLineContainer>
                                    <StyledBody1>{supplement.supplement.toUpperCase()}</StyledBody1>
                                    <StyledBody1><NumberFormat value={supplement.amount} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={currencySymbols} /></StyledBody1>
                                </PartyMemberLineContainer>

                            </div>)}
                        <UnderlineDiv />
                    </div>}


                <InvoiceLineContainer>
                    <StyledBody1><strong>Total (including taxes and fees)</strong></StyledBody1>
                    <StyledBody1><strong><NumberFormat value={invoice.invoiceTotal} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={currencySymbols} /></strong></StyledBody1>
                </InvoiceLineContainer>
                {invoice.guestPayments.length > 0 && 
                <>
                    {invoice.guestPayments.map((payment, i) =>
                        <div key={"partyCost" + i}>
                            <PartyMemberLineContainer>
                                <StyledBody1>{payment.paymentDescription}</StyledBody1>
                                <StyledBody1><NumberFormat value={payment.paymentAmount} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={getCurrencyCodeAndSymbol(payment.currency)} /></StyledBody1>
                            </PartyMemberLineContainer>
                            <UnderlineDiv />
                        </div>)}
                </>
                }
                {holidayType === EnumHolidayType.QUOTE &&
                    <InvoiceLineContainer>
                        <StyledLargerBody2>Deposit {quoteExpiryDate ? `due by ${moment(quoteExpiryDate, 'DD/MM/yyyy').format('ddd DD MMM yyyy')}` : ''}</StyledLargerBody2>
                        <StyledLargerBody2><NumberFormat value={invoice.deposit} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={currencySymbols} /></StyledLargerBody2>
                    </InvoiceLineContainer>
                }
                <InvoiceLineContainer>
                    <StyledLargerBody2>Balance due by {moment(invoice.balanceDueDate).format('ddd DD MMM yyyy')}</StyledLargerBody2>
                    <StyledLargerBody2><NumberFormat value={invoice.balance} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={currencySymbols} /></StyledLargerBody2>
                </InvoiceLineContainer>
                <UnderlineDiv />
            </div>}
        </Container>
    );
};

const StyledBody1 = styled(Body1)`
display: inline;
font-size: 14px;
letter-spacing: -1px;
`;

const PartyMemberLineContainer = styled.div`
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
`;

const InvoiceLineContainer = styled.div`
display: flex;
flex-flow: row nowrap;
justify-content: space-between;
margin: 8px 0px;
`;

const StyledBody2 = styled(Body2)`
display: block;
font-size: 10px;
color: ${({ theme }) => theme.colors.labelDark};
`;

const StyledLargerBody2 = styled(Body2)`
display: block;
font-size: 12px;
color: ${({ theme }) => theme.colors.labelDark};
`;

const UnderlineDiv = styled.div`
width: 100%;
height: 1px;
border-bottom: 1px solid ${({ theme }) => theme.colors.labelLight};
margin: 10px 0px;
`;

const Container = styled.div`
padding: 20px;
& > * {
    margin-bottom: 20px;
}
`;
