import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppUser, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { sessionStateActions, loginSocialMediaError, loginSocialMediaSuccess } from './session-actions';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { fetchContactFamilyDetails, setContactOnLoginSuccess } from '../contact/contact-actions';
import { fetchCountries } from '../reference-data';
import { fetchHolidays } from '../holiday';

export const loginSocialMediaEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(sessionStateActions.LOGIN_SOCIAL_MEDIA),
        mergeMap(action => {
            var data = JSON.stringify(action.payload);
            return api.post(endPointKeys.api_contact, 'user/loginBySocialMedia', data).pipe(
                map(result => result.data),
                mergeMap((appUser : IAppUser) => {
                    localStorage.removeItem("timedOut");
                    // sendToast(`Welcome ${appUser.firstName}`, ToastType.Success);
                    return of(loginSocialMediaSuccess(appUser),
                            setContactOnLoginSuccess(appUser),
                            fetchCountries(),
                            fetchHolidays(appUser.id),
                            fetchContactFamilyDetails(appUser.id));
                }),
                catchError(error => {
                    const appError : IAppError = { error, message: "Failed to log in"};
                    sendToast(appError.message, ToastType.Error);
                    return of(loginSocialMediaError(appError));
                }))
        })
    )