import React, { memo } from "react";
import Toggle from "react-toggle";
import styled from "styled-components";
import './toggle.css'

interface IToggleProps {
  title?: string;
  isChecked: boolean;
  onChange(): void;
}
export const ToggleComponent = memo<IToggleProps>(
  ({
    title,
    isChecked,
    onChange
  }): JSX.Element => {
    return (
      <Wrapper>
        <Toggle
          defaultChecked={isChecked}
          icons={false}
          onChange={onChange} />
        <StyledSpan>{title ? title : ''}</StyledSpan>
      </Wrapper>)
  });



const Wrapper = styled.div`
display: flex;
margin: 15px 0px;
`;

const StyledSpan = styled.span`
margin-left: 10px;
`;