import React, { memo } from "react";
import styled from "styled-components";
import Label from "../../typography/label";

export interface IDaysToGoProps {
    days: number;
}

export const DaysToGo = memo<IDaysToGoProps>(
    ({
        days
    }): JSX.Element => {
        return (
            <Container>               
                <TextWrapper>
                <DaysText>{days}</DaysText>
                <InfoText>Days to go</InfoText>
                </TextWrapper>               
            </Container>
        );
    }
);

const Container = styled.div`
display: flex;
position: relative;
width: 100px;
height: 100px;
flex-direction: column;
color: ${({ theme }) => theme.colors.white};
cursor: pointer;
`;

const TextWrapper = styled.div`
display: flex;
position: absolute;
top : 25px;
left: 20px;
flex-flow: column;
`;

const DaysText = styled(Label)`
font-size: 35px;
color: ${({ theme: { colors } }) => colors.white};
text-align: center;
margin: 5px;
text-transform: uppercase;
`;

const InfoText = styled(Label)`
font-size: 10px;
color: ${({ theme: { colors } }) => colors.white};
text-align: center;
margin: 5px;
text-transform: uppercase;

`;