import React, { memo, useContext, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import defaultTheme from "../../theme";
import ButtonText from "../typography/button";
import { Icon, IconTypes } from "../icons/icon-component";

export enum ButtonType {
  MAIN,
  SECONDARY,
  ALTERNATIVE,
  ALTERNATIVE_DARK,
  ICON,
}

export enum ButtonSize {
  NORMAL,
  SMALL
}

export enum ButtonHeight {
  TALL,
  NORMAL,
  SHORT
}

const ButtonVariants = {
  [ButtonType.MAIN]: {
    size: ButtonSize.NORMAL,
    color: defaultTheme.colors.white,
    backgroundColor: defaultTheme.colors.accentLight,
    borderColor: defaultTheme.colors.accentLight,
    shadowColor: defaultTheme.colors.accent,
    hover: {
      color: defaultTheme.colors.white,
      backgroundColor: defaultTheme.colors.accent,
      borderColor: defaultTheme.colors.accent,
      shadowColor: defaultTheme.colors.accentDark,
    }
  },
  [ButtonType.SECONDARY]: {
    size: ButtonSize.NORMAL,
    color: defaultTheme.colors.white,
    backgroundColor: defaultTheme.colors.primary,
    borderColor: defaultTheme.colors.primary,
    shadowColor: "#03402E",
    hover: {
      color: defaultTheme.colors.white,
      backgroundColor: defaultTheme.colors.primaryDark,
      borderColor: defaultTheme.colors.primaryDark,
      shadowColor: "#103024",
    }
  },
  [ButtonType.ALTERNATIVE]: {
    size: ButtonSize.NORMAL,
    color: defaultTheme.colors.primaryDark,
    backgroundColor: defaultTheme.colors.transparent,
    borderColor: defaultTheme.colors.primaryDark,
    shadowColor: "none",
    hover: {
      color: defaultTheme.colors.primary,
      backgroundColor: defaultTheme.colors.transparent,
      borderColor: "#317A64",
      shadowColor: "none",
    }
  },
  [ButtonType.ALTERNATIVE_DARK]: {
    size: ButtonSize.NORMAL,
    color: defaultTheme.colors.white,
    backgroundColor: defaultTheme.colors.transparent,
    borderColor: defaultTheme.colors.white,
    shadowColor: "none",
    hover: {
      color: defaultTheme.colors.accentLight,
      backgroundColor: defaultTheme.colors.transparent,
      borderColor: defaultTheme.colors.accentLight,
      shadowColor: "none",
    }
  },
  [ButtonType.ICON]: {
    size: ButtonSize.NORMAL,
    color: defaultTheme.colors.white,
    backgroundColor: defaultTheme.colors.transparent,
    borderColor: defaultTheme.colors.primary,
    shadowColor: "none",
    hover: {
      color: defaultTheme.colors.primary,
      backgroundColor: defaultTheme.colors.transparent,
      borderColor: defaultTheme.colors.primary,
      shadowColor: "none",
    }
  },
};

const ButtonVariantsSmall = {
  [ButtonType.MAIN]: {
    size: ButtonSize.SMALL,
    color: defaultTheme.colors.labelDark,
    backgroundColor: defaultTheme.colors.labelWhite,
    borderColor: defaultTheme.colors.labelWhite,
    shadowColor: defaultTheme.colors.transparent,
    hover: {
      color: defaultTheme.colors.labelLight,//
      backgroundColor: defaultTheme.colors.labelDark,//
      borderColor: defaultTheme.colors.labelDark,//
      shadowColor: defaultTheme.colors.labelDark,//
    }
  },
  [ButtonType.SECONDARY]: {
    size: ButtonSize.SMALL,
    color: defaultTheme.colors.primaryDark,//
    backgroundColor: defaultTheme.colors.transparent, //
    borderColor: defaultTheme.colors.primaryDark, //
    shadowColor: defaultTheme.colors.transparent,
    hover: {
      color: defaultTheme.colors.primary,//
      backgroundColor: defaultTheme.colors.transparent, //
      borderColor: defaultTheme.colors.primary, //
      shadowColor: defaultTheme.colors.transparent,
    }
  },
  [ButtonType.ALTERNATIVE]: {
    size: ButtonSize.SMALL,
    color: defaultTheme.colors.white,
    backgroundColor: defaultTheme.colors.transparent,
    borderColor: defaultTheme.colors.white,
    shadowColor: "none",
    hover: {
      color: defaultTheme.colors.accentLight,
      backgroundColor: defaultTheme.colors.transparent,
      borderColor: defaultTheme.colors.accentLight,
      shadowColor: "none",
    }
  },
  [ButtonType.ALTERNATIVE_DARK]: {
    size: ButtonSize.SMALL,
    color: defaultTheme.colors.white,
    backgroundColor: defaultTheme.colors.transparent,
    borderColor: defaultTheme.colors.white,
    shadowColor: "none",
    hover: {
      color: defaultTheme.colors.accentLight,
      backgroundColor: defaultTheme.colors.transparent,
      borderColor: defaultTheme.colors.accentLight,
      shadowColor: "none",
    }
  },
  [ButtonType.ICON]: {
    size: ButtonSize.SMALL,
    color: defaultTheme.colors.white,
    backgroundColor: defaultTheme.colors.transparent,
    borderColor: defaultTheme.colors.primary,
    shadowColor: "none",
    hover: {
      color: defaultTheme.colors.white,
      backgroundColor: defaultTheme.colors.transparent,
      borderColor: defaultTheme.colors.primary,
      shadowColor: "none",
    }
  },
};

export interface ButtonProps {
  type: ButtonType;
  size?: ButtonSize;
  height?: ButtonHeight;
  text: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  leftIcon?: string;
  rightIcon?: string;
  processing?: boolean;
  className?: string;
  [propName: string]: any;
  disabled?: boolean;
  dataTestId?: string
}

const Button = memo<ButtonProps>(
  ({
    type = ButtonType.MAIN,
    size = ButtonSize.NORMAL,
    height = (size !== ButtonSize.SMALL) ? ButtonHeight.NORMAL : ButtonHeight.SHORT,
    text,
    onClick,
    leftIcon,
    rightIcon,
    processing,
    className,
    disabled,
    dataTestId,
    ...rest
  }): JSX.Element => {
    const { colors } = useContext(ThemeContext);
    const memoizedStyle = useMemo(() => {
      return size === ButtonSize.NORMAL ? ButtonVariants[type] : ButtonVariantsSmall[type];
    }, [type, size]);

    const isIconType = useMemo(() => {
      return type === ButtonType.ICON;
    }, [type]);

    return (
      <StyledButton
        {...memoizedStyle}
        onClick={onClick}
        type={type === ButtonType.MAIN ? "submit" : "button"}
        isIconType={isIconType}
        height={height}
        data-testid={dataTestId}
        disabled={processing === true || disabled === true}
        className={className}
        {...rest}
      >
        {leftIcon && IconTypes[leftIcon] && (
          <LeftIconContainer>
            <Icon
              name={leftIcon}
              color={isIconType ? colors.white : "currentColor"}
            />
          </LeftIconContainer>
        )}
        <ButtonText size={size === ButtonSize.NORMAL ? 10 : 8}>{text}</ButtonText>
        {rightIcon && IconTypes[rightIcon] && (
          <RightIconContainer>
            <Icon
              name={rightIcon}
              color={isIconType ? colors.primary : "currentColor"}
            />
          </RightIconContainer>
        )}
        {processing && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginLeft: "5px" }}
            />
          )}
      </StyledButton>
    );
  }
);

export default Button;

interface StyledButtonProps {
  size: ButtonSize;
  height?: ButtonHeight;
  leftIcon?: string;
  rightIcon?: string;
  borderColor?: string;
  backgroundColor?: string;
  shadowColor?: string;
  color?: string;
  hover: {
    borderColor?: string;
    backgroundColor?: string;
    shadowColor?: string;
    color?: string;
  };
  isIconType: boolean;
  disabled: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  width: 100%;
  height: ${({ height }) => (height === ButtonHeight.TALL ? 50: height === ButtonHeight.SHORT ? 35: 40)}px;
  padding: ${({ size }) => (size === ButtonSize.NORMAL ? '0 20px': '0 8px')};
  outline: none;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  text-transform: uppercase;
  color: ${({ color }) => color};
  border-color: ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0 2px 0 ${({ shadowColor }) => shadowColor};
  text-align: ${({ isIconType, rightIcon }) => (isIconType ? "left" : rightIcon ? "right" : "center")};
  padding-left: ${({ isIconType, size }) => (isIconType ? 56 : size === ButtonSize.NORMAL ? 20: 8)}px;

  &:hover {
    color: ${({ hover }) => hover.color};
    border-color: ${({ hover }) => hover.borderColor};
    background-color: ${({ hover }) => hover.backgroundColor};
    box-shadow: 0 2px 0 ${({ shadowColor }) => shadowColor};
  }
`;

export const LeftIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  border-right-width: 2px;
  border-right-style: solid;
  border-color: inherit;
  color: inherit;
  padding: 14px;
`;

export const RightIconContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: inherit;
  padding: 5px;
`;
