import { IAppAction } from '../app-action';
import { IAddress, 
        IADFAddress, 
        IApiError, 
        IAppError, 
        IAppUser, 
        IFamily, 
        IProfileUpdate
        } from '../../models';

export const contactStateActions = {  
    
    SET_CONTACT_ON_LOGIN_SUCCESS : 'SET_CONTACT_ON_LOGIN_SUCCESS',

    FETCH_FAMILY_DETAILS: 'FETCH_FAMILY_DETAILS',
    FETCH_FAMILY_DETAILS_SUCCESS: 'FETCH_FAMILY_DETAILS_SUCCESS',
    FETCH_FAMILY_DETAILS_ERROR: 'FETCH_FAMILY_DETAILS_ERROR',

    DELETE_FAMILY_MEMBER: 'DELETE_FAMILY_MEMBER',
    DELETE_FAMILY_MEMBER_SUCCESS: 'DELETE_FAMILY_MEMBER_SUCCESS',
    DELETE_FAMILY_MEMBER_ERROR: 'DELETE_FAMILY_MEMBER_ERROR',

    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',

    FETCH_CONTACT_PROFILE: 'FETCH_CONTACT_PROFILE',
    FETCH_CONTACT_PROFILE_SUCCESS: 'FETCH_CONTACT_PROFILE_SUCCESS',
    FETCH_CONTACT_PROFILE_ERROR: 'FETCH_CONTACT_PROFILE_ERROR',

    SEARCH_ADDRESS: 'SEARCH_ADDRESS',
    SEARCH_ADDRESS_SUCCESS: 'SEARCH_ADDRESS_SUCCESS',
    SEARCH_ADDRESS_ERROR : 'SEARCH_ADDRESS_ERROR',

    FETCH_ADDRESS: 'FETCH_ADDRESS',
    FETCH_ADDRESS_SUCCESS : 'FETCH_ADDRESS_SUCCESS',
    FETCH_ADDRESS_ERROR : 'FETCH_ADDRESS_ERROR',

    CLEAR_SELECTED_ADDRESS : 'CLEAR_SELECTED_ADDRESS',

    CLEAR_ADDRESSES : 'CLEAR_ADDRESSES',

    UPDATE_FAMILY_DETAILS: 'UPDATE_FAMILY_DEATILS',
    UPDATE_FAMILY_DETAILS_SUCCESS: 'UPDATE_FAMILY_DEATILS_SUCCESS',
    UPDATE_FAMILY_DETAILS_ERROR: 'UPDATE_FAMILY_DEATILS_ERROR',

    SHOW_DIALOG_BOX:'SHOW_DIALOG_BOX',
    
    SET_ASSIGN_NEWFAMILY_TO_BOOK_PARTY: 'SET_ASSIGN_NEWFAMILY_TO_BOOK_PARTY',

    CLEAR_LAST_UPDATED_FAMILY: 'CLEAR_LAST_UPDATED_FAMILY'
}

export const fetchContactProfile = (contactId: string): IAppAction => ({
    type: contactStateActions.FETCH_CONTACT_PROFILE,
    payload: contactId
});

export const fetchContactProfileSuccess = (contact: IAppUser): IAppAction => ({
    type: contactStateActions.FETCH_CONTACT_PROFILE_SUCCESS,
    payload: contact
});

export const fetchContactProfileError = (error: IAppError): IAppAction => ({
    type: contactStateActions.FETCH_CONTACT_PROFILE_ERROR,
    payload: error
});

export const setContactOnLoginSuccess = (appUser: IAppUser) : IAppAction =>({
    type: contactStateActions.SET_CONTACT_ON_LOGIN_SUCCESS,
    payload : appUser
});

export const fetchContactFamilyDetails = (contactId: string): IAppAction =>({
    type: contactStateActions.FETCH_FAMILY_DETAILS,
    payload: contactId
});

export const fetchContactFamilyDetailsSuccess = (family: Array<IFamily>): IAppAction =>({
    type: contactStateActions.FETCH_FAMILY_DETAILS_SUCCESS,
    payload: family
});

export const fetchContactFamilyDetailsError = (error: IAppError): IAppAction =>({
    type: contactStateActions.FETCH_FAMILY_DETAILS_ERROR,
    payload: error
});

export const deleteFamilyMember = (familyId: string, contactId: string): IAppAction => ({
    type: contactStateActions.DELETE_FAMILY_MEMBER,
    payload: { familyId, contactId }
});

export const deleteFamilyMemberSuccess = (): IAppAction =>({
    type: contactStateActions.DELETE_FAMILY_MEMBER_SUCCESS
});

export const deleteFamilyMemberError = (error: IAppError): IAppAction =>({
    type: contactStateActions.DELETE_FAMILY_MEMBER_ERROR,
    payload: error
});

export const updateProfile = (updatePayload: IProfileUpdate): IAppAction =>({
    type: contactStateActions.UPDATE_PROFILE,
    payload: updatePayload
});

export const updateProfileSuccess = (): IAppAction =>({
    type: contactStateActions.UPDATE_PROFILE_SUCCESS
});

export const updateProfileError = (error: IAppError): IAppAction =>({
    type: contactStateActions.UPDATE_PROFILE_ERROR,
    payload: error
});

export const searchAddress = (postcode: string): IAppAction =>({
    type: contactStateActions.SEARCH_ADDRESS,
    payload: postcode
});

export const searchAddressSuccess = (addresses: Array<IADFAddress>): IAppAction =>({
    type: contactStateActions.SEARCH_ADDRESS_SUCCESS,
    payload: addresses
});

export const searchAddressError = (error: IAppError): IAppAction =>({
    type: contactStateActions.SEARCH_ADDRESS_ERROR,
    payload: error
});

export const fetchAddress = (key: string): IAppAction =>({
    type: contactStateActions.FETCH_ADDRESS,
    payload: key
});

export const fetchAddressSuccess = (addresse: IAddress): IAppAction =>({
    type: contactStateActions.FETCH_ADDRESS_SUCCESS,
    payload: addresse
});

export const fetchAddressError = (error: IAppError): IAppAction =>({
    type: contactStateActions.FETCH_ADDRESS_ERROR,
    payload: error
});

export const clearSelectedAddress = (): IAppAction =>({
    type: contactStateActions.CLEAR_SELECTED_ADDRESS
});

export const clearAddresses = (): IAppAction =>({
    type: contactStateActions.CLEAR_ADDRESSES
});
export const updateFamilyDetails = (family: IFamily, assignedToPartyId: number) => ({
    type: contactStateActions.UPDATE_FAMILY_DETAILS,
    payload: {
        family: family,
        partyId: assignedToPartyId
    }
});
export const updateFamilyDetailsSuccess = (familyId: string)=>({
    type: contactStateActions.UPDATE_FAMILY_DETAILS_SUCCESS,
    payload:familyId
});

export const updateFamilyDetailsError = (errors:Array<IApiError>)=>({
    type: contactStateActions.UPDATE_FAMILY_DETAILS_ERROR,
    payload:errors
});

export const  showDialogBox =(display:boolean)=>({
    type: contactStateActions.SHOW_DIALOG_BOX,
    payload:display
});

export const assignNewFamilyToBookParty = (assign: boolean) => ({
    type: contactStateActions.SET_ASSIGN_NEWFAMILY_TO_BOOK_PARTY,
    payload: assign
});

export const clearLastUpdatedFamily = () => ({
    type: contactStateActions.CLEAR_LAST_UPDATED_FAMILY
});