
import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useWindowSize } from "../../helpers/useWindowSize";
import { Login } from "..";

export const LoginContainer = () => {
  const {breakpoints } = useContext(ThemeContext);
  const size = useWindowSize();
  const isDesktop = size.width >= breakpoints.tablet;

  return (
    isDesktop ? 
        <DesktopContainer>
            <Login/>
        </DesktopContainer>
        :
        <MobileContainer>
            <Login/>
        </MobileContainer>
  );
};

const DesktopContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const MobileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;