import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const Icon4x4 = memo<IconProps>((props) => {
  return (
    <Svg width="51" height="51" viewBox="0 0 50.7 51">   
    <path fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.25px" d="M9.5,28.75c-2.88,0-5.22,2.34-5.22,5.22s2.34,5.22,5.22,5.22,5.22-2.34,5.22-5.22-2.34-5.22-5.22-5.22ZM9.5,36.74c-1.52,0-2.76-1.24-2.76-2.76s1.24-2.76,2.76-2.76,2.76,1.24,2.76,2.76-1.24,2.76-2.76,2.76Z"/>
    <path fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.25px" d="M37.69,28.64c-2.88,0-5.22,2.34-5.22,5.22s2.34,5.22,5.22,5.22,5.22-2.34,5.22-5.22-2.34-5.22-5.22-5.22ZM37.69,36.63c-1.52,0-2.76-1.24-2.76-2.76s1.24-2.76,2.76-2.76,2.76,1.24,2.76,2.76-1.24,2.76-2.76,2.76Z"/>
    <path fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.25px" d="M45.99,29.7v-7.04c0-.18-.04-.47-.08-.65l-1.49-5.69c-.05-.18-.15-.45-.23-.62l-.06-.12c-.08-.16-.29-.3-.48-.3h-21.68c-.18,0-.41.13-.51.28l-4.52,7.09c-.1.15-.33.3-.51.32l-13.18,1.46c-.18.02-.44.13-.59.25l-.58.48c-.14.12-.26.36-.26.54v3.48c0,.18-.15.33-.33.33h-.94c-.18,0-.32.15-.31.33l.23,3.22c.01.18.17.33.35.33h2.28c.18,0,.39-.14.46-.31l1.94-4.59c.24-.56.92-1.02,1.53-1.02h4.32c.61,0,1.36.42,1.68.94l2.88,4.69c.1.16.32.28.5.28h14.28c.18,0,.41-.13.5-.29l2.78-4.74c.31-.53,1.05-.95,1.66-.95h3.81c.61,0,1.39.4,1.75.89l2.91,3.99c.11.15.24.27.29.27h2.61c.18,0,.33-.15.33-.33v-1.87c0-.18-.15-.33-.33-.33h-.7c-.18,0-.33-.15-.33-.33ZM28.63,22.33c0,.3-.25.55-.55.55h-8.71c-.3,0-.43-.22-.29-.48l2.81-5.17c.15-.27.51-.48.82-.48h5.38c.3,0,.55.25.55.55v5.04ZM42.55,22.68h-11.2c-.3,0-.55-.25-.55-.55v-4.82c0-.3.25-.55.55-.55h9.82c.3,0,.61.24.68.54l1.13,4.85c.07.3-.12.54-.43.54Z"/>
    <rect fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.25px" x="46.36" y="19.11" width="3.89" height="10.45" rx=".55" ry=".55"/>
    </Svg>
  );
});
