import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { sessionStateActions, refreshTokenSuccess, refreshTokenError } from './session-actions';
import { IAppState } from '../app-state';


export const refreshTokenEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(sessionStateActions.REFRESH_TOKEN),
        mergeMap(action => {
            return api.get(endPointKeys.api_contact, 'User/RenewToken').pipe(
                map(result => result.data),
                mergeMap((token : string)  => {                   
                    return of(refreshTokenSuccess(token));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed refresh token" };
                    return of(refreshTokenError(appError));
                }))
        })
    )