import styled from "styled-components";

const Text = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  text-align: left;
  display: inline;
`;

export default Text;
