import React from 'react'
import styled from 'styled-components';


export enum TextType {
  BOLD,
  DEFAULT,
}
 export interface TextProps {
    title?: string;
    body?: string;
 }
function Text({ title, body }:TextProps) {
  return  <TextWrapper>
       {title && <Title>{title}</Title>}
        <Body>{body}</Body>
    </TextWrapper>
}

export default Text

const Title = styled.span`
 font-weight: 600;
 text-transform: uppercase;
 margin-right: 10px;
`;

const Body = styled.span`
`;

const TextWrapper  = styled.div`
 margin-top: 20px;
`;

