import React from "react";
import styled from "styled-components";

interface IImageBackgroundProps {
    url: string;
}

export const SDPImageBackground = (props:IImageBackgroundProps) => {
    return(
        <ImageArea url={props.url}/>
    );
}

export const SDPDashboardImageBackground = (props:IImageBackgroundProps) => {
    return(
        <DashboardImageArea url={props.url}/>
    );
}

const DashboardImageArea = styled.div<IImageBackgroundProps>`
background-image: linear-gradient(
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.6)
  ), url(${({ url }) => url});
background-repeat: no-repeat;
background-position: center top;
background-size: cover;
z-index: -1;
min-height: 100%;
min-width: 1024px;
width: 100%;
height: auto;
position: fixed;
top: 0;
left: 0;
@media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
left: 250px;
}

@media screen and (max-width: 1024px) {
img.bg {
  left: 50%;
  margin-left: -512px;   /* 50% */
}
`;

const ImageArea = styled.div<IImageBackgroundProps>`
background-image: url(${({ url }) => url});
background-repeat: no-repeat;
background-position: center top;
background-size: cover;
z-index: -1;
min-height: 100%;
min-width: 1024px;
width: 100%;
opacity: 0.7;
height: auto;
position: fixed;
top: 0;
left: 0;
@media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
left: 250px;
}

@media screen and (max-width: 1024px) {
img.bg {
  left: 50%;
  margin-left: -512px;   /* 50% */
}
`;