import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IFamily } from '../../models';
import { IAppState } from '../../store/app-state';
import { Accordion } from '../accordion';
import { Headline4 } from '../typography';
import { LabelText } from './party-detail-edit';

export interface IPartyDetailReadOnlyProp {
    // familyId: number;
    familyId: string;
}

export interface IInfoRowProps {
    title: string;
    value: any;
}

export const PartyDetailReadOnlyView = (prop: IPartyDetailReadOnlyProp) => {

    const familyMembers = useSelector((state: IAppState) => state.contact.contact.familyMembers);
    const family: IFamily = familyMembers.filter((f) => f.familyId === prop.familyId)[0];

    let emergencyPhone = "";
    if (family.emergencyPhone) {
        if (family.emergencyPhone.dialingCode && family.emergencyPhone.dialingCode.id === 0) {
            emergencyPhone = family.emergencyPhone.phone;
        }
        else if (family.emergencyPhone.dialingCode && family.emergencyPhone.dialingCode.title.length > 0) {
            emergencyPhone = `${family.emergencyPhone.dialingCode.title} ${family.emergencyPhone.phone}`;
        }
        else {
            emergencyPhone = family.emergencyPhone.phone;
        }
    }

    let travelInsurancePhone = "";
    if (family.insurance.insuranceContactNumber) {
        if (family.insurance.insuranceContactNumber.dialingCode && family.insurance.insuranceContactNumber.dialingCode.id === 0) {
            travelInsurancePhone = family.insurance.insuranceContactNumber.phone;
        }
        else if (family.insurance.insuranceContactNumber.dialingCode && family.insurance.insuranceContactNumber.dialingCode.title.length > 0) {
            travelInsurancePhone = `${family.insurance.insuranceContactNumber.dialingCode.title} ${family.insurance.insuranceContactNumber.phone}`;
        }
        else {
            travelInsurancePhone = family.insurance.insuranceContactNumber.phone;
        }
    }

    const toLoacalTime = (date: any) => {
        // const toUtc = moment.utc(date);
        // const localTime = moment(toUtc).local(); 
        return moment(date).format('Do MMMM YYYY')
    }

    return (
        <React.Fragment>
            <StyledHeadline4>{family.familyName}</StyledHeadline4>
            <InfoRow title="*Title" value={family.salutation ? family.salutation.title : ""} />
            <InfoRow title="*First name(s)" value={family.firstName ? family.firstName : ""} />
            <InfoRow title="*Surname" value={family.lastName ? family.lastName : ""} />
            <InfoRow title="*Relationship" value={family.relationship ? family.relationship.title : ""} />
            {/* <InfoRow title="*Date of birth" value={family.dob ? moment.utc(family.dob).format('D MMMM YYYY') : ""} /> */}
            <InfoRow title="*Date of birth" value={family.dob ? toLoacalTime(family.dob) : ""} />
            <InfoRow title="*Gender as per passport" value={family.sex ? family.sex : ""} />
            <LabelText>Please note names should be as per your passport. If you would like to update a name for any member of your party please contact your Travel Consultant.</LabelText>
            <Accordion title='Passport details' collapseStyle={{ marginTop: '10px' }}>
                <>
                    <InfoRow title="Passport number" value={family.passportNumber ? family.passportNumber : ""} key={1} />
                    <InfoRow title="Nationality" value={family.nationality ? family.nationality.title : ""} key={2} />
                    <InfoRow title="Country of issue" value={family.countryOfIssue ? family.countryOfIssue.title : ""} key={3} />
                    <InfoRow title="Passport issue date" value={family.passportIssueDate ? moment(family.passportIssueDate).format('Do MMMM YYYY') : ""} key={4} />
                    <InfoRow title="Passport expiry date" value={family.passportExpiryDate ? moment(family.passportExpiryDate).format('Do MMMM YYYY') : ""} key={5} />
                    {
                        family.frequentFlyer &&
                        family.frequentFlyer.map((flight, index) => {
                            return (
                                <React.Fragment>
                                    <InfoRow title="Airline" value={flight.cardType} key={index} />
                                    <InfoRow title="Frequent flyer number" value={flight.cardNumber} key={index + 1} />
                                </React.Fragment>
                            )
                        })
                    }
                    <>
                        {<TermWrapper>*Passport details are stored securely for this and future bookings. Please see our <a style={{ color: "#b3b3b3;", textDecoration: "underline" }} href="https://www.scottdunn.com/about/legalities/privacy-policy">Privacy Policy</a> for further details</TermWrapper>}
                    </>
                </>
            </Accordion>
            <Accordion title='Preferences'>
                <>
                    <InfoRow title="Seat preference" value={family.seatPreference ? family.seatPreference.title : ""} key={1} />
                    <InfoRow title="Dietary requirements" value={family.dietaryRequirement ? family.dietaryRequirement.profile : ""} key={2} />
                    {/* <InfoRow title="Medical info" value={family.medicalInfo ? family.medicalInfo.profile : ""} key={3}/>    */}
                    <InfoRow title="Medical Requirements" value={family.flagMedicalRequest === true ? "Yes" : "No"} />
                    <InfoRow title="Room / Bedding requests" value={family.roomBeddingRequest ? family.roomBeddingRequest.profile : ""} key={4} />
                </>
            </Accordion>
            <Accordion title='Next of Kin Emergency Contact details'>
                <>
                    <LabelText ><i>Where possible, please provide contact details for two next of kin that aren’t travelling with you. It is extremely important for us to have these details on record in case of an emergency during the trip.</i></LabelText>
                    <InfoRow title="Name" value={family.emergencyContactName ? family.emergencyContactName : ""} key={1} />
                    <InfoRow title="Phone number" value={emergencyPhone} key={2} />
                </>
            </Accordion>
            <Accordion title='Travel Insurance details'>
                <>
                    <InfoRow title="Provider" value={family.insurance ? family.insurance.insuranceCompany : ""} key={1} />
                    <InfoRow title="24 hr assistance number" value={travelInsurancePhone} key={2} />
                    <InfoRow title="Policy number" value={family.insurance ? family.insurance.insurancePolicyNumber : ""} key={3} />
                </>
            </Accordion>
        </React.Fragment>
    );
}

const InfoRow = (props: IInfoRowProps) => {
    return (
        <PartyInfoWrapper>
            <PartyInfoTitle>{props.title} </PartyInfoTitle> <PartyInfoValue>{props.value}</PartyInfoValue>
        </PartyInfoWrapper>
    )
}

const TermWrapper = styled.p`
display:inline-block;
flex-direction:row nowrap;
color: #b3b3b3;
font-size: 14px;
&:last-child{
    border-bottom:none;
}
`;

const PartyInfoWrapper = styled.div`
display:flex;
flex-flow:column nowrap;
border-bottom:2px solid ${({ theme }) => theme.colors.labelLight};
padding:20px 0 10px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    flex-flow:row nowrap;
}
`;

const PartyInfoTitle = styled.div`
color:${({ theme }) => theme.colors.label};
width:100%;

@media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) { 
padding-bottom : 10px;
}
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    width:30%;
}
`;

const PartyInfoValue = styled.div`
`;

const StyledHeadline4 = styled(Headline4)`
color: ${({ theme }) => theme.colors.primaryDark};
font-weight: 500;
margin-top:0;
margin-bottom:10px;


@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    margin-top:10px;
    margin-bottom:25px;
    
}`;
