import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconCar = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(0.781 14.489)">
        <path 
        fill={props.color} 
        d="M49.412,33.886a6.475,6.475,0,0,0-3.86-5.736c-1.507-.617-8.129-2.909-9.486-3.311-.838-.7-5.391-4.416-9.335-5.7-4.307-1.407-16.935.069-21.437.707L2.755,24.353A12.32,12.32,0,0,0,1,30.689v5.4a3.129,3.129,0,0,0,3.125,3.125H5.742a5.648,5.648,0,0,1,0-1.562H4.125a1.564,1.564,0,0,1-1.562-1.562v-5.4a10.779,10.779,0,0,1,1.543-5.551l2.17-3.856c.356-.048.952-.125,1.713-.217l.662,3.107a3.751,3.751,0,0,0,3.455,2.958c.276.015,19,1.058,19.277,1.058a6.224,6.224,0,0,0,3.359-.981l1.211-.772c3.126.933,7.776,2.562,8.752,3.027a5.235,5.235,0,0,1,3.149,4.531,9.162,9.162,0,0,1-.526,3.657H44.7a5.652,5.652,0,0,1,0,1.563h3.643A10.362,10.362,0,0,0,49.412,33.886ZM20.531,26.032l-8.34-.462a2.186,2.186,0,0,1-2.013-1.723l-.631-2.963c3-.336,7.327-.743,10.984-.78Zm10.589.586-9.026-.5V20.113a15.852,15.852,0,0,1,4.152.507c3.07,1,6.689,3.715,8.2,4.92A5.094,5.094,0,0,1,31.12,26.618Z" 
        transform="translate(-1 -18.546)"/>
        <path 
        fill={props.color} 
        d="M40.25,38a4.691,4.691,0,0,0-4.617,3.906H18.3a4.688,4.688,0,1,0,0,1.563H35.633A4.685,4.685,0,1,0,40.25,38ZM13.688,45.813a3.125,3.125,0,1,1,3.125-3.125A3.129,3.129,0,0,1,13.688,45.813Zm26.563,0a3.125,3.125,0,1,1,3.125-3.125A3.129,3.129,0,0,1,40.25,45.813Z" 
        transform="translate(-2.75 -22.802)"/>
      </g>
    </Svg>
  );
});