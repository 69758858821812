import { IAppAction } from '../app-action';
import { contactStateActions } from './contact-actions';
import { IAppUser, IFamilyDetails } from '../../models';
import { getDefaultContactState, IContactState } from './contact-state';

const handlers = {
    [contactStateActions.SET_CONTACT_ON_LOGIN_SUCCESS]: (state: IContactState, payload: IAppUser): IContactState => {
        return {
            ...state,
            contact: {
                ...payload,
                contactId: payload.id,
                isPartyDetailsEditable:false,
                familyMembers: []
            }
        };
    },
    [contactStateActions.FETCH_FAMILY_DETAILS]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            isProcessing: true
        };
    },
    [contactStateActions.FETCH_FAMILY_DETAILS_SUCCESS]: (state: IContactState, payload: IFamilyDetails): IContactState => {
        return {
            ...state,
            contact: {
                ...state.contact,
                familyMembers: [...payload?.familyDtos],
                isPartyDetailsEditable:payload?.isPartyDetailsEditable
            },
            isProcessing: false
        };
    },
    [contactStateActions.FETCH_CONTACT_PROFILE]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            isProcessing: true
        };
    },
    [contactStateActions.FETCH_CONTACT_PROFILE_SUCCESS]: (state: IContactState, payload: IAppUser): IContactState => {
        let family = state.contact.familyMembers;
        return {
            ...state,
            contact: {
                ...payload,
                contactId: payload.id,
                familyMembers: [...family],
                isPartyDetailsEditable: state.contact.isPartyDetailsEditable
            },
            isProcessing: false
        };
    },
    [contactStateActions.SEARCH_ADDRESS]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            isProcessing: true
        };
    },
    [contactStateActions.SEARCH_ADDRESS_SUCCESS]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            addressResult: payload,
            isProcessing: false
        };
    },
    [contactStateActions.SEARCH_ADDRESS_ERROR]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            isProcessing: false
        };
    },
    [contactStateActions.FETCH_ADDRESS]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            isProcessing: true
        };
    },
    [contactStateActions.FETCH_ADDRESS_SUCCESS]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            selectedAddress: payload,
            isProcessing: false
        };
    },
    [contactStateActions.FETCH_ADDRESS_ERROR]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            isProcessing: false
        };
    },
    [contactStateActions.CLEAR_SELECTED_ADDRESS]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            selectedAddress: null
        };
    },
    [contactStateActions.CLEAR_ADDRESSES]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            addressResult: []
        };
    },
    [contactStateActions.UPDATE_FAMILY_DETAILS]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            isProcessing: true,
            lastUpdatedFamilyId: 0
        };
    },
    [contactStateActions.UPDATE_FAMILY_DETAILS_SUCCESS]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            isProcessing: false,
            partyDetailErrors: [],
            showDialogBox: false,
            lastUpdatedFamilyId: payload
        };
    },
    [contactStateActions.UPDATE_FAMILY_DETAILS_ERROR]: (state: IContactState, payload: any): IContactState => {
        return {
            ...state,
            partyDetailErrors: payload,
            isProcessing: false,
            lastUpdatedFamilyId: 0
        };
    },
    [contactStateActions.SHOW_DIALOG_BOX]: (state: IContactState, payload: boolean): IContactState => {
        return {
            ...state,
            showDialogBox: payload
        };
    },
    [contactStateActions.CLEAR_LAST_UPDATED_FAMILY]: (state: IContactState): IContactState => {
        return {
            ...state,
            lastUpdatedFamilyId: 0 
        };
    },
    [contactStateActions.SET_ASSIGN_NEWFAMILY_TO_BOOK_PARTY]: (state: IContactState, payload: boolean): IContactState => {
        return {
            ...state,
            assignNewFamilyToBookParty: payload
        };
    }
};

const contactReducer = (state: IContactState = getDefaultContactState(), action: IAppAction) => {
    return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default contactReducer;