import { ICurrency } from "../models";

const currencyMapping = [
{ currency: 'US Dollar', code:'USD', symbol: '$'},
{ currency: 'UK Pound Sterling', code:'GBP', symbol: '£'},
{ currency: 'Hong Kong Dollar', code:'HKD', symbol: '$'},
{ currency: 'Singapore Dollar', code:'SGD', symbol: '$'},
{ currency: 'Swiss Franc', code:'CHF', symbol: 'CHF'},
{ currency: 'Euro', code:'EUR', symbol: '€'},
{ currency: 'Canadian Dollar', code:'CAD', symbol: '$'},
{ currency: 'Fiji Dollar', code:'FJD', symbol: '$'},
{ currency: 'South African Rand', code:'ZAR', symbol: 'R'},
{ currency: 'Malaysian Ringgit', code:'MYR', symbol: 'RM'},
{ currency: 'New Zealand Dollar', code:'NZD', symbol: '$'},
{ currency: 'Australian Dollar', code:'AUD', symbol: '$'},
{ currency: 'Japanese Yen', code:'JPY', symbol: '¥'},
{ currency: 'Swedish Kroner', code:'SEK', symbol: 'kr'},
{ currency: 'Icelandic Kroner', code:'ISK', symbol: 'kr'},
{ currency: 'Brazilian Real', code:'BRL', symbol: 'R$'},
{ currency: 'Norwegian Krone', code:'NOK', symbol: 'kr'},
{ currency: 'Danish Krone', code:'DKK', symbol: 'kr'},
{ currency: 'Chinese Renminbi', code:'CNY', symbol: '¥'},
{ currency: 'Poland Zloty', code:'PLN', symbol: 'zl'},
{ currency: 'Croatian Kunar', code:'HRK', symbol: 'kn'},
{ currency: 'Indonesian Rupiah', code:'IDR', symbol: 'Rp'},
{ currency: 'New Taiwan Dollar', code:'TWD', symbol: '$'},
{ currency: 'Zambian Kwacha', code:'ZMW', symbol: 'ZK'},
{ currency: 'Qatari Rial', code:'QAR', symbol: '?.?'},
{ currency: 'Bahraini Dinar', code:'BHD', symbol: '.?.?'},
{ currency: 'Czech Koruna', code:'CZK', symbol: 'Kc'},
{ currency: 'Israel Shekel', code:'ILS', symbol: '₪'},
];

export const getCurrencySymbol = (currency: string) =>
    currencyMapping.find(x => x.currency === currency)?.symbol

export const getCurrencyCode = (currency: string) =>
    currencyMapping.find(x => x.currency === currency)?.code


export const getCurrencyCodeAndSymbol = (currency: ICurrency): string => {
    return currency.currencyCode + ' ' + currency.displaySymbol;
}