import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { PartyInfoTitle } from ".";
import { Checkbox, CheckboxType, Dropdown, Input, InputTypes, LinkButton } from "..";
import { IBillingAddress, IContact, IProfileUpdate } from '../../models';
import { IAppState } from '../../store/app-state';
import { clearAddresses, clearSelectedAddress, updateProfile } from '../../store/contact/contact-actions';
import Button, { ButtonType } from '../button';
import { PostCodeDropdown } from '../postcode-lookup';

const defaultBillingAddress = (contact: IContact): IBillingAddress => {
    if (contact && contact.billingAddress) {
        return contact.billingAddress;
    }
    else {
        return {
            address1: '',
            address2: '',
            address3: '',
            address4: '',
            city: '',
            country: { id: 0, title: '' },
            county: '',
            // linkedContactId: contact ? contact.contactId : 0,
            linkedContactId: contact ? contact.contactId : "",
            postCode: '',
            sameAsPostalAddress: contact ? contact.billingAsPostal : false,
            updatingFlagBillingIsSameAsPostalAddress: false
        }
    }
}

interface IBillingAddressProp{
    onSave():void;
}

export const BillingAddress = (props: IBillingAddressProp) => {
    const dispatch = useDispatch();
    const countries = useSelector((state: IAppState) => state.referenceData.countries);
    const contact = useSelector((state: IAppState) => state.contact.contact);
    const [addNew, setAddNew] = React.useState<boolean>(false);
    const [addressManual, setAddressManual] = React.useState<boolean>(false);
    const [billingAddress, setBillingAddress] = React.useState<IBillingAddress>(defaultBillingAddress(contact));
    const [sameAsPostal, setSameAsPostal] = React.useState<boolean>(contact ? contact.billingAsPostal : false);    
    const [country, setCountry] = React.useState<string>(contact && contact.billingAddress && contact.billingAddress.country ? contact.billingAddress.country.title : '');
    const addressSelected = useSelector((state: IAppState) => state.contact.selectedAddress);

    const countryList = countries ? countries.map(c => c.country) : [];

    useEffect(() => {
        if (addressSelected !== null) {
            const payLoad: IProfileUpdate = {
                contactId: contact.contactId,
                fieldName: 'address',
                value: addressSelected
            };

            dispatch(updateProfile(payLoad));
            dispatch( clearSelectedAddress());
        }
    }, [addressSelected])
    

    useEffect(() => {
        if (contact.billingAsPostal === true) {
            const address: IBillingAddress = {
                address1: '',
                address2: '',
                address3: '',
                address4: '',
                city: '',
                country: { id: 0, title: '' },
                county: '',
                // linkedContactId: contact ? contact.contactId : 0,
                linkedContactId: contact ? contact.contactId : "",
                postCode: '',
                sameAsPostalAddress: contact ? contact.billingAsPostal : false,
                updatingFlagBillingIsSameAsPostalAddress: false
            }
            setBillingAddress(address);
        }

    },[contact])

    useEffect(() => {
        setSameAsPostal(contact.billingAsPostal);
    }, [contact.billingAsPostal])

    const handleChange = (name: string, data: any) => {
        let address: IBillingAddress = {
            ...billingAddress,
            [name]: data
        };
        setBillingAddress(address);
    }

    const handleSave = () => {        
        if (isValidAddress()) {

            let address = billingAddress;
            const selectedCountry = countries.find(c=> c.country=== country);
            if(selectedCountry){
                address= {
                    ...billingAddress,
                    country : {
                        id : selectedCountry.countryId,
                        title : selectedCountry.country
                    }
                }
            }

            let payLoad: IProfileUpdate = {
                contactId: contact.contactId,
                fieldName: 'address',
                value: address
            };
            dispatch(updateProfile(payLoad));
            props.onSave();
        }
    }

    const isValidAddress = (): boolean => {

        if ((billingAddress.address1.length > 0 || billingAddress.address2.length > 0)
            && billingAddress.postCode.length > 0
            && billingAddress.country) {
            return true;
        }
        return false;
    }

    const onAddNewClick = () => {
        dispatch(clearAddresses());
        dispatch(clearSelectedAddress());
        setAddNew(true);
    }

    const handleCheckBoxClick = () => {   
        
        const billingAsPostal = !sameAsPostal;
        setSameAsPostal(billingAsPostal);    
     
        const payLoad: IProfileUpdate = {
            contactId: contact.contactId,
            fieldName: 'address',
            value: {
                ...billingAddress,
                sameAsPostalAddress: billingAsPostal,
                updatingFlagBillingIsSameAsPostalAddress : true
                }
        };
        dispatch(updateProfile(payLoad));
    }
    return (
        <AddressContent>
            <PartyInfoTitle> Billing Address </PartyInfoTitle>
            <Checkbox type={CheckboxType.SECONDARY} label="Same as postal address" isChecked={sameAsPostal} onClick={handleCheckBoxClick} />

            {addNew === true && addressManual === false && contact.billingAddress === null &&
                <PostCodeWrapper>
                    <PostCodeDropdown />
                    <StyledLinkedButton onClick={() => setAddressManual(true)}>
                        Enter address manually
                </StyledLinkedButton>
                </PostCodeWrapper>}                   
            {(addressManual === true || contact.billingAddress) &&
                <>
                    <InputWrapper>
                        <Input
                            label="Address Line 1"
                            type={InputTypes.TEXT}
                            value={billingAddress.address1}
                            maxLength={50}
                            onChange={(e: any) => handleChange('address1', e.target.value)}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            label="Address Line 2"
                            maxLength={50}
                            type={InputTypes.TEXT}
                            value={billingAddress.address2}
                            onChange={(e: any) => handleChange('address2', e.target.value)}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            label="Town"
                            maxLength={50}
                            value={billingAddress.city}
                            onChange={(e: any) => handleChange('city', e.target.value)}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            label="Postcode"
                            maxLength={10}
                            type={InputTypes.TEXT}
                            value={billingAddress.postCode}
                            onChange={(e: any) => handleChange('postCode', e.target.value)}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <Dropdown
                            label="Country"
                            items={countryList}
                            isValid={true}
                            defaultValue='Please Choose'
                            selected={billingAddress.country ? billingAddress.country.title : ''}
                            onChange={e => setCountry(e)}
                        />
                    </InputWrapper>
                    <ButtontWrapper><ButtonAddNew type={ButtonType.MAIN} text="Save" onClick={handleSave} /></ButtontWrapper>
                </>
            }

            {(addNew === false && !contact.billingAddress) && <ButtonAddNew type={ButtonType.ALTERNATIVE} text="Add New" onClick={onAddNewClick} />}

        </AddressContent>
    )
};



const AddressContent = styled.div`
display:flex;
flex-flow:column nowrap;
padding:10px 0 0;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    flex-flow:column nowrap;
    width: 50%
}
`;

const ButtonAddNew = styled(Button)`
width:120px;
margin-top : 25px;
`;
const ButtontWrapper = styled.div`
width: 25%;
display: flex;
flex-direction : 'row-reverse'
`;

const PostCodeWrapper = styled.div`
margin-top: 10px;
`;

const StyledLinkedButton = styled(LinkButton)`
margin-top: 10px;
`;

const InputWrapper = styled.div`
margin: 5px 0px;
`;