import * as React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Headline5 } from '../typography/headline/headline';
import { Input, InputTypes } from '../input/input';
import Button, { ButtonType } from '../button/button';
import { saveNewPassword } from '../../store/session';
import { PasswordStrengthValidator } from '../password-strength-validator';

const NewPassword = (props: { code: string, cancel: () => void }) => {
  const { code, cancel } = props;
  const [password, setPassword] = React.useState<string>("");
  const [password2, setPassword2] = React.useState<string>("");
  const [isValidPassword, setIsValidPassword] = React.useState<boolean>(false);
  const [samePassword, setIsSamePassword] = React.useState<boolean>(true);
  const dispatch = useDispatch();

  const onSave = () => {
    if (isSamePassword() === true && isValidPassword === true && password.length > 7) {
      dispatch(saveNewPassword({ password: password, resetCode: code }));
    }
  }

  const isSamePassword = (): boolean => {
    const isSame = password === password2;
    setIsSamePassword(isSame);
    return isSame;
  }


  const onConfirmPasswordChange = (password: string) => {
    setPassword2(password);
    setIsSamePassword(true);
  }
  return (
    <NewPasswordWrapper>
      <StyledHeadline5>Create new password</StyledHeadline5>
      <Input
        placeholder="password"
        type={InputTypes.PASSWORD}
        value={password}
        maxLength={25}
        label='Enter your new password'
        onChange={(e: any) => setPassword(e.target.value)}
        autoFocus
      />
      <PasswordStrengthValidator password={password} onChange={setIsValidPassword} />
      <Input
        placeholder="password"
        isValid={samePassword}
        type={InputTypes.PASSWORD}
        value={password2}
        maxLength={25}
        label={samePassword === true ? "Confirm new password" : "Please enter the same password as above"}
        onChange={(e: any) => onConfirmPasswordChange(e.target.value)}
      />
      <ButtonWrapper>
        <ButtonContainer><StyledButton type={ButtonType.SECONDARY} text="cancel" onClick={cancel} /></ButtonContainer>
        <ButtonContainerWider><Button type={ButtonType.MAIN} text="Save password" onClick={onSave} /></ButtonContainerWider>
      </ButtonWrapper>
    </NewPasswordWrapper>
  );
}

const ButtonWrapper = styled.div`
display: flex;
flex-flow: row nowrap;
justify-content: space-between;


`;

const StyledButton = styled(Button)`
`;

const ButtonContainer = styled.div`
width 100px;
margin-right: 20px;
`;

const ButtonContainerWider = styled.div`
width: 180px;
@media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
  width: 230px;
}

@media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
width: 290px;
}
`;

const StyledHeadline5 = styled(Headline5)`
color: ${({ theme }) => theme.colors.white}
`;

const NewPasswordWrapper = styled.div`
align-self: center;
display: flex;
height: 80vh;
flex-flow: column nowrap;
align-items: start;
justify-content: start;

& > * {
  margin-bottom: 20px;
}

min-width: 300px;

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
  min-width: 300px;
}

@media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    min-width: 350px;
  }

@media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
  width: 410px;
  min-width: 410px;
}
`;

export default NewPassword;
