import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconCamera = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 46 46" {...props}>
    <g transform="translate(-892 -1690)">
        <path 
        fill={props.color}
        d="M1543.944,1065a2.925,2.925,0,0,1-2.944-2.9v-18.85a2.925,2.925,0,0,1,2.944-2.9h6.177a1.474,1.474,0,0,0,1.224-.646l1.632-2.413a2.95,2.95,0,0,1,2.448-1.291h10.148a2.952,2.952,0,0,1,2.449,1.291l1.632,2.413a1.473,1.473,0,0,0,1.225.646h6.177a2.924,2.924,0,0,1,2.943,2.9v18.85a2.925,2.925,0,0,1-2.943,2.9Zm-1.472-21.75v18.85a1.462,1.462,0,0,0,1.472,1.45h33.113a1.462,1.462,0,0,0,1.472-1.45v-18.85a1.463,1.463,0,0,0-1.472-1.45h-6.177a2.949,2.949,0,0,1-2.449-1.291l-1.632-2.413a1.477,1.477,0,0,0-1.225-.646h-10.148a1.474,1.474,0,0,0-1.224.646l-1.632,2.413a2.949,2.949,0,0,1-2.449,1.291h-6.177A1.463,1.463,0,0,0,1542.471,1043.25Zm8.831,9.063a9.2,9.2,0,1,1,9.2,9.063A9.141,9.141,0,0,1,1551.3,1052.313Zm1.472,0a7.727,7.727,0,1,0,7.727-7.613A7.679,7.679,0,0,0,1552.773,1052.313Zm16.925-7.613a1.472,1.472,0,1,1,1.472,1.451A1.461,1.461,0,0,1,1569.7,1044.7Zm-24.283-5.074v-1.451h3.679v1.451Z" 
        transform="translate(-645 662)"/>
        <rect fill="none" width="46" height="46" transform="translate(892 1690)"/>
    </g>
    </Svg>
  );
});

