import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconArrowDown = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
      <g transform="translate(9.307 16.38)">
        <path
          fill={props.color}
          d="M27.606,39.1,11.913,22.581l1.7-1.614L27.606,35.7,41.6,20.967l1.7,1.614Z"
          transform="translate(-11.913 -20.967)"
        />
      </g>
    </Svg>
  );
});
