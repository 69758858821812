import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconPlus = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
      <g transform="translate(10.938 10.938)">
        <path
          fill={props.color}
          d="M41.344,26.5h-12.5V14H26.5V26.5H14v2.344H26.5v12.5h2.344v-12.5h12.5Z"
          transform="translate(-14 -14)"
        />
      </g>
    </Svg>
  );
});
