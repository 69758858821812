import styled from "styled-components";
import Text from "../text";

export const Body1 = Text;
export const Body2 = styled(Text)`
  font-size: 13px;
  line-height: 1.7;
`;

export default Body1;
