import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError, IRetrieveHoliday } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import {  fetchHolidays, holidayStateActions, retriveHolidayError, updateRetriveHoliday } from './holiday-actions';

export const retrieveHolidayEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.RETRIEVE_HOLIDAY),
        mergeMap((action) => {
            let holiday: IRetrieveHoliday  = action.payload.holiday;
            var data = JSON.stringify(holiday);
            return api.post(endPointKeys.api_travel, `Booking/MSD/RetrieveHolidaySharing`, data).pipe(
                map(res => res.data),
                mergeMap((m : IRetrieveHoliday) => {                  
                    return of(updateRetriveHoliday(action.payload.updateHolidaySharing, m.isBooking), fetchHolidays(holiday.ContactId));
                }),
                catchError((error) => {
                    const appError : IAppError = { error: error, message: error.response.data.message};
                    return of(retriveHolidayError(appError))
                }))
        })
    )