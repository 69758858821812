import * as React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ButtonType, Input, InputTypes, PasswordStrengthValidator } from '../components';
import Button from '../components/button';
import { ImageBackground } from '../components/image-background/image-background';
import { Headline3 } from '../components/typography';
import { resetPasswordSuccessFlag, updatePassword } from '../store/session';
import { IAppState } from '../store/app-state';
import { useEffect } from 'react';

export const ChangePasswordPage = () => {
    const history = useHistory();
    const [password, setPassword] = React.useState<string>("");
    const [password2, setPassword2] = React.useState<string>("");
    const [isValidPassword, setIsValidPassword] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const contact = useSelector((state: IAppState) => state.contact.contact);
    const isProcessing = useSelector((state: IAppState) => state.session.isProcessing);
    const updateSuccess = useSelector((state: IAppState) => state.session.updatePasswordSuccess);
    const [samePassword,setIsSamePassword] =  React.useState<boolean>(true);
    const onSubmit = () => {
        if (isSamePassword() === true && isValidPassword === true && password.length > 7) {
            dispatch(updatePassword({ id: contact.contactId, title: password }));
        }
    }

    const onCancel = () => {
        history.goBack();
    }

    const isSamePassword = (): boolean => {
        const isSame = password === password2;
        setIsSamePassword(isSame);
        return isSame;
    }

    useEffect(() => {
        if(updateSuccess === true){
            dispatch(resetPasswordSuccessFlag());
            setTimeout(() => {
                history.goBack();
            },
                1000);            
        }
    }, [updateSuccess]);

    const onConfirmPasswordChange = (password: string) =>{
        setPassword2(password);
        setIsSamePassword(true);
    }
    

    return (
        <React.Fragment>
            <ImageBackground url="/images/backgroundImagePattern.gif" />
            <PageWrapper>
                <StyledHeadline3>Change Password</StyledHeadline3>
                <FormContainer>
                    <InputContainer>
                        <StyledInput
                            label="Enter your new password"                           
                            type={InputTypes.PASSWORD}
                            value={password}
                            maxLength={25}
                            onChange={(e: any) => setPassword(e.target.value)}
                        />
                        <StyledPasswordStrengthValidator password={password} onChange={setIsValidPassword} />
                        <StyledInput
                            label= {samePassword === true ?"Confirm new password" : "Please enter the same password as above"}                           
                            type={InputTypes.PASSWORD}
                            value={password2}
                            isValid={samePassword}
                            maxLength={25}
                            onChange={(e: any) => onConfirmPasswordChange(e.target.value)}
                        />
                    </InputContainer>
                    <ButtonWrapper>
                        <ButtonCancel type={ButtonType.ALTERNATIVE} text="Cancel" onClick={onCancel} />
                        <ButtonSave type={ButtonType.MAIN} text="Save Password" onClick={onSubmit} processing={isProcessing}/>
                    </ButtonWrapper>

                </FormContainer>
            </PageWrapper>
        </React.Fragment>
    );
}

const PageWrapper = styled.div`
display: flex;
flex-flow: column wrap;
margin:0 auto;
width: 300px;
flex-flow: column nowrap;
margin-left : 5px;
@media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 20px);
    margin:0 auto;
}
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: calc(100% - 80px);
    max-width : 800px;
    margin:0 auto;
}
`;

const StyledHeadline3 = styled(Headline3)`
color: ${({ theme }) => theme.colors.white};
padding-left:6px;
margin-bottom: 30px;
margin-top: 60px;
`;

const FormContainer = styled.div`
display: flex;
flex-flow: column nowrap;
width: 100%;
height: 450px;
background-color: ${({ theme }) => theme.colors.white};
padding : 40px;
`;

const InputContainer = styled.div`
display: flex;
flex-flow: column nowrap;
width: 100%;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:60%;
}
`;

const StyledInput = styled(Input)`
margin-bottom: 30px;
`;

const ButtonCancel = styled(Button)`
width:40%;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:200px;
}
height:50px;
margin-right: 10px;
`;

const ButtonWrapper = styled.div`
width: 100%;
flex-flow: row nowrap;
display: flex;
justify-content: space-between;
`;

const ButtonSave = styled(Button)`
width:50%;
height:50px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:200px;
}
`;

const StyledPasswordStrengthValidator = styled(PasswordStrengthValidator)`
 color: ${({ theme }) => theme.colors.primary};
margin-bottom: 15px;
`;