import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { sessionStateActions, logOutSuccess, logOutError } from './session-actions';
import { IAppState } from '../app-state';


export const logoutEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(sessionStateActions.LOGOUT),
        mergeMap(action => {
            const contactId = state$.value.contact.contact ? state$.value.contact.contact.contactId : 0;
            var data = JSON.stringify(contactId);
            return api.post(endPointKeys.api_contact, 'user/logout', data).pipe(
                map(result => result.data),
                mergeMap(() => {
                    localStorage.clear();
                    return of(logOutSuccess());
                }),
                catchError(error => {
                    localStorage.clear();
                    const appError: IAppError = { error, message: "Failed to log out" };
                    return of(logOutError(appError));
                }))
        })
    )