import { IAppUser, IPaymentDetails, IPaymentResults } from "../../models";

export interface IPaymentState {
	paymentDetails: IPaymentDetails | null;
	paymentResults: IPaymentResults | null;
	paymentError: string | null;
	isProcessing: boolean;
	termsSaved: boolean;
	isModalOpen: boolean;
	paymentSessionResults : any;
}

export const defaultAppUserState: IAppUser | null = null;

const defaultState: IPaymentState = {
	paymentDetails: null,
	paymentResults: null,
	paymentError: null,
	isProcessing: false,
	termsSaved: false,
	isModalOpen: false,
	paymentSessionResults: null,
};

export const getDefaultPaymentState = (options?: any) => {
	return {
		...defaultState,
		...options,
	};
};
