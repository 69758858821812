import React, { memo } from "react";
import styled from "styled-components";
import Chips from "../typography/chips";

export interface TagProps {
  label: string;
  className?: string;
}

export const Tag = memo<TagProps>(
  ({ label, className }): JSX.Element => {
    return (
      <Container className={className}>
        <StyledLabel>{label}</StyledLabel>
      </Container>
    );
  }
);

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 4px;
  background-color: ${({ theme: { colors } }) => colors.primary};
`;

const StyledLabel = styled(Chips)`
  display: inline;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.white};
`;
