import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconDelete = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 23.434 23.434" {...props}>
    <g transform="translate(-13.234 -13.234)">
    <g transform="translate(13.234 13.234)">
        <path d="M40.374,18.6,38.716,16.94,28.657,27,18.6,16.94,16.94,18.6,27,28.657,16.94,38.716,18.6,40.374l10.06-10.06,10.06,10.06,1.657-1.657-10.06-10.06Z" transform="translate(-16.94 -16.94)"/>
    </g>
    </g>
    </Svg>
  );
});


