import React, { memo } from "react";
import styled from "styled-components";
import Button, { ButtonType } from "../button";
import { Body1, Body2, Headline5 } from "../typography";

export interface ConsultantBoxProps {
  profileImage: string;
  name: string;
  downloadText? : string;
  onAmendmentCallback?: () => void;
  onConciergeCallback?: () => void;
  onDownloadQuoteCallback?: () => void;
  onCancelHolidayCallback?: ()=> void;
}
export const ConsultantBox = memo<ConsultantBoxProps>(
  ({
    name,
    profileImage,
    downloadText,
    onAmendmentCallback,
    onConciergeCallback,
    onDownloadQuoteCallback,
    onCancelHolidayCallback
  }) => {
    return (
      <Container>
        <StyledBody2>Your Travel Consultant</StyledBody2>
        <AvatarContainer>
          <Avatar src={profileImage} />
        </AvatarContainer>
        <StyledHeadline>{name}</StyledHeadline>
        <StyledDescription>
          Hi, I'm here to help you book an incredible holiday.
        </StyledDescription>
        {onAmendmentCallback && (
          <StyledButton
            type={ButtonType.ALTERNATIVE}
            text="request amendment"
            onClick={onAmendmentCallback}
          />
        )}
        {onConciergeCallback && (
          <StyledButton
            type={ButtonType.ALTERNATIVE}
            text="get in touch"
            onClick={onConciergeCallback}
          />
        )}
        {onCancelHolidayCallback && (
          <StyledButton
            type={ButtonType.ALTERNATIVE}
            text="cancel holiday"
            onClick={onCancelHolidayCallback}
          />
        )}
        {onDownloadQuoteCallback && (
          <StyledButton
            type={ButtonType.ALTERNATIVE}
            text={downloadText ? downloadText : "download quote"} 
            onClick={onDownloadQuoteCallback}
          />
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 330px;
  padding: 16px;
  margin: 0 auto;
  background-color: ${({ theme: { colors } }) => colors.labelWhite};
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;
  margin: 24px 0;
`;

interface AvatarProps {
  src: string;
}
const Avatar = styled.div<AvatarProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid ${({ theme: { colors } }) => colors.labelLight};
  background-image: url(${({ src }) => src});
  background-size: contain;
`;

const StyledBody2= styled(Body2)`
  text-align: center;
  color: ${({ theme: { colors } }) => colors.labelDark};
`;

const StyledHeadline = styled(Headline5)`
  margin: 18px 0;
  font-weight: 700;
  font-size: 16px;
  margin-top: -8px;
`;

const StyledDescription = styled(Body1)`
  text-align: center;
  font-size: 12px;
  margin-top: -10px;
  padding: 0px 40px 15px 40px;
`;

const StyledButton = styled(Button)`
  margin-top: 8px;
  margin-bottom: 10px;
`;
