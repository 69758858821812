export const ItineraryIcons = [
    { type: "Flight", icon: "IconPlaneTakeoff" },
    { type: "Private", icon: "IconTaxi" },
    { type: "Transfer", icon: "IconTaxi" },
    { type: "Accomm", icon: "IconBed" },
    { type: "Experience", icon: "IconCamera" },
    { type: "Offer", icon: "IconTag" },
    { type: "Restaurants", icon: "IconCutlery" },
    { type: "Ski", icon: "IconSki" },
    { type: "Spa", icon: "IconSki" },
    { type: "Childcare", icon: "IconSki" },
    { type: "Shopping", icon: "IconSki" },
    { type: "Activities", icon: "IconSki" },
    { type: "Alternate", icon: "IconArrowsLeftRight" },
    { type: "Upgrade", icon: "IconArrowUp" },
    { type: "Boat", icon: "IconCruise" },
    { type: "Boutique Hotel", icon: "IconBed" },
    { type: "Bush Camp", icon: "IconBed" },
    { type: "Catered Villa", icon: "IconBed" },
    { type: "City Hotel", icon: "IconBed" },
    { type: "Classic Camp", icon: "IconBed" },
    { type: "Classic Hotel", icon: "IconBed" },
    { type: "Classic Lodge", icon: "IconBed" },
    { type: "Scott Dunn Flagship chalet", icon: "IconBed" },
    { type: "Guesthouse", icon: "IconBed" },
    { type: "Luxury Guesthouse", icon: "IconBed" },
    { type: "Luxury Hotel", icon: "IconBed" },
    { type: "Luxury Lodge", icon: "IconBed" },
    { type: "Mobile Camp", icon: "IconBed" },
    { type: "Scott Dunn chalet", icon: "IconBed" },        
    { type: "Other Chalet", icon: "IconBed" },
    { type: "Premier Chalet", icon: "IconBed" },
    { type: "Scott Dunn Villa", icon: "IconBed" },
    { type: "Self Catered Villa", icon: "IconBed" },
    { type: "Train", icon: "IconTrain" },
    { type: "Villa Hotel", icon: "IconBed" },
    { type: "Luxury Camp", icon: "IconBed" },
    { type: "Luxury Catered Villa", icon: "IconBed" },
    // { type: "Other", icon: "IconBed" },
    { type: "International flight", icon: "IconPlaneTakeoff" },
    { type: "Domestic flight", icon: "IconPlaneTakeoff" },
    { type: "Private charter flight", icon: "IconPlaneTakeoff" },
    { type: "Shared charter flight", icon: "IconPlaneTakeoff" },
    { type: "Charter Flight", icon: "IconPlaneTakeoff" },
    { type: "Road transfer", icon: "IconTaxi" },
    { type: "Road", icon: "IconCar" },
    { type: "Deluxe transfer", icon: "IconTaxi" },
    { type: "Scott Dunn childrens club", icon: "IconBed" },
    { type: "Ourspace children's club hotel", icon: "IconBed" },
    { type: "Luxury car", icon: "IconCar" },
    { type: "Family car", icon: "IconCar" },
    { type: "4x4 car", icon: "Icon4x4" },
    { type: "4x4", icon: "Icon4x4" },
    { type: "Sports car", icon: "IconCar" },
    { type: "Private house and apartment", icon: "IconBed" },
    { type: "Economy", icon: "IconBed" }, ///??????
    { type: "Self drive", icon: "IconCar" },
    { type: "Self-Drive", icon: "IconCar" },
    { type: "Boat transfer", icon: "IconCruise" },
    { type: "Train transfer", icon: "IconTrain" },
    { type: "Air Transfer", icon: "IconTaxi" },
    { type: "Villa Estates", icon: "IconBed" },
    { type: "Car hire", icon: "IconCar" },
    { type: "On foot", icon: "IconFoot" },
    { type: "Airport Service", icon: "IconFoot" },
    { type: "Ferry transfer", icon: "IconCruise" },
    { type: "Ferry", icon: "IconCruise" },
    // { type: "Airport Service", icon: "IconTaxi" },
    { type: "Helicopter", icon: "IconHelicopter" },
    { type: "Airport Hotel", icon: "IconBed" },
    { type: "Resort", icon: "IconBed" },
    { type: "Seaplane", icon: "IconSeaplane" },
    { type: "Scott Dunn Private Chauffeur", icon: "IconCar" },
    { type: "Shared", icon: "IconPeople" },
    { type: "Meet & Assist", icon: "IconPeople" },
    { type: "Own Arrangements", icon: "IconFoot" },
    { type: "Multi-modal", icon: "IconMultiModel" },
    { type: "Other", icon: "IconCar" },
    { type: "Chauffeur", icon: "IconCar" },
    { type: "On Foot", icon: "IconOnFoot" },
    { type: "Airport Service", icon: "IconOnFoot"},
]

export const getItineraryIconFromType = (iconType: string) => {
    const mapping = ItineraryIcons.find(x => x.type === iconType);
    if (mapping){
        return mapping.icon;
    }
    return "IconPlane";
}