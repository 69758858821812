import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import styled, { keyframes, ThemeContext } from "styled-components";
import { IconDirectionLeft, Tab, Tag } from "../../components";
import Button, { ButtonSize, ButtonType } from "../../components/button";
import { Footer } from "../../components/holiday-footer";
import {
  HolidayAbout,
  HolidayInvoice,
  HolidayItinerary,
} from "../../components/holidays";
import { ImageBackground } from "../../components/image-background/image-background";
import { Loader } from "../../components/loader";
import { Headline3 } from "../../components/typography";
import TextComponent from "../../components/typography/text/text";
import { isFutureDate } from "../../helpers/date-helper";
import { GetCloudinaryImage } from "../../helpers/getCloudinaryImages";
import { openInNewTab } from "../../helpers/openInNewTab";
import { EnumHolidayType, IHoliday, IAppUser } from "../../models";
import { IAppState } from "../../store/app-state";
import { fetchHoliday } from "../../store/holiday";
import { selectTab } from "../../store/session";
import { QuoteOverview } from "./quote-overview";
import { SDPImageBackground } from "../../components/image-background/image-sdp-background";

export const QuotePage = () => {
  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );
  const { quoteId } = useParams<{ quoteId: string }>();
  const quote: IHoliday | null = useSelector(
    (state: IAppState) => state.holidays.holiday
  );
  const isProcessing: boolean = useSelector(
    (state: IAppState) => state.holidays.isProcessing
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const tabsSelected: any = useSelector(
    (state: IAppState) => state.session.tabs
  );
  const tabSelected = tabsSelected.quote;
  const tabIdx = tabSelected ? tabSelected : 0;
  const { colors } = React.useContext(ThemeContext);
  const isActiveQuote: boolean =
    quote === null
      ? false
      : (isFutureDate(quote.quoteExpiryDate, "DD/MM/YYYY") as boolean);
  const tabs = isActiveQuote
    ? ["Overview", "Itinerary", "Invoice", "About", "Notes"]
    : ["Overview", "Itinerary", "About", "Notes"];

  React.useEffect(() => {
    dispatch(fetchHoliday(Number(quoteId)));
  }, [dispatch, quoteId]);

  const onDownload = () => {
    if (quote && quote.publishingUrl) {
      openInNewTab(quote.publishingUrl);
    }
  };

  const setTab = useCallback(
    (tab: number) => {
      dispatch(selectTab("quote", tab));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (history.action !== "POP") {
      setTab(0);
    }
  }, [setTab, history]);

  const onBack = () => {
    history.goBack();
  };

  return (
    <PageWrapper>
      {isProcessing && <Loader fullScreen={true} />}
      {quote ? (
        <>
          <ImageBackground
            url={
              quote.primaryCountryImageId
                ? GetCloudinaryImage(quote.primaryCountryImageId, true)
                : "/images/backgroundImageLogin.jpg"
            }
          />
          <ContentLayout>
            <Icon onClick={onBack}>
              <IconDirectionLeft color={colors.white} size={30} />
            </Icon>
            <StyledHeadline3>{quote.bookName}</StyledHeadline3>
            <StyledTextComponent>{quote.primaryCountry}</StyledTextComponent>
            <div>
              <StyledTag
                label={isActiveQuote ? "Live Quote" : "Expired Quote"}
              />
            </div>
            <StyledButton
              text="Download"
              download
              href={quote.publishingUrl}
              onClick={onDownload}
              target="_blank"
              type={ButtonType.ALTERNATIVE_DARK}
              size={ButtonSize.NORMAL}
              rightIcon="IconDownload"
            />
          </ContentLayout>
          <ContentContainer>
            <TabContainer>
              <StyledTab
                initialIndex={tabIdx}
                elements={tabs}
                color={colors.black}
                size={12}
                onChange={(idx: number) => setTab(idx)}
              />
            </TabContainer>
            {tabs[tabIdx] === "Overview" && (
              <SlideInContainer>
                <QuoteOverview quote={quote} />
              </SlideInContainer>
            )}
            {tabs[tabIdx] === "Itinerary" && (
              <SlideInContainer>
                <HolidayItinerary holiday={quote} />
              </SlideInContainer>
            )}
            {tabs[tabIdx] === "Invoice" && (
              <SlideInContainer>
                <HolidayInvoice
                  invoice={quote.invoice}
                  holidayType={EnumHolidayType.QUOTE}
                  quoteExpiryDate={quote.quoteExpiryDate}
                />
              </SlideInContainer>
            )}
            {tabs[tabIdx] === "About" && (
              <SlideInContainer>
                <HolidayAbout holiday={quote} />
              </SlideInContainer>
            )}
            {tabs[tabIdx] === "Notes" && (
              <SlideInContainer>
                <NotesWrapper
                  dangerouslySetInnerHTML={{ __html: quote.notes }}
                />
              </SlideInContainer>
            )}
          </ContentContainer>
          <Footer
            holiday={quote}
            isLive={isActiveQuote ? isActiveQuote : false}
          />
        </>
      ) : appUser?.isSDP ? (
        <SDPImageBackground url="/images/SDPBackgroundImagePattern.png" />
      ) : (
        <ImageBackground url="/images/backgroundImagePattern.gif" />
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: calc(100% - 10px);
  padding-left: 5px;
  & > * {
    margin-bottom: 10px;
  }
  margin: auto;
  position: relative;
  margin-top: 20px;
  margin-bottom: 13px;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 30px);
    max-width: inherit;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 700px;
    max-width: inherit;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 800px;
  }
`;

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  margin: 0 auto;
  min-height: 800px;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;

  margin-bottom: 100px;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: inherit;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 700px;
    max-width: inherit;
    padding-left: 20px;
    margin-bottom: 55px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 800px;
  }
`;

const TabContainer = styled.div`
  top: 20px;
  bottom: 50px;
  max-width: 400px;
  overflow-x: auto;
`;

const inAnimation = keyframes`
    0% {
      right: 1200px;
    }
    100% {
      right: 0px;  
    }
`;

const SlideInContainer = styled.div`
  top: 0px;
  right: 1200px;
  animation: ${inAnimation} 1200ms ease-in-out;
`;

const Icon = styled.div`
  margin-left: -3px;
  cursor: pointer;
`;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 20px;
`;

const StyledTextComponent = styled(TextComponent)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 15px;
`;

const StyledTag = styled(Tag)`
  color: ${({ theme }) => theme.colors.white};
  display: inline;
  width: auto;
  margin-top: 10px;
  letter-spacing: 0.07em;
  padding-top: 2px;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 130px;
`;

const StyledTab = styled(Tab)`
  div > li {
    color: black;
  }
`;

const NotesWrapper = styled.div`
  padding: 20px 20px 20px 20px;
  text-align: justify;
  text-justify: inter-character;
  font-size: 14px;
`;
