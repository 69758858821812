import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconIosMenu = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 25 18" {...props}>
      <g transform="translate(-78 -428)">
        <rect
          fill={props.color}
          width="25"
          height="2"
          transform="translate(78 428)"
        />
        <rect
          fill={props.color}
          width="25"
          height="2"
          transform="translate(78 436)"
        />
        <rect
          fill={props.color}
          width="25"
          height="2"
          transform="translate(78 444)"
        />
      </g>
    </Svg>
  );
});
