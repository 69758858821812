import React, { memo, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Icon, IconTypes } from "../icons/icon-component";
import { Body1, Body2 } from "../typography";

export interface InfoLockupProps {
  label: string;
  text: string;
  icon: IconTypes;
  className?: string;
}
export const InfoLockup = memo<InfoLockupProps>(({ label, text, icon, className }) => {
  const { colors } = useContext(ThemeContext);
  return (
    <Container className={className}>
      <StyledLabel>{label}</StyledLabel>
      <DataBox>
        <Icon name={icon} size={26} color={colors.primaryDark} />
        <StyledText>{text}</StyledText>
      </DataBox>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.labelWhite};
  padding-bottom: 8px;
`;

const DataBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

const StyledLabel = styled(Body2)`
  color: ${({ theme: { colors } }) => colors.labelDark};
  letter-spacing: -1px;
`;

const StyledText = styled(Body1)`
  margin-left: 16px;
  font-size: 14px;
  letter-spacing: -1px;
`;
