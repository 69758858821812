import React, { useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components';
import { ButtonHeight, ButtonType } from '../..';
import { IHoliday, ITermsAndCondition } from '../../../models';
import Button from '../../button';
import { TermsAndConditions } from '../../holiday-pay';
import { Body2, Headline4 } from '../../typography';
import TextComponent from '../../typography/text/text';
import { IconTickInCircle } from "../../icons";
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store/app-state';
import { fetchHoliday } from '../../../store/holiday';
import { paymentResultsClear } from '../../../store/payment';

interface IHolidayImportantInformationProps {
    holiday: IHoliday;
    isScottDunnUser: boolean,
    onSave(bookId: number, terms: Array<number>): void;
    onClose(): void;
    onModalClose: () => void;
}

export const HolidayImportantInformation = ({ holiday, isScottDunnUser, onSave, onClose, onModalClose}: IHolidayImportantInformationProps) => {

    const defaultTermsAndConditions: ITermsAndCondition[] = [
        ...holiday.termsAndConditions.map((tc:any) => ({
            ...tc,
            accepted: false
        }))
    ];
    const dispatch = useDispatch();
    const { colors } = useContext(ThemeContext);
    const [acceptedTerm, setacceptedTerm] = useState(defaultTermsAndConditions);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const termsSaved = useSelector((state: IAppState) => state.payment.termsSaved);

    const handleTermsConditions = (updatedTc:ITermsAndCondition) =>{
        const tcs: ITermsAndCondition[] = [
         ...acceptedTerm.filter((tc) => tc.termId !== updatedTc.termId),{...updatedTc}
     ]; 
     setacceptedTerm(tcs);
     const requiredTerms = tcs.filter(t=>t.isMandatory && !t.accepted && !t.dateOfConfirmation).length === 0;  
     setTermsAgreed(requiredTerms);
    }
 
    const getAcceptedTerms = (): Array<number> => {
        return acceptedTerm.filter(x => x.accepted).map(m => m.termId);
    }

    const save = () => {
        onSave(holiday.bookId, getAcceptedTerms());
    }

    const close = (e: any) => {
        dispatch(fetchHoliday(holiday.bookId));
        dispatch(paymentResultsClear());
        onClose();
    } 

    return (
      <PageWrapper>
        {!termsSaved && (
          <>
            <StyledHeadline4>Thank you for booking</StyledHeadline4>
            <StyledText>
              We're excited to start preparing your holiday. Please complete the
              check boxes below for us to proceed with your booking.
            </StyledText>
            <TermsAndConditions
              onClick={(r) => handleTermsConditions(r)}
              termsAndConditions={acceptedTerm}
              displayDate={false}
            />
            <BtnWrapper>
            <StyledButton
              type={ButtonType.MAIN}
              height={ButtonHeight.TALL}
              text="Save"
              onClick={termsAgreed ? save : undefined}
              disabled={!termsAgreed}
            />

           { isScottDunnUser && <StyledButton
              type={ButtonType.MAIN}
              height={ButtonHeight.TALL}
              text="Skip"
              onClick={(_e: Event) => onModalClose()}
            />
           }
            </BtnWrapper>
          </>
        )}
        {termsSaved && (
          <Container>
            <IconTickInCircle color={colors.primaryDark} size={70} />
            <StyledBody2>Thank you for your confirmation.</StyledBody2>
            <StyledButton
              type={ButtonType.MAIN}
              height={ButtonHeight.TALL}
              text="Close"
              onClick={(e: Event) => close(e)}
            />
          </Container>
        )}
      </PageWrapper>
    );
}
const PageWrapper = styled.div`
display: flex;
flex-flow: column;
padding: 0px 40px;
`;

const BtnWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const StyledText = styled(TextComponent)`
`;


const StyledHeadline4 = styled(Headline4)`
color: ${({ theme }) => theme.colors.lable};
font-weight: 700;
margin:0px;
`;

const StyledButton = styled(Button)`
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:200px;
    }
`;

const StyledBody2 = styled(Body2)`
    width: 300px;
    text-align: center;
`; 

const Container = styled.div`
display: flex;
flex-flow: column nowrap;
justify-content: center;
align-items: center;
& > * {
    margin-bottom: 20px;
  }
`;