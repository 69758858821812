import React from "react"
import styled from "styled-components";
import { IHoliday } from "../../models";
import { ApexxPaymentView } from "./index";

export interface IStepper {
    holiday : IHoliday;
    hide: () => void;
}

export const PaymentView = (props:IStepper) => {   
    return (
        <StepperContainer>
   <ApexxPaymentView holiday={props.holiday} hide={props.hide}/>
        </StepperContainer>
    );
}


const StepperContainer = styled.div`
display:flex;
flex-direction: column;
overflow:hidden;
margin:5px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin:10px;
    height: 100%;
}
`;
