import styled from "styled-components";
import Text from "../text";

declare interface IButtonTextProps {
  size: number;
}

const ButtonText = styled(Text)<IButtonTextProps>`
  font-size:  ${({ size }) => size}px;
  line-height: 1.35;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-weight: 500;
`;

export default ButtonText;
