import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError, IBookParty } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { fetchBookParties, holidayStateActions, updateBookPartyError, updateBookPartySuccess } from './holiday-actions';

export const updateHolidayBookPartyEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.UPDATE_BOOK_PARTY),
        mergeMap((action) => {
            let bookParty : IBookParty  = action.payload;
            var data = JSON.stringify(bookParty);
            return api.put(endPointKeys.api_travel, `party/assignpartymemeber`,data).pipe(
                map(res => res.data),
                mergeMap((m : any) => { 
                    return of(updateBookPartySuccess(m),fetchBookParties(bookParty.bookId))
                }),
                catchError((error) => {
                    const appError : IAppError = { error: error, message: 'Error while assigning guest.'};
                    return of(updateBookPartyError(appError))
                }))
        })
    )