import React from "react"
import styled from "styled-components";
import Button, { ButtonHeight, ButtonType } from "../button";
export interface IDeleteBookingDialog{
    isQuote: boolean;
    OnClose: () => void;
    onDelete: () => void;
}


export const DeleteBookingDialog = (props:IDeleteBookingDialog) => {   

    return (
        <Container>
            <ContentWrapper>{`This ${ props.isQuote ? 'quote' : 'booking'} will remain visible for the lead passenger but will be removed from your list. Are you sure?`}</ContentWrapper>
            <ButtonWrapper>
                
                    <DialogButton type={ButtonType.ALTERNATIVE} height={ButtonHeight.TALL}
                    text= "Close" onClick={props.OnClose}/>
                

                <DialogButton type={ButtonType.MAIN} height={ButtonHeight.TALL}
                text="Delete" onClick={props.onDelete} 
                    
                />
            </ButtonWrapper>
        </Container>
    );
}


const Container = styled.div`
display:flex;
flex-direction: column;
overflow:hidden;
margin:5px;
max-width: 600px;
max-height:  600px;
color: rgb(0, 64, 46);
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin:10px;
    height: 100%;
}
`;


const ContentWrapper = styled.div`
overflow-y: hidden;
overflow-x: hidden;
padding: 0px 3px;
display: flex;
height: 100%;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0px 60px;
}
`;

const ButtonWrapper = styled.div`
margin:25px 65px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        display:flex;        
        flex-direction:column nowrap;
        justify-content: space-start;
    }
`;

const DialogButton = styled(Button)`
    width: 100px;
    margin-right: 10px;
    vertical-align: middle;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width:200px;
        margin-right: 40px;
    }
`;
