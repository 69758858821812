import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconDownload= memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
      <rect fill="none"  width="50" height="50" />
      <g transform="translate(8.594 8.594)">
        <path 
        fill={props.color} 
        d="M42.25,45v4.688H12.563V45H11v6.25H43.813V45Z" 
        transform="translate(-11 -18.438)"
        />
        <path 
        fill={props.color} 
        d="M38.311,29.042l-1.1-1.1-6.88,6.88V11H28.764V34.817l-6.88-6.88-1.1,1.1,8.766,8.766Z" 
        transform="translate(-13.139 -11)"
        />
      </g>
    </Svg>
  );
});