import React, { memo, useCallback, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Icon, IconTypes } from "../icons/icon-component";
import { Tag } from "../tag";
import { Headline3 } from "../typography";
import Subtitle from "../typography/subtitle";

export interface PageHeaderProps {
  heading: string;
  location: string;
  tags?: string[];
  onBackClick: () => void;
}

export const PageHeader = memo<PageHeaderProps>(
  ({ heading, location, tags, onBackClick }): JSX.Element => {
    const { colors } = useContext(ThemeContext);

    const renderTagCallback = useCallback((item: string, idx: number) => {
      return <Tag key={idx} label={item} />;
    }, []);
    return (
      <Container>
        <IconWrapper onClick={onBackClick}>
          <Icon
            name={IconTypes.IconDirectionLeft}
            color={colors.white}
            size={28}
          />
        </IconWrapper>
        <StyledHeading>{heading}</StyledHeading>
        <StyledLocation>{location}</StyledLocation>
        {tags && tags.length && (
          <TagContainer>{tags.map(renderTagCallback)}</TagContainer>
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${({ theme: { colors } }) => colors.transparent};
`;

const IconWrapper = styled.button`
  display: inline-flex;
  border: none;
  outline: none;
  padding: 0;
  background-color: ${({ theme: { colors } }) => colors.transparent};
`;

const StyledHeading = styled(Headline3)`
  color: ${({ theme: { colors } }) => colors.white};
  margin: 24px 0 8px 0;
`;

const StyledLocation = styled(Subtitle)`
  color: ${({ theme: { colors } }) => colors.white};
  margin-bottom: 8px;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;

  & > * {
    margin-right: 8px;
  }
`;
