import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconCruise= memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
      <g transform="translate(0.803 7.055)">
      <path 
        fill={props.color}
        d="M43.241,37.132H41.906c.163-.3,6.556-11.875,6.678-12.19.236-1.006.115-3.8-3.953-4.7l-2.56-6.855a3.139,3.139,0,0,0-2.928-2.032H28a5.481,5.481,0,0,0-2.649.684l-.692.383c-.63-1.648-1.78-3.388-3.774-3.388-4.26,0-7.1,3.619-9.422,12.235L8.636,22.678A4.65,4.65,0,0,0,6.688,24.5a25.682,25.682,0,0,0-3,9.1L1.1,34.279c-.5,3.887,1.695,7.306,4.721,9.884H49.444C49.444,39.411,46.416,37.132,43.241,37.132ZM20.883,10.592c1.135,0,1.921,1.269,2.392,2.591l-2.894,1.6A4.675,4.675,0,0,0,18.5,16.716l-.729,1.4-4.424,2.211C15.909,11.675,18.695,10.592,20.883,10.592ZM8.038,25.285a3.094,3.094,0,0,1,1.3-1.21l9.585-4.791.963-1.845a3.115,3.115,0,0,1,1.256-1.287L26.106,13.4A3.914,3.914,0,0,1,28,12.913H39.144a1.569,1.569,0,0,1,1.464,1.016l.5,1.328h-13.5l.361,1.562h13.72l1.185,3.173c-5.79-.434-13.748,2.034-19.121,4.305a6.983,6.983,0,0,0-2.506,1.793L17.9,29.831,5.325,33.161A23.646,23.646,0,0,1,8.038,25.285ZM6.4,42.6c-3.358-2.965-3.8-5.931-3.831-7.093l16.188-4.285,3.654-4.091a5.44,5.44,0,0,1,1.95-1.395c2.684-1.134,9.6-3.815,15.875-4.193a12.533,12.533,0,0,1,5.086.539h0A2.38,2.38,0,0,1,47.1,24.5l-7.85,14.191h3.993A4.7,4.7,0,0,1,47.863,42.6Z" 
        transform="translate(-1.028 -9.03)"/>
      <path 
        fill={props.color}
        d="M37.719,35.078c-.717.3-.91,1.384-.431,2.42s1.45,1.631,2.167,1.331.91-1.384.431-2.42S38.437,34.777,37.719,35.078Z" 
        transform="translate(-8.903 -14.711)"/>
      </g>
    </Svg>
  );
});