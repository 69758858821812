import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError, IHoliday } from '../../models';
import { IAppAction } from '../app-action';
import { holidayStateActions, fetchHolidaySuccess, fetchHolidayError, fetchBookParties, fetchItinerary } from './holiday-actions';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';

export const fetchHolidayEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.FETCH_HOLIDAY),
        mergeMap((action) => {
            const bookingId: number = action.payload;
            return api.get(endPointKeys.api_travel, `Booking/MSD/${bookingId}`).pipe(
                map(res => res.data),
                mergeMap((m: IHoliday) => {
                    return of(fetchHolidaySuccess(m), fetchBookParties(bookingId), fetchItinerary(bookingId))
                }),
                catchError((error) => {
                    const msg = error.response && error.response.data ? error.response.data.message: "Failed to retrieve holiday";
                    const appError: IAppError = { error, message:msg };
                    sendToast(appError.message, ToastType.Error);
                    return of(fetchHolidayError(appError))
                }))
        })
    )