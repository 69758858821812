import styled from "styled-components";

export interface SvgProps {
  size?: number;
  color?: string;
}

export const Svg = styled.svg<SvgProps>`
  fill: ${({ color }) => color || "currentColor"};
  color: ${({ color }) => color || "currentColor"};
  width: ${({ size }) => size || 24}px;
  height: ${({ size }) => size || 24}px;
`;
