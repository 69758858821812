import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { ThemeContext } from "styled-components";
import { useWindowSize } from "../../helpers/useWindowSize";
import { Icon, IconTypes } from "../icons/icon-component";

export interface CarouselProps {
  children: JSX.Element[];
  autoplay?: boolean;
  autoplayTime?: number;
}

export const Carousel = memo<CarouselProps>(
  ({ children, autoplay, autoplayTime = 2000 }) => {
    const [scrollIdx, setScrollIdx] = useState(0);
    const { breakpoints, colors } = useContext(ThemeContext);
    const windowWidth = useWindowSize().width;
    const scrollRef = useRef(null);
    const intervalRef = useRef<any>();

    const isMobile = useMemo(() => {
      return windowWidth <= breakpoints.tablet;
    }, [windowWidth, breakpoints]);

    const isEnd = useMemo(() => {
      if (!isMobile) {
        return scrollIdx < children.length - 2;
      } else {
        return scrollIdx < children.length - 1;
      }
    }, [isMobile, scrollIdx, children]);

    useEffect(() => {
      if (autoplay) {
        intervalRef.current = setInterval(() => {
          setScrollIdx((prev) => prev + 1);
        }, autoplayTime);
      }
      return () => {
        clearInterval(intervalRef.current);
      };
    }, [autoplay, autoplayTime]);

    useEffect(() => {
      if (!isEnd && intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }, [isEnd]);

    const renderItemsCallback = useCallback(
      (item: JSX.Element, idx: number): JSX.Element =>
        React.cloneElement(item, { key: idx, isMobile }),
      [isMobile]
    );

    return (
      <Container>
        <StyledArrowLeft
          type="button"
          onClick={() => setScrollIdx((prev) => prev - 1)}
          visible={!!scrollIdx}
        >
          <Icon
            name={IconTypes.IconArrowLeft}
            color={colors.white}
            size={isMobile ? 24 : 48}
          />
        </StyledArrowLeft>
        <StyledCarousel
          ref={scrollRef}
          scrollIdx={scrollIdx}
          isMobile={isMobile}
        >
          {children.map(renderItemsCallback)}
        </StyledCarousel>
        <StyledArrowRight
          type="button"
          onClick={() => setScrollIdx((prev) => prev + 1)}
          visible={isEnd}
        >
          <Icon
            name={IconTypes.IconArrowRight}
            color={colors.white}
            size={isMobile ? 24 : 48}
          />
        </StyledArrowRight>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
`;

interface StyledCarouselProps {
  scrollIdx: number;
  isMobile: boolean;
}

const StyledCarousel = styled.ul<StyledCarouselProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  transform: translateX(
    ${({ scrollIdx, isMobile }) => -scrollIdx * (isMobile ? 100 : 50)}%
  );
  transition: transform 300ms;
`;

interface CarouselItemProps {
  isMobile?: boolean;
}

export const CarouselItem = styled.li<CarouselItemProps>`
  display: block;
  height: 100%;
  width: ${({ isMobile }) => (isMobile ? 100 : 50)}%;
  min-width: ${({ isMobile }) => (isMobile ? 100 : 50)}%;
`;

interface ArrowBoxProps {
  visible: boolean;
}

const ArrowBox = styled.button<ArrowBoxProps>`
  position: absolute;
  background-color: transparent;
  border: 0;
  top: 68px;
  width: 64px;
  height: 64px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  transition: opacity 300ms;
`;

const StyledArrowLeft = styled(ArrowBox)`
  left: 0;
`;

const StyledArrowRight = styled(ArrowBox)`
  right: 0;
`;
