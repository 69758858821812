import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { Textarea } from "..";
import { GetMonthNames, HolidayBudgets, HolidayInterests } from "../../helpers/datasource-helper";
import { ILookUp, IWebEnquiry } from "../../models";
import { IAppState } from "../../store/app-state";
import { submitEnquiry } from "../../store/get-in-touch";
import Button, { ButtonType } from "../button";
import { Loader } from "../loader";
import { NumericStepper } from "../numeric-stepper";
import { SelectDropdown } from "../select-dropdown";

interface IFormField {
    isValid: boolean
}

interface IEnquiryState {
    holidayType: IFormField;
    adults: IFormField;
    travelMonth: IFormField;
    travelYear: IFormField;
    budget: IFormField;
    note: IFormField;
}

const getDefaultEnquiryState = (): IEnquiryState => {
    return {
        holidayType: { isValid: true },
        adults: { isValid: true },
        travelMonth: { isValid: true },
        travelYear: { isValid: true },
        budget: { isValid: true },
        note: { isValid: true }
    };
}

export interface IWebEnquiryProps {
    submitEnquiry(): void;
}

const getYears = (month: number): Array<ILookUp> => {
    const currentMonth = moment().month() + 1;
    let addition: number = 0;
    if (month !== 0 && month <= currentMonth) addition = 1;

    var year1 = moment().year() + addition;
    var year2 = year1 + 1;
    var year3 = year2 + 1;
    return [{ id: year1, title: year1.toString() }, { id: year2, title: year2.toString() }, { id: year3, title: year3.toString() }];
}

export const WebEnquiry = (props: IWebEnquiryProps) => {

    const dispatch = useDispatch();

    const [holidayType, setHolidayType] = React.useState<number>(0);
    const [travelMonth, setTravelMonth] = React.useState<number>(0);
    const [travelYear, setTravelYear] = React.useState<string>("");
    const [travelBudget, setTravelBudget] = React.useState<number>(0);
    const [adults, setAdults] = React.useState<number>(2);
    const [child, setChild] = React.useState<number>(0);
    const [rooms, setRooms] = React.useState<number>(1);
    const [notes, setNotes] = React.useState<string>('');
    const [formFields, setFormField] = React.useState<IEnquiryState>(getDefaultEnquiryState());
    const [, setYears] = React.useState<Array<string>>(getYears(travelMonth).map(y => y.title));


    const contact = useSelector((state: IAppState) => state.contact.contact);
    const isProcessing = useSelector((state: IAppState) => state.getInTouch.isProcessing);


    const handleHolidayType = (val: ILookUp | null) => {
        if (val && val !== null) {
            setHolidayType(val.id);
            setFieldState('holidayType', true);
        }
    };

    const handleTravelMonth = (val: ILookUp | null) => {

        if (val && val !== null) {
            const currentMonth = moment().month() + 1;

            if (currentMonth >= val.id) {
                setTravelYear('Please choose...');
            }

            setTravelMonth(val.id);
            setFieldState('travelMonth', true);
        }
    };

    const handleBudgetChange = (val: ILookUp | null) => {

        if (val && val !== null) {
            setTravelBudget(val.id);
            setFieldState('budget', true);
        }
    }

    const handleTravelYear = (val: ILookUp | null) => {
        if (val && val !== null) {
            setTravelYear(val.title);
        }
        setFieldState('travelYear', true);
    }

    useEffect(() => {
        setYears(getYears(travelMonth).map(m => m.title));
    }, [travelMonth]);


    const setFieldState = (name: string, valid: boolean) => {
        const fields: IEnquiryState = {
            ...formFields,
            [name]: { isValid: valid }
        };
        setFormField({ ...fields });
    }

    const validateFrom = () => {
        if (holidayType === 0) {
            setFieldState('holidayType', false);
        }
        else if (adults === 0) {
            setFieldState('adults', false);
        }
        else if (travelMonth === 0) {
            setFieldState('travelMonth', false);
        }
        else if (travelYear === "Please choose..." || Number(travelYear) === 0) {
            setFieldState('travelYear', false);
        }
        else if (travelBudget === 0) {
            setFieldState('budget', false);
        }
        else if (notes.length === 0) {
            setFieldState('note', false);
        }
    }

    const handleAdultNumber = (isIncrement: boolean) => {
        if (isIncrement === true) {
            setAdults(adults + 1);
        }
        else {
            if (adults > 1) {
                setAdults(adults - 1);
            }
        }
    }

    const handleChildCount = (isIncrement: boolean) => {
        if (isIncrement === true) {
            setChild(child + 1);
        }
        else {
            if (child > 0) {
                setChild(child - 1);
            }
        }
    }

    const handleRoomsCount = (isIncrement: boolean) => {
        if (isIncrement === true) {
            setRooms(rooms + 1);
        }
        else {
            if (rooms > 1) {
                setRooms(rooms - 1);
            }
        }
    }

    const handleSubmit = () => {
        validateFrom();

        if (holidayType > 0 && travelMonth > 0 && Number(travelYear) !== 0 && travelBudget !== 0) {
            const mainFamily = contact.familyMembers.find(f => f.isMain === true);
            const webEnq: IWebEnquiry = {
                adult: adults,
                child: child,
                infant: 0,
                contact: {
                    contactId: contact.id,
                    contact: contact.firstName,
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    primaryEmail: contact.primaryEmail,
                    primaryPhone: contact.primaryPhone,
                    address: contact.address,
                    isSDPGuest: contact.isSDP,
                    mainFamily: mainFamily,
                },
                baseCurrencyId: 25,
                businessUnitId: 1,
                // mainFamilyId: mainFamily ? mainFamily.familyId : 0,
                mainFamilyId: mainFamily ? mainFamily.familyId : '',
                month: travelMonth,
                year: Number(travelYear),
                notes: notes,
                productGroupId: holidayType,
                budget: travelBudget,
                rooms: rooms
            }
            dispatch(submitEnquiry(webEnq));
            props.submitEnquiry();
        }
    }

    return (
        <React.Fragment>
            {!isProcessing ? <FormContainer>
                <SelectDropdown

                    options={HolidayInterests}
                    isClearable={false}
                    placeholder="Please select"
                    isSearchable={false}
                    errorMessage='Please select holiday type'
                    isValid={formFields.holidayType.isValid}
                    label="Holiday Type"
                    onChange={(e) => handleHolidayType(e)}
                />

                <SelectDropdown
                    options={GetMonthNames()}
                    isClearable={false}
                    placeholder="Please select"
                    isSearchable={false}
                    isValid={formFields.travelMonth.isValid}
                    label={formFields.travelMonth.isValid === true ? "Preferred Month" : 'Please select a month'}
                    onChange={(e) => handleTravelMonth(e)}
                />

                <SelectDropdown
                    options={getYears(travelMonth)}
                    isClearable={false}
                    placeholder="Please select"
                    isSearchable={false}
                    isValid={formFields.travelYear.isValid}
                    label={formFields.travelYear.isValid === true ? "Preferred Year" : "Please select a year"}
                    onChange={(e) => handleTravelYear(e)}
                />

                <SelectDropdown
                    options={HolidayBudgets}
                    isClearable={false}
                    placeholder="Please select"
                    isSearchable={false}
                    isValid={formFields.budget.isValid}
                    label={formFields.budget.isValid === true ? "Budget Per Person" : "Please select a budget"}
                    onChange={(e) => handleBudgetChange(e)}
                />

                <StyledNumericStepper title='Adults (Age 15+)' count={adults} onDecrement={() => handleAdultNumber(false)} onIncrement={() => handleAdultNumber(true)} />
                <StyledNumericStepper title='Children (Age 0-15)' count={child} onDecrement={() => handleChildCount(false)} onIncrement={() => handleChildCount(true)} />
                <StyledNumericStepper title='Rooms' count={rooms} onDecrement={() => handleRoomsCount(false)} onIncrement={() => handleRoomsCount(true)} />

                <StyledTextArea
                    label="Please give us any details to help us plan your next adventure"
                    name="formName"
                    onChange={setNotes} />
                <Button type={ButtonType.MAIN} text="Send" onClick={handleSubmit} processing={isProcessing} />
            </FormContainer> : <Loader />}
        </React.Fragment>
    );
}


const StyledTextArea = styled(Textarea)`
resize: none;
`;
const FormContainer = styled.div`
display: flex;
flex-flow: column nowrap;
width: 80%;
height:800px;
background-color: ${({ theme }) => theme.colors.white};

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 413px;
}
`;

const StyledNumericStepper = styled(NumericStepper)`
  color: ${({ theme: { colors } }) => colors.labelDark};
  font-size: 14px;
  margin: 30px 0px 0px;
`;