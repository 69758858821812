import * as React from "react";
import styled from "styled-components";
import { Headline6 } from "../typography/headline/headline";
import { Input, InputTypes } from "../input/input";
import Button, { ButtonType } from "../button/button";
import NavLink from "../link/link";
import routes from "../../routing/routes";
import { Body1 } from "../typography";
import { resetPassword } from "../../store/session";
import { useDispatch } from "react-redux";
import { LinkButton } from "../link-button";
import { IsValidEmail } from "../../helpers/validation-helper";
import { Loader } from "../loader";

const ForgotPassword = ((props: { originalEmail: string, headerMessage?: string, btnText?: string, messageText?: string }) => {
  const [email, setEmail] = React.useState<string>(props.originalEmail);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const [displayLoading, setDisplayLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();


  const onReset = () => {

    if (IsValidEmail(email)) {
      setDisplayLoading(true);
      dispatch(resetPassword(email));
      setEmailSent(true);     
      setTimeout(()=>setDisplayLoading(false), 500);
    }
    else {
      setIsValid(false);
    }
  };

  const onEmailChange = (email: string) => {
    setEmail(email);
    setIsValid(true);
  }

  return (
    !emailSent ?
      <ForgotPasswordWrapper>
        {displayLoading === true && <Loader/>}
        <StyledHeadline6>{props.headerMessage ? props.headerMessage : "Forgotten your password?"}</StyledHeadline6>
        <StyledBody1>
          {
            props.messageText ? props.messageText : "Enter the email address you signed up with and we'll reset your password and send the new password to your email address immediately."
          }
    </StyledBody1>
        <Input
          placeholder="Email Address"
          type={InputTypes.EMAIL}
          value={email}
          isValid={isValid}
          errorMessage="Incorrect email format"
          onChange={(e: any) => onEmailChange(e.target.value)}
          autoFocus
        />
        <StyledButton type={ButtonType.MAIN} text={ props.btnText ? props.btnText : "Reset password"} onClick={onReset} />
        <StyledNavLink url={routes.home()}>Back to Log In</StyledNavLink>
      </ForgotPasswordWrapper>
      :
      <ForgotPasswordWrapper>
      {displayLoading === true && <Loader />}
        <StyledHeadline6>Email sent</StyledHeadline6>
        <StyledBody1>
          A link was sent to your email to set a new password. Please check your junk mail if you can’t find it.
    </StyledBody1>
        <ButtonContainer>
          <StyledLinkButton onClick={onReset}>
            Resend email
    </StyledLinkButton>
        </ButtonContainer>
        <StyledNavLink url={routes.home()}>
          Back to Log In
    </StyledNavLink>
      </ForgotPasswordWrapper>
  );
});

const StyledHeadline6 = styled(Headline6)`
  margin: 10px 0px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledBody1 = styled(Body1)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const StyledNavLink = styled(NavLink)`
  span {
    color: ${({ theme }) => theme.colors.accentDark} !important;
  }
  padding-left: 3px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 10px;
  button {
    margin-left; 0px;
  }
`;

const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.accentDark} !important;
`;

const ForgotPasswordWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
  transition: left 300ms;
`;

export default ForgotPassword;
