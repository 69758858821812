import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconFemaleUser = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 39.063 39.063" {...props}>
      <g transform="translate(-5.469 -5.469)">
        <g transform="translate(5.469 5.469)">
          <path
            fill={props.color}
            d="M26.531,7A19.531,19.531,0,1,0,46.063,26.531,19.553,19.553,0,0,0,26.531,7Zm0,37.5a17.861,17.861,0,0,1-9.311-2.606c3.029-1.327,5.9-1.305,6.175-4.66a9.208,9.208,0,0,0-.138-2.077A11.655,11.655,0,0,1,18.6,33.145a.94.94,0,0,1-.34-1.212c1.5-3.4,1.006-4.469,1.006-9.526,0-3.281,2.1-6.5,6.311-6.5a4.232,4.232,0,0,1,2.757-.969c2.668,0,5.58,2.216,5.58,6.385,0,4.389-.5,6.706,1.952,11.058.316.56.334.911-.24,1.171a18.811,18.811,0,0,1-5.844,1.814,8.743,8.743,0,0,0-.1,1.867c.273,3.32,3.146,3.361,6.152,4.669A17.859,17.859,0,0,1,26.531,44.5Zm7.8-4.831c-2.409-.824-2.984-1.16-3.1-2.563-.009-.113-.013-.242-.011-.382a21.149,21.149,0,0,0,4.826-1.647l.22-.1a2.225,2.225,0,0,0,1.277-1.334,2.508,2.508,0,0,0-.32-2.028c-1.87-3.314-1.834-5.217-1.774-8.371.011-.6.023-1.235.023-1.919,0-5.162-3.68-7.947-7.143-7.947a5.9,5.9,0,0,0-3.3.984c-4.817.272-7.333,4.182-7.333,8.046,0,1.171.026,2.115.049,2.947.074,2.723.1,3.64-.921,5.947a2.5,2.5,0,0,0,.832,3.094,12.8,12.8,0,0,0,4.174,2.021,4.889,4.889,0,0,1,0,.689c-.115,1.4-.68,1.732-3.046,2.53a20.622,20.622,0,0,0-3.072,1.237,17.968,17.968,0,1,1,21.611.01A21.272,21.272,0,0,0,34.333,39.669Z"
            transform="translate(-7 -7)"
          />
        </g>
      </g>
    </Svg>
  );
});
