import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Carousel, CarouselItem, ConsultantBox, InfoLockup, PartyAssign } from "../../components";
import { IconTypes } from "../../components/icons/icon-component";
import { GetCloudinaryImage } from "../../helpers/getCloudinaryImages";
import DictionaryReplace from "../../helpers/string-helper";
import { IHoliday } from "../../models";
import routes from "../../routing/routes";
import { routeChange } from "../../store/session";

export declare interface IQuoteProps {
    quote: IHoliday;
}

export const QuoteOverview = ({quote}: IQuoteProps) => {
    const dispatch = useDispatch();
    
    const onRequestConcierge = () => {
        dispatch(routeChange(routes.getInTouchHolidayRequest('RequestConcierge', quote && quote.bookId ? quote.bookId.toString(): '')));
    }
    return(
        <React.Fragment>
        <CarouselContainer>
        <Carousel autoplay autoplayTime={3000}>
        {quote.images.map(image =>
            <StyledCarouselItem url={GetCloudinaryImage(image.toString(), true)} key={'image-' + image.toString()}/>
        )}
        </Carousel>
        </CarouselContainer>
        <TextWrapper dangerouslySetInnerHTML={{ __html: DictionaryReplace(quote.primaryCountrySynopsis,quote.businessUnit.id)}}></TextWrapper>
        <OverviewLayout>
            <LockupContainer>
                <StyledInfoLockup icon={IconTypes.IconMap} label="Destination" text={quote.bookName}/>
                <StyledInfoLockup icon={IconTypes.IconCalendar} label="Departing" text={moment(quote.startDate, "DD/MM/YYYY").format("ddd, DD MMM YYYY")}/>
                <StyledInfoLockup icon={IconTypes.IconCalendar} label="Returning" text={moment(quote.endDate, "DD/MM/YYYY").format("ddd, DD MMM YYYY") 
                + ` (${quote.nights} night${Number(quote.nights) > 1 ? 's':''})`}/>
                <StyledInfoLockup icon={IconTypes.IconFemaleUser} label="Party Leader" text={quote.partyLeader}/>
                <PartyAssign/>
                <StyledInfoLockup icon={IconTypes.IconAirplaneTicket} label="Reference" text={quote.bookId.toString()}/>
            </LockupContainer>
            <ConsultantLayout>
                <ConsultantBox profileImage={GetCloudinaryImage(quote.consultant.imageId, false, 120, 120)} name={quote.consultant.name} onConciergeCallback={onRequestConcierge}/>
            </ConsultantLayout>
        </OverviewLayout>
        </React.Fragment>
    )
}

declare interface ICarouselItem {
    url: string;
}

const CarouselContainer = styled.div`
    margin-right: 15px;
`;
    
const StyledCarouselItem = styled(CarouselItem)<ICarouselItem>`
    background-image: url(${({ url }) => url});
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: 200px;
`;

const OverviewLayout = styled.div`
    display: flex;
    flex-flow: column nowrap;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        flex-flow: row nowrap;
    }
`;
    
const LockupContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-left: 20px;
    margin-right: 15px;
    margin-bottom: 10px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: 50%;
    }
`;
    
const StyledInfoLockup = styled(InfoLockup)`
    margin-bottom: 10px;
    width: 100%;
`;
    
const ConsultantLayout = styled.div`
    margin-bottom: 10px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: 50%;
    }
`;
    
const TextWrapper = styled.div`
    padding: 20px 20px 20px 20px;
    text-align: justify;
    text-justify: inter-character;
    font-size: 14px;
`;