import { IHolidaySummary } from "../../models";
import { IHoliday } from "../../models/holiday";
import { IBaseState } from "../base-state";

export interface IHolidayState  extends IBaseState {
  holidays: IHolidaySummary[];
  holiday: IHoliday | null;
  paymentSuccess: boolean;
  paymentError: any;
  retriveHoliday: {
    isError: boolean,
    errorCode: string,
    errorMessage: string,
  } | null;
}

  const defaultState : IHolidayState = {
    holidays: [],
    holiday: null,
    isProcessing: false,
    paymentSuccess: false,
    paymentError: null,
    retriveHoliday: null,
  };
  
  export const getDefaultHolidayState = (options?: any) => {
    return {
      ...defaultState,
      ...options
    };
  };