import React, { memo } from "react";
import styled from "styled-components";
import { Body2 } from "../typography/body";

export interface ErrorLabelProps {
  message: string;
  visible: boolean;
  className?:any;
}

export const ErrorLabel = memo<ErrorLabelProps>(
  ({ message, visible,className}): JSX.Element => {
    return (
      <MessageContainer visible={visible} className={className}>
        <Body2>{message}</Body2>
      </MessageContainer>
    );
  }
);

interface ContainerProps {
  visible: boolean;
}

export const MessageContainer = styled.div<ContainerProps>`
  position: absolute;
  right: 0;
  top: -21px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 270px;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.white};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 300ms;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 12px solid ${({ theme }) => theme.colors.accent};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 6px 12px;
  }
`;