import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconDashboard = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 46 46" {...props}>
      <g transform="translate(-893 -939)">
      <rect fill="none" data-name="Rectangle 379" width="46" height="46" transform="translate(893 939)" />
      <path
        fill={props.color}
        d="M2980.022,1327v-10.978H2991V1327Zm1.689-1.689h7.6v-7.6h-7.6Zm-15.2,1.689v-10.978h10.978V1327Zm1.689-1.689h7.6v-7.6h-7.6ZM2953,1327v-10.978h10.978V1327Zm1.689-1.689h7.6v-7.6h-7.6Zm25.333-11.822v-10.978H2991v10.978Zm1.689-1.689h7.6v-7.6h-7.6Zm-15.2,1.689v-10.978h10.978v10.978Zm1.689-1.689h7.6v-7.6h-7.6Zm-15.2,1.689v-10.978h10.978v10.978Zm1.689-1.689h7.6v-7.6h-7.6Zm25.333-11.822V1289H2991v10.978Zm1.689-1.689h7.6v-7.6h-7.6Zm-15.2,1.689V1289h10.978v10.978Zm1.689-1.689h7.6v-7.6h-7.6Zm-15.2,1.689V1289h10.978v10.978Zm1.689-1.689h7.6v-7.6h-7.6Z" 
        transform="translate(-2056 -346)"
      />
      </g>
    </Svg>
  );
});

