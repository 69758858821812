import React, { useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from "styled-components";
import { ButtonHeight, ButtonType, Input, InputTypes } from "..";
import routes from "../../routing/routes";
import {  Body2, Headline6 } from "../typography";
import { IAppUser, IHolidaySummary, IRetrieveHoliday, IUpdateRetrieveHoliday } from "../../models";
import { IAppState } from "../../store/app-state";
import { Icon, IconTypes } from "../icons/icon-component";
import { routeChange } from "../../store/session";
import moment from "moment";
import { YearDatePicker } from "../date-picker";
import Button from "../button";
import { retriveHoliday, retriveHolidaySuccess } from '../../store/holiday/holiday-actions';
import "./date.css";

export interface IRetrieveHolidayPage {
  isBooking: boolean;
}

export const RetrieveHolidayPage = (props: IRetrieveHolidayPage) => {
  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );
  const { colors } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [holidayId, setHolidayId] = React.useState<string>();
  // const contactId : number = useSelector((state:IAppState)=> state.contact.contact?.contactId);
  const contactId : string = useSelector((state:IAppState)=> state.contact.contact?.contactId);
  const retrieveHolidayError : any = useSelector((state:IAppState)=> state.holidays.retriveHoliday);
  const holidays: IHolidaySummary[] = useSelector((state: IAppState) => state.holidays.holidays);
  const [surname, setSurname] = React.useState<string>(
    appUser ? appUser.lastName : ""
  );
  const [travelDate, settravelDate] = React.useState<Date>();

  const setHoliday = (value: any) => {
    const editedValue = value
      .replace(/[^\d|.-]/g, "") //removes all character except of digits, dot and hypen
      .replace(/-/g, "") //removes every hyphen
      .replace(/(\.){2,}/g, "$1"); //removes every multiplied dot
      setHolidayId(editedValue);
  };

  React.useEffect(() => {
    window.addEventListener('mousemove', () => {});
  
    // returned function will be called on component unmount 
    return () => {
      dispatch(retriveHolidaySuccess());
      window.removeEventListener('mousemove', () => {})
    }
  }, [dispatch])

  const onRetrieve = () => {
    const matchedBooking = holidays.find(h => h.bookId === parseInt(holidayId ||  ""));
    if (matchedBooking !==  undefined) {
      dispatch(routeChange(props.isBooking ? routes.booking(holidayId!.toString()): routes.quote(holidayId!.toString())));
      
    } else {
      const holiday: IRetrieveHoliday = {
        BookId: parseInt(holidayId ||  ""),
        isBooking: props.isBooking,
        LastName: surname,
        ContactId: contactId,
        TravelDate: formatDate(travelDate || new Date()),
     }
 
     const updateHolidaySharing: IUpdateRetrieveHoliday =  {
       BookId: parseInt(holidayId ||  ""),
       ContactId: contactId,
       PrimaryOwne: false,
       CreatedOn: formatDate(new Date()),
     }
     dispatch(retriveHoliday(holiday, updateHolidaySharing));
     dispatch(retriveHolidaySuccess());
    }

    
}

const formatDate = (date: Date) =>  {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [year, month, day].join('-');
}

  return (
    <Container>
      <StyledHeadline>{`Retrieve a ${
        props.isBooking ? "booking" : "quote"
      }`}</StyledHeadline>

      <InfoContainer>
    <Container1>
      <StyledLabel>{`${ props.isBooking ? "Booking": "Quote"} reference number` }</StyledLabel>
      <DataBox>
        <Icon name={IconTypes.IconMap} size={26} color={colors.primaryDark} />
        <StyledWidthInput
            type={InputTypes.TEXT}
            value={holidayId?.toString()}
            onChange={(e: any) => setHoliday(e.target.value)}
          />
      </DataBox>
    </Container1>
    <Container1>
      <StyledLabel>Departure Date</StyledLabel>
      <DataBox>
        <Icon name={IconTypes.IconCalendar} size={26} color={colors.primaryDark} />
        <YearDatePicker
            startYear={moment().year() - 100}
            endYear={moment().year() + 15}
            maxDate={moment().add(15, 'years').toDate()}
            onDateSelect={(value) => settravelDate(value)}
            placeHolder="Select date of travel"
            selectedDate={travelDate ?  moment(travelDate).toDate(): undefined}
            style={{ height: "32px", paddingRight: "0px", paddingLeft: "8px", width: "50%" }}
        />
      </DataBox>
    </Container1>
    <Container1>
      <StyledLabel>Surname of lead passenger</StyledLabel>
      <DataBox>
        <Icon name={IconTypes.IconFemaleUser} size={26} color={colors.primaryDark} />
        <StyledWidthInput
            type={InputTypes.TEXT}
            value={surname}
            onChange={(e: any) => setSurname(e.target.value)}
          />
      </DataBox>
    </Container1>
    <ButtonWrapper>
                {<ButtonBook type={ButtonType.MAIN} height={ButtonHeight.NORMAL} text={`Retrieve this  ${ props.isBooking ? "BOOKING": "QUOTE"}`} onClick={() => onRetrieve()} />}
            </ButtonWrapper>
          {retrieveHolidayError && <Error>{retrieveHolidayError.errorCode === 404 ? `${ props.isBooking ? "Booking": "Quote"} not Found, Please check details`: retrieveHolidayError.errorMessage }</Error>}
          {retrieveHolidayError && <Error>Please contact us <a style ={{ padding: "0px 8px 0px 8px"}} href={routes.getInTouch()}> here </a>{ `if you cannot retrieve your ${ props.isBooking ? "booking": "quote"}`}</Error>}
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 0;
  overflow-y: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  width: 75%;
`;

const Error = styled.div`
  overflow-y: auto;
  background: #ffffff;
  color: red;
  display: flex;
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
flex-flow: row;
display: flex;
justify-content: space-between;
width: 60%;
padding: 25px 10px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 50%;
    justify-content: flex-end;
    padding: 30px 0px 20px 0px;
}
`;

const ButtonBook = styled(Button)`
width: 600px;
span {    
}

}
`;

const InfoContainer = styled.div`
  margin-top: 10px;
`;


const StyledHeadline = styled(Headline6)`
  margin: 8px 0;
  max-height: 50px;
`;

const StyledWidthInput = styled(Input)`
  margin-top: 5px;
  height: 32px;
  padding-left: 8px;
  width: 50%;
`;

const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;

`;

const DataBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

const StyledLabel = styled(Body2)`
  color: ${({ theme: { colors } }) => colors.labelDark};
  letter-spacing: -1px;
`;


