import React from 'react'
import styled from 'styled-components';
import { Body1 } from "../../typography";
interface IPaymentTermConditionMessage {
   businessUnitId: number;
}

export const HolidayPaymentTermConditionMessage = (props: IPaymentTermConditionMessage) => {

    return (
        <>        
             {props.businessUnitId === 1 && <TermWrapper data-testid="payment-tc-uk"><a href="https://www.scottdunn.com">https://www.scottdunn.com</a> is operated by <StyledBody1>Scott Dunn Limited</StyledBody1> under the laws of <StyledBody1>United Kingdom (company registered in England and Wales)</StyledBody1>, registration Number is <StyledBody1>2021650</StyledBody1> and its registered address
               is at <StyledBody1>Scott Dunn, Mindspace Hammersmith, 1 Butterwick, London W6 8DL</StyledBody1>.</TermWrapper>}
             {props.businessUnitId === 2 && <TermWrapper  data-testid="payment-tc-us"><a href="https://www.scottdunn.com/us">https://www.scottdunn.com/us</a> is operated by <StyledBody1>Scott Dunn USA Inc</StyledBody1> under the laws of <StyledBody1>United States</StyledBody1>, registration Number is <StyledBody1>2021650</StyledBody1> and its registered address is at <StyledBody1>420 North Cedros Avenue, Solana Beach,
               CA 92075, United States</StyledBody1>.</TermWrapper>}
             {props.businessUnitId === 3 && <TermWrapper  data-testid="payment-tc-sg"><a href="https://www.scottdunn.com/sg">https://www.scottdunn.com/sg</a> is operated by <StyledBody1>Scott Dunn Singapore PTE Ltd</StyledBody1> under the laws of <StyledBody1>Singapore</StyledBody1>, registration Number is <StyledBody1>2021650</StyledBody1> and its registered address is at <StyledBody1>250 North Bridge Road, #10-03
               Raffles City Tower, Singapore 17910</StyledBody1>.</TermWrapper>}
        </>
    )
};

const StyledBody1 = styled(Body1)`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 8px;
  font-size: 14px;
`;


const TermWrapper = styled.p`
display:inline-block;
flex-direction:row nowrap;
padding-bottom:20px;
padding-top:15px;
font-size: 14px;
&:last-child{
    border-bottom:none;
}
`;