import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { IEpicDependency } from "../../models";
import { IAppAction } from "../app-action";
import {
	fetchApiKeysActions,
	fetchKeysSuccess,
} from "./fetch-api-keys-actions";
import { IAppState } from "../app-state";
// import { sendToast, ToastType } from "../../helpers/sendToast";
export const fetchApiKeyEpic = (
	action$: ActionsObservable<IAppAction>,
	state$: StateObservable<IAppState>,
	{ api, endPointKeys }: IEpicDependency
): Observable<IAppAction> =>
	action$.pipe(
		ofType(fetchApiKeysActions.FETCH_KEYS),
		mergeMap(() => {
			return api
				.get(endPointKeys.api_travel, `Configuration/GetConfigurations`)
				.pipe(
					map((res) => res.data),
                    mergeMap((m: any) => {
                        localStorage.setItem(
                            "ocpKey",
                            m.ocpApimSubscriptionKey
                        );
                        localStorage.setItem("ocpKey", m.ocpApimSubscriptionKey);
                        localStorage.setItem("paymentKey", m.paymentKey);
                        localStorage.setItem("serviceApiKey", m.serviceApiKey);
                        return of(fetchKeysSuccess(m))
                    }),
					catchError((error) => {
						return of(error);
					})
				);
		})
	);
