import React from "react";
import styled from "styled-components";
import loader from '../../assets/gifs/loader.gif'

declare interface ILoaderProps {
    fullScreen?: boolean;
}

export const Loader = (props:ILoaderProps) => {
    return(
        <Container>
            <StyledImg src={loader} width={35} height={35} alt='Loading...' {...props}/>
        </Container>
    )
}

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    width: 700px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) { 
    width: calc(100vw - 250px);
    height: 100vh;
  }
`;

const StyledImg = styled.img<ILoaderProps>`
  display: block;
  margin: ${({fullScreen}) => fullScreen ? 'auto' : 'inherit'};
  position: relative;
  top: ${({fullScreen}) => fullScreen ? '50%' : 'inherit'};
  -webkit-transform: ${({fullScreen}) => fullScreen ? 'translateY(-50%)' : 'inherit'};
  -ms-transform: ${({fullScreen}) => fullScreen ? 'translateY(-50%)' : 'inherit'};
  transform: ${({fullScreen}) => fullScreen ? 'translateY(-50%)' : 'inherit'};
`;