import React, { memo } from "react";

import {
  IconAirplaneTicket,
  IconDashboard,
  IconEmail,
  IconEye,
  IconFacebook,
  IconFemaleUser,
  IconGoogle,
  IconHeadset,
  IconIosMenu,
  IconLock,
  IconLogo,
  IconArrowDown,
  IconEmailStroke,
  IconArrowLeft,
  IconArrowRight,
  IconCalendar,
  IconPlus,
  IconDirectionLeft,
  IconEdit,
  IconDownload,
  IconMap,
  IconPlane,
  IconPlaneTakeoff,
  IconBed,
  IconTaxi,
  IconTrain,
  IconCruise,
  IconHelicopter,
  IconCar,
  IconBus,
  IconSki,
  IconTag,
  IconCutlery,
  IconFoot,
  IconCamera,
  IconArrowUp,
  IconArrowsLeftRight,
  IconConcierge,
  IconIsland,
  IconTickInCircle,
  IconClose,
  Icon4x4,
  IconSeaplane,
  IconOnFoot,
  IconMultiModel,
} from ".";

export enum IconTypes {
  IconAirplaneTicket = "IconAirplaneTicket",
  IconDashboard = "IconDashboard",
  IconEmail = "IconEmail",
  IconEmailStroke = "IconEmailStroke",
  IconEye = "IconEye",
  IconFacebook = "IconFacebook",
  IconFemaleUser = "IconFemaleUser",
  IconGoogle = "IconGoogle",
  IconHeadset = "IconHeadset",
  IconIosMenu = "IconIosMenu",
  IconLock = "IconLock",
  IconLogo = "IconLogo",
  IconArrowDown = "IconArrowDown",
  IconArrowLeft = "IconArrowLeft",
  IconArrowRight = "IconArrowRight",
  IconCalendar = "IconCalendar",
  IconPlus = "IconPlus",
  IconDirectionLeft = "IconDirectionLeft",
  IconEdit = "IconEdit",
  IconDownload = "IconDownload",
  IconMap = "IconMap",
  IconPlane = "IconPlane",
  IconPlaneTakeoff = "IconPlaneTakeoff",
  IconBed = "IconBed",
  IconTaxi = "IconTaxi",
  IconTrain = "IconTrain",
  IconCruise = "IconCruise",
  IconHelicopter = "IconHelicopter",
  IconCar = "IconCar",
  IconBus = "IconBus",
  IconSki = "IconSki",
  IconTag = "IconTag",
  IconCutlery = "IconCutlery",
  IconFoot = "IconFoot",
  IconCamera = "IconCamera",
  IconArrowUp = "IconArrowUp",
  IconArrowsLeftRight = "IconArrowsLeftRight",
  IconConcierge = "IconConcierge",
  IconIsland = "IconIsland",
  IconTickInCircle = "IconTickInCircle",
  IconClose = "IconClose",
  Icon4x4 = "Icon4x4",
  IconMultiModel = "IconMultiModel",
  IconOnFoot = "IconOnFoot",
  IconSeaplane = "IconSeaplane",
}

export const IconNames = {
  IconAirplaneTicket: IconAirplaneTicket,
  IconDashboard: IconDashboard,
  IconEmail: IconEmail,
  IconEmailStroke: IconEmailStroke,
  IconEye: IconEye,
  IconFacebook: IconFacebook,
  IconFemaleUser: IconFemaleUser,
  IconGoogle: IconGoogle,
  IconHeadset: IconHeadset,
  IconIosMenu: IconIosMenu,
  IconLock: IconLock,
  IconLogo: IconLogo,
  IconArrowDown: IconArrowDown,
  IconArrowLeft: IconArrowLeft,
  IconArrowRight: IconArrowRight,
  IconCalendar: IconCalendar,
  IconPlus: IconPlus,
  IconDirectionLeft: IconDirectionLeft,
  IconEdit: IconEdit,
  IconDownload: IconDownload,
  IconMap: IconMap,
  IconPlane: IconPlane,
  IconPlaneTakeoff: IconPlaneTakeoff,
  IconBed: IconBed,
  IconTaxi: IconTaxi,
  IconTrain: IconTrain,
  IconCruise: IconCruise,
  IconHelicopter: IconHelicopter,
  IconCar: IconCar,
  IconBus: IconBus,
  IconSki: IconSki,
  IconTag: IconTag,
  IconCutlery: IconCutlery,
  IconFoot: IconFoot,
  IconCamera: IconCamera,
  IconArrowUp: IconArrowUp,
  IconArrowsLeftRight: IconArrowsLeftRight,
  IconConcierge: IconConcierge,
  IconIsland: IconIsland,
  IconTickInCircle: IconTickInCircle,
  IconClose: IconClose,
  Icon4x4: Icon4x4,
  IconSeaplane: IconSeaplane,
  IconMultiModel: IconMultiModel,
  IconOnFoot: IconOnFoot,
};

const getIcon = (name: string, size: number, color: string) => {
  const IconComponent = IconNames[name];
  return <IconComponent size={size} color={color} />;
};

export interface IconComponentProps {
  name: string;
  size?: number;
  color?: string;
}
export const Icon = memo<IconComponentProps>(
  ({ name, size = 48, color = "currentColor" }) => {
    return getIcon(name, size, color);
  }
);
