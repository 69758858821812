import React, { memo, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Icon, IconTypes } from "../icons/icon-component";
import ButtonText from "../typography/button";

export interface NewContentTileProps {
  title: string;
  onClick?: () => void;
}

export const NewContentTile = memo<NewContentTileProps>(
  ({ title, onClick }): JSX.Element => {
    const { colors } = useContext(ThemeContext);
    return (
      <Container>
        <IconContainer onClick={onClick}>
          <Icon name={IconTypes.IconPlus} color={colors.white} />
        </IconContainer>
        <StyledText size={10}>{title}</StyledText>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: rgba(54, 134, 110, 0.5);
`;

const IconContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.primary};
  margin-bottom: 16px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const StyledText = styled(ButtonText)`
  color: ${({ theme: { colors } }) => colors.white};
`;
