import { IAppAction } from '../app-action';
import {  IAppError, IPaymentDetails, IPaymentResults } from '../../models';

export const paymentStateActions = {
    MAKE_PAYMENT: "MAKE_PAYMENT",
    MAKE_PAYMENT_SUCCESS: "MAKE_PAYMENT_SUCCESS",
    MAKE_PAYMENT_ERROR: "MAKE_PAYMENT_ERROR",
    CONFIRM_PAYMENT: "CONFIRM_PAYMENT",
    CONFIRM_PAYMENT_SUCCESS: "CONFIRM_PAYMENT_SUCCESS",
    CONFIRM_PAYMENT_ERROR: "CONFIRM_PAYMENT_ERROR",
    PAYMENT_RESULTS_CLEAR: "PAYMENT_RESULTS_CLEAR",
    ACCEPT_TERMS: "ACCEPT_TERMS",
    ACCEPT_TERMS_SUCCESS: "ACCEPT_TERMS_SUCCESS",
    ACCEPT_TERMS_ERROR: "ACCEPT_TERMS_ERROR",
    PAYMENT_GATEAWAY: "PAYMENT_GATEAWAY",
    PAYMENT_GATEAWAY_SUCCESS: "PAYMENT_GATEAWAY_SUCCESS",
    PAYMENT_GATEAWAY_ERROR: "PAYMENT_GATEAWAY_ERROR",
    GET_PAYMENT_GATEWAY: "GET_PAYMENT_GATEWAY",
    GET_PAYMENT_GATEWAY_SUCCESS: "GET_PAYMENT_GATEWAY_SUCCESS",
    GET_PAYMENT_GATEWAY_ERROR: "GET_PAYMENT_GATEWAY_ERROR",
    GET_PAYMENT_GATEWAY_SESSION: "GET_PAYMENT_GATEWAY_SESSION",
    GET_PAYMENT_GATEWAY_SESSION_SUCCESS: "GET_PAYMENT_GATEWAY_SESSION_SUCCESS",
    GET_PAYMENT_GATEWAY_SESSION_ERROR: "GET_PAYMENT_GATEWAY_SESSION_ERROR",
	};

export const makePayment = (paymentDetails: IPaymentDetails): IAppAction => ({
  type: paymentStateActions.MAKE_PAYMENT,
  payload: paymentDetails,
  meta: { appInsights: { trackPayload: true}}
});

export const makePaymentSuccess = (results: IPaymentResults): IAppAction => ({
  type: paymentStateActions.MAKE_PAYMENT_SUCCESS,
  payload: results
});

export const makePaymentError = (error: IAppError): IAppAction => ({
  type: paymentStateActions.MAKE_PAYMENT_ERROR,
  payload: error,
  meta: { appInsights: { trackPayload: true}}
});

export const confirmPayment = (paymentDetails: IPaymentDetails): IAppAction => ({
  type: paymentStateActions.CONFIRM_PAYMENT,
  payload: paymentDetails,
  meta: { appInsights: { trackPayload: true}}
});

export const confirmPaymentSuccess = (results: IPaymentResults): IAppAction => ({
  type: paymentStateActions.CONFIRM_PAYMENT_SUCCESS,
  payload: results
});

export const confirmPaymentError = (error: IAppError): IAppAction => ({
  type: paymentStateActions.CONFIRM_PAYMENT_ERROR,
  payload: error,
  meta: { appInsights: { trackPayload: true}}
});

export const paymentResultsClear = (): IAppAction => ({
  type: paymentStateActions.PAYMENT_RESULTS_CLEAR
});

export const acceptTerms = (bookId: number, acceptedTermIds: Array<number>, emailAddress : string): IAppAction => ({
  type: paymentStateActions.ACCEPT_TERMS,
  payload: {bookId, acceptedTermIds, emailAddress},
  meta: { appInsights: { trackPayload: true}}
});

export const acceptTermsSuccess = (): IAppAction => ({
  type: paymentStateActions.ACCEPT_TERMS_SUCCESS
});

export const acceptTermsError = (error: IAppError): IAppAction => ({
  type: paymentStateActions.ACCEPT_TERMS_ERROR,
  payload: error,
  meta: { appInsights: { trackPayload: true}}
});

export const getPaymentGatewaySession = (data: any): IAppAction => ({
  type: paymentStateActions.GET_PAYMENT_GATEWAY_SESSION,
  payload: data
});

export const getPaymentGatewaySessionSuccess = (data: any): IAppAction => ({
  type: paymentStateActions.GET_PAYMENT_GATEWAY_SESSION_SUCCESS,
  payload: data
});

export const getPaymentGatewaySessionError = (error: IAppError): IAppAction => ({
  type: paymentStateActions.GET_PAYMENT_GATEWAY_SESSION_ERROR,
  payload: error
});
