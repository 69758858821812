import scottDunnTheme from "./scottDunnTheme";

const defaultTheme = {
  colors: {
    primary: scottDunnTheme.colors.green,
    primaryDark: scottDunnTheme.colors.greenDark,
    primaryExtraDark: scottDunnTheme.colors.greenExtraDark,
    accentLight: scottDunnTheme.colors.pinkLight,
    accent: scottDunnTheme.colors.pink,
    accentDark: scottDunnTheme.colors.pinkDark,
    body: scottDunnTheme.colors.black,
    label: scottDunnTheme.colors.grey,
    labelLight: scottDunnTheme.colors.greyLight,
    labelWhite: scottDunnTheme.colors.greyWhite,
    labelDark: scottDunnTheme.colors.greyDark,
    white: scottDunnTheme.colors.white,
    transparent: scottDunnTheme.colors.transparent,
    primaryDarkTransparent: scottDunnTheme.colors.greenDarkTransparent,
    red: scottDunnTheme.colors.red
    // black: scottDunnTheme.colors.black
  },
  fonts: {
    primary: scottDunnTheme.fonts.mallory,
    secondary: scottDunnTheme.fonts.chap,
  },
  unit: "10",
  breakpoints: {
    mobile: 375,
    tablet: 768,
    laptop: 1024,
    desktop: 1280,
  },
};

export default defaultTheme;
