import React from "react";
import styled from "styled-components";
import { Tag } from "../../../components";
import { Icon } from "../../../components/icons/icon-component";
import { Body1 } from "../../../components/typography";
import { getItineraryIconFromType } from "../../../helpers/icon-helper";
import { IHoliday } from "../../../models";
interface IHolidayItineraryProps{
    holiday: IHoliday;
};

export const HolidayItinerary = ({holiday}: IHolidayItineraryProps) => {
 
    return(
        <MainContainer>
            {holiday.itineraryGroups && holiday.itineraryGroups.map((itineraryGroup, i) => 
                <div key={'itinerary' + i}>
                <ItinDate>{itineraryGroup.groupTitle}</ItinDate>
                <ItineraryGroupContainer>
                {itineraryGroup.itin && itineraryGroup.itin.map((item, ii) =>
                <Container key={'item'+ ii} className="circleContainer">
                    <IconInCircle icon={getItineraryIconFromType(item.iconType)}/>
                    <TextContainer>
                        <StyledBody1>
                            {
                            ((item.itinType === "Accomm" && (item.itinOperator !== "Experience" && item.itinOperator !== "Offer")) 
                            || item.itinType === "Car Hire") ? (item.itinOperator ? item.itinOperator : item.itinLocation)
                            :  item.itinLocation
                            }
                        </StyledBody1>
                        {
                            item.itinOperator && item.itinType === "Transfer" && 
                            <StyledTag label={item.itinOperator}/>
                        }
                        {
                            item.itinBasis && item.itinType !== "Transfer" && 
                            <StyledTag label={item.itinBasis}/>
                        }
                        {/* <StyledTag label={item.itinType === "Transfer"? item.itinOperator:item.itinBasis}/> */}
                        <Detail>{item.itinType === "Car Hire" ? item.itinOperator : item.itinType === "Transfer"? item.travelDuration: item.itinDetails}    
                        {item.departureTime ? ' ' + item.departureTime:''}  {item.arrivalTime? ' - ' + item.arrivalTime:''}</Detail>
                        <Detail>{item.seatingDesc ? 'Seating - ' + item.seatingDesc : ''}</Detail>
                    </TextContainer>
                </Container>
                )}
                </ItineraryGroupContainer>
                </div>
            )}
        </MainContainer>
    )
}

interface IIconInCircle {
    icon: string;
}

const IconInCircle = ({icon}: IIconInCircle) => {
    return(
        <Circle className="circle"><Icon name={icon} size={24}/></Circle>
    );
}

const Circle = styled.div`
margin-top: 8px;
display: inline-block;
height: 46px;
border-radius: 60px;
border: ${({ theme }) => `1px solid ${theme.colors.labelDark}`};
padding: 0.5em 0.6em;
position: relative; 
`;

const MainContainer = styled.div`
padding: 20px;
`;

const StyledTag = styled(Tag)`
color: ${({ theme }) => theme.colors.white};
display: inline-block;
width: auto;
margin-top: 5x;
margin-bottom: 5px;
padding-top: 2px;
font-size: 8px;
`;

const ItinDate = styled(Body1)`
color: ${({ theme }) => theme.colors.primaryDark};
text-transform: uppercase;
font-weight: 700;
font-size: 12px;
display: block;
margin: 10px 0px 10px;
`;

const StyledBody1 = styled(Body1)`
font-size: 15px;
font-weight: 700;
display: block;
`;

const Detail = styled(Body1)`
color: ${({ theme }) => theme.colors.labelDark};
font-size: 12px;
display: block;
`;

const TextContainer = styled.div`
margin-left: 15px;
`;

const Container = styled.div`
display: flex;
flex-flow: row nowrap;
height: 115px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        height: 92px;
    }
`;

const ItineraryGroupContainer = styled.div`
* > .circle:before {
    position: absolute; 
    border: 1px dashed ${({ theme }) => theme.colors.labelDark}; 
    width: 0; 
    height: 67px; 
    display: block; 
    content: ''; 
    left: 50%; 
    top: -69px; 
    margin-left: -1px;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        height: 47px; 
        top: -47px; 
    }
}
.circleContainer:first-of-type > .circle:before {
    display: none;
}
`;


