import React from "react";
import styled from "styled-components";
import { PartyInfoTitle } from ".";
import {  IconEdit } from "..";
import { IAddress } from "../../models";

interface IAddressProps {
    title: string;
    address: IAddress | null;
    showEdit: boolean;
    onEditClick(name: string): void;
};

export const Address = (props: IAddressProps) => {
    return (
        <AddressDisplay
            title={props.title}
            address={props.address}
            showEdit={props.showEdit}
            onEditClick={props.onEditClick} />
    )
};

const AddressDisplay = (props: IAddressProps) => {
    return (
        <AddressContent>
            <PartyInfoTitle>{props.title} </PartyInfoTitle>
            {props.showEdit === true &&
                <DataWrapper>
                    <AddressInfo>{props.address && props.address.address1} </AddressInfo>  
                    <IconWrapper onClick={() => props.onEditClick('address')}><IconEdit size={40} /></IconWrapper>
                </DataWrapper>
            }
            {props.showEdit === false && <AddressInfo>{props.address && props.address.address1}</AddressInfo>}
            <AddressInfo>{props.address && props.address.address2}</AddressInfo>
            <AddressInfo>{props.address && props.address.address3}</AddressInfo>
            <AddressInfo>{props.address && props.address.address4}</AddressInfo>
            <AddressInfo>{props.address && props.address.city}</AddressInfo>
            <AddressInfo>{props.address && props.address.postCode}</AddressInfo>
            {props.address && props.address.country && <AddressInfo>{props.address && props.address.country.title}</AddressInfo>}
        </AddressContent>
    )
};

const AddressContent = styled.div`
display:flex;
flex-flow:column nowrap;
padding:10px 0 0;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    flex-flow:column nowrap;
    width: 50%
}
`;

const IconWrapper = styled.div`
color: ${({ theme }) => theme.colors.labelDark};
`;

const DataWrapper = styled.div`
display:flex;
flex-flow: row;
justify-content: space-between;
height: auto;
`;

const AddressInfo = styled.div`
color:${({ theme }) => theme.colors.primaryDark};
margin: 2px 0px;
flex-flow: row nowrap;
height: auto;
overflow: auto;
`;