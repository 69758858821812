import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError,  ICountry } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { fetchCountriesSuccess, fetchCountriesError, referenceDataStateActions } from '.';

export const fetchCountriesEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(referenceDataStateActions.FETCH_COUNTRIES),
        mergeMap(action => {        
            return api.get(endPointKeys.api_contact, 'LookUp/Country' ).pipe(
                map(result => result.data),
                mergeMap((countries: Array<ICountry>) => {
                    return of(fetchCountriesSuccess(countries));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed to load countries" };
                    sendToast(appError.message, ToastType.Error);
                    return of(fetchCountriesError(appError));
                }))
        })
);