import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconMap = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(6.25 4.688)">
        <path 
        fill={props.color}
        d="M40.813,31.322V44.669L31.438,42.66V31.322c-.574-.862-1.029-1.523-1.562-2.3V42.7L20.5,45.381V19.662l4.709-1.346a10.251,10.251,0,0,1,.02-1.631l-5.537,1.582L8,15.763V44.7l11.745,2.517L30.683,44.1,42.375,46.6V29.018C41.841,29.8,41.386,30.46,40.813,31.322ZM9.563,17.7,18.938,19.7V45.45L9.563,43.441Z" 
        transform="translate(-8 -8.136)"/>
        <path 
        fill={props.color}
        d="M41.375,6A9.4,9.4,0,0,0,32,15.375c0,3.392,1.275,5.894,3.125,8.714,2.05,3.124,3.062,4.239,6.25,9.742,3.186-5.5,4.2-6.611,6.25-9.742,1.875-2.857,3.125-5.32,3.125-8.714A9.385,9.385,0,0,0,41.375,6Zm0,24.737c-2.617-4.342-4.8-7-6.25-9.675a10.716,10.716,0,0,1-1.354-7.452,7.809,7.809,0,0,1,15.416,1.765,11.4,11.4,0,0,1-1.562,5.687C46.168,23.75,44,26.385,41.375,30.737Z" 
        transform="translate(-13.25 -6)"/>
        <path 
        fill={props.color} 
        d="M42.906,13a3.906,3.906,0,0,0,0,7.813,3.906,3.906,0,0,0,0-7.812Zm0,6.25a2.344,2.344,0,1,1,2.171-1.47A2.339,2.339,0,0,1,42.906,19.25Z" 
        transform="translate(-14.781 -7.531)"/>
      </g>
    </Svg>
  );
});
