import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ButtonType, InputTypes } from "../components";
import Button from "../components/button";
import { useHistory } from "react-router-dom";
import { ImageBackground } from "../components/image-background/image-background";
import { Address, BillingAddress, InfoRow } from "../components/my-profile";
import { Headline3 } from "../components/typography";
import TextComponent from "../components/typography/text/text";
import { IProfileUpdate, IAppUser } from "../models";
import routes from "../routing/routes";
import { IAppState } from "../store/app-state";
import { updateProfile } from "../store/contact/contact-actions";
import { ToggleComponent } from "../components/toggle";
import { SDPImageBackground } from "../components/image-background/image-sdp-background";
import { getFormattedDate } from "../helpers/date-helper";

interface IFormField {
  isEditMode: boolean;
  isValid: boolean;
}

interface IMyProfile {
  firstName: IFormField;
  lastName: IFormField;
  primaryEmail: IFormField;
  primaryPhone: IFormField;
  address: IFormField;
}

const getDefaultState = (): IMyProfile => {
  return {
    firstName: { isEditMode: false, isValid: true },
    lastName: { isEditMode: false, isValid: true },
    primaryEmail: { isEditMode: false, isValid: true },
    primaryPhone: { isEditMode: false, isValid: true },
    address: { isEditMode: false, isValid: true },
  };
};

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );
  const contact = useSelector((state: IAppState) => state.contact.contact);

  const billingAddress = useSelector((state: IAppState) =>
    state.contact.contact ? state.contact.contact.billingAddress : null
  );

  const [formFields, setFormField] = React.useState<IMyProfile>(
    getDefaultState()
  );

  const updateState = (name: any) => {
    const fields: IMyProfile = {
      ...getDefaultState(),
      [name]: {
        isEditMode: true,
      },
    };
    setFormField(fields);
  };

  const handleClose = (name: string) => {
    const fields: IMyProfile = {
      ...getDefaultState(),
      [name]: {
        isEditMode: false,
      },
    };
    setFormField(fields);
  };

    const handleSave = (name: string, data: any) => {
         // Adding condition to remove spaces from first name and last name 
         if(name === 'firstName' || name === 'lastName'){
            data = data.trim();
        }
        const payLoad: IProfileUpdate = {
            contactId: contact.contactId,
            fieldName: name,
            value: data
        };  
       dispatch(updateProfile(payLoad));
       setFormField(getDefaultState());
    }

  const onAddressUpdate = () => {
    const fields: IMyProfile = {
      ...getDefaultState(),
      address: { isEditMode: false, isValid: true },
    };
    setFormField(fields);
  };

  return (
    <React.Fragment>
      {appUser?.isSDP ? (
        <SDPImageBackground url="/images/SDPBackgroundImagePattern.png" />
      ) : (
        <ImageBackground url="/images/backgroundImagePattern.gif" />
      )}

      <PageWrapper>
        <StyledHeadline3>My Profile</StyledHeadline3>

        <StyledTextComponent>
          Manage your main contact and login details.
        </StyledTextComponent>
        {billingAddress && console.log(billingAddress)}
        {contact && (
          <ContentWrapper>
            <InfoRow
              title="First Name"
              value={contact.firstName}
              key={1}
              editMode={formFields.firstName.isEditMode}
              field="firstName"
              maxLength={50}
              errorMessage="Please enter a first name "
              inputType={InputTypes.TEXT}
              onEditClick={updateState}
              onEditClose={() => handleClose("firstName")}
              onSave={handleSave}
            />
            <InfoRow
              title="Last Name"
              value={contact.lastName}
              key={2}
              maxLength={50}
              editMode={formFields.lastName.isEditMode}
              field="lastName"
              errorMessage="Please enter a last name "
              inputType={InputTypes.TEXT}
              onEditClick={updateState}
              onEditClose={() => handleClose("lastName")}
              onSave={handleSave}
            />
            <InfoRow
              title="Email"
              value={contact.primaryEmail ? contact.primaryEmail.title : ""}
              key={3}
              inputType={InputTypes.EMAIL}
              field="primaryEmail"
              maxLength={50}
              errorMessage=""
              editMode={formFields.primaryEmail.isEditMode}
              onEditClick={updateState}
              onEditClose={() => handleClose("primaryEmail")}
              onSave={handleSave}
            />
            <InfoRow
              title="Phone"
              value={contact.primaryPhone}
              key={4}
              inputType={InputTypes.PHONE}
              field="primaryPhone"
              maxLength={15}
              errorMessage=""
              editMode={formFields.primaryPhone.isEditMode}
              onEditClick={updateState}
              onEditClose={() => handleClose("primaryPhone")}
              onSave={handleSave}
            />
            <AddressWrapper>
              <Address
                address={contact.address}
                title="Address"
                showEdit={false}
                onEditClick={() => {}}
              />
              {/* <PostCodeDropdown/> */}
              {billingAddress && formFields.address.isEditMode === false && (
                <Address
                  address={
                    contact.billingAsPostal === true ? null : billingAddress
                  }
                  title="Billing Address"
                  showEdit={true}
                  onEditClick={updateState}
                />
              )}

              {(billingAddress === undefined ||
                billingAddress === null ||
                formFields.address.isEditMode === true) && (
                <BillingAddress onSave={onAddressUpdate} />
              )}
            </AddressWrapper>
            {appUser?.isSDP && (
              <SDPMembershipWrapper>
                <SDPMemberContent>
                  Membership No{" "}
                  <SDPValue>
                    {appUser?.membershipNumber === "0"
                      ? "N/A"
                      : appUser?.membershipNumber}
                  </SDPValue>
                </SDPMemberContent>
                <SDPMemberContent>
                  Membership Tier <SDPValue>{appUser?.tierLevel}</SDPValue>
                </SDPMemberContent>
                <SDPMemberContent>
                  Relationship Manager <SDPValue>{appUser?.loyaltyPlanner}</SDPValue>
                </SDPMemberContent>
                <SDPMemberContent>
                  Membership Expiry Date{" "}
                  <SDPValue>
                    {getFormattedDate(
                      new Date(appUser?.membershipExpiryDate),
                      "DD MMM yyyy"
                    )}
                  </SDPValue>
                </SDPMemberContent>
              </SDPMembershipWrapper>
            )}

            <Preference>
              <PartyInfoTitle>Preferences </PartyInfoTitle>
              <ToggleComponent
                isChecked={contact.marketingOptIn}
                onChange={() =>
                  handleSave("subscription", !contact.marketingOptIn)
                }
                title="Marketing Opt in"
              />
            </Preference>

            <ButtonWrapper>
              <ButtonChangePassword
                type={ButtonType.ALTERNATIVE}
                text="Change Password"
                onClick={() => history.push(routes.changePassword())}
              />
            </ButtonWrapper>
          </ContentWrapper>
        )}
      </PageWrapper>
    </React.Fragment>
  );
};

const PageWrapper = styled.div`
  display: flex;
  width: 350px;
  flex-flow: column nowrap;
  margin-left: 5px;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 20px);
    margin: 0 auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: calc(100% - 80px);
    max-width: 800px;
    margin: 0 auto;
  }
`;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
`;

const StyledTextComponent = styled(TextComponent)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 40px;
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 40px 40px 20px 40px;
`;
const Preference = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border-bottom: 2px solid ${({ theme }) => theme.colors.labelLight};
  padding: 10px 0 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: column nowrap;
  }
  margin-bottom: 5px;
`;

const PartyInfoTitle = styled.div`
  color: ${({ theme }) => theme.colors.label};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-bottom: 10px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 35%;
  }
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.labelLight};
  padding-bottom: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row nowrap;
  }
`;

const SDPMembershipWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-bottom: 2px solid ${({ theme }) => theme.colors.labelLight};
  padding-bottom: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row nowrap;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 15px;
  width: 100%;
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-between;
  flex-direction: "row";
`;

const ButtonChangePassword = styled(Button)`
  width: 300px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 30%;
  }
`;

const SDPMemberContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 0 0;
  color: #b3b3b3;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: column nowrap;
    width: 50%;
  }
`;

const SDPValue = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 0 0;
  color: #00402e;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: column nowrap;
    width: 50%;
  }
`;
