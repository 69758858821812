import React, { useContext, useEffect, useState } from "react";
import { IAppUser } from "../models";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { useWindowSize } from "../helpers/useWindowSize";
import { IAppState } from "../store/app-state";
import { MainMenu, SubMenu } from ".";
import { logOut, refreshToken, toggleMobileMenu } from "../store/session";
import { IconIosMenu, IconDelete } from "./icons";
import { LoginContainer } from "./login/login-container";
import IdleTimer from "react-idle-timer";
import jwt_decode from "jwt-decode";
import moment from "moment";

interface IProps {
  children?: any;
}

const isLoggedInUser = (appUser: IAppUser | null): boolean => {
  if (appUser && appUser.token) {
    var decoded: any = jwt_decode(appUser.token);
    if(!decoded){
      return false;
    }
    //handle when local storage have an expired access token. compare the time and clear local storage if its an expired token 
    const expiryDate = moment(new Date(Number(decoded.exp) * 1000));
    const currentTime = moment(new Date());
    var duration = moment.duration(expiryDate.diff(currentTime)).asMinutes();
    if (duration < 2) {
      localStorage.clear();
      return false;
    }
    return true;
  }
  return false;
}

export const AppContainer = ({children}: IProps) => {
    const appUser: IAppUser | null = useSelector((state: IAppState) => state.session.appUser);
    const isMenuOpen: boolean = useSelector((state: IAppState) => state.session.isMobileMenuOpen);
    const { breakpoints, colors } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const size = useWindowSize();
    const isDesktop = size.width >= breakpoints.laptop;    
    const [timeout] = useState(1800); //seconds, 30 mins   
    const isLoggedIn = isLoggedInUser(appUser);
 
    const openMenu = () => {
        dispatch(toggleMobileMenu(!isMenuOpen));
  }

  const silentTokenRefresh = () => {
    if (isLoggedIn === true) {
      dispatch(refreshToken());
    }
    setTimeout(silentTokenRefresh, 1000 * 60 * 50);
  }

  setTimeout(() => {
    silentTokenRefresh();
  }, 1000 * 60 * 50);

    const MobileHeader = ({isSDP}:any) => {
        return(
            <MobileHeaderContainer>
                {isMenuOpen ? 
                <MobileIcon onClick={openMenu}>
                    <IconDelete size={20} color={colors.white}/>
                </MobileIcon>:
                <MobileIcon onClick={openMenu}>
                    <IconIosMenu size={20} color={colors.white}/>
                </MobileIcon>}
                <MobileLogo>
                  {isSDP ? 
                    <img height='35px' src={window.location.origin + '/images/sdp_msd_logo.svg'} alt='My Scott Dunn'/> :
                    <img height='30px' src={window.location.origin + '/images/msd_logo.svg'} alt='My Scott Dunn'/>
                  }
                </MobileLogo>
            </MobileHeaderContainer>
        )
    }

    const storageChange = React.useCallback(
      (e: any) => {
        //logout if another window has timed out
        if(e.StorageArea && e.StorageArea.timedOut){
          dispatch(logOut());
          localStorage.removeItem("session");
        }
   },[dispatch]);

  useEffect(() => {
    window.addEventListener("storage",(e) => storageChange(e));
   return () => {
    window.removeEventListener('storage', e => storageChange(e));
  };
}
    ,[storageChange]);

    const onActive = (e: Event) => {
      //console.log('user active', e);
      localStorage.removeItem("timedOut");
    }
    const onAction = (e: Event) => {
      //console.log('user did something', e);
      localStorage.removeItem("timedOut");
    }
    const onIdle = (e: Event) => {
      //console.log('user idle', e);
      dispatch(logOut());
      localStorage.setItem("timedOut", (Date.now() + (timeout * 1000)).toString());
    }

    return ( 
        isLoggedIn ?
        <div>
            {isDesktop ? 
            <DesktopContainer>
                <DesktopMenu  isSDP = {appUser?.isSDP}/>
                <DesktopContents>
                    {children}
                </DesktopContents>
            </DesktopContainer> 
            : 
            <MobileContainer>
                <MobileHeader isSDP = {appUser?.isSDP}/>
                <MobileMenu isMenuOpen={isMenuOpen}/>
                <MobileContent>
                {children}
                </MobileContent>
            </MobileContainer>}
            <IdleTimer
            element={document}
            onActive={onActive}
            onIdle={onIdle}
            onAction={onAction}
            debounce={250}
            timeout={timeout*1000} />
        </div>:
        <div>
            <LoginContainer/>
        </div>
    )
}

const DesktopContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const DesktopContents = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const MobileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const MobileLogo = styled.div`
  margin-top: 10px;
`;

const MobileHeaderContainer = styled.div`
  height: 50px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryDark};
  display: flex;
  flex-direction: row nowrap;
  justify-content: center;
  position: fixed;
  z-index: 30;
`;

const MobileContent = styled.div`
margin-top: 50px;
`;

const MobileIcon = styled.span`
    position: absolute;
    top: 15px;
    left: 15px;
    height: 25px;
    width: 25px;
`;

const MobileMenu = (props: { isMenuOpen: Boolean }) => {
  const menuClass: string = props.isMenuOpen ? "menu-show" : "menu-hide";
  return (
    <MobileInnerContainer className={`${menuClass}`}>
      <MainMenu />
      <SubMenu />
    </MobileInnerContainer>
  );
};

const MobileInnerContainer = styled.div`
    position: fixed;
    background-color: ${({ theme }) => theme.colors.primaryExtraDark};
    font-size: 13px;
    left: 0px;
    top: 50px;
    transition: transform .3s ease-out;
    width: 100vw;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        width: 300px;
    }
    height: calc(100vh - 50px);
    overflow: scroll;
    z-index: 10;
    &.menu-show {
        transform: translateX(0vw);
        overflow: hidden;
    }
    &.menu-hide {
        transform: translateX(-100vw);
    }
`;

const DesktopMenu = ({isSDP}:any) => {
  return (
    <DesktopInnerContainer>
      {isSDP  ? 
      <DesktopLogo>
        <img
          height="65px"
          src={window.location.origin + "/images/sdp_msd_logo.svg"}
          alt="My Scott Dunn"
        />
      </DesktopLogo>:
      <DesktopLogo>
      <img
        height="50px"
        src={window.location.origin + "/images/msd_logo.svg"}
        alt="My Scott Dunn"
      />
      </DesktopLogo>
      }
      <MainMenu />
      <SubMenu />
    </DesktopInnerContainer>
  );
};

const DesktopInnerContainer = styled.div`
  min-width: 250px;
  flex-grow: 0;
  padding: 10px;
  font-size: 12px;
`;

const DesktopLogo = styled.div`
  margin-top: 4vh;
  margin-left: 30px;
`;
