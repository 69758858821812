import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconIsland = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
    <rect fill="none" width="50" height="50"/>
    <g transform="translate(0.312 3.943)">
        <path 
        fill={props.color}
        d="M48.828,52.1c-.028.022-2.86,2.181-5.577,2.181-1.394,0-3.5-.586-6.059-2.551a10.259,10.259,0,0,1-6.05,2.551,10.25,10.25,0,0,1-6.049-2.55c-1.691,1.29-3.971,2.55-6.061,2.55s-4.362-1.259-6.052-2.541C11.307,53,8.991,54.286,6.923,54.286c-2.636,0-5.556-2.165-5.585-2.187L.4,53.348c.136.1,3.361,2.5,6.524,2.5a12.131,12.131,0,0,0,6.052-2.171,11.977,11.977,0,0,0,6.057,2.171,11.968,11.968,0,0,0,6.049-2.166,11.676,11.676,0,0,0,6.06,2.166A11.676,11.676,0,0,0,37.2,53.684a11.819,11.819,0,0,0,6.052,2.164c3.257,0,6.4-2.4,6.533-2.507Z" 
        transform="translate(-0.399 -15.26)"/>
        <path 
        fill={props.color}
        d="M15.556,16.111c-3.214,3.791-2.631,8.611-.187,13.007.994-1.9,2.992-5.983,6.113-8.493a3.392,3.392,0,0,0,4.637,2.121,23.794,23.794,0,0,1,2.988,11.316,25.958,25.958,0,0,0-2.694-.145c-7.7,0-15.125,3.481-18.472,8.552a4.286,4.286,0,0,0,2.144-.284c3.373-4.026,9.733-6.705,16.327-6.705,6.509,0,12.782,2.613,16.187,6.551a5.517,5.517,0,0,0,2.3.466c-2.714-4.136-8.14-7.218-14.219-8.226A25.309,25.309,0,0,0,27.4,21.779a3.284,3.284,0,0,0,.765-2.385,20.524,20.524,0,0,1,9.288,4.3c.221-5.567-1.5-10.64-6.791-11.785,1.563-1.54,3.772-1.584,6.778-1.859A11.652,11.652,0,0,0,33.6,6.342,10.029,10.029,0,0,0,26.484,5.3,6.9,6.9,0,0,0,21.351,10a8.854,8.854,0,0,0-7.028-.513c-4.451,1.692-6.078,6.733-7.108,9.6C10.694,17.342,13.291,15.879,15.556,16.111Zm10.214,5.07a1.865,1.865,0,0,1-2.877-1.515,4.569,4.569,0,0,1,1.583-.595,15.325,15.325,0,0,1,2.087.092A1.846,1.846,0,0,1,25.771,21.181ZM14.879,10.942c3.059-1.163,5.828.444,7.561,1.389.239-1.279.543-4.579,4.412-5.513A8.511,8.511,0,0,1,34.311,8.79c-4.274.557-5.187,2.365-6.416,4.385l1.27.1c5.243.4,6.448,4.977,6.717,7.41A21.211,21.211,0,0,0,24.4,17.527c-.187.008-4.412.274-8.969,8.176a8.486,8.486,0,0,1,2.135-9.415l.889-.8c-1.636-.746-3.569-1.921-8.3.359A9.26,9.26,0,0,1,14.879,10.942Z" 
        transform="translate(-1.89 -5.047)"/>
    </g>
    </Svg>
  );
});