import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconHeadset = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 39.063 43.75" {...props}>
      <g transform="translate(-5.469 -3.906)">
        <g transform="translate(5.469 3.906)">
          <path
            fill={props.color}
            d="M26.531,5A19.491,19.491,0,0,0,9.957,34.813l.173.978a7.031,7.031,0,0,0,8.145,5.7,3.123,3.123,0,0,0,2.535-3.62L18.775,26.333a3.125,3.125,0,0,0-3.62-2.534,7.039,7.039,0,0,0-5.763,6.092,17.861,17.861,0,0,1,3.135-16.6l2.123.671a4.056,4.056,0,0,0,1.22.19,3.786,3.786,0,0,0,2.351-.8,13.427,13.427,0,0,1,16.621,0,3.868,3.868,0,0,0,3.571.613l2.123-.671a17.968,17.968,0,0,1,3.138,16.643A7.033,7.033,0,0,0,37.907,23.8a3.125,3.125,0,0,0-3.62,2.534L32.252,37.874a3.123,3.123,0,0,0,2.535,3.62h0a7.138,7.138,0,0,0,.721.086,12.7,12.7,0,0,1-4.094,3.29A2.333,2.333,0,1,0,32,46.406c0-.025-.007-.049-.007-.074a14.031,14.031,0,0,0,5.582-4.925,6.96,6.96,0,0,0,5.358-5.617l.172-.976A19.492,19.492,0,0,0,26.531,5ZM11.9,27.586a5.433,5.433,0,0,1,3.53-2.249,1.561,1.561,0,0,1,1.81,1.267l2.035,11.541A1.561,1.561,0,0,1,18,39.956h0a5.468,5.468,0,0,1-6.335-4.436l-.678-3.847A5.433,5.433,0,0,1,11.9,27.586ZM37.942,12.473a2.31,2.31,0,0,1-2.133-.35,14.988,14.988,0,0,0-18.555,0,2.3,2.3,0,0,1-2.133.35l-1.461-.462c.226-.232.312-.339.853-.826a17.934,17.934,0,0,1,24.4.338c.341.325.314.309.489.488Zm4.13,19.2-.678,3.847a5.468,5.468,0,0,1-6.335,4.436h0a1.561,1.561,0,0,1-1.267-1.81L35.826,26.6a1.561,1.561,0,0,1,1.81-1.267,5.468,5.468,0,0,1,4.436,6.335Z"
            transform="translate(-7 -5)"
          />
        </g>
      </g>
    </Svg>
  );
});
