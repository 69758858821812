import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconTaxi = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(4.688 5.51)">
        <path 
        fill={props.color} 
        d="M46.609,20.683a2.724,2.724,0,0,0-2.719-2.734H41.34l-1.416-4.286a3.13,3.13,0,0,0-2.646-2.128c-1.269-.131-3.238-.312-5.5-.445V8.615a1.564,1.564,0,0,0-1.562-1.562H22.406a1.564,1.564,0,0,0-1.562,1.563V11.09c-2.259.132-4.228.314-5.5.445A3.13,3.13,0,0,0,12.7,13.663l-1.416,4.286H8.734A2.733,2.733,0,0,0,8.7,23.414a10.578,10.578,0,0,0-1.918,5.942v12.03a1.564,1.564,0,0,0,1.563,1.563h4.688a1.564,1.564,0,0,0,1.563-1.562V38.262H38.031v3.125a1.564,1.564,0,0,0,1.563,1.563h4.688a1.564,1.564,0,0,0,1.563-1.562V29.357a10.577,10.577,0,0,0-1.918-5.942A2.722,2.722,0,0,0,46.609,20.683Zm-2.719-1.172a1.172,1.172,0,0,1,0,2.344H42.648l-.027-.031-.764-2.313ZM22.406,8.574h7.813V11.01c-2.759-.122-5.048-.123-7.812,0Zm-8.222,5.579a1.565,1.565,0,0,1,1.323-1.064,97.029,97.029,0,0,1,21.611,0,1.565,1.565,0,0,1,1.323,1.064l2.287,6.921H11.9Zm-6.622,6.53a1.173,1.173,0,0,1,1.172-1.172h2.034L10,21.825l-.027.031H8.734A1.173,1.173,0,0,1,7.563,20.683Zm.781,20.7V38.262h4.688v3.125Zm35.939,0H39.594V38.262h4.688Zm0-12.03V36.7H8.344V29.357A9.364,9.364,0,0,1,11.37,22.67l.011-.034H41.244l.011.034A9.365,9.365,0,0,1,44.281,29.357Z" 
        transform="translate(-6 -7.053)"/>
        <rect width="12.5" height="1.563" transform="translate(14.063 24.178)"/>
        <path 
        fill={props.color} 
        d="M17.444,33.731l-4.687-.586A1.564,1.564,0,0,0,11,34.7v2.24A1.564,1.564,0,0,0,12.563,38.5H17.25a1.564,1.564,0,0,0,1.563-1.562V35.281A1.565,1.565,0,0,0,17.444,33.731Zm-4.881,3.2V34.7l4.688.586v1.654Z" 
        transform="translate(-7.094 -12.758)"/>
        <path 
        fill={props.color} 
        d="M44.369,33.731A1.565,1.565,0,0,0,43,35.281v1.654A1.564,1.564,0,0,0,44.563,38.5H49.25a1.564,1.564,0,0,0,1.563-1.562V34.7a1.565,1.565,0,0,0-1.756-1.55Zm4.882,3.2H44.563V35.281L49.25,34.7Z" 
        transform="translate(-14.094 -12.758)"/>
      </g>
    </Svg>
  );
});

