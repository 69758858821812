import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { IEpicDependency, IAppError, IProfileUpdate } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { contactStateActions, fetchContactProfile, updateProfileError } from '../contact/contact-actions';

export const updateProfileEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(contactStateActions.UPDATE_PROFILE),
        mergeMap(action => {
            let profilePayload : IProfileUpdate  = action.payload;
            var data = JSON.stringify(profilePayload);
            return api.put(endPointKeys.api_contact, 'Contact/MSD/UpdateProfile', data).pipe(
                map(result => result.data),
                mergeMap((resp: string) => {
                    return of(fetchContactProfile(profilePayload.contactId));
                }),
                catchError(error => {
                    const msg = error.response && error.response.data ? error.response.data: "Failed update profile";
                    const appError: IAppError = { error, message:msg };
                    sendToast(appError.message, ToastType.Error);
                    return of(updateProfileError(appError));
                }))
        })
    );

