import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { fetchBookPartiesError, fetchBookPartiesSuccess, holidayStateActions } from './holiday-actions';

export const fetchHolidayBookPartyEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.FETCH_BOOK_PARTIES),
        mergeMap((action) => {
            const bookid: number = action.payload;
            return api.get(endPointKeys.api_travel, `party/getbookparty?bookid=${bookid}`).pipe(
                map(res => res.data),
                map((m : any) => fetchBookPartiesSuccess(m)),
                catchError((error) => {
                    const appError : IAppError = { error: error, message: 'Error while loading book parties.'};
                    return of(fetchBookPartiesError(appError))
                }))
        })
    )