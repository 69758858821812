import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconBed = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(4.688 7.813)">
        <path 
        fill={props.color} 
        d="M11.563,13.125a1.564,1.564,0,0,1,1.563-1.562H41.25a1.564,1.564,0,0,1,1.563,1.563V22.5h1.563V13.125A3.129,3.129,0,0,0,41.25,10H13.125A3.129,3.129,0,0,0,10,13.125V22.5h1.563Z" 
        transform="translate(-6.875 -10)"/>
        <path 
        fill={props.color} 
        d="M42.719,26.688H39.594c0-2.585-2.8-4.687-6.25-4.687s-6.25,2.1-6.25,4.688H25.531c0-2.585-2.8-4.687-6.25-4.687s-6.25,2.1-6.25,4.688H9.906A3.911,3.911,0,0,0,6,30.594V46.219H7.563V41.531h37.5v4.688h1.563V30.594A3.911,3.911,0,0,0,42.719,26.688Zm-9.375-3.125c2.541,0,4.688,1.431,4.688,3.125H28.656C28.656,24.993,30.8,23.563,33.344,23.563Zm-14.062,0c2.541,0,4.688,1.431,4.688,3.125H14.594C14.594,24.993,16.74,23.563,19.281,23.563ZM45.063,39.969H7.563V30.594A2.346,2.346,0,0,1,9.906,28.25H42.719a2.346,2.346,0,0,1,2.344,2.344Z" 
        transform="translate(-6 -12.625)"/>
      </g>
    </Svg>
  );
});
