import React, { memo } from "react";
import styled, { css } from "styled-components";
import Text from "../text";

enum HeadlineLevel {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

export const headlineStyles = {
  [HeadlineLevel.H1]: css`
    font-size: 30px;
    line-height: 1.1;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 45px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
      font-size: 65px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
      font-size: 70px;
    }
  `,
  [HeadlineLevel.H2]: css`
    font-size: 60px;
    line-height: 1.1;
  `,
  [HeadlineLevel.H3]: css`
    font-size: 30px;
    line-height: 1.1;
    margin: 40px 0px 10px 0px;
    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
      font-size: 38px;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 48px;
    }
  `,
  [HeadlineLevel.H4]: css`
    font-size: 22px;
    line-height: 1.1;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 20px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
      font-size: 22px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
      font-size: 34px;
    }
  `,
  [HeadlineLevel.H5]: css`
    font-size: 20px;
    line-height: 1.1;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 18px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
      font-size: 19px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
      font-size: 25px;
    }
  `,
  [HeadlineLevel.H6]: css`
    font-size: 16px;
    line-height: 1.1;

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      font-size: 17px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
      font-size: 17px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
      font-size: 22px;
    }
  `,
};

export interface HeadlineProps {
  level?: HeadlineLevel;
  children: string;
  className?: string;
}

export const Headline = memo<HeadlineProps>(
  ({ className, children, level = HeadlineLevel.H1 }): JSX.Element => {
    return (
      <HeadlineStyle as={level} level={level} className={className}>
        {children}
      </HeadlineStyle>
    );
  }
);

export const Headline1 = memo<HeadlineProps>(
  ({ children, className }): JSX.Element => {
    return (
      <Headline level={HeadlineLevel.H1} className={className}>
        {children}
      </Headline>
    );
  }
);

export const Headline2 = memo<HeadlineProps>(
  ({ children, className }): JSX.Element => {
    return (
      <Headline level={HeadlineLevel.H2} className={className}>
        {children}
      </Headline>
    );
  }
);

export const Headline3 = memo<HeadlineProps>(
  ({ children, className }): JSX.Element => {
    return (
      <Headline level={HeadlineLevel.H3} className={className}>
        {children}
      </Headline>
    );
  }
);

export const Headline4 = memo<HeadlineProps>(
  ({ children, className }): JSX.Element => {
    return (
      <Headline level={HeadlineLevel.H4} className={className}>
        {children}
      </Headline>
    );
  }
);

export const Headline5 = memo<HeadlineProps>(
  ({ children, className }): JSX.Element => {
    return (
      <Headline level={HeadlineLevel.H5} className={className}>
        {children}
      </Headline>
    );
  }
);

export const Headline6 = memo<HeadlineProps>(
  ({ children, className }): JSX.Element => {
    return (
      <Headline level={HeadlineLevel.H6} className={className}>
        {children}
      </Headline>
    );
  }
);

export interface HeadlineStyleProps {
  level: HeadlineLevel;
  className?: string;
}

const HeadlineStyle = styled(Text)<HeadlineStyleProps>`
  color: ${({ theme }) => theme.colors.primaryDark};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 86px;
  ${({ level }) => headlineStyles[level]}
`;

export default Headline;
