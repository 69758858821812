import React from "react";
import styled from "styled-components";
import { IHoliday } from "../../../models";
import { MiniConsultantBox } from "../../../components/mini-consultant-box";
import { Headline5 } from "../../../components/typography";
import { GetCloudinaryImage } from "../../../helpers/getCloudinaryImages";
import DictionaryReplace from "../../../helpers/string-helper";

interface IHolidayAboutProps{
    holiday: IHoliday;
}

export const HolidayAbout = ({holiday}: IHolidayAboutProps) => {

    return(<div>
        <MiniConsultantBox
        profileImage={GetCloudinaryImage(holiday.consultant.imageId, false, 120, 120)}
        name={holiday.consultant.name}/>
        {holiday.consultant.synopsis && holiday.consultant.synopsis.length > 0 && <>
        <StyledHeadline>About Me</StyledHeadline>
        <NotesWrapper>{DictionaryReplace(holiday.consultant.synopsis,holiday.businessUnit.id)}</NotesWrapper>
        </>}
        {holiday.consultant.favouritePlaces && holiday.consultant.favouritePlaces.length > 0 && 
            <><StyledHeadline>{DictionaryReplace("Favourite Places",holiday.businessUnit.id)}</StyledHeadline>
            {holiday.consultant.favouritePlaces.map((place, i) => 
                <FavouritePlaceWrapper key={"favouriteplace"+i}>
                    <ImageWrapper url={GetCloudinaryImage(place.id.toString(), true)}/>
                    <TextWrapper>
                        <StyledHeadline>{place.title.toUpperCase()}</StyledHeadline>
                        <NotesWrapper>{DictionaryReplace(place.synopsis,holiday.businessUnit.id)}</NotesWrapper>
                 </TextWrapper>
                </FavouritePlaceWrapper>
             )}
        </>}
    </div>) 
};

const StyledHeadline = styled(Headline5)`
  margin: 12px 10px 15px 20px; 
  font-weight: 700;
  font-size: 16px;
  display: block;
`;

const NotesWrapper = styled.div`
padding: 0px 20px 5px 20px;
text-align: justify;
text-justify: inter-character;
font-size: 14px;
`;

const FavouritePlaceWrapper = styled.div`
display: flex;
flex-flow: column nowrap;
margin-bottom: 20px;
margin-left: 0px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row nowrap;
    margin-left: 20px;
}
`;

const TextWrapper = styled.div`
display: flex;
flex-flow: column nowrap;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 70%;
}
margin-right: 20px;
margin-top: -8px;
`;

interface ImageProps {
    url: string;
}

const ImageWrapper = styled.div<ImageProps>`
background-image: url(${({ url }) => url});
background-repeat: no-repeat;
background-position: center top;
background-size: cover;
width: calc(100% - 40px);
height: 250px;
margin-left: 20px;
margin-right: 20px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 30%;
    height: 150px;
    margin-left: 0px;
    margin-right: 0px;
}
`;