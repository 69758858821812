import * as React from "react";
import styled from "styled-components";
import { ImageBackground } from "../components/image-background/image-background";
import { AboutUsText } from "../helpers/datasource-helper";
import { Headline3 } from "../components/typography";
import { DictionaryReplace } from "../helpers/string-helper";
import { useSelector } from "react-redux";
import { IAppState } from "../store/app-state";
import { IAppUser } from "../models";
import { SDPImageBackground } from "../components/image-background/image-sdp-background";

export const AboutPage = () => {
  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );
  const contact = useSelector((state: IAppState) => state.contact.contact);
  const businessUnitId = contact && contact.businessUnitId;
  return (
    <PageWrapper>
      {appUser?.isSDP ? (
        <SDPImageBackground url="/images/SDPBackgroundImagePattern.png" />
      ) : (
        <ImageBackground url="/images/backgroundImagePattern.gif" />
      )}
      <StyledHeadline3>About Scott Dunn</StyledHeadline3>
      <NotesWrapper
        dangerouslySetInnerHTML={{
          __html: DictionaryReplace(AboutUsText(), businessUnitId),
        }}
      />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  width: calc(100% - 10px);
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 800px;
    max-width: inherit;
  }
`;

const NotesWrapper = styled.div`
  background-color: white;

  font-size: 14px;
  padding: 20px 40px 40px;
  height: 100%;
  p {
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.primary};
  }
`;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
`;
