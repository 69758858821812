import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError, IAppUser } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { contactStateActions, fetchContactProfileError, fetchContactProfileSuccess } from '../contact/contact-actions';
import { updateAppUserDetails } from '../session';

export const fetchContactProfileEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(contactStateActions.FETCH_CONTACT_PROFILE),
        mergeMap(action => {               
            return api.get(endPointKeys.api_contact, 'contact/profile/'+ action.payload ).pipe(
                map(result => result.data),
                mergeMap((appUser: IAppUser) => {
                    return of(fetchContactProfileSuccess(appUser), updateAppUserDetails(appUser));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed to load contact profile" };
                    sendToast(appError.message, ToastType.Error);
                    return of(fetchContactProfileError(appError));
                }))
        })
);
