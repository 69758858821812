import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError,   IADFAddress } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { contactStateActions, searchAddressSuccess,searchAddressError } from '../contact/contact-actions';

export const searchAddressEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(contactStateActions.SEARCH_ADDRESS),
        mergeMap(action => {        
            return api.get(endPointKeys.api_contact, 'ADF/SearchAddress/'+ action.payload ).pipe(
                map(result => result.data),
                mergeMap((addresses: Array<IADFAddress>) => {
                    if(addresses.length === 0){
                        const appError: IAppError = { message: "No address found for the searched postcode" };
                        sendToast(appError.message, ToastType.Error);
                    }
                    return of(searchAddressSuccess(addresses));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed to search address" };
                    sendToast(appError.message, ToastType.Error);
                    return of(searchAddressError(appError));
                }))
        })
);
