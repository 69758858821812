import React, { memo } from "react";
import styled from "styled-components";
import { IconAddNew } from "../icons/IconAddNew";
import Label from "../typography/label";

export interface ITileAddNew {    
    text: string;
    height: number;
    onClick?: () => void;
    className?: string;
}

export const TileAddNew = memo<ITileAddNew>(
    ({
        className,
        onClick,
        ...rest
    }): JSX.Element => {
        return (
            <Container onClick={onClick} {...rest} className={className}>
                <IconNew size={50} {...rest}/>
                <InfoText>{rest.text}</InfoText>
            </Container>
        );
    }
);

const Container = styled.div<ITileAddNew>`
position: relative;
display: flex;
width: 185px;
min-width: 185px;
height: 215px;
flex-direction: column;
overflow: hidden;
background-color:${({ theme }) => theme.colors.primaryDarkTransparent};
cursor: pointer;
`;

const IconNew = styled(IconAddNew)<ITileAddNew>`
margin: ${({ height }) => (height < 220 ? 50 : 130)}px auto 10px;
color: white;
border-radius: 50%;
border: 1px solid transparent;
background-color:${({ theme }) => theme.colors.primary};
`;

const InfoText = styled(Label)`
font-size: 12px;
font-weight: 500;
color:  white;
text-align: center;
text-transform: uppercase;
margin-top: 15px;
`;