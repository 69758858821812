import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { sessionStateActions, updatePasswordSuccess, updatePasswordError } from './session-actions';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';

export const updatePasswordEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(sessionStateActions.UPDATE_PASSWORD),
        mergeMap(action => {
            var data = JSON.stringify(action.payload);
            console.log('typeof id', typeof(action.payload.id));
            return api.post(endPointKeys.api_contact, 'user/ChangePassword', data).pipe(
                map(result => result.data),
                mergeMap(() => {
                    sendToast(`Password successfully changed`, ToastType.Success);
                    return of(updatePasswordSuccess());
                }),
                catchError(error => {
                    const appError : IAppError = { error, message: "Failed to change password"};
                    sendToast(appError.message, ToastType.Error);
                    return of(updatePasswordError());
                }))
        })
    )