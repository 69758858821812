import { combineEpics } from 'redux-observable';
import { loginEpic, 
        resetPasswordEpic, 
        savePasswordEpic,
        updatePasswordEpic,
        logoutEpic,
        loginSocialMediaEpic,
        signUpSocialMediaEpic,
        signUpEmailEpic,
        refreshTokenEpic } from './session';
import { fetchContactProfileEpic, 
        fetchContactFamilyEpic,
        deleteFamilyEpic,
        updateProfileEpic,
        searchAddressEpic,
        fetchAddressEpic,
        updateFamilyDetailsEpic} from "./contact";
import { fetchHolidaysEpic } from './holiday/holiday-fetch-holidays-epic';
import { fetchHolidayBookPartyEpic, 
        fetchHolidayEpic, 
        fetchItineraryEpic,
        retrieveHolidayEpic,
        deleteRetrieveHolidayEpic,
        retrieveUpdateHolidayEpic,
 } from './holiday'; 
import { submitGetInTouchRequestEpic, 
        submitEnquiryEpic } from './get-in-touch';
import { fetchCountriesEpic } from './reference-data';
import { updateHolidayBookPartyEpic } from './holiday/holiday-update-book-party-epic';

import { updateHolidayBookGDPREpic } from './holiday/holiday-update-book-gdpr-epic';
import { confirmPaymentEpic, getPaymentGatewaySessionEpic } from './payment';
import { acceptTermsEpic } from './payment/accept-terms-epic';
import { fetchApiKeyEpic } from './fetch-api-keys';


export const epics = [
    loginEpic,
    resetPasswordEpic,
    savePasswordEpic,
    fetchContactProfileEpic,
    fetchContactFamilyEpic,
    fetchHolidayEpic,
    deleteFamilyEpic,
    submitGetInTouchRequestEpic,
    fetchHolidaysEpic,
    deleteFamilyEpic,
    fetchHolidayBookPartyEpic,
    updateProfileEpic,
    fetchCountriesEpic,
    updateHolidayBookPartyEpic,
    fetchItineraryEpic,
    updatePasswordEpic,
    submitEnquiryEpic,
    searchAddressEpic,
    fetchAddressEpic,
    signUpEmailEpic,
    signUpSocialMediaEpic,
    loginSocialMediaEpic,
    updateFamilyDetailsEpic,
    logoutEpic,
    confirmPaymentEpic,
    refreshTokenEpic,
    acceptTermsEpic,
    updateHolidayBookGDPREpic,
    retrieveHolidayEpic,
    deleteRetrieveHolidayEpic,
    retrieveUpdateHolidayEpic,
    fetchApiKeyEpic,
    getPaymentGatewaySessionEpic
];

// Temporary hack to test epics (to avoid rxjs source code error cannot read apply of undefined by forcing all epics to return):
// export const rootEpic = () => combineEpics(...epics);
// Nb. this hack will prevent app from running so change back after testing
export const rootEpic = combineEpics(...epics);
