export  const singaporeBankDetails = [
	{
		title: "BANK:",
		body: "DBS Bank Singapore",
	},
	{
		title: "Bank address:",
		body:"12 Marina Boulevard. DBS Asia Central @ MBFC Tower 3, Singapore, 018982",
	},
	{
		title: "Account name:",
		body: "Scott Dunn Asia Pte",
	},
	{
		title: "Bank holder address:",
		body: "One George Street, Singapore, 049145",
	},
	{
		title: "Account number:",
		body: "0039417527",
	},
	{
		title: "Swift/bic:",
		body: "DBSSSGSG",
	},
];

export const usaBankDetails = [
	{
		title: "Name of bank:",
		body: "Bank of Southern California, N.A.",
	},
	{
		title: "Name on the bank account:",
		body: "Scott Dunn USA Inc",
	},
	{
		title: "Routing number of the bank:",
		body: "122243402",
	},
	{
		title: "Bank account number:",
		body: "502002455",
	},
];

export const ukBankDetails = [
	{
		title: "",
		body: "Scott Dunn Ltd",
	},
	{
		title: "",
		body: "Nat West Bank",
	},
	{
		title: "Account no:",
		body: "40517977",
	},
	{
		title: "Sort Code:",
		body: "52-41-07",
	},
	{
		title: "BIC:",
		body: "NWBKGB2L",
	},
	{
		title: "IBAN:",	
		body: "GB66 NWBK 5241 0740 5179 77",
	},
];