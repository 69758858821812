import { IAppAction } from '../app-action';
import { IAppUser, IAppError, IBookParty, IHolidaySummary, IBookGDPR, IRetrieveHoliday, IUpdateRetrieveHoliday } from '../../models';
import { history } from '../../app-history';
import { IHoliday, IItineraryGroup } from '../../models';

export const holidayStateActions = {
  ROUTE_CHANGE: 'ROUTE_CHANGE',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  SIGNUP: 'SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  APP_ERROR: 'APP_ERROR',
  FETCH_HOLIDAYS: 'FETCH_HOLIDAYS',
  FETCH_HOLIDAYS_SUCCESS: 'FETCH_HOLIDAYS_SUCCESS',
  FETCH_HOLIDAYS_ERROR: 'FETCH_HOLIDAYS_ERROR',
  FETCH_HOLIDAY: 'FETCH_HOLIDAY',
  FETCH_HOLIDAY_SUCCESS: 'FETCH_HOLIDAY_SUCCESS',
  FETCH_HOLIDAY_ERROR: 'FETCH_HOLIDAY_ERROR',
  FETCH_ITINERARY: 'FETCH_ITINERARY',
  FETCH_ITINERARY_SUCCESS: 'FETCH_ITINERARY_SUCCESS',
  FETCH_ITINERARY_ERROR: 'FETCH_ITINERARY_ERROR',
  TOGGLE_MOBILE_MENU: 'TOGGLE_MOBILE_MENU',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  SAVE_NEW_PASSWORD: 'SAVE_NEW_PASSWORD',
  SAVE_NEW_PASSWORD_SUCCESS: 'SAVE_NEW_PASSWORD_SUCCESS',
  SAVE_NEW_PASSWORD_ERROR: 'SAVE_NEW_PASSWORD_ERROR',
  FETCH_BOOK_PARTIES: 'FETCH_BOOK_PARTIES',
  FETCH_BOOK_PARTIES_SUCCESS: 'FETCH_BOOK_PARTIES_SUCCESS',
  FETCH_BOOK_PARTIES_ERROR: 'FETCH_BOOK_PARTIES_ERROR',
  UPDATE_BOOK_PARTY:'UPDATE_BOOK_PARTY',
  UPDATE_BOOK_PARTY_SUCCESS:'UPDATE_BOOK_PARTY_SUCCESS',
  UPDATE_BOOK_PARTY_ERROR:'UPDATE_BOOK_PARTY_ERROR',
  // MAKE_HOLIDAY_PAYMENT: 'MAKE_HOLIDAY_PAYMENT',
  // MAKE_HOLIDAY_PAYMENT_SUCCESS: 'MAKE_HOLIDAY_PAYMENT_SUCCESS',
  // MAKE_HOLIDAY_PAYMENT_ERROR: 'MAKE_HOLIDAY_PAYMENT_ERROR',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  PAYMENT_ERROR_CLEAR: 'PAYMENT_ERROR_CLEAR',
  UPDATE_BOOK_GDPR:'UPDATE_BOOK_GDPR',
  UPDATE_BOOK_GDPR_SUCCESS:'UPDATE_BOOK_GDPR_SUCCESS',
  UPDATE_BOOK_GDPR_ERROR:'UPDATE_BOOK_GDPR_ERROR',
  RETRIEVE_HOLIDAY:'RETRIEVE_HOLIDAY',
  RETRIEVE_HOLIDAY_SUCCESS:'RETRIEVE_HOLIDAY_SUCCESS',
  RETRIEVE_HOLIDAY_ERROR:'RETRIEVE_HOLIDAY_ERROR',
  UPDATE_RETRIEVE_HOLIDAY:'UPDATE_RETRIEVE_HOLIDAY',
  DELETE_RETRIEVE_HOLIDAY:'DELETE_RETRIEVE_HOLIDAY',
};

interface ISaveNewPasswordPayload {
  password: string;
  resetCode: string;
}

export const routeChange = (url: string) => {
  history.push(url);
   return {
     type: `${holidayStateActions.ROUTE_CHANGE}_${(url.toUpperCase())}`,
     payload: { url: `${url}`}
   }
 }

export const login = (email: string, password: string): IAppAction => ({
  type: holidayStateActions.LOGIN,
  payload: { email, password },
  meta: { appInsights: { trackPayload: true}}
});

export const loginSuccess = (appUser: IAppUser): IAppAction => ({
  type: holidayStateActions.LOGIN_SUCCESS,
  payload: appUser
});

export const loginError = (error: IAppError): IAppAction => ({
  type: holidayStateActions.LOGIN_ERROR,
  payload: error,
  meta: { appInsights: { trackPayload: true}}
});

export const logOut = (): IAppAction => ({
  type: holidayStateActions.LOGOUT
});

export const fetchHolidays = (contactId: string): IAppAction => ({
  type: holidayStateActions.FETCH_HOLIDAYS,
  payload: contactId
});

export const fetchHolidaysSuccess = (holidays: IHolidaySummary[]): IAppAction => ({
  type: holidayStateActions.FETCH_HOLIDAYS_SUCCESS,
  payload: holidays
});

export const fetchHolidaysError = (error: IAppError): IAppAction => ({
  type: holidayStateActions.FETCH_HOLIDAYS_ERROR,
  payload: error
});

export const fetchHoliday = (bookingId: number): IAppAction => ({
  type: holidayStateActions.FETCH_HOLIDAY,
  payload: bookingId
});

export const fetchHolidaySuccess = (holiday: IHoliday): IAppAction => ({
  type: holidayStateActions.FETCH_HOLIDAY_SUCCESS,
  payload: holiday
});

export const fetchHolidayError = (error: IAppError): IAppAction => ({
  type: holidayStateActions.FETCH_HOLIDAY_ERROR,
  payload: error
});

export const fetchItinerary = (bookingId: number): IAppAction => ({
  type: holidayStateActions.FETCH_ITINERARY,
  payload: bookingId
});

export const fetchItinerarySuccess = (itinerary: IItineraryGroup[]): IAppAction => ({
  type: holidayStateActions.FETCH_ITINERARY_SUCCESS,
  payload: itinerary
});

export const fetchItineraryError = (error: IAppError): IAppAction => ({
  type: holidayStateActions.FETCH_ITINERARY_ERROR,
  payload: error
});

export const updateBookParty = (bookParty:IBookParty): IAppAction =>({
  type: holidayStateActions.UPDATE_BOOK_PARTY,
  payload: bookParty,
  
});

export const updateBookPartySuccess = (status:boolean): IAppAction =>({
  type: holidayStateActions.UPDATE_BOOK_PARTY_SUCCESS,
  payload: status
});

export const updateBookPartyError = (error:IAppError): IAppAction =>({
  type: holidayStateActions.UPDATE_BOOK_PARTY_ERROR,
  payload: error
});

export const updateBookGDPR = (bookParty:IBookGDPR): IAppAction =>({
  type: holidayStateActions.UPDATE_BOOK_GDPR,
  payload: bookParty,
});

export const updateBookGDPRSuccess = (status:boolean): IAppAction =>({
  type: holidayStateActions.UPDATE_BOOK_GDPR_SUCCESS,
  payload: status
});

export const updateBookGDPRError = (error:IAppError): IAppAction =>({
  type: holidayStateActions.UPDATE_BOOK_GDPR_ERROR,
  payload: error
});

export const retriveHoliday = (holiday:IRetrieveHoliday, updateHolidaySharing: IUpdateRetrieveHoliday): IAppAction =>({
  type: holidayStateActions.RETRIEVE_HOLIDAY,
  payload: { holiday, updateHolidaySharing }
});

export const updateRetriveHoliday = (holiday:IUpdateRetrieveHoliday, isBooking: boolean): IAppAction =>({
  type: holidayStateActions.UPDATE_RETRIEVE_HOLIDAY,
  payload:{ holiday, isBooking },
});

export const retriveHolidaySuccess = (): IAppAction =>({
  type: holidayStateActions.RETRIEVE_HOLIDAY_SUCCESS,
  payload: {}
});

export const retriveHolidayError = (error: any): IAppAction =>({
  type: holidayStateActions.RETRIEVE_HOLIDAY_ERROR,
  payload: error
});

export const deleteRetrieveHoliday = (contactId: string, bookingId: number): IAppAction =>({
  type: holidayStateActions.DELETE_RETRIEVE_HOLIDAY,
  payload: {
    contactId,
    bookingId
  },
});



export const toggleMobileMenu = (isOpen: boolean): IAppAction => ({
  type: holidayStateActions.TOGGLE_MOBILE_MENU,
  payload: isOpen
});

export const resetPassword = (email: string): IAppAction => ({
  type: holidayStateActions.RESET_PASSWORD,
  payload: email
});

export const resetPasswordSuccess = (): IAppAction => ({
  type: holidayStateActions.RESET_PASSWORD_SUCCESS
});

export const resetPasswordError = (): IAppAction => ({
  type: holidayStateActions.RESET_PASSWORD_ERROR
});

export const saveNewPassword = (payload: ISaveNewPasswordPayload): IAppAction => ({
  type: holidayStateActions.SAVE_NEW_PASSWORD,
  payload: payload
});

export const saveNewPasswordSuccess = (): IAppAction => ({
  type: holidayStateActions.SAVE_NEW_PASSWORD_SUCCESS
});

export const saveNewPasswordError = (): IAppAction => ({
  type: holidayStateActions.SAVE_NEW_PASSWORD_ERROR
});
export const fetchBookParties = (bookId: number): IAppAction => ({
  type: holidayStateActions.FETCH_BOOK_PARTIES,
  payload: bookId
});

export const fetchBookPartiesSuccess = (bookParty: IBookParty[]): IAppAction => ({
  type: holidayStateActions.FETCH_BOOK_PARTIES_SUCCESS,
  payload: bookParty 
});

export const fetchBookPartiesError = (error: IAppError): IAppAction => ({
  type: holidayStateActions.FETCH_BOOK_PARTIES_ERROR,
  payload: error
});

// export const makePayment = (paymentDetails: IPaymentDetails): IAppAction => ({
//   type: holidayStateActions.MAKE_HOLIDAY_PAYMENT,
//   payload: paymentDetails
// });

// export const makePaymentSuccess = (): IAppAction => ({
//   type: holidayStateActions.MAKE_HOLIDAY_PAYMENT_SUCCESS,
//   payload: true 
// });

// export const makePaymentError = (error: IAppError): IAppAction => ({
//   type: holidayStateActions.MAKE_HOLIDAY_PAYMENT_ERROR,
//   payload: error
// });

export const paymentError = (error: any): IAppAction => ({
  type: holidayStateActions.PAYMENT_ERROR,
  payload: error 
});

export const paymentErrorClear = (): IAppAction => ({
  type: holidayStateActions.PAYMENT_ERROR_CLEAR
});