import React, { memo } from "react";
import styled from "styled-components";
import { Body2, Headline6, Headline4 } from "../typography";
import Label from "../typography/label";

export enum QuoteTileBadgeTypes {
  LIVE = "live",
  BOOK = "book",
  EXPIRED = "",
}

export interface QuoteTileProps {
  imageUrl: string;
  title: string;
  location: string;
  consultantName: string;
  consultantImageUrl: string;
  bookId: number;
  badgeType?: QuoteTileBadgeTypes;
  day?: string;
  monthAndYear?: string;
  isLive?: boolean;
  isRetrieved?: boolean;
  onClick?: () => void;
  onDeleteRetrieveBooking?: (bookId: number) => void;
  onDelete?: () => void;
}

export const QuoteTile = memo<QuoteTileProps>(
  ({
    imageUrl,
    title,
    location,
    consultantName,
    consultantImageUrl,
    bookId,
    badgeType,
    day,
    monthAndYear,
    isLive,
    isRetrieved,
    onClick,
    onDeleteRetrieveBooking,
    onDelete,
  }): JSX.Element => {
    const tileTitle =
      title.length > 50 ? title.substring(0, 50) + "..." : title;
    return (
      <Container>
        {isRetrieved && (
          <StyledImg
            onClick={() =>
              onDeleteRetrieveBooking && onDeleteRetrieveBooking(bookId)
            }
            // onClick={onDelete && onDelete}
            src="/images/delete.svg"
          />
        )}
        {/* <div style={{width: '100%' }} onClick={onClick}> */}
          {badgeType && badgeType === QuoteTileBadgeTypes.BOOK && (
            <DateHolder isLiveHoliday={isLive ? isLive : false}>
              <DayText>{day ? day : ""}</DayText>
              <MonthAndYear>{monthAndYear ? monthAndYear : ""}</MonthAndYear>
            </DateHolder>
          )}
          {badgeType && badgeType !== QuoteTileBadgeTypes.BOOK && (
            <StyledRibbon>
              <RibbonText>{badgeType}</RibbonText>
            </StyledRibbon>
          )}
          <ImageBackground src={imageUrl}  onClick={onClick}/>
          <Content onClick={onClick}>
            <StyledHeadline>{tileTitle}</StyledHeadline>
            <StyledLocation>{location}</StyledLocation>
            <ConsultantRow>
              <Avatar src={consultantImageUrl} />
              <ConsultantInfo>
                <InfoText>Created by</InfoText>
                <InfoText>{consultantName}</InfoText>
              </ConsultantInfo>
            </ConsultantRow>
          </Content>
        {/* </div> */}
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  // flex-direction: row;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    // flex-direction: column;
    flex-direction: row;
    margin-right: 10px;
  }
`;

const StyledRibbon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: -45px;
  width: 140px;
  height: 25px;
  background-color: ${({ theme: { colors } }) => colors.accentLight};
  transform: rotate(-45deg);
`;

const StyledImg = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: -45px;
  width: 140px;
  height: 25px;
  left: 65%;
    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    left: 90%;
    height: 20px;
    width: 20px;
  }
     @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    left: 90%;
    height: 15px;
    width: 15px;
  }
`;

interface IDateHolderProp {
  isLiveHoliday: boolean;
}

const DateHolder = styled.div<IDateHolderProp>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 80px;
  height: 75px;
  background-color: ${({ theme: { colors }, isLiveHoliday }) =>
    isLiveHoliday === true ? colors.accentLight : colors.labelDark};
  
  @media(max-width: ${({theme})=> theme.breakpoints.tablet}px){
      width: 80px;
      height: 50px;
    }
`;

const RibbonText = styled(Label)`
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.white};
`;

const DayText = styled(Headline4)`
  color: ${({ theme: { colors } }) => colors.white};
  margin: 0px;
`;

const MonthAndYear = styled(Label)`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.white};
`;

interface ImageBackgroundProps {
  src: string;
}
const ImageBackground = styled.img<ImageBackgroundProps>`
  width: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  margin-bottom: -4px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 50%;
    width: 100%;
    min-height: 210px;
  }

  @media(max-width: ${({theme})=> theme.breakpoints.tablet}px){
    width: 40%;
    height: 185px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  padding: 24px;
  background-color: ${({ theme: { colors } }) => colors.white};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 60%;
    width: 60%;
    width: inherit;
  }
`;

const StyledHeadline = styled(Headline6)`
  margin: 8px 0;
  min-height: 50px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-height: 75px;
  }
`;

const StyledLocation = styled(Body2)`
  color: ${({ theme: { colors } }) => colors.label};
`;

const ConsultantRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

interface AvatarProps {
  src: string;
}
const Avatar = styled.div<AvatarProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid ${({ theme: { colors } }) => colors.labelLight};
  background-image: url(${({ src }) => src});
  background-size: contain;
  margin-right: 8px;
`;

const ConsultantInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoText = styled(Label)`
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.labelDark};
`;
