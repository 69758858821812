export const GetCloudinaryImage = (id: string, fullSize: Boolean, width?: number, height?: number) => {
    const w = width ? width : 100;
    const h = height ? height : 100;
    if (fullSize) {
      if(id) { 
      return `https://images.scottdunn.com/c_fill,f_auto,q_auto/${id.toString()}`;
      }
      else { //handle no image
        return "";
      }
    }
    if(id) {
      return `https://images.scottdunn.com/c_fill,f_auto,q_auto,h_${h.toString()},w_${w.toString()}/${id.toString()}`;
    }
    else { //handle no image
      return "";
    }
  }