import { USDictionary } from "./dictionary";

  export const DictionaryReplace = (value:string,businessUnit:number):string  =>  {
    let content = value;
      if(businessUnit !== 2 || content === "" || content === null || content === undefined) return value;      
      USDictionary.forEach(word => {        
        content = content.replace(word.Key,word.Value);
        }
      );
    return content;
  }

  export default DictionaryReplace;