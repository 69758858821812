import { IAppAction } from '../app-action';
import { IPaymentState, getDefaultPaymentState } from './payment-state';
import { paymentStateActions } from './payment-actions';
import { IAppError, IPaymentResults } from '../../models';

const handlers = {
	[paymentStateActions.MAKE_PAYMENT]: (
		state: IPaymentState,
		payload: any
	): IPaymentState => {
		return {
			...state,
			isProcessing: true,
			paymentDetails: payload,
		};
	},
	[paymentStateActions.MAKE_PAYMENT_SUCCESS]: (
		state: IPaymentState,
		payload: IPaymentResults
	): IPaymentState => {
		return {
			...state,
			isProcessing: false,
			paymentResults: payload,
			paymentError: payload.error ? payload.error : null,
		};
	},
	[paymentStateActions.MAKE_PAYMENT_ERROR]: (
		state: IPaymentState,
		payload: IAppError
	): IPaymentState => {
		return {
			...state,
			isProcessing: false,
			paymentError: payload.message ? payload.message : null,
		};
	},
	[paymentStateActions.PAYMENT_RESULTS_CLEAR]: (
		state: IPaymentState,
		payload: IAppError
	): IPaymentState => {
		return {
			...state,
			isProcessing: false,
			paymentDetails: null,
			paymentResults: null,
			paymentError: null,
			termsSaved: false,
			paymentSessionResults: null
		};
	},
	[paymentStateActions.ACCEPT_TERMS]: (
		state: IPaymentState,
		payload: any
	): IPaymentState => {
		return {
			...state,
			isProcessing: true,
			termsSaved: false,
		};
	},
	[paymentStateActions.ACCEPT_TERMS_SUCCESS]: (
		state: IPaymentState
	): IPaymentState => {
		return {
			...state,
			isProcessing: false,
			termsSaved: true,
		};
	},
	[paymentStateActions.ACCEPT_TERMS_ERROR]: (
		state: IPaymentState,
		payload: IAppError
	): IPaymentState => {
		return {
			...state,
			isProcessing: false,
		};
	},
	[paymentStateActions.GET_PAYMENT_GATEWAY_SESSION_SUCCESS]: (
		state: IPaymentState,
		payload: any
	): IPaymentState => {
		return {
			...state,
			paymentSessionResults: payload,
		};
	},
};

const paymentReducer = (state: IPaymentState = getDefaultPaymentState(), action: IAppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default paymentReducer;
