const scottDunnTheme = {
  colors: {
    green: "#36866e",
    greenDark: "#00402e",
    greenExtraDark: "#003123",
    pinkLight: "#ff9c9c",
    pink: "#c46464",
    pinkDark: "#a74141",
    black: "#000000",
    greyDark: "#7d7d7d",
    grey: "#b3b3b3",
    greyLight: "#e5e5e5",
    greyWhite: "#f2f2f2",
    white: "#ffffff",
    transparent: "transparent",
    greenDarkTransparent : 'rgb(54 134 110 / 39%)',
    red: '#A84141'
  },
  fonts: {
    mallory: "Mallory",
    chap: "Chap",
  },
};

export default scottDunnTheme;
