import 'rxjs';
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { logger } from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import { api, endPointKeys } from '../services/service-api';
import { IEpicDependency } from '../models';
import { rootReducer } from './root-reducer';
import { rootEpic } from './root-epic';
import { history } from '../app-history';
import { IAppAction } from './app-action';
import { IAppState } from './app-state';
import { insightsMonitor } from 'redux-appinsights';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const dependencies: IEpicDependency = {
  api,
  endPointKeys
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['session']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware<IAppAction, IAppAction, IAppState, IEpicDependency>({dependencies:dependencies});
const middlewareList = [epicMiddleware, logger, routerMiddleware(history), insightsMonitor()];
const windowIfDefined = typeof window === 'undefined' ? null : window as any;
const composeEnhancers = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = composeEnhancers(applyMiddleware(...middlewareList));

let store = createStore(persistedReducer, middlewares)
epicMiddleware.run(rootEpic);
let persistor = persistStore(store)
export { store, persistor };