import React, { memo } from "react";
import styled from "styled-components";
import Body1 from "../typography/body";


export enum CheckboxType {
  MAIN,
  SECONDARY 
}

export interface CheckboxProps {
  label?: string;
  isChecked: boolean;
  className?: string;
  onClick: (checked: boolean) => void;
  type?: CheckboxType;
}

export const Checkbox = memo<CheckboxProps>(
  ({ label, isChecked,className, onClick,type }): JSX.Element => {
    return (
      <Container className={className}>
        <StyledCheckbox themeType={type === undefined || type === null ? CheckboxType.MAIN : type} type="checkbox" checked={isChecked} readOnly />
        <Checkmark themeType={type === undefined || type === null ? CheckboxType.MAIN : type} onClick={() => onClick(!isChecked)} />
        <LabelBox>
          <Body1 className={className}>{label}</Body1>
        </LabelBox>
      </Container>
    );
  }
);

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 25px;
  position: relative;
`;

export const LabelBox = styled.div`
  position: absolute;
  left: 32px;
`;

interface ICheckmark {
  themeType:CheckboxType
}

export const Checkmark = styled.label<ICheckmark>`
  position: absolute;
  top: 0;
  height: 25px;
  width: 25px;
  background-color:${({ theme })=> theme.colors.white}; 
  border-radius: 2px;
  border:2px solid ${({ themeType,theme })=> themeType === CheckboxType.MAIN ? theme.colors.white: theme.colors.primaryDark};
  &::after {
    content: "";
    position: absolute;
    display: none;
    left:7px;
    top: 2px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color:${({ themeType,theme })=> themeType === CheckboxType.MAIN ? theme.colors.white: theme.colors.primaryDark};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const StyledCheckbox = styled.input<ICheckmark>`
  display: none;

  &:checked + label {
    background-color: ${({ theme,themeType }) => themeType === CheckboxType.MAIN ? theme.colors.accentLight:theme.colors.white};

    &::after {
      display: block;
    }
  }
`;
