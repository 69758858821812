import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Body1 from "../typography/body";

export interface TabProps {
  initialIndex?: number;
  elements: string[];
  color?: string;
  size?: number;
  onChange: (idx: number) => void;
  className?: string;
}

export const Tab = memo<TabProps>(
  ({ initialIndex = 0, elements, color, size, onChange, className }): JSX.Element => {
    const [selectedItem, setSelectedItem] = useState(initialIndex);

    const selectItemCallback = useCallback(
      (idx: number): void => {
        setSelectedItem(idx);
        onChange(idx);
      },
      [onChange]
    );

   React.useEffect(() => {
        setSelectedItem(initialIndex);
    }, [initialIndex])

    const renderItemsCallback = useCallback(
      (item, idx) => {
        return (
          <StyledElement key={idx} color={color}>
            <StyledButton onClick={() => selectItemCallback(idx)}>
              <StyledText selected={selectedItem === idx} size={size}>
                {item}
              </StyledText>
            </StyledButton>
          </StyledElement>
        );
      },
      [selectedItem, color, size, selectItemCallback]
    );

    const underlineWidth = useMemo(() => {
      return 100 / elements.length;
    }, [elements]);

    const underlineOffset = useMemo(() => {
      return underlineWidth * selectedItem;
    }, [underlineWidth, selectedItem]);

    return (
      <StyledTab className={className}>
        <StyledNav>{elements.map(renderItemsCallback)}</StyledNav>
        <StyledLine offset={underlineOffset} width={underlineWidth} />
      </StyledTab>
    );
  }
);

export const StyledTab = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid;
  border-color: ${({ theme }) => theme.colors.labelLight};
`;

export const StyledNav = styled.ul`
  display: flex;
  flex: 1;
  list-style: none;
  padding: 0;
`;

export const StyledElement = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${({ theme, color }) => color || theme.colors.body};

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const StyledButton = styled.button`
  color: inherit;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

interface StyledTextProps {
  size?: number;
  selected?: boolean;
}

export const StyledText = styled(Body1)<StyledTextProps>`
  font-size: ${({ size }) => size}px;
  font-weight: 400;
  &:hover {
    color: inherit;
  }
`;

interface LineProps {
  width: number;
  offset: number;
}

export const StyledLine = styled.div<LineProps>`
  position: absolute;
  bottom: 0;
  left: ${({ offset }) => offset}%;
  transition: left 300ms;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.accentLight};
  width: ${({ width }) => width}%;
  pointer-events: none;
`;

export default Tab;
