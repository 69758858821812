import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconEdit = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
      <g >
        <rect fill="none" width="24" height="24" />
        <path
          fill={props.color}
          d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM21.41,6.34,17.66,2.59,15.13,5.13l3.75,3.75,2.53-2.54Z"          
        />
      </g>
    </Svg>
  );
});
