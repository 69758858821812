import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { sessionStateActions, resetPasswordSuccess, resetPasswordError } from './session-actions';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';

export const resetPasswordEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(sessionStateActions.RESET_PASSWORD),
        mergeMap(action => {
            var data = JSON.stringify(action.payload);
            return api.post(endPointKeys.api_contact, 'user/resetPassword', data).pipe(
                map(result => result.data),
                mergeMap(() => {
                    return of(resetPasswordSuccess());
                }),
                catchError(error => {
                    const appError : IAppError = { error, message: "Failed to send email"};
                    sendToast(appError.message, ToastType.Error);
                    return of(resetPasswordError());
                }))
        })
    )