import { IAppAction } from '../app-action';
import { ISessionState, getDefaultSessionState } from './session-state';
import { sessionStateActions } from './session-actions';
import { IAppUser, IAppError } from '../../models';

const validateEmail = (email: string) => { 
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(re.test(email)){
      //Email valid. Procees to test if it's from the right domain (Second argument is to check that the string ENDS with this domain, and that it doesn't just contain it)
      if(email.indexOf("@scottdunn.com", email.length - "@scottdunn.com".length) !== -1){
         return true;
      }
  }
  return false;
}

const handlers = {
  [sessionStateActions.LOGIN]: (state: ISessionState, payload: any): ISessionState => {
    return {
      ...state,
      isProcessing: true,
      processingLoginType: 'Email',
      resetLogin: false
    };
  },
  [sessionStateActions.LOGIN_SUCCESS]: (state: ISessionState, payload: IAppUser): ISessionState => {
    return {
      ...state,
      appUser: {
        ...payload,
        isScottDunnUser: validateEmail(payload.primaryEmail.title),
      },
      isProcessing: false,
      processingLoginType: '',
      resetLogin: false
    };
  },
  [sessionStateActions.LOGIN_ERROR]: (state: ISessionState, payload: IAppError): ISessionState => {
    return {
      ...state,
      appUser: null,
      isProcessing: false,
      processingLoginType: '',
      resetLogin: false
    };
  },
  [sessionStateActions.LOGIN_SOCIAL_MEDIA]: (state: ISessionState, payload: any): ISessionState => {
    return {
      ...state,
      isProcessing: true,
      processingLoginType: payload.type,
      resetLogin: false
    };
  },
  [sessionStateActions.LOGIN_SOCIAL_MEDIA_SUCCESS]: (state: ISessionState, payload: IAppUser): ISessionState => {
    return {
      ...state,
      appUser: {
        ...payload
      },
      isProcessing: false,
      processingLoginType: '',
      resetLogin: false
    };
  },
  [sessionStateActions.LOGIN_SOCIAL_MEDIA_ERROR]: (state: ISessionState, payload: IAppError): ISessionState => {
    return {
      ...state,
      appUser: null,
      isProcessing: false,
      processingLoginType: '',
      resetLogin: false
    };
  },
  [sessionStateActions.SIGNUP_EMAIL]: (state: ISessionState, payload: any): ISessionState => {
    return {
      ...state,
      isProcessing: true,
      processingLoginType: 'Email',
      resetLogin: false
    };
  },
  [sessionStateActions.SIGNUP_EMAIL_SUCCESS]: (state: ISessionState, payload: IAppUser): ISessionState => {
    return {
      ...state,
      appUser: {
        ...payload
      },
      isProcessing: false,
      processingLoginType: '',
      resetLogin: false
    };
  },
  [sessionStateActions.SIGNUP_EMAIL_ERROR]: (state: ISessionState, payload: IAppError): ISessionState => {
    return {
      ...state,
      appUser: null,
      isProcessing: false,
      processingLoginType: '',
      resetLogin: false
    };
  },
  [sessionStateActions.SIGNUP_SOCIAL]: (state: ISessionState, payload: any): ISessionState => {
    return {
      ...state,
      isProcessing: true,
      processingLoginType: payload.type,
      resetLogin: false
    };
  },
  [sessionStateActions.SIGNUP_SOCIAL_SUCCESS]: (state: ISessionState, payload: IAppUser): ISessionState => {
    return {
      ...state,
      appUser: {
        ...payload
      },
      isProcessing: false,
      processingLoginType: '',
      resetLogin: false
    };
  },
  [sessionStateActions.SIGNUP_SOCIAL_ERROR]: (state: ISessionState, payload: IAppError): ISessionState => {
    return {
      ...state,
      appUser: null,
      isProcessing: false,
      processingLoginType: '',
      resetLogin: false
    };
  },
  [sessionStateActions.SAVE_NEW_PASSWORD_SUCCESS]: (state: ISessionState, payload: IAppUser): ISessionState => {
    return {
      ...state,
      resetLogin: true
    };
  },
  [sessionStateActions.LOGOUT_SUCCESS]: (state: ISessionState): ISessionState => {
    return {
      ...state,
      appUser: null,
    };
  },
  [sessionStateActions.LOGOUT_ERROR]: (state: ISessionState): ISessionState => {
    return {
      ...state,
      appUser: null,
    };
  },
  [sessionStateActions.TOGGLE_MOBILE_MENU]: (state: ISessionState, payload: boolean): ISessionState => ({
    ...state,
    isMobileMenuOpen: payload
  }),
  [sessionStateActions.UPDATE_PASSWORD]: (state: ISessionState): ISessionState => ({
    ...state,
    isProcessing: true
  }),
  [sessionStateActions.UPDATE_PASSWORD_SUCCESS]: (state: ISessionState): ISessionState => ({
    ...state,
    isProcessing: false,
    updatePasswordSuccess: true
  }),
  [sessionStateActions.UPDATE_PASSWORD_ERROR]: (state: ISessionState, payload: IAppError): ISessionState => ({
    ...state,
    isProcessing: false
  }),
  [sessionStateActions.RESET_UPDATE_PASSWORD_SUCCESS]: (state: ISessionState, payload: IAppError): ISessionState => ({
    ...state,
    updatePasswordSuccess: false
  }),
  [sessionStateActions.UPDATE_APP_USER_DETAILS]: (state: ISessionState, payload: IAppUser): ISessionState => {
    const user = state.appUser;
    if (user && user !== null) {
      return {
        ...state,
        appUser: {
          ...user,
          firstName: payload.firstName,
          lastName: payload.lastName
        },
      };
    }
    else {
      return { ...state };
    }
  },
  [sessionStateActions.REFRESH_TOKEN_SUCCESS]: (state: ISessionState, payload: any): ISessionState => {
    const user = state.appUser;
    if (user && user !== null) {
      return {
        ...state,
        appUser: {
          ...user,
          token: payload
        },
      };
    }
    else {
      return { ...state };
    }
  },

  [sessionStateActions.SELECT_TAB]: (state: ISessionState, payload: any): ISessionState => {
    return {
      ...state,
      tabs: {
        ...state.tabs,
        [payload.pageName]: payload.tab
      }
    };
  },

  [sessionStateActions.USER_ALREADY_EXISTS]: (state: ISessionState, payload: boolean ): ISessionState => ({
    ...state,
    isUserAlreadyExist: payload,
  }),

};

const sessionReducer = (state: ISessionState = getDefaultSessionState(), action: IAppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default sessionReducer;
