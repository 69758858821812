import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { Input, InputTypes } from "../..";
import { getCurrencySymbol, getCurrencyCode } from "../../../helpers/getCurrencySymbol";
import { IHoliday } from "../../../models";
import { IconTypes } from "../../icons/icon-component";
import { InfoLockup } from "../../info-lockup";
import { Body1, Body2 } from "../../typography";
import { BookedBookingSubStatus } from "../../../helpers/datasource-helper";

export interface IBookingOverview {
    booking: IHoliday;
    paymentAmount(amount: number): void;
}

export const BookingOverview = (props: IBookingOverview) => {
    const { booking } = props;
    const holidayBooked = booking && BookedBookingSubStatus().includes(booking.bookSubStatusId);
    const pax = booking && booking.bookParty && booking.bookParty.length;
    const invoiceCurrency = booking && booking.invoice && booking.invoice.currency ? booking.invoice.currency.currency : '';
    const currencySymbol = getCurrencySymbol(invoiceCurrency);
    const currencyCode = getCurrencyCode(invoiceCurrency);
    const currency = currencyCode && currencySymbol && currencyCode?.toString() + currencySymbol?.toString();
    const remainingBalance = booking && booking.invoice ? booking.invoice.balance : 0;
    const deposit = booking && booking.invoice && booking.invoice.deposit;
    const [amount, setAmount] = React.useState<string>(holidayBooked ? remainingBalance.toFixed(2).toString() : deposit ? deposit.toFixed(2).toString() : '');

    const setPaymentAmount = (value: any) => {
        const editedAmount = value.replace(/[^\d|.-]/g, '') //removes all character except of digits, dot and hypen
            .replace(/-/g, '') //removes every hyphen
            .replace(/(\.){2,}/g, '$1') //removes every multiplied dot
        setAmount(editedAmount);
        props.paymentAmount(editedAmount);
    }
    return (booking &&
        <Container>
            <TextMessage>{!holidayBooked ? "You are about to book the following holiday" :
                "You are about to make a payment on the following holiday"}</TextMessage>
            <InfoContainer>
                <StyledInfoLockup icon={IconTypes.IconMap} label="Destination" text={booking.bookName} />
                <DepatureInfo>
                    <StyledInfoLockup icon={IconTypes.IconCalendar} label="Departing" text={moment(booking.startDate, "DD/MM/YYYY").format("ddd, DD MMM YYYY")} />
                    <StyledInfoLockup icon={IconTypes.IconCalendar} label="Leaving" text={moment(booking.endDate, "DD/MM/YYYY").format("ddd, DD MMM YYYY")
                        + `${'\u00A0\u00A0'}(${booking.nights} night${Number(booking.nights) > 1 ? 's' : ''})`} />
                </DepatureInfo>
                <StyledInfoLockup icon={IconTypes.IconMap} label="Reference" text={booking.bookId.toString()} />
                <StyledInfoLockup icon={IconTypes.IconMap} label="Guests" text={pax ? pax.toString() : ''} />
                <SummaryAmountWrapper>
                    <StyledTotal>
                        Total : <NumberFormat value={booking.invoice.invoiceTotal} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={booking.invoice.currency.displaySymbol} />
                    </StyledTotal>
                    <DespoitWrapper>
                        {!holidayBooked && <>
                            <StyledDeposit>
                                Deposit required : <NumberFormat value={booking.invoice.deposit} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={booking.invoice.currency.displaySymbol} />
                            </StyledDeposit>
                            <StyledDeposit> Due date: {moment(booking.quoteExpiryDate, "YYYY-MM-DD").format('Do MMMM YYYY')}</StyledDeposit>
                        </>}
                        {holidayBooked && <>
                            <StyledDeposit>
                                Payment required : <NumberFormat value={booking.invoice.balance} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={booking.invoice.currency.displaySymbol} />
                            </StyledDeposit>
                            <StyledDeposit> Due date: {moment(booking.invoice.balanceDueDate, "YYYY-MM-DD").format('Do MMMM YYYY')}</StyledDeposit>
                        </>}
                    </DespoitWrapper>
                </SummaryAmountWrapper>
                <StyledDeposit>
                        How much would you like to pay today?
                    </StyledDeposit>
                        <StyledWidthInput
                            placeholder={currency}
                            type={InputTypes.TEXT}
                            isValid={amount !== '0'}
                            errorMessage={"Please enter an amount"}
                            value={amount.toString()}
                            onChange={(e: any) => setPaymentAmount(e.target.value)}
                            autoFocus
                            onFocus={(e: any) => setPaymentAmount(e.target.value)}
                        />
                
            </InfoContainer>
        </Container>

    );
}


const Container = styled.div`
padding:10px 0;
overflow-y:auto;
`;

const InfoContainer = styled.div`
margin-top:10px;
`;

const TextMessage = styled.div`
font-size: 16px;
`;

const DepatureInfo = styled.div`

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display:flex;
    flex-direction:column nowrap;
    justify-content:space-between;
} 
`;

const StyledInfoLockup = styled(InfoLockup)`
    margin-bottom: 15px;
    width: 100%;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.labelLight};   
`;

const SummaryAmountWrapper = styled.div`
flex-flow: column nowrap;
display: flex;
justify-content: flex-start;
width: 100%;
margin-bottom: 10px;
`;
const StyledTotal = styled(Body1)`
font-weight: 500;
font-size: 15px;
letter-spacing: 0.7px;
`;

const StyledDeposit = styled(Body2)`
font-size: 15px;
color:${({ theme }) => theme.colors.labelDark};
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {  
    padding-right: 35px;
}
`;

const DespoitWrapper = styled.div`
flex-flow: column nowrap;
display:flex;
&:last-child{
        margin-right:0px;   
    }
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row nowrap;
    padding-right: 35px;
};
border-bottom: 2px solid ${({ theme: { colors } }) => colors.labelLight};   
margin-bottom: 5px;
padding-bottom: 15px;
`;


const StyledWidthInput = styled(Input)`
width: 50%;
margin-top: 5px;
`;