import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonType, Input, InputTypes } from "..";
import Button from "../button";
import { Body2 } from "../typography";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddress, searchAddress } from "../../store/contact/contact-actions";
import { IAppState } from "../../store/app-state";

export const PostCodeDropdown = () => {

    const dispatch = useDispatch();
    const addressList = useSelector((state: IAppState) => state.contact.addressResult);
    const isProcessing = useSelector((state: IAppState) => state.contact.isProcessing);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [postcode, setPostCode] = useState<string>('');

    useEffect(() => {
        if (addressList && Array.isArray(addressList) && addressList.length > 0) {
            setIsOpen(true);
        }
    }, [addressList])

    const onFindClick = () => {
        if (postcode.length !== 0) {
            dispatch(searchAddress(postcode));
        }
    }

    const getAddress = (addressKey: string) => {
        setIsOpen(false);
        dispatch(fetchAddress(addressKey));
    }

    let addressResult = addressList;
    if (addressResult && !Array.isArray(addressResult))
        addressResult = [addressResult];
    
    return (
        <Container>
            <DropDownContainer>
                <Input
                    type={InputTypes.TEXT}
                    value={postcode}
                    label='Enter your postcode'
                    onChange={(e: any) => setPostCode(e.target.value)}
                />
                <StyledButton
                    type={ButtonType.MAIN}
                    text="Find Address"
                    onClick={onFindClick}
                    processing={isProcessing} />

                {isOpen && (
                    <ListContainer>
                        {addressResult && addressResult.map(function (a, index) {
                            return (
                                <ListItem key={index} onClick={(e) => getAddress(a.key)}>
                                    <Body2>{a.address}</Body2>
                                </ListItem>
                            );
                        })}
                    </ListContainer>
                )}
            </DropDownContainer>
        </Container>
    );
};


const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const DropDownContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 75px;
`;

const ListContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 160px;
  top: 75px;
  left: 0;
  z-index: 2;
  overflow-y: scroll;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 2px solid ${({ theme: { colors } }) => colors.labelLight};
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.labelWhite};
  }
`;

const StyledButton = styled(Button)`
height: 45px;
margin-left:10px;
width: 70%;
margin-top: 28px;
`;