import React, { memo, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { NavLink, NavLinkProps } from "react-router-dom";
import { Icon, IconTypes } from "../icons/icon-component";
import { Headline6 } from "../typography/headline";

export interface DashboardButtonProps {
  label: string;
  backgroundImage: string;
  icon: IconTypes;
  url: string;
  target?: string;
}

const DashboardButton = memo<DashboardButtonProps>(
  ({ label, backgroundImage, icon, url, ...rest }): JSX.Element => {
    const { colors } = useContext(ThemeContext);

    return (
      <StyledNavLink backgroundImage={backgroundImage} to={url} {...rest}>
        {icon && IconTypes[icon] && (
          <IconContainer>
            <Icon name={icon} size={40} color={colors.primary as string} />
          </IconContainer>
        )}
        <StyledHeadline6>{label}</StyledHeadline6>
      </StyledNavLink>
    );
  }
);

export default DashboardButton;

const StyledHeadline6 = styled(Headline6)`
  position: absolute;
  left: 80px;
  font-weight: bold;
  top: 10px;  
  
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    left: 20px;
    width: 70px;
    top: 60px;  
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    left: 180px;
    width: 160px;
    top: 80px;
  }
`;

interface StyledDashboardButtonProps {
  backgroundImage: string;
}

const StyledNavLink = styled(NavLink)<
  StyledDashboardButtonProps & NavLinkProps
>`
  display: block;
  position: relative;
  width: 100%;
  height: 110px;
  margin: 5px 0px 0px 0px;
  background-size: 150px 175px;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: none;
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.5);
  color: ${({ theme }) => theme.colors.primaryDark};

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 150px;
    height: 150px;
    margin: 5px 10px 10px 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 330px;
    height: 175px;
    background-image: url(${({ backgroundImage }) => backgroundImage});
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 385px;
    background-image: url(${({ backgroundImage }) => backgroundImage});
    &:nth-child(1){
      margin: 10px 10px 10px 0px;
    }
    &:nth-child(2){
      margin: 10px 0px 10px 10px;
    }
    &:nth-child(3){
      margin: 10px 10px 10px 0px;
    }
    &:nth-child(4){
      margin: 10px 0px 10px 10px;
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  color: inherit;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    left: 175px;
  }
`;
