 const routes = {
    home: () => '/',
    bookings: () => '/Bookings',
    quotes: () => '/Quotes',
    guests: () => '/Guests',
    getInTouch: () => '/GetInTouch',
    about: () => '/About',
    termsAndConditions: () => '/TermsAndConditions',
    profile: () => '/Profile',
    quote: (quoteId: string) => `/Quotes/${quoteId}`,
    booking: (bookingId: string) => `/Bookings/${bookingId}`,
    getInTouchHolidayRequest: (type: string, bookingId?: string) => `/GetInTouch/${type}/${bookingId}`,
    getInTouchRequest: (type: string) => `/GetInTouch/${type}`,
    changePassword : () => '/ChangePassword',
    dataDeletionRequests: () => '/DataDeletionRequests',
    privacyStatement: () => '/PrivacyStatement'
  };

  export default routes;
  