import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError, IBookGDPR } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import {  holidayStateActions, updateBookGDPRError, updateBookGDPRSuccess } from './holiday-actions';

export const updateHolidayBookGDPREpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.UPDATE_BOOK_GDPR),
        mergeMap((action) => {
            let book : IBookGDPR  = action.payload;
            var data = JSON.stringify(book);
            return api.put(endPointKeys.api_travel, `Booking/BookingGDPR`, data).pipe(
                map(res => res.data),
                mergeMap((m : any) => { 
                    return of(updateBookGDPRSuccess(m))
                }),
                catchError((error) => {
                    const appError : IAppError = { error: error, message: 'Error while updating booking gdpr.'};
                    return of(updateBookGDPRError(appError))
                }))
        })
    )