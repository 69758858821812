import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError,  IFamily } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
// import { sendToast, ToastType } from '../../helpers/sendToast';
import { contactStateActions, fetchContactFamilyDetailsSuccess, fetchContactFamilyDetailsError } from '../contact/contact-actions';

export const fetchContactFamilyEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(contactStateActions.FETCH_FAMILY_DETAILS),
        mergeMap(action => {        
            return api.get(endPointKeys.api_contact, 'family/'+ action.payload ).pipe(
                map(result => result.data),
                mergeMap((family: Array<IFamily>) => {
                    return of(fetchContactFamilyDetailsSuccess(family));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed to load contact family details" };
                    // sendToast(appError.message, ToastType.Error);
                    return of(fetchContactFamilyDetailsError(appError));
                }))
        })
);
