import * as React from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { QuoteTileBadgeTypes, Tab } from "../components";
import { ImageBackground } from "../components/image-background/image-background";
import { Body2, Headline3 } from "../components/typography";
import TextComponent from "../components/typography/text/text";
import { IAppUser, IHolidaySummary } from "../models";
import { IAppState } from "../store/app-state";
import { fetchHolidays } from "../store/holiday";
import { Loader } from "../components/loader";
import { selectTab } from "../store/session";
import { HolidayTiles } from "../components/holidays";
import { useHistory } from "react-router-dom";
import { RetrieveHolidayPage } from "../components/retrieve-holiday/retrive-holiday-page";
import { isFutureDate } from "../helpers/date-helper";
import { SDPImageBackground } from "../components/image-background/image-sdp-background";

export const MyQuotesPage = () => {
  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );
  const holidays: IHolidaySummary[] = useSelector(
    (state: IAppState) => state.holidays.holidays
  );
  const isProcessing: boolean = useSelector(
    (state: IAppState) => state.holidays.isProcessing
  );
  const tabsSelected: any = useSelector(
    (state: IAppState) => state.session.tabs
  );

  const getTab = () => {
    const tabSelected = tabsSelected.quotes;
    return tabSelected ? tabSelected : 0;
  };
  const { colors } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const quotes: IHolidaySummary[] = holidays.filter(
    (x) => x.bookSubStatusId === 25
  );
  const activeQuotes: IHolidaySummary[] = quotes.filter((x) =>
    isFutureDate(x.quoteExpiryDate, "DD/MM/YYYY")
  );
  const expiredQuotes: IHolidaySummary[] = quotes.filter(
    (x) => !isFutureDate(x.quoteExpiryDate, "DD/MM/YYYY")
  );

  React.useEffect(() => {
    if (appUser) {
      dispatch(fetchHolidays(appUser.id));
    }
  }, [appUser, dispatch]);

  const setTab = React.useCallback(
    (tab: number) => {
      dispatch(selectTab("quotes", tab));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (history.action !== "POP") {
      setTab(0);
    }
  }, [setTab, history]);

  return (
    <PageWrapper>
      {appUser?.isSDP ? (
        <SDPImageBackground url="/images/SDPBackgroundImagePattern.png" />
      ) : (
        <ImageBackground url="/images/backgroundImagePattern.gif" />
      )}
      <ContentLayout>
        <StyledHeadline3 data-testid="my-quotes-page-header">
          My Quotes
        </StyledHeadline3>
        <StyledTextComponent data-testid="my-quotes-page-view-text">
          Here you can view all your quotes.
        </StyledTextComponent>
        <TabContainer data-testid="my-quotes-page-tab-container">
          <Tab
            initialIndex={getTab()}
            elements={["Live Quotes", "Expired Quotes", "Retrieve Quote"]}
            color={colors.white}
            size={14}
            onChange={(idx: number) => setTab(idx)}
          />
        </TabContainer>
        {!isProcessing ? (
          <>
            {getTab() === 0 && (
              <QuoteTileContainer>
                <HolidayTiles
                  data-testid="my-quotes-page-active-tab"
                  holidays={activeQuotes}
                  badgeType={QuoteTileBadgeTypes.LIVE}
                  isQuote={true}
                  showRequestNewQuote={true}
                />
              </QuoteTileContainer>
            )}
            {getTab() === 1 && (
              <>
                {expiredQuotes && expiredQuotes.length > 0 ? (
                  <HolidayTiles
                    data-testid="my-quotes-page-expired-tab"
                    holidays={expiredQuotes}
                    badgeType={QuoteTileBadgeTypes.EXPIRED}
                    isQuote={true}
                  />
                ) : (
                  <StyledBody2>You have no expired quotes</StyledBody2>
                )}
              </>
            )}
            {getTab() === 2 && (
              <>
                {" "}
                <RetrieveHolidayPage isBooking={false} />
              </>
            )}
            {getTab() === 0 && activeQuotes.length > 0 && (
              <StyledBody2 data-testid="my-quotes-page-total-active">
                Total live quotes ({activeQuotes.length})
              </StyledBody2>
            )}
            {getTab() === 1 && expiredQuotes.length > 0 && (
              <StyledBody2 data-testid="my-quotes-page-total-expired">
                Total expired quotes ({expiredQuotes.length})
              </StyledBody2>
            )}
          </>
        ) : (
          getTab() !== 2 && <Loader />
        )}
      </ContentLayout>
    </PageWrapper>
  );
};

const ContentLayout = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: calc(100% - 10px);
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 800px;
    max-width: inherit;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
  }
`;

const TabContainer = styled.div`
  top: 20px;
  bottom: 50px;
  max-width: 400px;
`;

const QuoteTileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  /* margin:0 -10px; */
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row wrap;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-flow: row wrap;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
`;

const StyledTextComponent = styled(TextComponent)`
  color: ${({ theme }) => theme.colors.white};
`;

const StyledBody2 = styled(Body2)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 10px;
  margin-left: 10px;
`;
