import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconBus = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(3.125 4.688)">
        <path 
        fill={props.color} 
        d="M15.125,48.25A3.125,3.125,0,1,0,12,45.125,3.129,3.129,0,0,0,15.125,48.25Zm0-4.687a1.563,1.563,0,1,1-1.562,1.563A1.564,1.564,0,0,1,15.125,43.563Z" 
        transform="translate(-5.75 -13.875)"/>
        <path
        fill={props.color}  
        d="M47.125,48.25A3.125,3.125,0,1,0,44,45.125,3.129,3.129,0,0,0,47.125,48.25Zm0-4.687a1.563,1.563,0,1,1-1.562,1.563A1.564,1.564,0,0,1,47.125,43.563Z" 
        transform="translate(-12.75 -13.875)"/>
        <rect 
        fill={props.color} width="10.938" height="1.563" transform="translate(16.406 30.469)"/>
        <path 
        fill={props.color}  
        d="M45.406,20.063h-.781V9.125A3.129,3.129,0,0,0,41.5,6H10.25A3.129,3.129,0,0,0,7.125,9.125V20.063H6.344A2.346,2.346,0,0,0,4,22.406v4.688a2.346,2.346,0,0,0,2.344,2.344h.781V41.156a2.34,2.34,0,0,0,1.563,2.2v3.269a1.564,1.564,0,0,0,1.563,1.563h3.125a1.564,1.564,0,0,0,1.563-1.562V43.5H36.813v3.125a1.564,1.564,0,0,0,1.563,1.563H41.5a1.564,1.564,0,0,0,1.563-1.562V43.356a2.34,2.34,0,0,0,1.563-2.2V29.438h.781a2.346,2.346,0,0,0,2.344-2.344V22.406A2.346,2.346,0,0,0,45.406,20.063ZM8.688,9.125A1.564,1.564,0,0,1,10.25,7.563H41.5a1.564,1.564,0,0,1,1.563,1.563V12.25H8.688ZM6.344,27.875a.782.782,0,0,1-.781-.781V22.406a.782.782,0,0,1,.781-.781h.781v6.25Zm7.032,18.75H10.25V43.5h3.125Zm25,0V43.5H41.5v3.125Zm4.688-5.469a.782.782,0,0,1-.781.781H9.469a.782.782,0,0,1-.781-.781V32.563H43.063Zm0-10.156H8.688V13.813H43.063V31Zm3.125-3.906a.782.782,0,0,1-.781.781h-.781v-6.25h.781a.782.782,0,0,1,.781.781Z" 
        transform="translate(-4 -6)"/>
      </g>
    </Svg>
  );
});