import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { sessionStateActions, saveNewPasswordSuccess, saveNewPasswordError, routeChange } from './session-actions';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import routes from '../../routing/routes';

export const savePasswordEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(sessionStateActions.SAVE_NEW_PASSWORD),
        mergeMap(action => {
            var data = JSON.stringify(action.payload);
            return api.post(endPointKeys.api_contact, 'user/savePassword', data).pipe(
                map(result => result.data),
                mergeMap(() => {
                    sendToast(`Password successfully changed`, ToastType.Success);
                    return of(saveNewPasswordSuccess(), routeChange(routes.home()));
                }),
                catchError(error => {
                    const appError : IAppError = { error, message: "Failed to save password"};
                    sendToast(appError.message, ToastType.Error);
                    return of(saveNewPasswordError());
                }))
        })
    )