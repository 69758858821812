import React, { useState } from "react";
import styled from "styled-components";
import { IconArrowDown } from "../icons";
import { Headline6 } from "../typography";


export interface IAccordion{
    children:JSX.Element;
    title : string;
    expanded?: boolean;
    collapseStyle?:any;
}

const ToggleAccordion = (initialState:boolean) => {
    const [isOpen, setIsOpen] = useState(initialState);
  
    function toggle() {
        setIsOpen(!isOpen);
    }
  
    return {
        isOpen,
      toggle,setIsOpen
    }
  };
  

export const Accordion = (props:IAccordion) => {
    const {isOpen, toggle} = ToggleAccordion((props.expanded === undefined || props.expanded === null) ? false : props?.expanded);    
    return(
        <React.Fragment>
            <Collapse collapseStyle={props.collapseStyle}>       
                <CollapseTitle onClick={toggle}><StyledHeadline6>{props.title}</StyledHeadline6>  </CollapseTitle>
                <IconWrapper onClick={toggle}> 
                    {
                     isOpen === true ?
                     <CollapseIcon size={25} /> : <ExpandIcon size={25} /> 
                    }
                </IconWrapper>
            </Collapse>
                {
                    isOpen === true ? <CollapseContent>{props.children}</CollapseContent> : null
                }  
        </React.Fragment>
    );
}

const IconWrapper = styled.div`
cursor: pointer;
`;

const CollapseIcon  = styled(IconArrowDown)`
transform: rotate(180deg);
color: ${({ theme: { colors } }) => colors.labelDark};
`;

const ExpandIcon  = styled(IconArrowDown)`
transform: rotate(0deg);
color: ${({ theme: { colors } }) => colors.labelDark};
`;

const Collapse = styled.div<any>`
border-bottom:2px solid ${({ theme: { colors } }) => colors.primaryDark};
display:flex;
justify-content: space-between;
flex-flow: row;
//margin-top:34px;
margin-top: ${props => props.collapseStyle && props.collapseStyle.marginTop ? props.collapseStyle.marginTop : '34px'};
`;
const CollapseTitle = styled.span`
color:${({ theme: { colors } }) => colors.primaryDark};
font-size:16px;
`;
const CollapseContent = styled.div`
`;

const StyledHeadline6 = styled(Headline6)`
color: ${({ theme }) => theme.colors.primaryDark};

`;