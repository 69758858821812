import { IAddress, IADFAddress, IApiError } from "../../models";
import { IContact } from "../../models/contact";
import { IBaseState } from "../base-state";

export interface IContactState extends IBaseState {
    contact: IContact;
    addressResult: Array<IADFAddress>;
    selectedAddress: IAddress | null;
    partyDetailErrors: Array<IApiError>;
    showDialogBox: boolean;
    assignNewFamilyToBookParty: boolean;
    lastUpdatedFamilyId: number;
}
const defaultState = {
    contact: null,
    isProcessing: false,
    addressResult: [],
    selectedAddress: null,
    partyDetailErrors: [],
    showDialogBox: false,
    assignNewFamilyToBookParty: false,
    lastUpdatedFamilyId : 0
};

export const getDefaultContactState = (options?: any) => {
    return {
        ...defaultState,
        ...options
    };
};
