import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconCheck = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
      <g transform="translate(8.492 10.238)">
        <path
          fill={props.color}
          d="M42.844,13.1,23.962,38.5,12.636,25.533,10.87,27.075,24.109,42.232,44.724,14.5Z"
          transform="translate(-10.87 -13.105)"
        />
      </g>
    </Svg>
  );
});
