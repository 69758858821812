import React, { memo } from "react";
import styled from "styled-components";
import { Body2, Headline5 } from "../typography";

export interface MiniConsultantBoxProps {
  profileImage: string;
  name: string;
}
export const MiniConsultantBox = memo<MiniConsultantBoxProps>(
  ({
    name,
    profileImage,
  }) => {
    return (
      <Container>
        <AvatarContainer>
          <Avatar src={profileImage} />
        </AvatarContainer>
        <TextWrapper>
            <StyledBody2>Your Travel Consultant</StyledBody2>
            <StyledHeadline>{name}</StyledHeadline>
        </TextWrapper>
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px 5px 20px;
  justify-content: flex-start;
  width: 330px;
  height: 100px;
  background-color: ${({ theme: { colors } }) => colors.labelWhite};
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80px;
  height: 80px;
  margin: 10px 15px 10px 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 200px;
  padding: 6px;
  background-color: ${({ theme: { colors } }) => colors.labelWhite};
`;

interface AvatarProps {
  src: string;
}
const Avatar = styled.div<AvatarProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid ${({ theme: { colors } }) => colors.labelLight};
  background-image: url(${({ src }) => src});
  background-size: contain;
`;

const StyledBody2= styled(Body2)`
  color: ${({ theme: { colors } }) => colors.labelDark};
  margin-top: 22px;
  font-size: 11px;
`;

const StyledHeadline = styled(Headline5)`
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  margin-top: -24px;
`;
