import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconSki = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(3.125 5.469)">
        <path 
        fill={props.color} 
        d="M44.631,38.073a.78.78,0,0,0-1.079.237c-.976,1.526-2.053,1.916-4.267,1.547l-6.579-1.1,3.336-6.2a2.909,2.909,0,0,0-2.562-4.288H28.494l1.314-5.113c-.265-.1-.516-.2-.732-.272-.249-.083-.5-.155-.753-.226l-1.843,7.172h7a1.347,1.347,0,0,1,1.186,1.985L31.079,38.48l-4.259-.715,4.541-5.585h-7.48a1.555,1.555,0,0,0-1.072.426l-8.952,8.44L11.3,39.429l9.827-9.384,1.586-7.954a8.355,8.355,0,0,0-1.01.165c-.217.052-.435.116-.652.182l-1.36,6.817L9.936,38.569,5.2,35.576A.781.781,0,0,0,4.364,36.9L20.972,47.388a6.381,6.381,0,0,0,3.366,1.19,5.017,5.017,0,0,0,2.889-1,.781.781,0,0,0-.888-1.286c-1.491,1.03-2.634.974-4.533-.223l-6.585-4.16,8.661-8.169h4.2l-3.029,3.728-2.413-.405-1.426,1.345L39.028,41.4a9.914,9.914,0,0,0,1.627.155,4.659,4.659,0,0,0,4.214-2.4A.781.781,0,0,0,44.631,38.073Z" 
        transform="translate(-4 -10.301)"/>
        <path 
        fill={props.color} 
        d="M12.734,26.019c1.246-1.173,5.666-5.087,10.225-6.177a16.116,16.116,0,0,1,8.233.667,104.684,104.684,0,0,1,10.795,5.183.781.781,0,0,0,.738-1.377,105.683,105.683,0,0,0-11.038-5.288,17.5,17.5,0,0,0-9.09-.7c-4.8,1.148-9.245,4.97-10.933,6.56a.781.781,0,0,0,1.071,1.138Z" 
        transform="translate(-5.622 -9.406)"/>
        <path 
        fill={props.color} 
        d="M32.906,14.813A3.906,3.906,0,1,0,29,10.906,3.911,3.911,0,0,0,32.906,14.813Zm0-6.25a2.344,2.344,0,1,1-2.344,2.344A2.346,2.346,0,0,1,32.906,8.563Z" 
        transform="translate(-9.469 -7)"/>
      </g>
    </Svg>
  );
});
