import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { getInTouchActions, submitEnquiryError, submitEnquirySuccess } from '.';

export const submitEnquiryEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(getInTouchActions.SUBMIT_ENQUIRY),
        mergeMap(action => {
            var data = JSON.stringify(action.payload);
            return api.post(endPointKeys.api_travel, 'Booking/CreateWebEnquiry', data).pipe(
                map(result => result.data),
                mergeMap(() => {
                    return of(submitEnquirySuccess(), sendToast("Request Successful", ToastType.Success));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed to submit enquiry request" };
                    sendToast(appError.message, ToastType.Error);
                    return of(submitEnquiryError(appError));
                }))
        })
    );