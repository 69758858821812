import axios, { AxiosRequestConfig } from "axios";
import { from, Observable } from "rxjs";
import appConfig from "../config/app-config";
import { IEndPointKeys, IServiceApi } from "../models";
import { AppUser } from "../helpers/getAppUser";
export const endPointKeys: IEndPointKeys = appConfig.endPoints;

const config = (
	apiEndPoint: string,
	url: string,
	data?: any,
	contentType?: string
) => {
	const currentUser = AppUser();
	return {
		url: url !== "" ? apiEndPoint + "/" + url : apiEndPoint,
		headers: {
			Authorization: "Bearer " + (currentUser && currentUser.token),
			"Content-Type": contentType || "application/json",
			//"Ocp-Apim-Subscription-Key": localStorage.getItem("ocpKey"),
			//secret: localStorage.getItem("serviceApiKey"),
			contactid: currentUser ? currentUser.id : 0,
		},
		data: data || {},
	};
};
const requestMethods = {
	get(apiEndPoint: string, url: string, data?: any): AxiosRequestConfig {
		return {
			...config(apiEndPoint, url, data),
			method: "GET",
		};
	},
	post(
		apiEndPoint: string,
		url: string,
		data?: any,
		contentType?: any
	): AxiosRequestConfig {
		return {
			...config(apiEndPoint, url, data, contentType),
			method: "POST",
		};
	},
	put(apiEndPoint: string, url: string, data?: any): AxiosRequestConfig {
		return {
			...config(apiEndPoint, url, data),
			method: "PUT",
		};
	},
	delete(apiEndPoint: string, url: string, data?: any): AxiosRequestConfig {
		return {
			...config(apiEndPoint, url, data),
			method: "DELETE",
		};
	},
};
export const api: IServiceApi = {
	get(apiEndPoint: string, url: string, data?: any): Observable<any> {
		return from(axios(requestMethods.get(apiEndPoint, url, data)));
	},
	post(
		apiEndPoint: string,
		url: string,
		data?: any,
		contentType?: any
	): Observable<any> {
		return from(
			axios(requestMethods.post(apiEndPoint, url, data, contentType))
		);
	},
	put(apiEndPoint: string, url: string, data?: any): Observable<any> {
		return from(axios(requestMethods.put(apiEndPoint, url, data)));
	},
	view(apiEndPoint: string, url: string): Observable<any> {
		return from(axios(requestMethods.get(apiEndPoint, url)));
	},
	delete(apiEndPoint: string, url: string, data?: any): Observable<any> {
		return from(axios(requestMethods.delete(apiEndPoint, url, data)));
	},
};
