import { IAppError, ICountry } from "../../models";
import { IAppAction } from "../app-action";

export const referenceDataStateActions = {
    FETCH_COUNTRIES: 'FETCH_COUNTRIES',
    FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
    FETCH_COUNTRIES_ERROR: 'FETCH_COUNTRIES_ERROR'
};

export const fetchCountries = (): IAppAction => ({
    type: referenceDataStateActions.FETCH_COUNTRIES
});

export const fetchCountriesSuccess = (countries: Array<ICountry>): IAppAction => ({
    type: referenceDataStateActions.FETCH_COUNTRIES_SUCCESS,
    payload: countries
});

export const fetchCountriesError = (appError: IAppError): IAppAction => ({
    type: referenceDataStateActions.FETCH_COUNTRIES_ERROR,
    payload: appError
});