import * as React from "react";
import styled from "styled-components";
import { ImageBackground } from "../components/image-background/image-background";
import { Headline3 } from "../components/typography";
import { useSelector } from "react-redux";
import { IAppState } from "../store/app-state";
import { IAppUser } from "../models";
import { SDPImageBackground } from "../components/image-background/image-sdp-background";

export const TermsAndConditionsPage = () => {
  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );

  return (
    <PageWrapper>
      {appUser?.isSDP ? (
        <SDPImageBackground url="/images/SDPBackgroundImagePattern.png" />
      ) : (
        <ImageBackground url="/images/backgroundImagePattern.gif" />
      )}
      <StyledHeadline3>Terms and Conditions</StyledHeadline3>
      <NotesWrapper>
        We've made sure the process of booking your holiday with us is as easy
        and straightforward as possible. For full details of the terms and
        conditions of your holiday please refer to each individual booking and
        the terms and conditions tab otherwise via our website or alternatively
        please don't hesitate to contact your travel expert who will be happy to
        help you.
      </NotesWrapper>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  width: calc(100% - 10px);
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 800px;
    max-width: inherit;
  }
`;

const NotesWrapper = styled.div`
  background-color: white;
  text-align: justify;
  text-justify: inter-character;
  font-size: 14px;
  padding: 20px 40px 20px;
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
`;
