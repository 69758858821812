import React from "react";
import styled from "styled-components";
import { IconPeople } from "../icons";
import Label from "../typography/label";
import { DialogBox, DialogSize } from "../modal-dialog";
import { PartyEdit } from "./party-edit";
import { IAppState } from "../../store/app-state";
import { IBookParty } from "../../models";
import Button, { ButtonHeight, ButtonSize, ButtonType } from "../button";
import { useSelector, useDispatch } from "react-redux";
import { PartyDetailView } from "../party-detail";
import { IHolidayState } from "../../store/holiday";
import { assignNewFamilyToBookParty, showDialogBox } from "../../store/contact/contact-actions";


export const PartyAssign = () => {
    const [selectedParty, setSelectedParty] = React.useState<number>(0);
    const [selectedPartyStatusId, setSelectedPartyStatusId] = React.useState<number>(0);
    const [ showPartyEditDialog, togglePartyEdit] = React.useState<boolean>(false);
    const [ showPartyDetailDialog, togglePartyDetail] = React.useState<boolean>(false);
    const holidays: IHolidayState = useSelector((state: IAppState) => state.holidays);
    const partyMembers : Array<IBookParty> = holidays.holiday && holidays.holiday.bookParty ? holidays.holiday.bookParty : [];
    // const contactId : number = useSelector((state:IAppState)=> state.contact.contact?.contactId);
    const contactId : string = useSelector((state:IAppState)=> state.contact.contact?.contactId);
    const dispatch = useDispatch();

    const onAssignGuestClick = (partyId:number,partyStatusId:number) =>{
        setSelectedParty(partyId);
        setSelectedPartyStatusId(partyStatusId)
        handlePartyEdit();
    }

    const onAddNew = ()=>{
        handlePartyEdit();
        if(showPartyDetailDialog === false){
            dispatch(assignNewFamilyToBookParty(true));
            dispatch(showDialogBox(true));
            togglePartyDetail(true);
        }
    }

    const handlePartyEdit = () =>{
        togglePartyEdit(!showPartyEditDialog);              
    }

    const handlePartyDetail = () => {
        togglePartyDetail(!showPartyDetailDialog);
        dispatch(assignNewFamilyToBookParty(false));
    }

    return (
        <Container>
            <TextLabel>Guests</TextLabel>
            {
                partyMembers && partyMembers.length > 0 && Array.from(partyMembers).map(function (party, index) {
                    if(holidays.holiday?.partyLeader === party.partyName) return null;
                    return (
                        <PartyDetailRow key={index}>
                            <PartyDetail>
                                <PeopleIcon size={26} />
                                <PartyName>{party.partyName}</PartyName>
                            </PartyDetail>
                            {(party.familyId === '' || party.familyId === '0') && (party.contactId === "" || party.contactId === '0') &&
                                <AssignButton type={ButtonType.MAIN} size={ButtonSize.SMALL} height={ButtonHeight.SHORT}
                                    onClick={() => onAssignGuestClick(party.partyId,party.partyStatusId)} text="ASSIGN GUEST" />
                            }
                        </PartyDetailRow>
                    );
                })
            }           

            <DialogBox isOpen={showPartyEditDialog ?? false} hide={handlePartyEdit} size={DialogSize.SMALL}>              
                <PartyEdit hideDialogBox={handlePartyEdit} addNew={onAddNew} partyId={selectedParty} partyStatusId={selectedPartyStatusId} />
            </DialogBox>

            <DialogBox isOpen={showPartyDetailDialog} hide={handlePartyDetail} size={DialogSize.NORMAL} >            
            <PartyDetailView 
                        familyId={'' || '0'}
                        contactId={contactId}  
                        isMain={false}
                        closeForm={handlePartyDetail}
                        assignedToPartyId={selectedParty}
                        isNewParty = {true} />   
            </DialogBox>

        </Container>
    );
}

const Container = styled.div`
 border-bottom: 1px solid ${({ theme: { colors } }) => colors.labelWhite};
 padding-bottom: 8px; 
`;

const PeopleIcon = styled(IconPeople)`
    color: ${({ theme }) => theme.colors.primaryDark};
`;

const TextLabel = styled(Label)`
color: ${({ theme }) => theme.colors.labelDark};
letter-spacing: -1px;
`;


const PartyName = styled(Label)`
font-size: 14px;
letter-spacing: -1px;
margin-left: 16px;
color: ${({ theme }) => theme.colors.label};
`;

const PartyDetail = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;
`;

const PartyDetailRow = styled.div`
display: flex;
flex-direction:row nowrap;
justify-content: space-between;
`;
const AssignButton = styled(Button)`
width:95px;
`;