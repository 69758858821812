import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError, IItineraryGroup } from '../../models';
import { IAppAction } from '../app-action';
import { holidayStateActions, fetchItinerarySuccess, fetchItineraryError } from './holiday-actions';
import { IAppState } from '../app-state';

export const fetchItineraryEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.FETCH_ITINERARY),
        mergeMap((action) => {
            const bookingId: number = action.payload;
            return api.get(endPointKeys.api_travel, `Itinerary/MSD/${bookingId}`).pipe(
                map(res => res.data),
                mergeMap((data: IItineraryGroup[]) => {
                    return of(fetchItinerarySuccess(data))
                }),
                catchError((error) => {
                    const appError: IAppError = { error: error, message: 'Failed to retrieve itinerary' };
                    return of(fetchItineraryError(appError))
                }))
        })
    )