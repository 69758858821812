import { IBaseState } from "../base-state";

export interface IGetInTouchState extends IBaseState {
    enquirySuccess: boolean;
}
const defaultState: IGetInTouchState = {
    isProcessing: false,
    enquirySuccess: false
};

export const getDefaultGetInTouchState = (options?: any) => {
    return {
        ...defaultState,
        ...options
    };
};
