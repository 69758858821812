import React, { useContext } from "react";
import { useSelector } from "react-redux";
import styled, { ThemeContext } from "styled-components";
import { IconDelete, IconEdit, Input, InputTypes } from "..";
import { IsValidEmail } from "../../helpers/validation-helper";
import { ILookUp, IPhone } from "../../models";
import { IAppState } from "../../store/app-state";
import Button, { ButtonType } from "../button";
import { SelectDropdown } from "../select-dropdown";

export interface IInfoRowProps {
    editMode: boolean;
    title: string;
    value: any;
    field: string;
    inputType?: InputTypes | null;
    errorMessage: string;
    maxLength?: number;
    onEditClick(name: string): void;
    onEditClose(): void;
    onSave(field: string, value: any): void;
}

export const InfoRow = (props: IInfoRowProps) => {
    const contact = useSelector((state: IAppState) => state.contact.contact);
    const [data, setData] = React.useState<any>(props.value);
    //const [dialingCode, setDialingCode] = React.useState<ILookUp>(contact && contact.primaryPhone && contact.primaryPhone.dialingCode ? { id: 0, title: contact.primaryPhone.dialingCode.title } : { id: 0, title: '' });
    const [error, setError] = React.useState<string>("");
    const countries = useSelector((state: IAppState) => state.referenceData.countries);
    const contactNumberCountry: any = countries && countries.find((item) => item.countryId === (contact && contact.primaryPhone && contact.primaryPhone.dialingCode && contact.primaryPhone.dialingCode.id ? contact.primaryPhone.dialingCode.id : 0))
    const contactNumberDialingCode: any = (`${contactNumberCountry && contactNumberCountry.country ? contactNumberCountry.country : ''}`) + (contactNumberCountry && contactNumberCountry.dialingCode ? ` (${contactNumberCountry.dialingCode})` : contact && contact.primaryPhone && contact.primaryPhone.dialingCode && contact.primaryPhone.dialingCode.title ? ` (${contact.primaryPhone.dialingCode.title})` : "");
    const [dialingCode, setDialingCode] = React.useState<ILookUp>(contact && contact.primaryPhone && contact.primaryPhone.dialingCode ? { id: contact.primaryPhone.dialingCode.id, title: contactNumberDialingCode ? contactNumberDialingCode : "" } : { id: 0, title: '' });
    const { colors } = useContext(ThemeContext);

    const handleSave = () => {
        if (error.length === 0) {
            // handle phone input differently
            if (props.inputType && props.inputType === InputTypes.PHONE) {
                props.onSave(props.field, {
                    id: dialingCode ? dialingCode.id : 0,
                    countyCode: dialingCode ? dialingCode.title : '',
                    phone: Number(data.phone) //Number removes leading zeros, which we don't want when combined with the country prefix
                });
            }
            else {
                props.onSave(props.field, data);
            }
        }
    }

    const handleOnChange = (data: any) => {
        // when phone, set the value as dialig code and phone 
        if (props.inputType && props.inputType === InputTypes.PHONE) {
            const re = /^[0-9\b]+$/;          
            if (data.toString() === '' || re.test(data.toString())){
                setData({
                    countyCode: dialingCode,
                    phone: data
                });
            }
        }
        else {
            setData(data.toString().trimStart());
            if (props.inputType && props.inputType === InputTypes.TEXT) {
                const isValid = data && data.toString().trim().length !== 0;
                let errorMessage = isValid === true ? "" : props.errorMessage;
                setError(errorMessage);
            }
            if (props.inputType && props.inputType === InputTypes.EMAIL) {
                let isValid = IsValidEmail(data);
                let errorMessage = isValid === true ? "" : "Please enter a valid email address";
                setError(errorMessage);
            }
        }
    }

    let valueReadOnly = '';
    let phoneNumber = '';
    if (props.inputType && props.inputType === InputTypes.PHONE) {
        let phone = data as IPhone;
        if (phone && phone !== null) {
            valueReadOnly = dialingCode ? `${dialingCode.title} ${phone.phone}` : phone.phone;
            phoneNumber = phone.phone;
        }
    }
    else {
        valueReadOnly = props.value;
    }

    if (props.editMode === true) {

        //const filtered: Array<ILookUp> = countries.filter(res => res.dialingCode && res.dialingCode.length > 1).map((c) => ({ id: c.countryId, title: c.dialingCode }));
        const filtered: Array<ILookUp> = countries.filter(res => res.dialingCode && res.dialingCode.length > 1).map((c) => ({ id: c.countryId, title: `${c.country} (${c.dialingCode})` }));

        const isPhoneInput = props.inputType && props.inputType === InputTypes.PHONE ? true : false;
        const labelText = error && error.length !== 0 ? error : (props.inputType && props.inputType === InputTypes.PHONE ? '' : props.title);

        return (
            <DataWrapper editMode={true}> 
            <IconWrapper2 onClick={props.onEditClose}> 
                <IconDelete size={10} color={colors.textLight}/>
            </IconWrapper2>
                {(props.inputType && props.inputType === InputTypes.PHONE) &&
                    <DropdownWrapper>
                        <SelectDropdown
                            options={filtered}
                            defaultValue={dialingCode}
                            isClearable={false}
                            placeholder=""
                            isSearchable={false}
                            errorMessage='Please select'
                            isValid={true}
                            label="Phone"
                            onChange={(e) => setDialingCode(e ? { id: e.id, title: e.title } : { id: 0, title: '' })}
                        />

                    </DropdownWrapper>}
                <InputWrapper isPhone={isPhoneInput}>
                    <Input
                        label={labelText}
                        placeholder={props.title}
                        type={InputTypes.TEXT}
                        value={isPhoneInput ? phoneNumber : data}
                        isValid={error ? error.length === 0 : true}
                        // errorMessage={error}
                        maxLength={props.maxLength}
                        onChange={(e: any) => handleOnChange(e.target.value)}
                        autoFocus
                    />
                </InputWrapper>
                <ButtonWrapper isPhone={isPhoneInput}>
                    <StyledButton type={ButtonType.MAIN} text="Save" onClick={handleSave} />
                </ButtonWrapper>
            </DataWrapper>
        );
    }
    return (
        <ProfileContainer>
            <PartyInfoTitle>{props.title} </PartyInfoTitle>
            <DataWrapper editMode={false}>
                <PartyInfoValue>{valueReadOnly}</PartyInfoValue>  <IconWrapper onClick={() => props.onEditClick(props.field)}><IconEdit size={40} /></IconWrapper>
            </DataWrapper>
        </ProfileContainer>
    );
};

const StyledButton = styled(Button)`
height: 48px;
`;

const DropdownWrapper = styled.div`
width: 50%;
margin-right: 5px;
`

const ProfileContainer = styled.div`
display:flex;
flex-flow:column nowrap;
border-bottom:2px solid ${({ theme }) => theme.colors.labelLight};
padding:10px 0 0;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    flex-flow:column nowrap;
}
`;

interface IDataWrapperProps {
    editMode: boolean;
}

const DataWrapper = styled.div<IDataWrapperProps>`
display:flex;
flex-flow: row;
justify-content: space-between;
position: relative;
padding-top:  ${({ editMode }) => (editMode === true ? "15px" : "0px")};
`;

export const PartyInfoTitle = styled.div`
color:${({ theme }) => theme.colors.label};
width:100%;

@media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) { 
padding-bottom : 10px;
}
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    width:35%;
}
`;

export const PartyInfoValue = styled.div`
color:${({ theme }) => theme.colors.primaryDark};
`;

const IconWrapper = styled.div`
color: ${({ theme }) => theme.colors.labelDark};
cursor: pointer;
`;

const IconWrapper2 = styled.div`
color: ${({ theme }) => theme.colors.labelDark};
cursor: pointer;
position: absolute;
right: 2px;
top: 14px;
`;

interface IWrapperProps {
    isPhone: boolean;
};

const InputWrapper = styled.div<IWrapperProps>`
margin-right:10px;
width:  ${({ isPhone }) => (isPhone === true ? "50%" : "75%")};
padding-top:  ${({ isPhone }) => (isPhone === true ? "51px" : "0px")};
`;

const ButtonWrapper = styled.div<IWrapperProps>`
width:  ${({ isPhone }) => (isPhone === true ? "25%" : "25%")};
padding-top:  ${({ isPhone }) => (isPhone === true ? "51px" : "28px")};
`;

