import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { acceptTermsError, acceptTermsSuccess, paymentStateActions } from './payment-actions';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';

export const acceptTermsEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(paymentStateActions.ACCEPT_TERMS),
        mergeMap(action => {
            var data = JSON.stringify(action.payload);
            return api.post(endPointKeys.api_travel, 'payment/PostTAndCs', data).pipe(
                map(result => result.data),
                mergeMap(() => {
                    return of(acceptTermsSuccess());
                }),
                catchError(error => {
                    const appError : IAppError = { error, message: error.response.data.message};
                    sendToast(appError.message, ToastType.Error);
                    return of(acceptTermsError(appError));
                }))
        })
    )