import moment from "moment";
import React, { FormEvent } from "react";
import styled from "styled-components";
import { CountryOptions, FrequentFlyer, Relationships, SeatPreference, TitleOptions } from "../../helpers/datasource-helper";
import { IContactIdLookUp, IFamily, ILookUp } from "../../models";
import { IAppState } from "../../store/app-state";
import { updateFamilyDetails } from "../../store/contact/contact-actions";
import { Accordion } from "../accordion";
import { YearDatePicker } from "../date-picker/date-picker";
import { Input, InputTypes } from "../input";
import { SelectDropdown } from "../select-dropdown";
import { Textarea } from "../textarea";
import { Headline4 } from "../typography";
import Label from "../typography/label";
import { getPartyDetailFormDefaultState, IPartyDetailEdit, IPartyDetailForm, useForm } from "./form-helper";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, CheckboxType } from "..";
import { MultiSelectDropdown } from "../select-multi-dropdown";

export const PartyDetailEdit = (props: IPartyDetailEdit) => {
    const familyMembers = useSelector((state: IAppState) => state.contact.contact.familyMembers);
    const isPartyDetailsEditable = useSelector((state: IAppState) => state.contact.contact.isPartyDetailsEditable);
    const family: IFamily = familyMembers.filter((f) => f.familyId === props.familyId)[0];
    const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
    const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
    const fieldRef = React.useRef<HTMLInputElement>(null);
    const { formData,
        handleInputChange,
        handleFrequentFlyerChange,
        handleDropdownChange,
        handleEmergencyPhoneChange,
        setEmergencyPhoneValidation,
        passportDatesValidation,
        onSelectHandleInsurance,
        onSelectHandleEmergencyContact,
        handleCopyEmergencyContactChk,
        handleCopyInsuranceChk,
        setInsurancePhoneValidation,
        handleInsurancePhoneChange } = useForm(getPartyDetailFormDefaultState(props, family));
    const dispatch = useDispatch();
    const validationErrors = useSelector((state: IAppState) => state.contact.partyDetailErrors);
    const allowMainPartySelect = !props.isNewParty && familyMembers.findIndex(s => s.isMain) === -1;
    const countries = useSelector((state: IAppState) => state.referenceData.countries);
    const [isTitleEnable, setIsTitleEnable] = React.useState<boolean>(formData.title.field.title === undefined ? true : false);
    //const countryCodes: Array<ILookUp> = countries ? countries.filter(res => res.dialingCode && res.dialingCode.length > 1).map((c) => ({ id: c.countryId, title: c.dialingCode })) : [];
    const countryCodes: Array<ILookUp> = countries ? countries.filter(res => res.dialingCode && res.dialingCode.length > 1).map((c) => ({ id: c.countryId, title: `${c.country} (${c.dialingCode})` })) : [];
    if (!props.isNewParty) {
        const defaultEmergencyContactNumber: any = countries.find((item) => item.countryId === formData.emergencyPhone.field.dialingCode.id)
        if (defaultEmergencyContactNumber) {
            formData.emergencyPhone.field.dialingCode.title = `${defaultEmergencyContactNumber.country} (${defaultEmergencyContactNumber.dialingCode})`
        }
        const defaultInsuranceContactNumber: any = countries.find((item) => item.countryId === formData.insuranceContactNumber.field.dialingCode.id)
        if (defaultInsuranceContactNumber) {
            formData.insuranceContactNumber.field.dialingCode.title = `${defaultInsuranceContactNumber.country} (${defaultInsuranceContactNumber.dialingCode})`
        }
    }
    // const familyOptions: Array<ILookUp> = familyMembers ? familyMembers.filter((f) => f.familyId !== props.familyId).map((family) => ({ id: family.familyId, title: family.familyName })) : [];
    const familyOptions: Array<IContactIdLookUp> = familyMembers ? familyMembers.filter((f) => f.familyId !== props.familyId).map((family) => ({ id: family.familyId, title: family.familyName })) : [];
    
    const setFamily = (form: IPartyDetailForm): IFamily => {

        let upsertObj: IFamily = !props.isNewParty ? family : {
            isMain: false,
            contactId: props.contactId,
            // familyId: 0,
            familyId: "",
            salutation: { id: 0, title: "" },
            familyName: "",
            firstName: "",
            lastName: "",
            sex: "",
            dob: moment().toDate(),
            relationship: { id: 0, title: "" },
            passportNumber: "",
            passportExpiryDate: moment().toDate(),
            passportIssueDate: moment().toDate(),
            nationality: { id: 0, title: "" },
            countryOfIssue: { id: 0, title: "" },
            insurance: { insuranceCompany: "", insuranceContactNumber: { phoneId: 0, phone: "", dialingCode: { id: 0, title: "" } }, insurancePolicyNumber: "" },
            emergencyContactName: "",
            emergencyPhone: { phoneId: 0, phone: "", dialingCode: { id: 0, title: "" } },
            seatPreference: { id: 0, title: "" },
            frequentFlyer: [],
            // dietaryRequirement: { familyId: 0, profileId: 0, profile: "", profileType: { id: 0, title: "" } },
            dietaryRequirement: { familyId: "", profileId: 0, profile: "", profileType: { id: 0, title: "" } },
            flagMedicalRequest: false,
            // roomBeddingRequest: { familyId: 0, profileId: 0, profile: "", profileType: { id: 0, title: "" } },
            roomBeddingRequest: { familyId: "", profileId: 0, profile: "", profileType: { id: 0, title: "" } },
            insuranceCopyFamilyIDs: [],
            emergencyContactCopyFamilyIDs: [],
            flagCopyEmergencyDetails: false,
            flagCopyInsuranceDetails: false
        }
        if (upsertObj.salutation == null) { upsertObj.salutation = { id: 0, title: "" }; }
        upsertObj.salutation.id = form.title.field.id;
        upsertObj.salutation.title = form.title.field.title;
        upsertObj.firstName = form.firstName.field ? form.firstName.field.trim() : "";
        upsertObj.lastName = form.lastName.field? form.lastName.field.trim() : "";
        upsertObj.sex = form.gender.field;
        upsertObj.dob = form.dob.field;
        if (upsertObj.relationship == null) { upsertObj.relationship = { id: 0, title: "" }; }
        upsertObj.relationship.id = form.relationship.field.id;
        upsertObj.relationship.title = form.relationship.field.title;
        upsertObj.passportNumber = form.passportNumber.field;
        upsertObj.passportIssueDate = form.passportIssueDate.field;
        upsertObj.passportExpiryDate = form.passportExpiryDate.field;
        if (upsertObj.nationality == null) { upsertObj.nationality = { id: 0, title: "" }; }
        upsertObj.nationality.id = form.nationality.field.id;
        upsertObj.nationality.title = form.nationality.field.title;
        if (upsertObj.countryOfIssue == null) { upsertObj.countryOfIssue = { id: 0, title: "" }; }
        upsertObj.countryOfIssue.id = form.countryOfIssue.field.id;
        upsertObj.countryOfIssue.title = form.countryOfIssue.field.title;
        upsertObj.insurance.insuranceCompany = form.insuranceCompany.field;
        upsertObj.insurance.insuranceContactNumber = form.insuranceContactNumber.field;
        upsertObj.insurance.insurancePolicyNumber = form.insurancePolicyNumber.field;
        upsertObj.emergencyContactName = form.emergencyContactName.field;
        upsertObj.emergencyPhone = form.emergencyPhone.field;
        if (upsertObj.seatPreference == null) { upsertObj.seatPreference = { id: 0, title: "" }; }
        upsertObj.seatPreference.id = form.seatPreference.field.id;
        upsertObj.seatPreference.title = form.seatPreference.field.title;
        upsertObj.frequentFlyer = form.frequentFlyer.field;
        // if (upsertObj.dietaryRequirement == null) { upsertObj.dietaryRequirement = { familyId: 0, profileId: 0, profile: "", profileType: { id: 0, title: "" } }; }
        if (upsertObj.dietaryRequirement == null) { upsertObj.dietaryRequirement = { familyId: "", profileId: 0, profile: "", profileType: { id: 0, title: "" } }; }
        upsertObj.dietaryRequirement.familyId = props.familyId;
        upsertObj.dietaryRequirement.profile = form.dietaryRequirement.field;
        upsertObj.flagMedicalRequest = form.flagMedicalRequest.field;
        // if (upsertObj.roomBeddingRequest == null) { upsertObj.roomBeddingRequest = { familyId: 0, profileId: 0, profile: "", profileType: { id: 0, title: "" } }; }
        if (upsertObj.roomBeddingRequest == null) { upsertObj.roomBeddingRequest = { familyId: "", profileId: 0, profile: "", profileType: { id: 0, title: "" } }; }
        upsertObj.roomBeddingRequest.profile = form.roomBeddingRequest.field;
        upsertObj.roomBeddingRequest.familyId = props.familyId;
        upsertObj.flagCopyEmergencyDetails = form.flagCopyEmergencyDetails.field;
        upsertObj.flagCopyInsuranceDetails = form.flagCopyInsuranceDetails.field;
        upsertObj.emergencyContactCopyFamilyIDs = form.emergencyContactCopyFamilyIDs.field;
        upsertObj.insuranceCopyFamilyIDs = form.insuranceCopyFamilyIDs.field;
        return upsertObj;
    }



    const handleSubmit = (e: FormEvent) => {

        e.preventDefault();
        setIsFormSubmitted(true);
        //setEmergencyPhoneValidation();
        formData.firstName.field = formData.firstName.field ? formData.firstName.field.trim(): '';
        formData.lastName.field = formData.lastName.field ? formData.lastName.field.trim(): '';
        if (formData.title.isValid &&
            formData.firstName.isValid &&
            formData.lastName.isValid &&
            formData.countryOfIssue.isValid &&
            formData.relationship.isValid &&
            formData.dob.isValid &&
            formData.gender.isValid &&
            setEmergencyPhoneValidation() &&
            setInsurancePhoneValidation() &&
            passportDatesValidation()
           ) {
            setIsFormValid(true);
            setIsTitleEnable(false);
            const familyObj = setFamily(formData);
            const emergencyPhoneDialingCodeObj: any = countries.find((item) => item.countryId === formData.emergencyPhone.field.dialingCode.id)
            if(emergencyPhoneDialingCodeObj !== null && emergencyPhoneDialingCodeObj !== undefined)
            familyObj.emergencyPhone.dialingCode = {id: emergencyPhoneDialingCodeObj.countryId, title: emergencyPhoneDialingCodeObj.dialingCode}
            const insuranceDialingCodeObj: any = countries.find((item) => item.countryId === formData.insuranceContactNumber.field.dialingCode.id)
            if(insuranceDialingCodeObj !== null && insuranceDialingCodeObj !== undefined)
            familyObj.insurance.insuranceContactNumber.dialingCode = {id: insuranceDialingCodeObj.countryId, title: insuranceDialingCodeObj.dialingCode}
            dispatch(updateFamilyDetails(familyObj, props.assignedToPartyId));
        } else {
            setIsFormValid(false);
            // if (!isFormValid && fieldRef.current) {
            //     fieldRef.current.scrollIntoView({ behavior: "smooth" });
            // }
        }
        if ((!isFormValid && fieldRef.current) || (validationErrors && validationErrors.length > 0 && fieldRef.current)) {
            fieldRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <FormWrapper onSubmit={handleSubmit} id="new-party" noValidate>
            <EmptyContainerDiv ref={fieldRef}>
                <StyledHeadline4>{props.isNewParty ? "Add new member" : family.familyName}</StyledHeadline4>
            </EmptyContainerDiv>
            {/* {!isFormValid && isFormSubmitted && <ErrorText ref={fieldRef}>Please fill the required field(s).</ErrorText>} */}
            <EmptyContainerDiv >
                {
                    !isFormValid && isFormSubmitted && <ErrorText ref={fieldRef}>Please fill the required field(s).</ErrorText>
                }
                {
                    validationErrors && validationErrors.length > 0 && <ErrorText>Please fill in required fields</ErrorText>
                }
                {
                    validationErrors && validationErrors.length > 0 &&
                    <ListItems>
                        {
                            validationErrors.map((e, index) => {
                                return (
                                    <Item key={index}>{e.message}</Item>
                                )
                            })
                        }
                    </ListItems>
                }

            </EmptyContainerDiv>
            <InfoDropdownRow >
                <SmallDropdown
                    label={!(isFormSubmitted && !formData.title.isValid) === true ? "*Title" : "Please select a title"}
                    options={TitleOptions}
                    defaultValue={props.isNewParty || formData.title.field.title === undefined ? null : { id: formData.title.field.id, title: formData.title.field.title }}
                    placeholder="Select..."
                    onChange={(e) => handleDropdownChange({ field: "title", key: e?.id, value: e?.title })}
                    isValid={!(isFormSubmitted && !formData.title.isValid)}
                    disabled={props.isNewParty || isTitleEnable ? false : !isPartyDetailsEditable}
                />
            </InfoDropdownRow>
            <InfoInputRow>
                <InputText
                    label={(isFormSubmitted && !formData.firstName.isValid) === true ? "Please enter a first name" : "*First name(s)"}
                    required={true}
                    isValid={!(isFormSubmitted && !formData.firstName.isValid)}
                    name="firstName"
                    maxLength={35}
                    type={InputTypes.TEXT}
                    disabled={props.isNewParty ? false : !isPartyDetailsEditable}
                    onChange={(e) => handleInputChange({ event: e })} value={formData.firstName.field} />
            </InfoInputRow>
            <InfoInputRow>
                <InputText
        
                    label={(isFormSubmitted && !formData.lastName.isValid) === true ? "Please enter a last name" : "*Last name"}
                    required={true}
                    
                    isValid={!(isFormSubmitted && !formData.lastName.isValid)}
                    name="lastName"
                    maxLength={50}
                    type={InputTypes.TEXT}
                    disabled={props.isNewParty ? false : !isPartyDetailsEditable}
                    onChange={(e) => handleInputChange({ event: e })} value={formData.lastName.field} />
            </InfoInputRow>
            <InfoInputRow >
                <DropdownList
                    label={!(isFormSubmitted && !formData.relationship.isValid) === true ? "*Relationship" : "Please select a relationship"}
                    options={allowMainPartySelect ? Relationships : Relationships.filter(r => r.id !== 19)}
                    placeholder="Please choose..."
                    defaultValue={props.isNewParty || formData.relationship.field.id === 0 ? null :
                        { id: formData.relationship.field.id, title: formData.relationship.field.title }}
                    isValid={!(isFormSubmitted && !formData.relationship.isValid)}
                    onChange={(e) => handleDropdownChange({ field: "relationship", key: e?.id, value: e?.title })}
                />
            </InfoInputRow>
            <InfoInputRow>
                <DatePicker>
                    {/* <ErrorMessage message="Please select date of birth" visible={(isFormSubmitted && !formData.dob.isValid)} />                */}
                    <YearDatePicker
                        label={(isFormSubmitted && !formData.dob.isValid) === true ? "Please select date of birth" : "*Date of birth"}
                        startYear={moment().year() - 100}
                        endYear={moment().year()}
                        maxDate={moment().toDate()}
                        onDateSelect={(e) => {
                            let convertedDate = e as Date;
                            handleInputChange({
                                field: "dob",
                                value: moment(convertedDate).format('YYYY MM DD'),
                            });
                        }}
                        isValid={!(isFormSubmitted && !formData.dob.isValid)}
                        placeHolder="Select date of birth"
                        selectedDate={
                            formData.dob.field !== null ? new Date(formData.dob.field) : null
                        }
                    />
                </DatePicker>
            </InfoInputRow>
            <InfoDropdownRow >
                <DropdownList
                    label={!(isFormSubmitted && !formData.gender.isValid) === true ? "*Gender as per passport" : "Please select a gender as per passport"}
                    placeholder="Select..."
                    options={[{ id: 1, title: "Male" }, { id: 2, title: "Female" }, { id: 3, title: "Non-binary" }]}
                    defaultValue={props.isNewParty || formData.gender.field === "" ? null : { id: formData.gender.field === "Male" ? 1 : formData.gender.field === "Female" ? 2 : 32, title: formData.gender.field }}
                    onChange={(e) => handleInputChange({ field: "gender", value: e?.title })}
                    isValid={!(isFormSubmitted && !formData.gender.isValid)}
                />
            </InfoDropdownRow>
            <LabelText>Border agencies require the sex provided to match your passport. Every guest needs to feel safe, welcome and respected. To provide us with more information please speak to your Travel Consultant</LabelText>
            <LabelText>Please note names should be as per your passport. If you would like to update a name for any member of your party please contact your Travel Consultant.</LabelText>
            <Accordion title="Passport details" expanded={false} collapseStyle={{ marginTop: '10px' }}>
                <AccordionWrapper>
                    <InfoInputRow>
                        <Input name="passportNumber"
                            // label={!(isFormSubmitted && !formData.passportNumber.isValid) === true ? "*Passport number" : "Please enter a passport number"}
                            label="Passport number"
                            maxLength={50}
                            type={InputTypes.TEXT}
                            onChange={(e) => handleInputChange({ event: e })}
                            value={formData.passportNumber.field}
                            // required={true}
                            // required={false}
                            // isValid={!(isFormSubmitted && !formData.passportNumber.isValid)}
                        />
                    </InfoInputRow>
                    <InfoInputRow>
                        <SelectDropdown
                            // label={!(isFormSubmitted && !formData.nationality.isValid) === true ? "*Nationality" : "Please select a nationality"}
                            label="Nationality"
                            options={CountryOptions}
                            defaultValue={props.isNewParty || formData.nationality.field.title === undefined ? null : {
                                id: formData.nationality.field.id, title: formData.nationality.field.title
                            }}
                            placeholder="Please choose..."
                            isValid={!(isFormSubmitted && !formData.nationality.isValid)}
                            onChange={(e) => handleDropdownChange({ field: "nationality", key: e?.id, value: e?.title })}

                        />
                    </InfoInputRow>
                    <InfoInputRow>
                        <SelectDropdown
                            // label={!(isFormSubmitted && !formData.countryOfIssue.isValid) === true ? "*Country of issue" : "Please select a country of issue"}
                            label="Country of issue"
                            options={CountryOptions}
                            defaultValue={props.isNewParty || formData.countryOfIssue.field.title === undefined ? null : {
                                id: formData.countryOfIssue.field.id, title: formData.countryOfIssue.field.title
                            }}
                            placeholder="Please choose..."
                            isValid={!(isFormSubmitted && !formData.countryOfIssue.isValid)}
                            onChange={(e) => handleDropdownChange({ field: "countryOfIssue", key: e?.id, value: e?.title })}

                        />
                    </InfoInputRow>
                    <InputWrapper>
                        <DatePicker>
                            <YearDatePicker
                                // label={
                                //     !isFormSubmitted ?
                                //         "Passport issue date" :
                                //             !passportDatesValidation() ?
                                //                 "Issue date should be earlier date than expiry date" :
                                //                 "Passport issue date"
                                // }
                                // label={
                                //                 "Passport issue date"
                                // }
                                label={passportDatesValidation() === true ? "Passport issue date" : "Issue date should be earlier data than expiry date"}
                                startYear={moment().year() - 100}
                                endYear={moment().year()}
                                maxDate={moment().toDate()}
                                onDateSelect={(e) => {
                                    handleInputChange({ field: "passportIssueDate", value: moment(e.setHours(5)).toDate() });

                                }}
                                //isValid={!(isFormSubmitted && !passportDatesValidation())}
                                // isValid={true}
                                isValid={passportDatesValidation()}
                                placeHolder="Select passport issue date"
                                selectedDate={formData.passportIssueDate.field !== null ? moment(formData.passportIssueDate.field).toDate() : null}
                            />

                        </DatePicker>
                    </InputWrapper>
                    <InputWrapper>
                        <DatePicker>
                            <YearDatePicker
                                label="Passport expiry date"
                                // label={!(isFormSubmitted && !formData.passportExpiryDate.isValid) === true ? "*Passport expiry date" : "Please select a passport expiry date"}
                                startYear={moment().year()}
                                endYear={moment().year() + 15}
                                maxDate={moment().add(15, 'years').toDate()}
                                onDateSelect={(e) => {
                                    handleInputChange({ field: "passportExpiryDate", value: moment(e.setHours(5)).toDate() });
                                }}
                                isValid={!(isFormSubmitted && !formData.passportExpiryDate.isValid)}
                                placeHolder="Select passport expiry date"
                                selectedDate={formData.passportExpiryDate.field !== null ? moment(formData.passportExpiryDate.field).toDate() : null}
                            />

                        </DatePicker>
                    </InputWrapper>
                    <InfoInputRow>
                        {formData.frequentFlyer != null && formData.frequentFlyer.field.length > 0 && !props.isNewParty ?
                            formData.frequentFlyer.field.map((flight, index) => {
                                return (
                                    <TwoColumn key={index} >
                                        <DropdownList
                                            label="Airline"
                                            options={FrequentFlyer}
                                            defaultValue={props.isNewParty ? null : { id: flight.id, title: flight.cardType }}
                                            placeholder="Select.."
                                            onChange={(e) => handleFrequentFlyerChange({ index: flight.id, field: "frequentFlyer", key: "cardType", value: e?.title })}
                                            isValid={true}
                                        />
                                        <InputWrapper>
                                            <InputText name="frequentFlyer"
                                                onChange={(e) => {
                                                    const target = (e?.target as HTMLTextAreaElement).value;
                                                    handleFrequentFlyerChange({ index: flight.id, field: "frequentFlyer", key: "cardNumber", value: target })
                                                }}
                                                label="Frequent flyer number"
                                                maxLength={50}
                                                value={flight.cardNumber} />
                                        </InputWrapper>
                                    </TwoColumn>
                                )
                            })
                            :
                            <TwoColumn >
                                <DropdownList
                                    label="Airline"
                                    options={FrequentFlyer}
                                    placeholder="Select.."
                                    onChange={(e) => handleFrequentFlyerChange({ index: 0, field: "frequentFlyer", key: "cardType", value: e?.title })}
                                    isValid={true}
                                />
                                <InputWrapper>
                                    <InputText name="frequentFlyer"
                                        onChange={(e) => {
                                            const target = (e?.target as HTMLTextAreaElement).value;
                                            handleFrequentFlyerChange({ index: 0, field: "frequentFlyer", key: "cardNumber", value: target })
                                        }}
                                        label="Frequent flyer number"
                                        maxLength={50}
                                        type={InputTypes.TEXT}
                                    //value=""
                                    />
                                </InputWrapper>
                            </TwoColumn>
                        }
                    </InfoInputRow>
                    <>
                        {<TermWrapper>*Passport details are stored securely for this and future bookings. Please see our <a style={{ color: "#b3b3b3", textDecoration: "underline" }} href="https://www.scottdunn.com/about/legalities/privacy-policy">Privacy Policy</a> for further details</TermWrapper>}
                    </>
                </AccordionWrapper>
            </Accordion>
            <Accordion title="Preferences" expanded={false}>
                <AccordionWrapper>
                    <InfoInputRow>
                        <SelectDropdown
                            label="Seat preferences"
                            options={SeatPreference}
                            defaultValue={props.isNewParty || formData.seatPreference.field === undefined ? null : {
                                id: formData.seatPreference.field.id ? formData.seatPreference.field.id : 0, title: formData.seatPreference.field.title ? formData.seatPreference.field.title : ''
                            }}
                            placeholder="Please choose..."
                            isValid={!(isFormSubmitted && !formData.seatPreference.isValid)}
                            onChange={(e) => handleDropdownChange({ field: "seatPreference", key: e?.id, value: e?.title })}


                        />
                    </InfoInputRow>
                    <InfoInputRow>
                        <Textarea maxLength={2500} name="dietaryRequirement" defaultValue={formData.dietaryRequirement != null ? formData.dietaryRequirement.field : ""} onChange={(e) => handleInputChange({ field: "dietaryRequirement", value: e })} label="Dietary requirements" />
                    </InfoInputRow>
                    <CheckBoxWraper>
                        <StyledCheckBox type={CheckboxType.SECONDARY} isChecked={formData.flagMedicalRequest.field} onClick={(e) => handleInputChange({ field: "flagMedicalRequest", value: e })} />
                        <Description>Check this box if you require assistance or auxiliary aids for mobility, sensory, respiratory, severe allergies, disability related, or medically related and your Travel Consultant will make contact with the suppliers to look at the arrangements and work with you to decide if this is a suitable holiday for your individual requirements.</Description>
                    </CheckBoxWraper>
                    <InfoInputRow>   <Textarea maxLength={2500} name="roomBeddingRequest" onChange={(e) => handleInputChange({ field: "roomBeddingRequest", value: e })} label="Room / Bedding requests" defaultValue={formData.roomBeddingRequest != null ? formData.roomBeddingRequest.field : ""} /></InfoInputRow>
                </AccordionWrapper>
            </Accordion>
            <Accordion title="Next of Kin Emergency Contact details" expanded={false}>
                <AccordionWrapper>
                    <LabelText ><i>Where possible, please provide contact details for two next of kin that aren’t travelling with you. It is extremely important for us to have these details on record in case of an emergency during the trip.</i></LabelText>
                    <InfoInputRow>
                        <InputText maxLength={100}
                            name="emergencyContactName"
                            onChange={(e) => handleInputChange({ event: e })}
                            //label={!(isFormSubmitted && !formData.emergencyContactName.isValid) === true ? "*Name" : "Please enter the emergency contact name"}
                            label="Name"
                            value={formData.emergencyContactName != null ? formData.emergencyContactName.field : ""}
                            // required={true}
                            // required={false}
                            // isValid={!(isFormSubmitted && !formData.emergencyContactName.isValid)}
                        />
                    </InfoInputRow>
                    {(isFormSubmitted && !formData.emergencyPhone.isValid) ?
                        <EmergencyPhoneErrorContainer>
                            <EmergencyPhoneErrorLabel>enter valid phone number</EmergencyPhoneErrorLabel>
                        </EmergencyPhoneErrorContainer>
                        : <EmergencyPhoneLabel>Phone number</EmergencyPhoneLabel>
                    }
                    {/* <EmergencyPhoneLabel>Phone number</EmergencyPhoneLabel> */}
                    <TwoColumn>
                        <CountryCodeWrapper>
                            <DropdownList
                                options={countryCodes}
                                isClearable={false}
                                placeholder=""
                                defaultValue={formData.emergencyPhone.field ? formData.emergencyPhone.field.dialingCode : null}
                                isSearchable={false}
                                isValid={!(isFormSubmitted && !formData.emergencyPhone.isValid)}
                                // isValid={true}
                                //label={!(isFormSubmitted && !formData.emergencyPhone.isValid) === true ? "*Phone number" : ""}
                                onChange={(e) => handleEmergencyPhoneChange({ index: 0, field: "emergencyPhone", key: "dialingCode", value: e })}
                                labelContainerStyle={{ marginTop: "0px" }}
                            />
                        </CountryCodeWrapper>

                        <PhoneNumberText maxLength={15} name="emergencyContactNumber"
                            isValid={!(isFormSubmitted && !formData.emergencyPhone.isValid)}
                            //isValid={true}
                            onChange={(e) => {
                                const target = (e?.target as HTMLTextAreaElement).value;
                                handleEmergencyPhoneChange({ index: 0, field: "emergencyPhone", key: "phoneNumber", value: target })
                            }}

                            label=""
                            value={formData.emergencyPhone != null && formData.emergencyPhone.field != null ? formData.emergencyPhone.field.phone : ""} />


                    </TwoColumn>

                    <CheckBoxCopyWraper>
                        <StyledCheckBox type={CheckboxType.SECONDARY} isChecked={formData.flagCopyEmergencyDetails.field} onClick={(e) => handleCopyEmergencyContactChk(e)} />
                        <CopyCheckDescription>Copy Emergency Contact Details to Additional Guests</CopyCheckDescription>
                    </CheckBoxCopyWraper>

                    {formData.flagCopyEmergencyDetails.field &&
                        <MultiSelectDropdown
                            options={familyOptions}
                            isClearable={false}
                            placeholder="Please select guest(s)"
                            isSearchable={false}
                            isValid
                            label="Select guest(s)"
                            onSelect={onSelectHandleEmergencyContact}
                            onRemove={onSelectHandleEmergencyContact}
                        />
                    }
                </AccordionWrapper>
            </Accordion>
            <Accordion title="Travel Insurance details" expanded={false}>
                <AccordionWrapper>
                    <InfoInputRow>
                        <InputText maxLength={50}
                            name="insuranceCompany"
                            onChange={(e) => handleInputChange({ event: e })}
                            // label={!(isFormSubmitted && !formData.insuranceCompany.isValid) === true ? "*Provider" : "Please enter a provider"}
                            label="Provider"
                            value={formData.insuranceCompany != null ? formData.insuranceCompany.field : ""}
                            // required={true}
                            // required={false}
                            // isValid={!(isFormSubmitted && !formData.insuranceCompany.isValid)}
                        />
                    </InfoInputRow>
                    {(isFormSubmitted && !formData.insuranceContactNumber.isValid) ?
                        <EmergencyPhoneErrorContainer>
                            <EmergencyPhoneErrorLabel>enter valid 24 hr assistance number</EmergencyPhoneErrorLabel>
                        </EmergencyPhoneErrorContainer>
                        : <EmergencyPhoneLabel>24 hr assistance number</EmergencyPhoneLabel>
                    }
                    {/* <EmergencyPhoneLabel>24 hr assistance number</EmergencyPhoneLabel> */}
                    <TwoColumn>
                        <CountryCodeWrapper>
                            <DropdownList
                                options={countryCodes}
                                isClearable={false}
                                placeholder=""
                                defaultValue={formData.insuranceContactNumber.field ? formData.insuranceContactNumber.field.dialingCode : null}
                                isSearchable={false}
                                isValid={!(isFormSubmitted && !formData.insuranceContactNumber.isValid)}
                                onChange={(e) => handleInsurancePhoneChange({ index: 0, field: "insuranceContactNumber", key: "dialingCode", value: e })}
                                labelContainerStyle={{ marginTop: "0px" }}
                            />
                        </CountryCodeWrapper>

                        <PhoneNumberText maxLength={15} name="insuranceContactNumberText"
                            isValid={!(isFormSubmitted && !formData.insuranceContactNumber.isValid)}
                            onChange={(e) => {
                                const target = (e?.target as HTMLTextAreaElement).value;
                                handleInsurancePhoneChange({ index: 0, field: "insuranceContactNumber", key: "phoneNumber", value: target })
                            }}

                            label=""
                            value={formData.insuranceContactNumber != null && formData.insuranceContactNumber.field != null ? formData.insuranceContactNumber.field.phone : ""} />


                    </TwoColumn>
                    <InfoInputRow>
                        <InputText maxLength={50}
                            name="insurancePolicyNumber"
                            onChange={(e) => handleInputChange({ event: e })}
                            // label={!(isFormSubmitted && !formData.insurancePolicyNumber.isValid) === true ? "*Policy number" : "Please enter a policy number"}
                            label="Policy number"
                            value={formData.insurancePolicyNumber != null ? formData.insurancePolicyNumber.field : ""}
                            // required={true}
                            // required={false}
                            // isValid={!(isFormSubmitted && !formData.insurancePolicyNumber.isValid)}
                        />
                    </InfoInputRow>

                    <CheckBoxCopyWraper>
                        <StyledCheckBox type={CheckboxType.SECONDARY} isChecked={formData.flagCopyInsuranceDetails.field} onClick={(e) => handleCopyInsuranceChk(e)} />
                        <CopyCheckDescription>Copy Insurance Details to Additional Guests</CopyCheckDescription>
                    </CheckBoxCopyWraper>

                    {formData.flagCopyInsuranceDetails.field &&
                        <MultiSelectDropdown
                            options={familyOptions}
                            isClearable={false}
                            placeholder="Please select guest(s)"
                            isSearchable={false}
                            isValid
                            label="Select guest(s)"
                            onSelect={onSelectHandleInsurance}
                            onRemove={onSelectHandleInsurance}
                        />
                    }

                </AccordionWrapper>
            </Accordion>
            {/* <div>
                {
                    validationErrors && validationErrors.length > 0 && <ErrorText>Please fill in required fields</ErrorText>
                }
                <ListItems>
                    {
                        validationErrors && validationErrors.length > 0 && validationErrors.map((e, index) => {
                            return (
                                <Item key={index}>{e.message}</Item>
                            )
                        })
                    }
                </ListItems>

            </div> */}
        </FormWrapper>

    );
}

const EmptyContainerDiv = styled.div`
display: flex;
flex-flow: column;
`;

const TermWrapper = styled.p`
display:inline-block;
flex-direction:row nowrap;
color: #b3b3b3;
font-size: 14px;
&:last-child{
    border-bottom:none;
}
`;


const ListItems = styled.ul`
display:flex;
flex-flow: column nowrap;
color:red;
justify-content:center;
width:auto;
`;
const Item = styled.li`
`;


const ErrorText = styled(Label)`
color:red;
display:flex;
margin-top:15px;
justify-content:left;
`;

const StyledCheckBox = styled(Checkbox)`
font-size:13px;
color:${({ theme }) => theme.colors.labelDark};
margin: 10px 0px;
display: flex;
width:50px;
`;

const CountryCodeWrapper = styled.div`

display: flex;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 50%;
}
`;

const InputText = styled(Input)`
margin-top:10px;
padding:2px 0px 4px;
`;

const PhoneNumberText = styled(Input)`
margin-top:8px;
margin-left:0px;
padding:2px 0px 4px;
`;


const DatePicker = styled.div`
position: relative;
  display: flex;
  flex-flow: column;
  width: 40%;
`;

const TwoColumn = styled.div`
display:flex;
justify-content:flex-start;
flex-flow: column nowrap;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
        flex-flow: row nowrap;
}
`;

export const AccordionWrapper = styled.div`
margin-top:10px;
`;

export const LabelText = styled(Label)`
  color: ${({ theme }) => theme.colors.labelDark};
  margin-top: 10px;
  display: flex;
`;
const FormWrapper = styled.form`
padding:0 0 20px;
`;

const Description = styled.span`
color: ${({ theme }) => theme.colors.labelDark};
font-size: 14px;
font-family: ${({ theme }) => theme.fonts.secondary};
margin-left: 20px;
margin-top: 7px;
padding-left:10px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    margin-left: 10px;
}
`;

const CopyCheckDescription = styled.span`
color: ${({ theme }) => theme.colors.labelDark};
font-size: 14px;
font-family: ${({ theme }) => theme.fonts.secondary};
margin-left: 0px;
margin-top: 10px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    margin-left: 0px;
}
`;

const DropdownList = styled(SelectDropdown)`
margin:0px 20px 10px 0px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    width:250px;
}
`;

const SmallDropdown = styled(SelectDropdown)`
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    width:150px;
}
`;

const StyledHeadline4 = styled(Headline4)`
color: ${({ theme }) => theme.colors.primaryDark};
font-weight: 500;
margin-top:0;
margin-bottom:10px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    margin-top:10px;
    margin-bottom:25px;    
}`;

const InfoDropdownRow = styled.div`
width:150px;
padding-right:10px;
`;

const InputWrapper = styled.div`
padding-top: 11px;
}`;

const InfoInputRow = styled.div`
max-width:450px;
@media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 10px);    
}
`;

const CheckBoxWraper = styled.div`
display: flex;
flex-flow: row;
@media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 10px);    
}
flex-direction:row nowrap;
`;

const CheckBoxCopyWraper = styled.div`
display: flex;
margin-top:15px;
flex-flow: row;
@media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 10px);    
}
flex-direction:row nowrap;
`;

const EmergencyPhoneErrorContainer = styled.div`
`;

const EmergencyPhoneErrorLabel = styled.label`
    color: #c46464;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 400;
    font-family: Mallory;    
    line-height: 1.6;    
    text-align: left;
    display: inline;
`;

const EmergencyPhoneLabel = styled.label`
    color: #7d7d7d;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 400;
    font-family: Mallory;    
    line-height: 1.6;    
    text-align: left;
    display: inline;
`;