import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { GetPartyStatusId } from "../../helpers/party-helper";
import { IBookParty } from "../../models";
import { IAppState } from "../../store/app-state";
import { fetchContactFamilyDetails } from "../../store/contact/contact-actions";
import { updateBookParty } from "../../store/holiday";
import Button, { ButtonType } from "../button";
import { Dropdown } from "../dropdown";
import { Headline5 } from "../typography";
import Label from "../typography/label";

interface IPartyEdit {
    partyId: number,
    partyStatusId: number,
    addNew(): void;
    hideDialogBox(): void;
}

export const PartyEdit = (props: IPartyEdit) => {

    ///IHolidayState
    const [selectedParty, setSelectedParty] = useState<string>("");
    const contact = useSelector((state: IAppState) => state.contact.contact);
    const isProcessing: boolean = useSelector((state: IAppState) => state.session.isProcessing);
    const holiday = useSelector((state: IAppState) => state.holidays.holiday);
    const bookPartyMembers: Array<IBookParty> = (holiday != null && holiday.bookParty !== undefined && holiday?.bookParty) ? holiday.bookParty : [];

    const dispatch = useDispatch();

    if (contact.familyMembers == null || contact.familyMembers.length === 0) {
        dispatch(fetchContactFamilyDetails(contact.contactId));
    }

    const getGuestList = () => {
        let guestList: Array<string> = [];
        if (contact.familyMembers !== null) {
            const partyMembers = bookPartyMembers.map((p) => p.partyName);
            guestList = contact.familyMembers.filter(f => !partyMembers.includes(f.familyName)
                && props.partyStatusId === GetPartyStatusId(f.dob, holiday?.endDate)).map(i => i.familyName);
        }
        guestList.push("Add new guest")
        return guestList;
    }

    const selectedOption = (value: string) => {
        if (value === "Add new guest") {
            props.addNew();
        }
        setSelectedParty(value);
    }

    const updateParty = () => {
        const family = contact.familyMembers.filter(i => i.familyName === selectedParty)[0];
        const party = bookPartyMembers.filter(i => i.partyId === props.partyId)[0];

        let updatedBookParty: IBookParty = {
            bookId: party.bookId,
            contactId: contact.contactId,
            partyId: party.partyId,
            familyId: family?.familyId,
            partyName: family?.familyName,
            partyStatusId: GetPartyStatusId(family?.dob, holiday?.endDate)
        };

        dispatch(updateBookParty(updatedBookParty));
        if (!isProcessing) {
            props.hideDialogBox();
        }
    }

    return (
        <Container>
            <StyledHeadline5>Assign guest</StyledHeadline5>
            <StyledTextComponent>Choose a guest from your saved guests or create a new guest.</StyledTextComponent>
            <Dropdown items={getGuestList()} onChange={e => selectedOption(e)} />
            <SubmitButton type={ButtonType.MAIN} text="submit" onClick={updateParty} processing={isProcessing} />
        </Container>
    );
}


const Container = styled.div`
margin:-8% 10% 10%;
display:flex;
flex-direction:column;
height: auto;
padding-top: 50px;;
`;

const StyledHeadline5 = styled(Headline5)`
color: ${({ theme }) => theme.colors.primaryDark};
font-weight: 500;
margin-top:0;
margin-bottom:10px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) { 
    margin-top:10px;
    margin-bottom:25px;
}`;

const StyledTextComponent = styled(Label)`
color: ${({ theme }) => theme.colors.labelDark};
margin-bottom: 10px;
`;

const SubmitButton = styled(Button)`
margin-top:25px;
`;
