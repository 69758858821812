import { toast, ToastContainer, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
export enum ToastType {
  Success = 'Success',
  Error = 'Error',
  Info = 'Info',
}
export const sendToast = (message: string, type: ToastType) => {
  const options: ToastOptions = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,    
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: type === ToastType.Success ? {backgroundColor: '#36866E'} : 
           type === ToastType.Error ? {backgroundColor: '#A74141'} : {backgroundColor: '#7D7D7D'}
   };
  return (
    <ToastContainer limit={0}>
    {toast.dismiss()}    
    {type === ToastType.Success && toast.success(message, options)}
    {type === ToastType.Error && toast.error(message, options)}
    {type === ToastType.Info && toast.info(message, options)}
    </ToastContainer>
    )
}