import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ButtonHeight, ButtonType } from '../..';
import Button from '../../button';
import { Body2 } from '../../typography';
import { IconTickInCircle } from "../../icons";

interface IPaymentComplete {
    onClose: (e: Event) => void;
}

export const PaymentComplete = ({onClose}: IPaymentComplete) => {
    const { colors } = useContext(ThemeContext);
    return(
        <Container>
            <IconTickInCircle color={colors.primaryDark} size={70} />
            <StyledBody2>Thank you for your payment. Your consultant will be in touch to send a booking receipt.</StyledBody2>
            <StyledButton type={ButtonType.MAIN} height={ButtonHeight.TALL} 
                text="Complete" onClick={(e: Event) => onClose(e)} />
        </Container>
    )
}

const StyledButton = styled(Button)`
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:200px;
    }
`;

const StyledBody2 = styled(Body2)`
    width: 300px;
    text-align: center;
`;

const Container = styled.div`
display: flex;
flex-flow: column nowrap;
justify-content: center;
align-items: center;
& > * {
    margin-bottom: 20px;
  }
`;