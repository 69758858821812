import * as React from "react";
import styled from "styled-components";
import { Body1, Headline6 } from "../typography";

const PrivacyStatement = () => {
  return (
      <Container>
      <StyledHeadline6>Privacy Statement</StyledHeadline6>
      <StyledBody1>
      This privacy policy sets out how we use and protect any personal data that you give us or that we collect when you use this website (www.scottdunn.com) or our app. This policy is limited to the information which we collect and use about you.
      </StyledBody1>
      <StyledBody1>
      Third parties, including those who operate websites which we link to in our site or app, may collect and use other information about you, and if you want to find out about what data they collect and how they use it you should refer to their privacy policy.
      </StyledBody1>
      <StyledBody1>
      We will never misuse or sell your data to anyone. We ensure any data we hold is stored securely.
      </StyledBody1>
      <StyledBody1>
      Should we ask you to provide personal data when using our services or our website or app, then you can be assured that it will only be used in accordance with this privacy policy.
      </StyledBody1>
      <StyledBody1>
      We may change this policy from time to time by updating this page and you should check this page to ensure that you are happy with any changes. This policy is effective from 25 May 2018.
      </StyledBody1>
      <StyledBody1>
      Who we are
     </StyledBody1>
     <StyledBody1>
     We are Scott Dunn  ("Scott Dunn", "We", "Us"). We are a group of companies specialising in tailor-made and luxury holidays around the world and together we operate the website and app. We are all "controllers" of the personal data you provide us through the website and/or the app and we will process your personal data in accordance with this privacy policy. Our group is comprised of the following companies:
     </StyledBody1>
     <StyledBody1>
     United Kingdom (company registered in England and Wales)<br/>
Scott Dunn Limited (registration number 2021650)<br/>
Austria<br/>
Scott Dunn Limited and CoKG<br/>
Ing. -Gomperz -Weg 2, 6580 St Anton am Scoff Dunn Limited and CoKG Arlberg, Austria<br/>
United States<br/>
Scott Dunn USA Inc.<br/>
420 North Cedros Avenue, Solana Beach, CA 92075, United States<br/>
Singapore<br/>
Scott Dunn Singapore PTE Ltd.<br/>
250 North Bridge Road, #10-03 Raffles City Tower, Singapore 179101<br/>
<br/>
For any questions you may have in connection with this policy please contact our Data Manager using the following contact details:<br/>
Address:           Data Manager, Scott Dunn, Riverbank House, 1 Putney Bridge Approach, London, SW6 3JD<br/>
Telephone number: 020 8682 5000<br/>
Email: dataprotection@scottdunn.com<br/>
</StyledBody1>
<StyledBody1>
What information do we collect<br/>
We collect and use ("process") your personal data when you use our website and our mobile application, register with us, complete a booking form, fill response cards, subscribe to mailing lists, enter competitions, participate in market research and when you contact us online and offline. This information includes:<br/>
</StyledBody1>
<StyledBody1>
What information do we collect<br/>
We collect and use ("process") your personal data when you use our website and our mobile application, register with us, complete a booking form, fill response cards, subscribe to mailing lists, enter competitions, participate in market research and when you contact us online and offline. This information includes:<br/>
</StyledBody1>
<StyledBody1>
2.1 Information you give us. This includes your:<br/>
name, nationality, date of birth, passport details, contact information, address;<br/>
subscription and demographic information such as preferences, interests, age and family information;<br/>
information relating to your holiday such as reviews, blogs, image or video uploads, questionnaire data, holiday enquiries and brochure or marketing request;<br/>
information relating to quoting and booking a travel service, including destinations, countries and products you are interested in, information regarding family and travel companion(s) such as names, addresses, emergency contacts, special service needs, passport information, dates of birth, insurance information, visa information, nationality, frequent flier program membership details, flight details (in the event that flights are not booked with us) and country of residence. This information can include special category data (which is sometimes also referred to as "sensitive personal data") such as information about disabilities or medical or dietary restrictions;<br/>
information you freely give us to set up a honeymoon list service;<br/>
information you give us when you complete surveys, participate in competitions, give feedback, take advantage of offers, take part in market research, fill in forms on our site or mobile app, correspond with us and when you make complaints;<br/>
any other information that you voluntarily give us that is relevant to travel planning or feedback.<br/>
</StyledBody1>
<StyledBody1>
2.2 Information we collect about you. We may automatically collect the following information when you use our site, our app or when you access our marketing information or contact us by email, via LiveChat, online enquiry or phone:<br/>
browsing information (including the manner in which you navigate our website and your booking journey), referral information, purchase and search history; the domain and IP address that your computer uses to connect to the internet;<br/>
information about your computer or device (such as the screen resolution and type of device, e.g. Mac or Windows), browser type, operating system, internet connection, referral information;<br/>
telephone information including phone numbers, call duration and call recordings for both outgoing and incoming calls;<br/>
transcripts of conversations over LiveChat and records of details you complete through our online enquiry forms;<br/>
details such as your username when you interact with us on social media (e.g. you tag us or mention us in a post)<br/>
</StyledBody1>
<StyledBody1>
2.3 Information provided to us by or collected from third parties. We may collect the following information from third parties:<br/>
the information mentioned in section 2.1 of this policy (above) which is provided to us by your family members, travel companions or other persons who manage your travel arrangements;<br/>
we update address information from the Royal Mail "Change of Address" file;<br/>
Google Analytics provides us with information about your visit to our website. This covers session information, length of visits, repeat visits, page interaction information (such as clicks and mouse movements), and search terms which lead you to our website;<br/>
contact details given to us by partners who have run competitions for Scott Dunn products.<br/>
</StyledBody1>
<StyledBody1>
What do we do with the information we gather<br/>
We use information held about you in the following ways:<br/>
to tailor trips to you and your travel companions;<br/>
to book the travel services you have booked through our travel partners globally;<br/>
to book flights on your behalf and on behalf of your travel companions;<br/>
to send you travel related documentation such as booking confirmations;<br/>
to maintain contact with you during the booking process;<br/>
to send you newsletters and/or marketing information if you make an enquiry or booking with us OR if you consent;<br/>
to maintain our records and audit our business;<br/>
to comply with regulatory requirements and requests for information received from regulators and competent authorities;<br/>
to train our staff and improve our business, products and services;<br/>
to market our business on third party websites;<br/>
to contact you for market research purposes;<br/>
to personalise and customise the content of our website according to your interests;<br/>
to tailor the content of our marketing to you;<br/>
to retain factual information which can be relevant to resolving disputes;<br/>
to prevent and to assist in the detection of crime;<br/>
to maintain and communicate with you about your account with us;<br/>
to provide you with services you request from us and to otherwise enter into and perform contracts with you, including providing customer support;<br/>
to administer and improve our site and app, including ensuring that online content is presented to you in the most effective and/or personalised manner, remembering your preferences and allowing you to participate in interactive features;<br/>
to maintain and improve the integrity and security of our systems and data;<br/>
to measure the effectiveness of marketing;<br/>
to carry out market research.<br/>
</StyledBody1>
<StyledBody1>
Using your information in accordance with data protection laws<br/>
Data protection laws require that we meet certain conditions before we are allowed to use your data in the manner described in this privacy policy. To use your personal data, we will rely on one of the following conditions, depending on the purpose for which we use your data:<br/>
Necessity for entering into / performing contracts with you: When we agree to provide you with a service which you request from us a contract is formed between you and us. In order to enter into and perform this contract we will need to process certain data. Failure to provide the requisite personal information will unfortunately mean we cannot provide our products to you. You cannot object to our use of personal data which is necessary for entering into or performing a contract with you.<br/>
Consent: We may provide you with certain marketing information about our services/products or those of a third party where you have provided your consent for us to do so. You may withdraw your consent at any time. Please note that we may send you marketing information without your consent about services/products which are similar to those we previously supplied to you or which we previously negotiated about, provided that in such cases you have an absolute right to object to/opt out of receiving further marketing information.<br/>
Necessity for compliance with legal obligations which apply to us: We collect and process the contact information of other persons who you request that we provide our services to so that we can communicate this privacy policy to them in compliance with our legal obligations under data protection law. We also keep a list of individuals who have objected to us sending them marketing information so that we are able to ensure that we respect their right to do so by not sending them any further marketing communications.<br/>
Necessity for our legitimate interests or the legitimate interest of a third party: To use your personal data for any other purpose described in this privacy policy, we will rely on a condition known as "legitimate interests". In order to do so we must be able to show that we have a specific legitimate interest for which it is necessary that we process your personal data, and that your interests, fundamental rights and freedoms do not override our relevant legitimate interest. This requires that we carry out a balancing exercise which is detailed below:<br/>
</StyledBody1>
<StyledBody1>
Legitimate interest<br/>
We have a legitimate interest in:<br/>
providing our services, effectively and efficiently (e.g. we rely on this legitimate interest when we process data about travel companions of the person making bookings with us since we can only rely on the necessity for performing a contract condition mentioned above to process data about individuals we have a contract with);<br/>
using your details to service enquiries effectively and efficiently;<br/>
using your details to contact you when you have interacted with us previously e.g. if you contact us over social media, to return a phone call or follow up with you after you have travelled;<br/>
operating our website and app in an efficient, effective and user friendly manner;<br/>
improving our services;<br/>
promoting our business and services including by sending magazine, newsletters and organising competitions;<br/>
effectively operating and managing our business in an organised manner;<br/>
maintaining and improving the integrity and security of our systems and data that we hold;<br/>
enabling and assisting businesses and persons involved in the provision of services you request, such as airlines, hoteliers and transport companies, to comply with their legal and regulatory obligations;<br/>
ensuring that we are able to demonstrate that we comply with applicable laws and regulations;<br/>
cooperating with competent authorities, including in the detection and prevention of crime.<br/>
</StyledBody1>
<StyledBody1>
Necessity<br/>
We consider that it is reasonable for us to process your personal data for the purposes of our legitimate interests outlined above as we process your personal data only so far as is necessary to provide our goods and/or services.<br/>
</StyledBody1>
<StyledBody1>
Impact of processing<br/>
We consider that it is reasonable for us to process your personal data for the purposes of our legitimate interests outlined above as:<br/>
it can be reasonably expected for us to process personal information in this way;<br/>
the data processing involved does not pose significant risks for the data subjects;<br/>
we will implement safeguards to protect data subjects;<br/>
data subjects can withdraw their consent to our processing of sensitive personal data.<br/>
In order to provide our services we may also, on occasion, need to process sensitive personal data. This is likely to be limited to information relating to your health for the purpose of providing special services you request, managing dietary requirements and arranging for insurance.<br/>
</StyledBody1>
<StyledBody1>
For us to use this data, data protection laws require that we meet additional conditions (apart from the ones listed above). We process sensitive personal data about you only if we are able to satisfy one of the following conditions:<br/>
Explicit consent: When you have explicitly consented to us processing the sensitive data for a specific purpose. You can withdraw consent at any time.<br/>
Necessity to protect your vital interests when you are unable to give us consent: In a situation where the consent obtained by us does not cover a form of data processing which is required to safeguard your vital interests, we will rely on this condition. This may be applicable, for example, where we need to share data with a third party provider of medical services where the consent you have given us does not cover such sharing.<br/>
Children and other travel companions<br/>
This privacy policy also applies to children and others on whose behalf you request our services.<br/>
</StyledBody1>
<StyledBody1>
As it is not always possible for us to deal with such individuals personally, we need to rely on the assumption that you have a right to share their personal data with us. Where you ask us to send booking information directly to another person on whose behalf you make a booking, we will send that person a copy of this privacy policy. However, in all other cases, we need you (if you are making the booking) to communicate this privacy policy to all individuals whose personal data you provide to us as we are not able to do so directly.<br/>
We do not accept responsibility for processing personal data you provide us with of individuals who are not informed about such processing because of a failure on your part to provide them with a copy of this policy.<br/>
We encourage you to explain the contents of this privacy policy to any children who you ask us to provide our services to, and we would be happy to do so ourselves if they contact us.<br/>
</StyledBody1>
<StyledBody1>
Marketing lists<br/>
We keep a list of individuals who request our services, with their contact details, so that we can send them information about similar products and services we provide. Any person included in this list can, at any time, instruct us to stop sending them such information. Every marketing communication from us will also contain an explanation of how individuals can stop receiving similar communications.<br/>
We will not, under any circumstances, add to our marketing list the details of persons who are under the age of 18.<br/>
</StyledBody1>
<StyledBody1>
Who else may see your data<br/>
We may share your information, only to the extent that such sharing is necessary for achieving the purposes listed above, with the following third parties:<br/>
Travel businesses for the purpose of providing our services to you, namely local ground operators, airlines, hoteliers, transport companies, car hire companies and other travel companies involved in delivering the services we provide you.<br/>
Salesforce, Google, Criteo, Facebook, GPMD, Kenshoo, more2 and other providers of marketing services for the purpose of marketing our business.<br/>
Data analytics service providers and market research companies, including Google (using Google Analytics), for the purpose of improving our customers' experience and targeting advertising.<br/>
Cloud storage providers for storage of electronic documents and information, including Mimecast which is an email back up service.<br/>
Delivery companies for the purpose of delivering documents related to your travel arrangements, such as couriers, the Royal Mail, City Link and their affiliates.<br/>
Competent authorities in jurisdictions you may be travelling to where this is required to comply with applicable laws and regulations. Advanced Passenger Information (API) is required by many countries prior to arrival (or departure). API information includes personal identity information such as name, passport details, visa information, nationality, transit and destination data. Airlines and transport companies are required to transmit this information by law and data entry is requested at the time of ticket issue. To comply with these regulations we pass the relevant information to the transport companies who transmit the information to the relevant authorities.<br/>
Companies within our group, meaning our subsidiaries, our ultimate holding companies and their subsidiaries, which we share our operations with. Sub-contractors engaged by us for the purpose of operating our business and delivering our services. These may include providers of technology services such as providers of hosting, IT and web services.<br/>
Law enforcement or fraud prevention agencies, as well as our legal advisers, courts and any other authorised bodies, for the purposes of investigating any actual or suspected criminal activity or other regulatory or legal matters, and to ensure the safety of others and the property of others.<br/>
Prospective purchasers and purchasers of our business or assets for the purpose of considering the prospective sale of our business or assets and for carrying on the business.<br/>
Our legal advisors and the courts for the purpose of enforcing our rights.<br/>
</StyledBody1>
<StyledBody1>
Where we store your personal data<br/>
Due to the nature of our business, your personal data may be transferred outside the European Economic Area to our carefully selected suppliers in order for us to provide you with the services you enquire about and book through us. Data protection and privacy regulations in these countries generally do not require the same high level of protection for personal data as that provided in the EEA. We transfer your personal data on the basis that this is necessary for us to enter into and perform our contract with you.<br/>
Data security<br/>
Data security is of the utmost importance to us and we are committed to ensuring information we collect from you is secure. In order to prevent unauthorised access or disclosure, we have put in place physical, electronic and managerial procedures to safeguard and secure the information we collect online and offline.<br/>
Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site/your account, you are responsible for keeping this password confidential.  We ask you not to share a password with anyone.<br/>
Unfortunately, the transmission of information via the internet is not completely secure.  Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk.  Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.<br/>
How long we keep your information for<br/>
We will only retain personal data for as long as is required for the purpose for which we collected it.<br/>
Different retention periods apply to different items of information. For example, we retain your contact details and preferences for a period of seven years from your last enquiry or booking to ensure the best possible service on future bookings however credit card payment details are deleted once payments have been made.<br/>
If you would like more information about the specific retention periods please contact our Data Manager using the contact details provided above (see section 1).<br/>
Once the relevant retention period expires, the data and all copies of it will be deleted or physically destroyed, safely and securely.<br/>
</StyledBody1>
<StyledBody1>
Your rights<br/>
You have a number of rights under data protection law in relation to the way we process your personal data.  These are set out below.  You may contact us using the details below to exercise any of these rights, and we will respond to any request received from you without undue delay and within one month from the date of the request.<br/>
If you would like more information about your rights or if you would like to exercise your rights, please contact our Data Manager by writing to: Data Manager, Scott Dunn, Riverbank House, 1 Putney Bridge Approach, London, SW6 3JD, by telephone on 020 8682 5000 or by email on dataprotection@scottdunn.com. You can also change your details and preferences by logging on and updating your profile, subscription details and other personal information on our website.<br/>
Right to access<br/>
A right to access personal data held by us about you.<br/>
Right to rectification<br/>
A right to require us to rectify any inaccurate personal data held by us about you.<br/>
Right to erasure<br/>
A right to require us to erase personal data held by us about you. This right will only apply in certain cases where, for example, we no longer need to use the personal data to achieve the purpose we collected it for; where you withdraw your consent if we are using your personal data based on your consent; or where you successfully object to the way we process your data (refer to the right to object below).<br/>
Right to restriction of processing<br/>
In certain circumstances, a right to restrict our processing of personal data held by us about you. This right will only apply where, for example, you dispute the accuracy of the personal data held by us; where you would have the right to require us to erase the personal data but would prefer that our processing is restricted instead; or where we no longer need to use the personal data to achieve the purpose we collected it for, but you require the data for the purposes of dealing with legal claims.<br/>
Right to data portability<br/>
In certain circumstances, a right to receive personal data, which you have provided to us, in a structured, commonly used and machine readable format. You also have the right to require us to transfer this personal data to another organisation, at your request.<br/>
Right to object<br/>
You have a right to object to our processing of personal data held by us about you where the processing of such data is necessary for the purposes of our legitimate interests, unless we are able to demonstrate, on balance, legitimate grounds for continuing to process personal data which override your rights or which are for the establishment, exercise or defence of legal claims.<br/>
You also have an absolute right to object to the sending of marketing information. All marketing information we send you will give you the option to opt out of such communication.<br/>
Right to withdrawal of consent<br/>
When we rely on your consent to process personal data, you may withdraw your consent at any time. This will not affect the validity of anything we have done before you withdraw your consent but we will promptly cease the data processing to which the withdrawn consent relates.<br/>
</StyledBody1>
<StyledBody1>
The above is only a summary of your rights as a data subject. These rights are subject to various conditions and exceptions which are provided for by law. For more detailed information please contact us on the details provided above.<br/>
If you have any concerns regarding our processing of your personal data, or are not satisfied with our handling of any request by you in relation to your rights, you also have the right to make a complaint to the Information Commissioner's Office.  Their address is:<br/>
First Contact Team<br/>
Information Commissioner's Office<br/>
Wycliffe House<br/>
Water Lane<br/>
Wilmslow<br/>
SK9 5AF<br/>
</StyledBody1>
<StyledBody1>
Links to other websites<br/>
Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting third party sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.<br/>
</StyledBody1>
    </Container>
  );
};

const Container = styled.div`
  margin: 0px 20px 20px 20px;
  display: flex;
  flex-flow: column nowrap;
`;

const StyledHeadline6 = styled(Headline6)`
  margin: 0px auto 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledBody1 = styled(Body1)`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 8px;
  font-size: 10px;
`;

export default PrivacyStatement;
