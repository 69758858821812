import React, { useLayoutEffect, useState } from 'react';

interface Size {
  width: number;
  height: number;
}

export const useWindowSize = () => {
const [size, setSize] = useState<Size>({width: 0, height: 0});
  useLayoutEffect(() => {
    function updateSize() {
      setSize({width: window.innerWidth, height: window.innerHeight});
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const ShowWindowDimensions = (props:any) => {
  const {width, height} = useWindowSize();
  return <span>Window size: {width} x {height}</span>;
}