import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconProfile = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 39.063 39.063" {...props}>
      <g transform="translate(-5.469 -5.469)">
        <g transform="translate(5.469 5.469)">
          <path
            fill={props.color}
            d="M26.531,7A19.531,19.531,0,1,0,46.063,26.531,19.553,19.553,0,0,0,26.531,7Zm0,37.5a17.863,17.863,0,0,1-9.955-3.019c3.047-.924,5.6-1.431,5.889-4.523a9.809,9.809,0,0,0-.236-2.6c-1.078-1.151-1.313-3.016-1.738-4.938-1.368-.275-1.746-2.742-1.651-3.936.082-1.031.624-1.1.767-1.1a.3.3,0,0,1,.035,0s-.8-3.57-.283-5.676c.724-2.937,5.089-4.577,7.967-4.577a6.1,6.1,0,0,1,6.186,4.577c.519,2.105-.283,5.676-.283,5.676a.3.3,0,0,1,.035,0c.143,0,.686.069.767,1.1.095,1.194-.283,3.662-1.651,3.936-.425,1.922-.336,3.264-1.738,4.938a9.809,9.809,0,0,0-.236,2.6c.291,3.1,2.911,3.624,6,4.575A17.859,17.859,0,0,1,26.531,44.5Zm11.423-4.108c-.642-.22-1.265-.408-1.851-.584-3-.9-3.985-1.334-4.141-2.995a7.429,7.429,0,0,1,.136-1.778,8.489,8.489,0,0,0,1.592-4.14c.023-.134.047-.269.071-.407,1.524-1.07,1.954-3.549,1.828-5.132a3.055,3.055,0,0,0-.627-1.719,14.713,14.713,0,0,0,.068-5.305,7.7,7.7,0,0,0-7.7-5.766c-3.252,0-8.514,1.832-9.484,5.766a14.711,14.711,0,0,0,.068,5.3,3.055,3.055,0,0,0-.627,1.72c-.126,1.588.307,4.076,1.841,5.141l.022.106a10.614,10.614,0,0,0,1.632,4.453,7.331,7.331,0,0,1,.132,1.756c-.156,1.661-1.123,2.084-4.075,2.959-.57.169-1.174.349-1.8.559a17.967,17.967,0,1,1,22.917.062Z"
            transform="translate(-7 -7)"
          />
        </g>
      </g>
    </Svg>
  );
});
