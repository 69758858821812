import React, { memo } from "react";
import styled, { css } from "styled-components";
import Body1 from "../typography/body";
import { NavLink as ReactNavLink, NavLinkProps } from "react-router-dom";

interface LinkProps {
  url: string;
  children: string;
  target?: string;
  className?: string;
}

// interface LinkWithSelectedStateProps {
//   url: string;
//   children: any;
//   className: string;
// }

export const Link = memo<LinkProps>(
  ({ url, children, target, className }): JSX.Element => {
    return (
      <StyledLink className={className} href={url} target={target}>
        <Body1 className={className}>{children}</Body1>
      </StyledLink>
    );
  }
);

export const NavLink = memo<NavLinkProps>(
  ({ children, ...rest }): JSX.Element => {
    return (
      <StyledNavLink {...rest}>
        <Body1>{children}</Body1>
      </StyledNavLink>
    );
  }
);

// export const LinkWithSelectedState = memo<LinkWithSelectedStateProps>(
//   ({ url, children, className }): JSX.Element => {
//   const location = useLocation();
//   const isActive = location.pathname === url;
//   const classN = isActive ? className : '';
//   return (
//       <Link url={url} className={classN}>{children}</Link>
//   );
// });

const linkStyle = css`
  color: ${({ theme }) => theme.colors.labelDark};
`;

const StyledLink = styled.a`
  ${linkStyle}
`;

const StyledNavLink = styled(ReactNavLink)`
  ${linkStyle}
`;

export default Link;
