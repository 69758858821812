import * as React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { AboutPage, BookingPage, ChangePasswordPage, DashboardPage, DataDeletionRequestsPage, GetInTouchPage, MyBookingsPage, MyGuestsPage, MyQuotesPage, PrivacyStatementPage, ProfilePage, QuotePage, TermsAndConditionsPage } from "../pages";
import { history } from "../app-history";
import routes from "./routes";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  AppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import { useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { AppContainer } from "../components/app-container";

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "0622f053-31f6-4fe3-89f2-2dd356f8dfa3", // appConfig.appInsightsKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
  },
});
appInsights.loadAppInsights();

interface IProps {
  children: React.ReactNode;
}

const AppInsightsContextProvider = ({ children }: IProps) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export const useAppInsightsContext = () => useContext(AppInsightsContext);

export const AppRouter = () => {
  return (
    <Router history={history}>
      <AppInsightsContextProvider>
        <AppContainer>
          <Switch>
            <Route exact={true} path={routes.home()}>
              <DashboardPage />
            </Route>
            <Route exact={true} path={routes.quotes()}>
              <MyQuotesPage />
            </Route>
            <Route exact={true} path={routes.bookings()}>
              <MyBookingsPage />
            </Route>
            <Route exact={true} path={routes.guests()}>
              <MyGuestsPage />
            </Route>
            <Route exact={true} path={routes.getInTouch()}>
              <GetInTouchPage />
            </Route>
            <Route exact={true} path={routes.getInTouchHolidayRequest(':type', ':bookingId')}>
              <GetInTouchPage />
            </Route>
            <Route exact={true} path={routes.getInTouchRequest(':type')}>
              <GetInTouchPage />
            </Route>
            <Route exact={true} path={routes.about()}>
              <AboutPage />
            </Route>
            <Route exact={true} path={routes.termsAndConditions()}>
              <TermsAndConditionsPage />
            </Route>
            <Route exact={true} path={routes.profile()}>
              <ProfilePage />
            </Route>
            <Route exact={true} path={routes.quote(':quoteId')}>
              <QuotePage />
            </Route>
            <Route exact={true} path={routes.booking(':bookingId')}>
              <BookingPage />
            </Route>
            <Route exact={true} path={routes.changePassword()}>
              <ChangePasswordPage />
            </Route>
            <Route exact={true} path={routes.dataDeletionRequests()}>
              <DataDeletionRequestsPage/>
            </Route>
            <Route exact={true} path={routes.privacyStatement()}>
              <PrivacyStatementPage/>
            </Route>
          </Switch>
        </AppContainer>
      </AppInsightsContextProvider>
      <StyledContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
};

const StyledContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: 0;
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export { reactPlugin };
