import { ICountry } from '../../models';
import { IAppAction } from '../app-action';
import { getDefaultReferenceDataState, IReferenceDataState } from './reference-data-state';
import { referenceDataStateActions } from './reference-data-state-actions';

const handlers = {
    [referenceDataStateActions.FETCH_COUNTRIES_SUCCESS]: (state: IReferenceDataState, payload: Array<ICountry>): IReferenceDataState => {
        return {
            ...state,
           countries: payload
        };
    }   
};

const referenceDataReducer = (state: IReferenceDataState = getDefaultReferenceDataState(), action: IAppAction) => {
    return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default referenceDataReducer;