import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconHelicopter = memo<IconProps>((props) => {
  return ( 
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(0 8.594)">
        <path 
        fill={props.color} 
        d="M51.949,43.02,51.3,43.7a4.714,4.714,0,0,1-3.37,1.429h-.642L45.846,42h.531a5.192,5.192,0,0,0,4.315-2.271,5.411,5.411,0,0,0,.751-4.927l-1.935-6.17a10.144,10.144,0,0,0-9.635-6.944H34.542V17H32.98v4.688H15.05a6.967,6.967,0,0,1,.561,1.563H35.323v8.594a4.679,4.679,0,0,0,4.688,4.672H50.16a3.909,3.909,0,0,1-.736,2.3,3.647,3.647,0,0,1-3.047,1.622h-8.71a9.386,9.386,0,0,1-9.375-9.375V26.375H15.611a6.971,6.971,0,0,1-.561,1.563H26.73v3.125a10.947,10.947,0,0,0,7.324,10.311l-1.725,3.751H28.292v1.563H47.926a6.282,6.282,0,0,0,4.493-1.906l.653-.676ZM36.886,31.844V23.25h2.987a8.583,8.583,0,0,1,8.153,5.876l1.826,5.843H40.011A3.129,3.129,0,0,1,36.886,31.844Zm-1.305,9.951A10.933,10.933,0,0,0,37.667,42h6.458l1.438,3.125H34.049Z" 
        transform="translate(-3.292 -12.313)"/>
        <path 
        fill={props.color} 
        d="M51.938,12.563H34.75V11H33.188v1.563H16v1.563H51.938Z" 
        transform="translate(-3.5 -11)"/>
        <path
        fill={props.color} 
        d="M3.563,12.563H5.343l1.316,3.948a6.963,6.963,0,0,1,1.795.443L6.469,11H2v6.658a7.016,7.016,0,0,1,1.563-.779Z" 
        transform="translate(-0.438 -11)"/>
        <path 
        fill={props.color} 
        d="M5.469,20a5.469,5.469,0,1,0,5.453,5.469A5.475,5.475,0,0,0,5.469,20Zm0,9.375a3.906,3.906,0,1,1,3.891-3.906A3.911,3.911,0,0,1,5.469,29.375Z" 
        transform="translate(0 -12.969)"/>
      </g>
    </Svg>
  );
});