import * as React from 'react';
import styled from 'styled-components';
import { ImageBackground } from '../components/image-background/image-background';

export const DataDeletionRequestsPage = () => {

    return (
        <PageWrapper>
            <ImageBackground url="/images/backgroundImagePattern.gif"/>
            Send an email to us to ask us to delete your data.
        </PageWrapper>
    );
}

const PageWrapper = styled.div`
display: flex;
flex-flow: column nowrap;
`;

