import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { ErrorLabel, LabelText } from "..";
import { Icon, IconTypes } from "../icons/icon-component";
import { Body2 } from "../typography";

export interface DropdownProps {
  label?: string;
  items: string[];
  selected?: string;
  defaultValue?: string;
  isValid?: boolean;
  errorMessage?: string;
  onChange: (item: string) => void;
  className?:any;
}

export const Dropdown = memo<DropdownProps>(({ label,items,selected,defaultValue,errorMessage,isValid,className, onChange }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);

  const onHeaderClickCallback = useCallback((e) => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const onItemClickCallback = useCallback(
    (e, item: string) => {
      setIsOpen(false);
      setValue(item);
      onChange(item);
    },
    [onChange]
  );

  const renderOptionsCallback = useCallback(
    (item: string, idx: number) => (
      <ListItem key={idx} onClick={(e) => onItemClickCallback(e, item)}>
        <Body2>{item}</Body2>
      </ListItem>
    ),
    [onItemClickCallback]
  );
  const valueMemo = useMemo(() => {
    const defualtText = defaultValue !== undefined ? defaultValue : "Please choose…";
    if (value) {
      return value;
    }
    return selected !== "" && selected !== undefined ? selected : defualtText;
  }, [value, selected, defaultValue]);

  return (
    <Container className={className}>
       <LabelContainer> {label && <LabelText as="label">{label}</LabelText>}
       {errorMessage && (
          <ErrorLabel message={errorMessage} visible={!isValid} />
        )}</LabelContainer>
      <DropDownContainer>      
        <Header onClick={onHeaderClickCallback}>
          <Body2>{valueMemo}</Body2>
          <Icon name={IconTypes.IconArrowDown} size={16} />
        </Header>
        {isOpen && (
          <ListContainer>{items.map(renderOptionsCallback)}</ListContainer>
        )}
      </DropDownContainer>
    </Container>
  );
});


const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const LabelContainer = styled.div`
  margin: 15px 0px 10px;
`;

const DropDownContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 48px;
  background-color: ${({ theme: { colors } }) => colors.labelLight};
  box-shadow: 0 2px 0 #00000029;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  cursor: pointer;

  & svg {
    color: ${({ theme: { colors } }) => colors.label};
  }
`;
const ListContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 160px;
  top: 48px;
  left: 0;
  z-index: 2;
  overflow-y: scroll;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 2px solid ${({ theme: { colors } }) => colors.labelLight};
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.labelWhite};
  }
`;
