import * as React from "react";
import styled from "styled-components";
import { Body1 } from "../typography";
import { LinkButton } from "../link-button";

const EmailAlreadyExist = ((props: { onReset: () => void, onNewPassword: () => void }) => {
  const {onReset, onNewPassword } = props;
  return (<ForgotPasswordWrapper  data-testid="user-already-exists">
    <StyledBody1>
      Looks like you're already known to us. If this is your first time logging in,
      please click <StyledLinkButton onClick={onNewPassword}>here</StyledLinkButton> and we'll send a password link to your email address. If you've forgotten your password, please click <StyledLinkButton onClick={onReset}>here</StyledLinkButton>.
    </StyledBody1>
  </ForgotPasswordWrapper>);
});

const StyledBody1 = styled(Body1)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 30px;
`;

const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.accentDark} !important;
`;

const ForgotPasswordWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
  transition: left 300ms;
`;

export default EmailAlreadyExist;
