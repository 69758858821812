import React, { memo } from "react";
import styled from "styled-components";
import { IconFemaleUser, IconProfile } from "..";
import { Body1 } from "../typography";
import Label from "../typography/label";

export interface PartyTileProp {
    // id: number;
    id: string;
    title: string;
    isMale: boolean;
    isLead: boolean;
    className?: string;
    onClick?: () => void;
}

export const PartyTile = memo<PartyTileProp>(
    ({
        id,
        title,
        isMale,
        isLead,
        className,
        onClick,
    }): JSX.Element => {
        return (
            <Container onClick={onClick} className={className}>
                {isMale === true ? <IconMale size={50} /> : <IconFemale size={50} />}
                <StyledText>{title}</StyledText>
                {isLead === true ? <InfoText>You</InfoText> : <InfoText>&nbsp;</InfoText>}
            </Container>
        );
    }
);


const Container = styled.div`
position: relative;
display: flex;
width: 185px;
min-width: 185px;
height: 215px;
flex-direction: column;
overflow: hidden;
background-color: white;
padding : 25px;
margin: 10px 7px;
cursor: pointer;
`;

const IconFemale = styled(IconFemaleUser)`
margin: 26px auto 10px;
color: ${({ theme }) => theme.colors.primaryDark};
`;

const IconMale = styled(IconProfile)`
margin: 26px auto 10px;
color: ${({ theme }) => theme.colors.primaryDark};
`;

const InfoText = styled(Label)`
font-size: 12px;
font-weight: 300;
color: ${({ theme: { colors } }) => colors.labelDark};
text-align: center;
margin-top: 5px;
`;

const StyledText = styled(Body1)`
    margin-top:10px;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.07em;
    color: ${({ theme: { colors } }) => colors.primaryDark};
    text-align: center;
`;