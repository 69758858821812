import React, { useState } from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { getCurrencySymbol } from "../../../helpers/getCurrencySymbol";
import { IHoliday, ITermsAndCondition } from "../../../models";
import { Body1 } from "../../typography";
import Button, { ButtonType } from "../../button";
import { PayView } from "../payment-iFrame";
import Text from "../../text/index";
import {
  singaporeBankDetails,
  ukBankDetails,
  usaBankDetails,
} from "../../../helpers/bankDetails";

interface IPayment {
  holiday: IHoliday;
  paymentAmount?: number;
  acceptedTandC: ITermsAndCondition[];
}

export const Payment = (props: IPayment) => {
  const { holiday } = props;
  const invoiceCurrency =
    holiday && holiday.invoice && holiday.invoice.currency
      ? holiday.invoice.currency.currency
      : "";
  const invoiceCurrencyCode =
    holiday && holiday.invoice && holiday.invoice.currency
      ? holiday.invoice.currency.currencyCode
      : "";
  const currencySymbol = getCurrencySymbol(invoiceCurrency);
  const [buttonClick, setButtonCLick] = useState<null | String>(null);

  return (
    <>
      <Container>
        <StyledTotal>
          {`Total: `}
          <NumberFormat
            value={holiday.invoice && holiday.invoice.invoiceTotal}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            displayType="text"
            prefix={currencySymbol}
          />
        </StyledTotal>
        <StyledTotal>
          {`Amount paying today: `}
          <NumberFormat
            value={props.paymentAmount}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            displayType="text"
            prefix={currencySymbol}
          />
        </StyledTotal>
        {buttonClick === "bank" || buttonClick === null ? (
          <>
            <TextWrapper>How Would you like to pay? </TextWrapper>
            <ButtonContainer>
              <ButtonTransfer
                onClick={() => setButtonCLick("bank")}
                type={
                  buttonClick === "bank"
                    ? ButtonType.MAIN
                    : ButtonType.ALTERNATIVE
                }
                text="Bank Transfer"
                dataTestId="bank-transfer"
              />
              <ButtonCard
                onClick={() => setButtonCLick("card")}
                type={
                  buttonClick === "card"
                    ? ButtonType.MAIN
                    : ButtonType.ALTERNATIVE
                }
                text="Card"
                dataTestId="card-button"
              />
            </ButtonContainer>
          </>
        ) : null}
        {buttonClick !== "card" ? (
          <>
            {buttonClick === "bank" && (
              <>
                <Text
                  body={`Please quote ${holiday.bookId} and party leader name in the Reference field.`}
                />
                <BankDetails data-testid="bank-details">
                  {holiday.businessUnit.id === 1
                    ? ukBankDetails.map((obj, i) => (
                        <Text key={i} title={obj.title} body={obj.body}></Text>
                      ))
                    : holiday.businessUnit.id === 2
                    ? usaBankDetails.map((obj, i) => (
                        <Text key={i} title={obj.title} body={obj.body}></Text>
                      ))
                    : holiday.businessUnit.id === 3 &&
                      singaporeBankDetails.map((obj, i) => (
                        <Text key={i} title={obj.title} body={obj.body}></Text>
                      ))}
                </BankDetails>
                <ConfirmationText>
                  Your payment will be confirmed via email within three working
                  days.{" "}
                </ConfirmationText>
                <ConfirmationText>
                  If you do not recieve confirmation, please contact us with
                  your booking reference
                </ConfirmationText>
              </>
            )}
          </>
        ) : (
          <>
            <StyledP>{`Holiday payments can be made using Visa, ${
              holiday.businessUnit.id === 2 ? "Amex and" : ""
            } Mastercard debit and credit cards only with no additional fees, or you may wish to pay by Bank transfer.`}</StyledP>
            <PayView
              bookId={holiday.bookId}
              amount={props.paymentAmount ? props.paymentAmount : 0}
              currency={invoiceCurrencyCode}
              acceptedTandC={props.acceptedTandC}
            />
            <LogoContentWrapper>
              <SlideInTransition>
                <StyledImg src="/images/VisaMasterCardLogos.png" />
                {holiday.businessUnit.id === 2 ? (
                  <AmexImg src="/images/amex.png" />
                ) : (
                  ""
                )}
              </SlideInTransition>
            </LogoContentWrapper>
            <StyledP>
              If you experience any issues with card payment, please feel free
              to call us or complete a bank transfer instead.
            </StyledP>
          </>
        )}
      </Container>
    </>
  );
};

const StyledTotal = styled(Body1)`
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.7px;
  margin: 5px 15px;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 20px 20px 5px 15px;
  flex-flow: column nowrap;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const StyledP = styled.p`
  padding: 0px 0px 0px 15px;
  flex-flow: column nowrap;
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.red};
  height: 5%;
  flex-direction: column;
`;

const LogoContentWrapper = styled.div`
  height: 40px;
  align-self: center;
  padding: 0px 3px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 60px 60px;
    margin-top: 60px;
  }
`;

const SlideInTransition = styled.div`
  width: 100%;
  padding: 25px 0px 10px 0px;
  animation-timing-function: ease-in-out;
  animation: inAnimation 0.5s;
  @keyframes inAnimation {
    from {
      right: -300px;
    }
    to {
      right: 0px;
    }
  }
`;

const StyledImg = styled.img`
  width: fix-content;
  align-self: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonTransfer = styled(Button)`
  margin-top: 20px;
  padding: 10px;
  width: 200px;
`;

const ButtonCard = styled(Button)`
  margin-top: 20px;
  padding: 10px;
  width: 200px;
`;

const TextWrapper = styled.p`
  padding: 0px 0px 0px 15px;
  font-size: 13px;
`;

const BankDetails = styled.div`
  margin: 20px 0;
`;

const ConfirmationText = styled.div``;

const AmexImg = styled.img`
  widht: 100px;
  height: 40px;
  margin-left: 20px;
`;
