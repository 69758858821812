import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconTickInCircle = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 64 64" {...props}>
          <g transform="translate(-765 -521)">
    <g transform="translate(765 521)">
      <g>
        <rect id="Rectangle_155" data-name="Rectangle 155" width="64" height="64" fill="none"/>
      </g>
      <g transform="translate(7.049 7.049)">
        <g data-name="Group 43">
          <path fill={props.color}  
          d="M32.174,7A25.174,25.174,0,1,0,57.348,32.174,25.2,25.2,0,0,0,32.174,7Zm0,48.334a23.16,23.16,0,1,1,23.16-23.16A23.187,23.187,0,0,1,32.174,55.334Z" 
          transform="translate(-7 -7)"
          />
        </g>
      </g>
    </g>
    <g transform="translate(776 534)">
      <g data-name="_x36_4px_boxes">
        <rect id="Rectangle_113" data-name="Rectangle 113" width="42" height="42" fill="none"/>
      </g>
      <g data-name="Production" transform="translate(7.134 8.6)">
        <path fill={props.color}  
        d="M37.728,13.1,21.867,34.438,12.353,23.545l-1.483,1.3L21.99,37.572,39.308,14.28Z" 
        transform="translate(-10.87 -13.105)"
        />
      </g>
    </g>
  </g>
    </Svg>
  );
});


