import React, { useEffect } from "react";
import styled from "styled-components";
import { PartyTile, TileAddNew } from "../components";
import { ImageBackground } from "../components/image-background/image-background";
import { Body2, Headline3 } from "../components/typography";
import TextComponent from "../components/typography/text/text";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  fetchContactFamilyDetails,
  showDialogBox,
} from "../store/contact/contact-actions";
import { IAppState } from "../store/app-state";
import { DialogBox, DialogSize } from "../components/modal-dialog";
import { PartyDetailView } from "../components/party-detail";
import { IFamily, IAppUser } from "../models";
import { Loader } from "../components/loader";
import { SDPImageBackground } from "../components/image-background/image-sdp-background";

export const MyGuestsPage = () => {
  const dispatch = useDispatch();
  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );
  const contactId: string = useSelector((state: IAppState) =>
    state.session.appUser ? state.session.appUser.id : ""
  );

  const contact = useSelector(
    (state: IAppState) => state.contact.contact,
    shallowEqual
  );
  const isProcessing: boolean = useSelector(
    (state: IAppState) => state.contact.isProcessing
  );
  const [toggleDialogBox, setShowDialogBox] = React.useState<boolean>(false);
  const [selectedParty, setSelectedParty] = React.useState<IFamily>();
  const [isNewParty, setIsNewParty] = React.useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchContactFamilyDetails(contactId));
  }, [dispatch, contactId]);

  const onPartyTileClick = (family: IFamily) => {
    setSelectedParty(family);
    setShowDialogBox(true);
    dispatch(showDialogBox(true));
  };

  const onCloseDialog = () => {
    setIsNewParty(false);
    setShowDialogBox(false);
    dispatch(showDialogBox(false));
  };

  const addNewParty = () => {
    setIsNewParty(true);
    setShowDialogBox(true);
    dispatch(showDialogBox(true));
  };

  return (
    <React.Fragment>
      {appUser?.isSDP ? (
        <SDPImageBackground url="/images/SDPBackgroundImagePattern.png" />
      ) : (
        <ImageBackground url="/images/backgroundImagePattern.gif" />
      )}
      <PageWrapper>
        <StyledHeadline3>Party details</StyledHeadline3>
        <StyledTextComponent>
          Edit and update the details of you and your guests.
        </StyledTextComponent>
        {!isProcessing ? (
          <PartyWrapper>
            {contact &&
              contact.familyMembers &&
              contact.familyMembers.length > 0 &&
              Array.from(contact.familyMembers).map(function (family, index) {
                return (
                  <StyledPartyTile
                    isMale={family.sex !== undefined && family.sex === "Male"}
                    isLead={family.isMain}
                    id={family.familyId}
                    title={family.familyName}
                    onClick={() => onPartyTileClick(family)}
                    key={family.familyId}
                  />
                );
              })}
            <TileAddNewContainer>
              <StyledAddNewTile
                text="Add new guest"
                onClick={() => addNewParty()}
                height={215}
              />
            </TileAddNewContainer>
          </PartyWrapper>
        ) : (
          <Loader />
        )}
        {contact &&
          contact.familyMembers &&
          contact.familyMembers.length > 0 && (
            <StyledBody2>
              Total guests ({contact.familyMembers.length})
            </StyledBody2>
          )}
        <DialogBox
          isOpen={toggleDialogBox}
          hide={onCloseDialog}
          size={DialogSize.NORMAL}
        >
          <PartyDetailView
            familyId={!isNewParty && selectedParty ? selectedParty.familyId : ""}
            contactId={contactId}
            isMain={!isNewParty && selectedParty ? selectedParty.isMain : false}
            closeForm={onCloseDialog}
            assignedToPartyId={0}
            isNewParty={isNewParty}
          />
        </DialogBox>
      </PageWrapper>
    </React.Fragment>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin: 0 auto;
  width: 93%;
  flex-flow: column nowrap;
  margin-left: 5px;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 20px);
    margin: 0 auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: calc(100% - 80px);
    max-width: 800px;
    margin: 0 auto;
  }
`;

const PartyWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 800px;
    margin-top: 10px;
    flex-flow: row wrap;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 10px;
    flex-flow: row wrap;
  }
  margin: 10px auto;
`;

const StyledPartyTile = styled(PartyTile)`
  width: 97%;
  flex-flow: column nowrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 150px;
  }
`;

const StyledAddNewTile = styled(TileAddNew)`
  width: 100%;
  flex-flow: column nowrap;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 150px;
  }
`;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
  padding-left: 6px;
`;

const StyledTextComponent = styled(TextComponent)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 28px;
  padding-left: 8px;
`;

const TileAddNewContainer = styled.div`
  margin: 10px 7px;
`;

const StyledBody2 = styled(Body2)`
  color: ${({ theme }) => theme.colors.white};
  margin-top: -10px;
  margin-bottom: 10px;
  margin-left: 10px;
`;
