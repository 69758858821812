import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconCutlery= memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
    <rect fill="none" width="50" height="50"/>
    <g transform="translate(6.245 6.206)">
        <rect fill="none" width="9.764" height="1.562" transform="translate(33.16 3.273) rotate(135)"/>
        <rect fill="none" width="9.764" height="1.562" transform="translate(35.37 5.483) rotate(135)"/>
        <path 
        fill={props.color} 
        d="M39.633,25.4l-.551-.38-1.448,1.528,1.105,1.105.593-.626a4.7,4.7,0,0,0,5.637-1.35c1.112-1.374,5.209-6.122,7.046-8.247l-1.108-1.108c-1.81,2.093-6,6.945-7.153,8.372A3.094,3.094,0,0,1,39.633,25.4Z" 
        transform="translate(-14.477 -9.775)"/>
        <path 
        fill={props.color}
        d="M34.268,22.325,35.8,20.877l-.38-.551a3.092,3.092,0,0,1,.706-4.122c1.427-1.156,6.279-5.343,8.372-7.153L43.386,7.943c-2.125,1.838-6.873,5.935-8.247,7.047a4.7,4.7,0,0,0-1.35,5.637l-.626.593Z" 
        transform="translate(-13.499 -7.943)"/>
        <path 
        fill={props.color}
        d="M14.564,48.579a2.343,2.343,0,0,1-3.311-3.315l8.833-8.372a5.8,5.8,0,0,1-1.316-.905l-8.59,8.142a3.9,3.9,0,1,0,5.52,5.524l10.11-10.666-1.072-1.14Z" 
        transform="translate(-8.214 -14.078)"/>
        <path 
        fill={props.color}
        d="M14.468,10.573a3.792,3.792,0,0,0-5.644,5.048l10.32,12.92a4.269,4.269,0,0,0,5.295,1.137L37.859,43.958a3.534,3.534,0,0,0,5.071-4.922ZM41.783,42.886a1.923,1.923,0,0,1-2.786,0L24.667,27.64l-.558.41a2.716,2.716,0,0,1-3.745-.484l-10.32-12.92a2.23,2.23,0,0,1,3.319-2.968L41.826,40.14A1.943,1.943,0,0,1,41.783,42.886Z" 
        transform="translate(-7.994 -8.276)"/>
      </g>
    </Svg>
  );
});