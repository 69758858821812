import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError, IAddress } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { contactStateActions, fetchAddressSuccess,fetchAddressError } from '../contact/contact-actions';

export const fetchAddressEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(contactStateActions.FETCH_ADDRESS),
        mergeMap(action => {        
            return api.get(endPointKeys.api_contact, 'ADF/AddressByKey/'+ action.payload ).pipe(
                map(result => result.data),
                mergeMap((address: IAddress) => {
                    return of(fetchAddressSuccess(address));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed to fetch address" };
                    sendToast(appError.message, ToastType.Error);
                    return of(fetchAddressError(appError));
                }))
        })
);
