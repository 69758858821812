import React from "react";
import "@adyen/adyen-web/dist/adyen.css";
import { useEffect } from "react";
import AdyenCheckout from "@adyen/adyen-web";
import type { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";
import styled from "styled-components";
import {IAddress} from "../../models";

interface PaymentPageProps {
  buId: number;
  id: string;
  sessionData: string;
  address:IAddress;
  country: string;
}

export const AdyenPaymentPage = (props: PaymentPageProps) => {
  const {buId, id, sessionData, address, country} = props;
  const BU = buId ? buId : 1;
    const adyenPublicKey = BU === 1 ? (process.env.REACT_APP_ADYEN_CLIENT_KEY_BU_1 ? process.env.REACT_APP_ADYEN_CLIENT_KEY_BU_1 : '') :
        BU === 2 ? (process.env.REACT_APP_ADYEN_CLIENT_KEY_BU_2 ? process.env.REACT_APP_ADYEN_CLIENT_KEY_BU_2 : '') :
            BU === 3 ? (process.env.REACT_APP_ADYEN_CLIENT_KEY_BU_3 ? process.env.REACT_APP_ADYEN_CLIENT_KEY_BU_3 : '') : '';
  
  const configuration: CoreOptions = {
    environment: process.env.REACT_APP_ADYEN_ENVIRONMENT, // Change to one of the environment values specified in step 4.
    clientKey: adyenPublicKey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
    analytics: {
      enabled: false, // Set to false to not send analytics data to Adyen.
    },
    session: {
      id, // Unique identifier for the payment session.
      sessionData, // The payment session data.
    },
    // Any payment method specific configuration.
    // Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        hasCVC: true,
        billingAddressRequired: true,
        data: {
          billingAddress: {
              "city": address.city, 
              "country": country, 
              "houseNumberOrName": address.address1, 
              "postalCode": address.postCode, 
              "street": address.address2+' '+address.address3
              // "stateOrProvince": address.county
          }
        }
      },
      bcmc: {
        hasHolderName: true,
        holderNameRequired: true,
        hasCVC: true,
        billingAddressRequired: true,
      },
    },
  } as const;
  
  useEffect(() => {
    (async () => {
      const checkout = await AdyenCheckout(configuration);
      checkout.create("dropin").mount("#dropin-container");
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Container><div id="dropin-container"></div></Container>;
};

const Container = styled.div`
    height: 1000px;
    width: 100%;
`;
