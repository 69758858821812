import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconArrowsLeftRight = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
    <g transform="translate(-62 -1806)">
        <rect fill="none" width="50" height="50" transform="translate(62 1806)"/>
        <path 
        fill={props.color}  
        d="M2446.658,1191.734l8.865-8.985,1.17,1.185-6.844,6.936c.647.04-1.249.026,18.747.026,9.452,0,14.29-11.54,7.74-18.44h2.16c6.157,8.278.283,20.116-9.9,20.116-19.866,0-18.144.014-18.8-.025l6.894,6.987-1.17,1.185Zm-6.155-4.19c-6.157-8.279-.283-20.117,9.9-20.117,16.868,0,18.858.031,19.048.042l-6.909-7,1.17-1.185,8.866,8.985-8.866,8.985-1.17-1.185,6.821-6.913c-.893-.079,1.475-.054-18.96-.049-9.451,0-14.289,11.54-7.739,18.441Z" 
        transform="translate(-2372.992 650.72)"/>
    </g>
    </Svg>
  );
});
