import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconPlane = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(2.344 1.563)">
        <path 
        fill={props.color}
        d="M48.313,34.3V28.9L30.344,18.25V6.688a4.688,4.688,0,0,0-9.375,0V18.25L3,28.9v5.4l17.969-5.324V40.62l-3.906,2.344v5.4l8.594-2.578,8.594,2.578v-5.4L30.344,40.62V28.972ZM32.688,43.849v2.414l-7.031-2.109-7.031,2.109V43.849L22.531,41.5V26.879L4.563,32.2V29.789L22.531,19.141V6.688a3.125,3.125,0,0,1,6.25,0V19.141L46.75,29.789V32.2L28.781,26.879V41.505Z" 
        transform="translate(-3 -2)"/>
      </g>
    </Svg>
  );
});
