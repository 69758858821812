import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconPeople= memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 56 56" {...props}>
      <g transform="translate(-0.5 -0.5)">
        <g transform="translate(1.79 9.711)">
          {/* <path
            fill={props.color}
            d="M26.531,7A19.531,19.531,0,1,0,46.063,26.531,19.553,19.553,0,0,0,26.531,7Zm0,37.5a17.863,17.863,0,0,1-9.955-3.019c3.047-.924,5.6-1.431,5.889-4.523a9.809,9.809,0,0,0-.236-2.6c-1.078-1.151-1.313-3.016-1.738-4.938-1.368-.275-1.746-2.742-1.651-3.936.082-1.031.624-1.1.767-1.1a.3.3,0,0,1,.035,0s-.8-3.57-.283-5.676c.724-2.937,5.089-4.577,7.967-4.577a6.1,6.1,0,0,1,6.186,4.577c.519,2.105-.283,5.676-.283,5.676a.3.3,0,0,1,.035,0c.143,0,.686.069.767,1.1.095,1.194-.283,3.662-1.651,3.936-.425,1.922-.336,3.264-1.738,4.938a9.809,9.809,0,0,0-.236,2.6c.291,3.1,2.911,3.624,6,4.575A17.859,17.859,0,0,1,26.531,44.5Zm11.423-4.108c-.642-.22-1.265-.408-1.851-.584-3-.9-3.985-1.334-4.141-2.995a7.429,7.429,0,0,1,.136-1.778,8.489,8.489,0,0,0,1.592-4.14c.023-.134.047-.269.071-.407,1.524-1.07,1.954-3.549,1.828-5.132a3.055,3.055,0,0,0-.627-1.719,14.713,14.713,0,0,0,.068-5.305,7.7,7.7,0,0,0-7.7-5.766c-3.252,0-8.514,1.832-9.484,5.766a14.711,14.711,0,0,0,.068,5.3,3.055,3.055,0,0,0-.627,1.72c-.126,1.588.307,4.076,1.841,5.141l.022.106a10.614,10.614,0,0,0,1.632,4.453,7.331,7.331,0,0,1,.132,1.756c-.156,1.661-1.123,2.084-4.075,2.959-.57.169-1.174.349-1.8.559a17.967,17.967,0,1,1,22.917.062Z"
            transform="translate(-7 -7)"
          /> */}
           <path  fill={props.color} d="M14.929,41.672H3.945a5.551,5.551,0,0,1,1.52-2.827c2.683-3.068,6.459-1.73,6.726-4.989a6.428,6.428,0,0,0-.1-1.443,8.054,8.054,0,0,1-3.225-1.4.654.654,0,0,1-.236-.842c1.042-2.364.7-3.1.7-6.619A4.262,4.262,0,0,1,13.7,19.039a2.923,2.923,0,0,1,1.909-.673c1.848,0,3.864,1.54,3.864,4.436,0,3.05-.349,4.659,1.351,7.683.219.389.231.633-.166.813a12.99,12.99,0,0,1-4.046,1.26,6.092,6.092,0,0,0-.072,1.3c.205,2.5,2.529,2.324,4.791,3.5.757-.286,1.477-.518,2.144-.72A11.388,11.388,0,0,0,19.988,34.9c-1.236-.425-1.543-.59-1.638-.872a16.062,16.062,0,0,0,2.889-1.058l.15-.069a2.208,2.208,0,0,0,1.25-1.334,2.408,2.408,0,0,0-.274-1.953,8.755,8.755,0,0,1-1.141-5.472c.008-.421.016-.866.016-1.346,0-3.048-2.106-6.2-5.629-6.2a4.778,4.778,0,0,0-2.506.7,6.018,6.018,0,0,0-5.538,6.257c0,.818.018,1.489.034,2.066a6.91,6.91,0,0,1-.58,3.841,2.429,2.429,0,0,0,.789,2.965,9.366,9.366,0,0,0,2.616,1.36c-.042.469-.122.581-1.641,1.1a9.828,9.828,0,0,0-4.648,2.8,7.135,7.135,0,0,0-1.95,3.844l-.158,1.911H13.559A15.7,15.7,0,0,1,14.929,41.672Z" transform="translate(-2.028 -11.656)"/>
            <path  fill={props.color} d="M55.426,47.031c-.039-.418-.376-2.646-3.182-5.482a16.508,16.508,0,0,0-7.522-3.984c-2.929-.889-3.687-1.271-3.82-2.709a7.009,7.009,0,0,1,.119-1.617,8.54,8.54,0,0,0,1.547-4.092q.026-.15.052-.3c1.483-1.136,1.907-3.579,1.784-5.151a3.285,3.285,0,0,0-.592-1.724,14.52,14.52,0,0,0,.046-5.159A7.69,7.69,0,0,0,36.17,11c-3.222,0-8.439,1.846-9.407,5.808a14.523,14.523,0,0,0,.046,5.159,3.286,3.286,0,0,0-.592,1.724c-.124,1.578.3,4.032,1.8,5.163l0,.016A10.712,10.712,0,0,0,29.6,33.261a6.961,6.961,0,0,1,.115,1.595c-.133,1.438-.878,1.813-3.756,2.674a16.465,16.465,0,0,0-7.6,4.02c-2.806,2.836-3.143,5.064-3.182,5.482L15,48.961h40.6ZM16.943,47.2s.175-1.873,2.679-4.4c4.729-4.779,11.384-2.7,11.856-7.772a9.648,9.648,0,0,0-.228-2.538c-1.041-1.122-1.268-2.94-1.678-4.814-1.321-.268-1.685-2.673-1.594-3.837.079-1.005.6-1.072.741-1.072a.284.284,0,0,1,.033,0s-.774-3.48-.273-5.532c.7-2.863,4.913-4.461,7.691-4.461a5.9,5.9,0,0,1,5.972,4.461c.5,2.052-.273,5.532-.273,5.532a.284.284,0,0,1,.033,0c.138,0,.662.067.741,1.072.091,1.164-.273,3.569-1.594,3.837-.41,1.874-.324,3.182-1.678,4.814a9.648,9.648,0,0,0-.228,2.538c.469,5.049,7.2,3.075,11.845,7.772,2.5,2.531,2.679,4.4,2.679,4.4Z" transform="translate(-3.549 -11)"/>
        </g>
      </g>
    </Svg>
  );
});
