import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import styled, { keyframes, ThemeContext } from "styled-components";
import { IconDirectionLeft, Tab, Tag } from "../components";
import { ImageBackground } from "../components/image-background/image-background";
import { Loader } from "../components/loader";
import { Headline3, Headline4 } from "../components/typography";
import TextComponent from "../components/typography/text/text";
import { GetCloudinaryImage } from "../helpers/getCloudinaryImages";
import { EnumHolidayType, IHoliday } from "../models";
import { IAppState } from "../store/app-state";
import { fetchHoliday } from "../store/holiday";
import { HolidayOverview } from "../components/holidays/overview";
import { BookingFooter } from "../components/holidays/holiday-footer";
import { DialogBox, DialogSize } from "../components/modal-dialog";
import { HolidayImportantInformation } from "../components/holidays/holiday-important-info-popup";
import { HolidayAbout } from "../components/holidays/holiday-about";
import { HolidayInvoice } from "../components/holidays/holiday-invoice";
import { HolidayItinerary } from "../components/holidays/holiday-itinerary";
import Button, { ButtonSize, ButtonType } from "../components/button";
import { getDateDiff } from "../helpers/date-helper";
import { selectTab } from "../store/session";
import { useCallback } from "react";
import { acceptTerms } from "../store/payment";
import { openInNewTab } from "../helpers/openInNewTab";
import routes from "../routing/routes";
import { IAppUser } from "../models";
import { SDPImageBackground } from "../components/image-background/image-sdp-background";

export const BookingPage = () => {
  const appUser: IAppUser | null = useSelector(
    (state: IAppState) => state.session.appUser
  );
  const { bookingId } = useParams<{ bookingId: string }>();
  const holiday: IHoliday | null = useSelector(
    (state: IAppState) => state.holidays.holiday
  );
  const isProcessing: boolean = useSelector(
    (state: IAppState) => state.holidays.isProcessing
  );
  const tabsSelected: any = useSelector(
    (state: IAppState) => state.session.tabs
  );
  const tabSelected = tabsSelected.booking;
  const tabIdx = tabSelected ? tabSelected : 0;
  const isScottDunnUser: boolean = useSelector(
    (state: IAppState) => state.session.appUser?.isScottDunnUser || false
  );
  const emailAddress: string = useSelector((state: IAppState) =>
    state.contact && state.contact.contact && state.contact.contact.primaryEmail
      ? state.contact.contact.primaryEmail.title
      : ""
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { colors } = React.useContext(ThemeContext);
  const tabs = ["Overview", "Itinerary", "Invoice", "About", "Notes"];

  const areAnyTermsAndConditionsNotAgreed = useCallback((): boolean => {
    if (holiday) {
      return (
        holiday?.termsAndConditions?.filter(
          (t) => t.isMandatory && !t.accepted && !t.dateOfConfirmation
        ).length !== 0
      );
    }
    return false;
  }, [holiday]);

  React.useEffect(() => {
    if (holiday && holiday.termsAndConditions) {
      if (areAnyTermsAndConditionsNotAgreed()) {
        setShowTAndCs(true);
      }
    }
  }, [holiday, areAnyTermsAndConditionsNotAgreed]);

  const [showTAndCs, setShowTAndCs] = React.useState<boolean>(false);

  const onModalClose = () => {
    setShowTAndCs(false);
    history.push(routes.bookings());
  };

  React.useEffect(() => {
    dispatch(fetchHoliday(Number(bookingId)));
  }, [dispatch, bookingId]);

  const onBack = () => {
    history.goBack();
  };

  const setTab = React.useCallback(
    (tab: number) => {
      dispatch(selectTab("booking", tab));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (history.action !== "POP") {
      setTab(0);
    }
  }, [setTab, history]);

  const currency =
    holiday && holiday.invoice && holiday.invoice.currency
      ? holiday.invoice.currency.currency
      : "";

  const daysToTravel = (): string => {
    if (holiday === undefined || holiday === null) return "";
    const days = getDateDiff(holiday.startDate, "DD/MM/YYYY");
    return days > 0 ? days.toString() + " days to go" : "";
  };

  const saveTerms = (bookId: number, terms: Array<number>) => {
    dispatch(acceptTerms(bookId, terms, emailAddress));
  };

  const onDownload = () => {
    if (holiday && holiday.publishingUrl) {
      openInNewTab(holiday.publishingUrl);
    }
  };

  return (
    <PageWrapper>
      {isProcessing && <Loader fullScreen={true} />}
      {holiday ? (
        <>
          <ImageBackground
            url={
              holiday.primaryCountryImageId
                ? GetCloudinaryImage(holiday.primaryCountryImageId, true)
                : "/images/backgroundImageLogin.jpg"
            }
          />
          <ContentLayout>
            <Icon onClick={onBack}>
              <IconDirectionLeft color={colors.white} size={30} />
            </Icon>
            <StyledHeadline3>{holiday.bookName}</StyledHeadline3>
            <StyledTextComponent>{holiday.primaryCountry}</StyledTextComponent>
            <div>
              <StyledTag label={`${holiday.displayStatus}`} />{" "}
            </div>
            <StyledHeadline4>{daysToTravel()}</StyledHeadline4>
            <StyledButton
              text="Download"
              download
              href={holiday.publishingUrl}
              onClick={onDownload}
              target="_blank"
              type={ButtonType.ALTERNATIVE_DARK}
              size={ButtonSize.NORMAL}
              rightIcon="IconDownload"
            />
          </ContentLayout>
          {/* <ImportantInfoWrapper>
                        <StyledWarningIcon size={30} />
                        <ImportantInfoTextWrapper>
                            <ImportantInfoText>Important Information</ImportantInfoText>
                            <HolidayDetailsWrapper>
                                <HolidayDetailText>Your holiday details are now available for download.  </HolidayDetailText>
                                <StyledLink onClick={toggle}>Read Message</StyledLink>
                            </HolidayDetailsWrapper>
                        </ImportantInfoTextWrapper>
                    </ImportantInfoWrapper> */}
          <ContentContainer>
            <TabContainer>
              <StyledTab
                initialIndex={tabIdx}
                elements={tabs}
                color={colors.black}
                size={12}
                onChange={(idx: number) => setTab(idx)}
              />
            </TabContainer>
            {tabs[tabIdx] === "Overview" && (
              <SlideInContainer>
                <HolidayOverview holiday={holiday} />
              </SlideInContainer>
            )}
            {tabs[tabIdx] === "Itinerary" && (
              <SlideInContainer>
                <HolidayItinerary holiday={holiday} />
              </SlideInContainer>
            )}
            {tabs[tabIdx] === "Invoice" && (
              <SlideInContainer>
                <HolidayInvoice
                  holidayType={EnumHolidayType.BOOK}
                  invoice={holiday.invoice}
                  quoteExpiryDate={holiday.quoteExpiryDate}
                />
              </SlideInContainer>
            )}
            {tabs[tabIdx] === "About" && (
              <SlideInContainer>
                <HolidayAbout holiday={holiday} />
              </SlideInContainer>
            )}
            {tabs[tabIdx] === "Notes" && (
              <SlideInContainer>
                <NotesWrapper
                  dangerouslySetInnerHTML={{ __html: holiday.notes }}
                />
              </SlideInContainer>
            )}
          </ContentContainer>
          <BookingFooter
            holiday={holiday}
            bookId={holiday.bookId}
            currency={currency}
            total={holiday.invoice.invoiceTotal}
            party={holiday.adults + holiday.child + holiday.infant}
            nights={holiday.nights}
            travelDate={holiday.startDate}
          />
        </>
      ) : appUser?.isSDP ? (
        <SDPImageBackground url="/images/SDPBackgroundImagePattern.png" />
      ) : (
        <ImageBackground url="/images/backgroundImagePattern.gif" />
      )}

      {holiday && (
        <DialogBox
          isOpen={showTAndCs}
          hide={onModalClose}
          size={DialogSize.NORMAL}
        >
          <HolidayImportantInformation
            isScottDunnUser={isScottDunnUser}
            onModalClose={() => setShowTAndCs(false)}
            holiday={holiday}
            onSave={saveTerms}
            onClose={() => setShowTAndCs(false)}
          />
        </DialogBox>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const ContentLayout = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: calc(100% - 10px);
  padding-left: 5px;
  & > * {
    margin-bottom: 10px;
  }
  margin: auto;
  position: relative;
  margin-top: 20px;
  margin-bottom: 13px;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 30px);
    max-width: inherit;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 700px;
    max-width: inherit;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 800px;
  }
`;

// const ImportantInfoWrapper = styled.div`
// display: flex;
// background-color: ${({ theme }) => theme.colors.accentLight};
// margin: 0 auto;
// height: 60px;
// @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
//     max-width: inherit;
// }
// @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
//     width: 700px;
//     max-width: inherit;
// }
// @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
//     width: 800px;
// }
// `;

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  margin: 0 auto;
  min-height: 800px;
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;

  margin-bottom: 100px;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: inherit;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 700px;
    max-width: inherit;
    padding-left: 20px;
    margin-bottom: 100px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 800px;
  }
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 130px;
`;

const TabContainer = styled.div`
  top: 20px;
  bottom: 50px;
  max-width: 400px;
  overflow-x: auto;
`;

const inAnimation = keyframes`
    0% {
      right: 1200px;
    }
    100% {
      right: 0px;  
    }
`;

const SlideInContainer = styled.div`
  top: 0px;
  right: 1200px;
  animation: ${inAnimation} 1200ms ease-in-out;
`;

const Icon = styled.div`
  margin-left: -3px;
  cursor: pointer;
`;

// const ImportantInfoTextWrapper = styled.div`
// display: flex;
// flex-flow: column;
// `;
// const ImportantInfoText = styled.div`
// font-size : 14px;
// font-weight: 500;
// padding: 10px 2px 0px;
// display: flex;
// `;

// const HolidayDetailText = styled.div`
// font-size : 12px;
// display: flex;
// margin-top: 1px;
// `;

// const HolidayDetailsWrapper = styled.div`
// display: flex;
// `;

// const StyledLink = styled(LinkButton)`
// color: ${({ theme }) => theme.colors.body};
// text-decoration: underline;
// font-size : 11px;
// display: flex;`;

// const StyledWarningIcon = styled(IconWarning)`
// display: flex;
// margin: 12px;
// `;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 20px;
  width: 60%;
`;

const StyledHeadline4 = styled(Headline4)`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 10px;
  top: 20px;
`;

const StyledTextComponent = styled(TextComponent)`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 15px;
`;

const StyledTag = styled(Tag)`
  color: ${({ theme }) => theme.colors.white};
  display: inline;
  width: auto;
  margin-top: 10px;
  letter-spacing: 0.07em;
  padding-top: 2px;
  height: 30px;
`;

const StyledTab = styled(Tab)`
  div > li {
    color: black;
  }
`;

const NotesWrapper = styled.div`
  padding: 20px 20px 20px 20px;
  text-align: justify;
  text-justify: inter-character;
  font-size: 14px;
`;
