import React,{ memo } from "react";
import styled from "styled-components";
import { ErrorLabel, LabelText } from "..";
import Select from "react-select"
import defaultTheme from "../../theme/defaultTheme";
import { ILookUp } from "../../models";


export interface ISelectDropdown {
    label?: string;
    placeholder:string;
    options: ILookUp[];
    isClearable?: boolean;
    defaultValue?: ILookUp | null;
    isSearchable?: boolean;
    errorMessage?: string;
    isValid?: boolean;
    onChange: (item: ILookUp | null) => void;
    className?:any;
    disabled?: boolean;
    labelContainerStyle?:any;
}


export const SelectDropdown = memo<ISelectDropdown>(({label,defaultValue,options,onChange,
    isClearable,isSearchable,errorMessage,isValid,placeholder,className, disabled, labelContainerStyle}) => {   
    
    const customStyles = {
        option: (provided: any, state: { isSelected: any; }) => ({
          ...provided,         
          color:'#00000', //defaultTheme.colors.black,
          padding: 15,
          backgroundColor: state.isSelected ? defaultTheme.colors.labelLight: defaultTheme.colors.white
        }),
        control: (provided: any) => ({            
            ...provided,            
            backgroundColor:defaultTheme.colors.labelLight,
            borderRadius:'0px',
            padding:'5px',
            borderColor:defaultTheme.colors.labelLight,
            boxShadow: isValid ? '0 2px 0 #00000029':'0px',
            border: isValid ? '0px': '2px solid rgb(196 100 100)',    
            fontFamily: 'Mallory',       
          }),
      }

    return (
        <Container className={className}  >
            <LabelContainer labelContainerStyle={labelContainerStyle}>{label && <LabelText as="label" isValid={isValid}>{label}</LabelText>}</LabelContainer>
            {errorMessage && <Error message={errorMessage} visible={!isValid} />}
            <Select                             
                options={options}                
                isClearable={isClearable}
                isSearchable={isSearchable}       
                defaultValue={defaultValue}
                onChange={onChange}
                styles={customStyles}
                placeholder={placeholder}                  
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id.toString()}
                isDisabled = {disabled || false}
                />
        </Container>
    );
}
);

export default SelectDropdown;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

const LabelContainer = styled.div<any>`
  margin: 20px 0px 10px;
  margin-top: ${props => props.labelContainerStyle && props.labelContainerStyle.marginTop ? props.labelContainerStyle.marginTop : '20px'};
`;

const Error = styled(ErrorLabel)`
top: 5px;
max-width:100%;
`;