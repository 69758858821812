import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PartyDetailReadOnlyView } from '.';
import { ButtonType } from '..';
import { IAppState } from '../../store/app-state';
import { deleteFamilyMember } from '../../store/contact/contact-actions';
import Button from '../button';
import { PartyDetailEdit } from './party-detail-edit';

export interface IPartyDetailProp {
    // familyId: number;
    familyId: string;
    // contactId: number;
    contactId: string;
    isMain: boolean;
    closeForm(): void;
    isNewParty :boolean;
    assignedToPartyId: number;
}

export const PartyDetailView = (props: IPartyDetailProp) => {

    const [isEditMode, setEditMode] = React.useState<boolean>(props.isNewParty);
    const isProcessing: boolean = useSelector((state: IAppState) => state.contact.isProcessing);
    const displayDialogBox: boolean = useSelector((state: IAppState) => state.contact.showDialogBox);
    const dispatch = useDispatch();

    const deleteFamily = () =>{
        dispatch(deleteFamilyMember(props.familyId,props.contactId));
        props.closeForm();
    }

    useEffect(() => {
        if(!displayDialogBox)
        {
            props.closeForm();
        }
    }, [displayDialogBox,props])

    return (
        <React.Fragment>
            <ContentWrapper>
                {isEditMode === false && <PartyDetailReadOnlyView familyId={props.familyId} />}
                {isEditMode === true && <PartyDetailEdit isNewParty={props.familyId === '0' || props.familyId === ''} contactId={props.contactId} familyId={props.familyId} assignedToPartyId={props.assignedToPartyId} />}
            </ContentWrapper>
            <FooterWrapper>
                <ButtonWrapper isMain = {props.isMain} isEditMode={isEditMode}>
                    {props.isMain !== true && isEditMode !== true  && <ButtonDelete  type={ButtonType.ALTERNATIVE} text= "Delete" onClick={() => deleteFamily()} />}
                    {isEditMode !== true ?
                    <ButtonEdit   type={ButtonType.MAIN} text="Edit details" onClick={() => setEditMode(true)} />
                    :
                    <>
                    <ButtonEdit  
                        type={ButtonType.MAIN} 
                        form="new-party" 
                        text="Save details"
                        processing={isProcessing}
                         />
                    </>                   
                    }
                </ButtonWrapper>
            </FooterWrapper>
        </React.Fragment>
    );
}

const ButtonDelete = styled(Button)`
width:300px;
margin-right:15px;

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:30%;
}
`;

const ButtonEdit= styled(Button)`
width:450px;

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:30%;
}
`;

const FooterWrapper = styled.div`
background-color:${({ theme }) => theme.colors.labelLight};
display:flex;
justify-content: flex-end;
bottom: 0;
width: 100%;
margin-top: auto;
bottom: 0;
`;
interface IButtonWrapperProps{
    isMain : boolean;
    isEditMode : boolean;
}

const ButtonWrapper = styled.div<IButtonWrapperProps>`
padding:35px 35px; 35px; 0px;
width: 100%; 
flex-flow: row nowrap;
display: flex;
justify-content: ${({ isEditMode }) => (isEditMode !== true ? "space-between":"flex-end")};
flex-direction: ${({ isMain }) => (isMain === true ? "row-reverse" : "row")};
`;

const ContentWrapper = styled.div`
display: flex;
flex-flow: column nowrap;
padding:0 45px;
height: calc(100vh - 200px);
overflow-y: scroll;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: calc(100vh - 300px);
}
`;
