import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconOnFoot = memo<IconProps>((props) => {
  return (
    <Svg width="50.7" height="50.75" viewBox="0 0 50.7 50.75">
      <path fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.5px" d="M31.2,18.7c1.87-1.68,6.16-2.54,8.73-.65,2.04,1.5,2.98,5.06,2.91,8.51-.05,2.44-.95,5.12-1.72,7.33-.84,2.37-1.79,4.57-3.34,5.6-2.8-.69-5.92-1.04-8.62-1.83.66-3.48-1.25-4.84-1.83-7.33-.91-3.89,1.45-9.45,3.88-11.63Z"/>
      <path fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.5px" d="M37.02,42.29c-.42,4.05-2.08,7.86-6.25,8.08-2.59.14-4.78-1.59-4.96-4.09-.17-2.43,1.3-4.55,2.59-6.03,2.93.63,5.81,1.3,8.62,2.05Z"/>
      <path fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.5px" d="M12.68,25.78c2.46-.72,5.84-1.08,8.16-1.68,1.33-.34,2.81,3.53,2.9,4.88.27,3.99-3.48,6.4-7.11,4.53-2.56-1.32-3.66-4.12-3.96-7.73Z"/>
      <path fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.5px" d="M9.16,17.74C7.26,12.13,6.46.91,14.06.39c1.37-.09,3.04.25,4.06.83,3.16,1.8,5.39,7.2,5.08,11.56-.25,3.48-2.9,4.43-2.45,8.73-2.01.4-4.18.65-6.41,1-.72.11-1.58.43-2.06.34-1.52-.3-2.63-3.69-3.11-5.1Z"/>
    </Svg>
  );
});
