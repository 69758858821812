import * as React from 'react';
import styled from 'styled-components';
import { ImageBackground } from '../components/image-background/image-background';
import PrivacyStatement from '../components/login/privacy-statement';

export const PrivacyStatementPage = () => {

    return (
        <PageWrapper>
            <ImageBackground url="/images/backgroundImagePattern.gif"/>
            <PrivacyWrapper>
                <PrivacyStatement/>
            </PrivacyWrapper>
        </PageWrapper>
    );
}

const PageWrapper = styled.div`
display: flex;
flex-flow: column nowrap;
`;

const PrivacyWrapper = styled.div`
color: ${({ theme }) => theme.colors.white};
margin: 20px;
`;

