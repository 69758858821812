import { IAppAction } from '../app-action';
import { getInTouchActions } from '.';
import { getDefaultGetInTouchState, IGetInTouchState } from './get-in-touch-state';

const handlers = {    
    [getInTouchActions.SUBMIT_ENQUIRY]: (state: IGetInTouchState, payload: any): IGetInTouchState => {
        return {
            ...state,
            isProcessing: true
        };
    },
    [getInTouchActions.SUBMIT_ENQUIRY_SUCCESS]: (state: IGetInTouchState, payload: any): IGetInTouchState => {
        return {
            ...state,
            isProcessing: false,
            enquirySuccess: true
        };
    },
    [getInTouchActions.SUBMIT_ENQUIRY_ERROR]: (state: IGetInTouchState, payload: any): IGetInTouchState => {
        return {
            ...state,
            isProcessing: false
        };
    },
    [getInTouchActions.SUBMIT_REQUEST]: (state: IGetInTouchState, payload: any): IGetInTouchState => {
        return {
            ...state,
            isProcessing: true
        };
    },
    [getInTouchActions.SUBMIT_REQUEST_SUCCESS]: (state: IGetInTouchState, payload: any): IGetInTouchState => {
        return {
            ...state,
            isProcessing: false,
            enquirySuccess: true
        };
    },
    [getInTouchActions.SUBMIT_REQUEST_ERROR]: (state: IGetInTouchState, payload: any): IGetInTouchState => {
        return {
            ...state,
            isProcessing: false,
            enquirySuccess: false
        };
    },
    [getInTouchActions.RESET_SUCCESS_MESSAGE]: (state: IGetInTouchState, payload: any): IGetInTouchState => {
        return {
            ...state,           
            enquirySuccess: false
        };
    },
};

const getInTouchReducer = (state: IGetInTouchState = getDefaultGetInTouchState(), action: IAppAction) => {
    return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default getInTouchReducer;