import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconArrowLeft = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props}>
      <g transform="translate(0 50) rotate(-90)">
        <rect fill="none" width="50" height="50" />
        <g transform="translate(9.307 15.486)">
          <path
            fill={props.color}
            d="M27.606,19.822,11.913,36.341l1.7,1.614L27.606,23.225,41.6,37.956l1.7-1.614Z"
            transform="translate(-11.913 -19.822)"
          />
        </g>
      </g>
    </Svg>
  );
});
