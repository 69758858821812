import React, { memo } from "react";
import styled from "styled-components";
import Body1 from "../typography/body";

export interface LinkButtonProps {
  children: string;
  className?: string;
  onClick: () => void;
}

export const LinkButton = memo<LinkButtonProps>(
  ({ children, onClick, className }): JSX.Element => {
    return (
      <StyledLink className={className} onClick={onClick}>
        <Body1 className={className} >{children}</Body1>
      </StyledLink>
    );
  }
);

const StyledLink = styled.button`
  color: ${({ theme }) => theme.colors.labelDark};
  background: ${({ theme }) => theme.colors.transparent};
  text-decoration: underline;
  border: none;
  outline: none;
  cursor: pointer;
`;
