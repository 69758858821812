import React, { memo } from "react";
import styled from "styled-components";
import { ErrorLabel, LabelText } from "..";
import { IContactIdLookUp} from "../../models";
import { Multiselect } from 'multiselect-react-dropdown';


export interface IMultiSelectDropdown {
    label?: string;
    placeholder: string;
    // options: ILookUp[];
    options: IContactIdLookUp[];
    isClearable?: boolean;
    // defaultValue?: ILookUp | null;
    defaultValue?: IContactIdLookUp | null;
    isSearchable?: boolean;
    errorMessage?: string;
    isValid?: boolean;
    // onChange?: (item: ILookUp[] | null) => void;
    onChange?: (item: IContactIdLookUp[] | null) => void;
    className?: any;
    disabled?: boolean;
    labelContainerStyle?: any;
    onSelect?: (selectedList: any | null, selectedkey: any | null) => void;
    onRemove?: (selectedList: any | null, selectedkey: any | null) => void;
}

export const MultiSelectDropdown = memo<IMultiSelectDropdown>(({ label, defaultValue, options, onChange,
    isClearable, isSearchable, errorMessage, isValid, placeholder, className, disabled, labelContainerStyle, onSelect, onRemove, }) => {

    return (
        <Container className={className}  >
            <LabelContainer labelContainerStyle={labelContainerStyle}>{label && <LabelText as="label" isValid={isValid}>{label}</LabelText>}</LabelContainer>
            {errorMessage && <Error message={errorMessage} visible={!isValid} />}
            
            <Multiselect
                options={options} // Options to display in the dropdown
                onSelect={onSelect}
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="title" // Property name to display in the dropdown options
                showCheckbox
                style={{ searchBox: { minHeight: "50px", paddingTop: "10px", paddingLeft: "10px" }, option: { fontFamily: "Mallory", fontSize: "13px" }, chips: { fontFamily: "Mallory", fontSize: "13px" } }}
                placeholder={placeholder}
                hidePlaceholder={true}
                closeOnSelect={false}
                avoidHighlightFirstOption={true}
                showArrow={true}
            />
        </Container>
    );
}
);

export default MultiSelectDropdown;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin:10px
`;

const LabelContainer = styled.div<any>`
  margin: 20px 0px 10px;
  margin-top: ${props => props.labelContainerStyle && props.labelContainerStyle.marginTop ? props.labelContainerStyle.marginTop : '20px'};
`;

const Error = styled(ErrorLabel)`
top: 5px;
max-width:100%;
`;