import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ITermsAndCondition } from "../../../models/terms-and-condition";
import { Checkbox, CheckboxType } from "../../checkbox/checkbox";
import{ HolidayPaymentTermConditionMessage } from "../../holidays/payment-term-condition-message";

export interface ITermsAndConditions {   
    termsAndConditions:ITermsAndCondition[];
    onClick:(val:ITermsAndCondition) => void;
    displayDate: boolean;
    businessUnitId?: number;
}

export const TermsAndConditions = ({termsAndConditions, onClick, displayDate: holidayBooked, businessUnitId}: ITermsAndConditions) => {
 
    const handleSelect = (termId:any, e: any) => {  
        if(holidayBooked) return;    
        const updatedTc: ITermsAndCondition = termsAndConditions.filter(t => t.termId === termId)[0];
        updatedTc.accepted = e;
        onClick(updatedTc);      
    }

    const getAgreementDate = () => {
        var acceptedTerms = termsAndConditions.filter(x => x.dateOfConfirmation != null);
        if(acceptedTerms.length > 0){
            const date = moment(acceptedTerms[0].dateOfConfirmation);
            return `on ${date.format("ddd, DD MMM YYYY")}`;
        }
        return null;
    }
    
    return (
        <Container>
            {termsAndConditions && Array.from(termsAndConditions).sort((a,b)=>a.orderId - b.orderId).map(function (term, index) {                
                return (
                    <TermWrapper key={index}>                     
                            <StyledCheckbox type={CheckboxType.SECONDARY} isChecked={term.accepted || term.dateOfConfirmation !== null}  
                            onClick={(e) => handleSelect(term.termId, e)}/>
                            <Description onClick={() => handleSelect(term.termId, !term.accepted)} dangerouslySetInnerHTML={{ __html:term.description}} />              
                    </TermWrapper>
                );
            })
            }
            {holidayBooked && <PaddedDescription>You agreed to these terms {getAgreementDate()}.</PaddedDescription>}
            {businessUnitId && <HolidayPaymentTermConditionMessage businessUnitId={businessUnitId}/>}
        </Container>
    );
}


const Container = styled.div`
`;

const TermWrapper = styled.div`
display:flex;
flex-direction:row nowrap;
border-bottom: 2px solid ${({ theme: { colors } }) => colors.labelLight};
padding-bottom:20px;
padding-top:15px;
&:last-child{
    border-bottom:none;
}
`;

const StyledCheckbox = styled(Checkbox)`
margin-right:50px;
top: 5px;
`

const Description = styled.span`
font-size: 14px;
/* @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-left: 0px;
} */
`;

const PaddedDescription = styled.div`
font-size: 14px;
margin: 10px;
`;
