import { IAppAction } from '../app-action';
import { IHolidayState, getDefaultHolidayState } from './holiday-state';
import { holidayStateActions } from './holiday-actions';
import { IBookParty, IHoliday, IHolidaySummary, IItineraryGroup } from '../../models';

const handlers = {
  [holidayStateActions.FETCH_HOLIDAYS]: (state: IHolidayState, payload: number): IHolidayState => {
    return {
      ...state,
      holidays: [],
      isProcessing: true
    };
  },

  [holidayStateActions.FETCH_HOLIDAYS_SUCCESS]: (state: IHolidayState, payload: IHolidaySummary[]): IHolidayState => {
    return {
      ...state,
      holidays: payload,
      isProcessing: false
    };
  },

  [holidayStateActions.FETCH_HOLIDAYS_ERROR]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
    isProcessing: false
  }),

  
  [holidayStateActions.FETCH_HOLIDAY]: (state: IHolidayState, payload: number): IHolidayState => {
    return {
      ...state,
      holiday: null,
      isProcessing: true
    };
  },

  [holidayStateActions.FETCH_HOLIDAY_SUCCESS]: (state: IHolidayState, payload: IHoliday): IHolidayState => {
    return {
      ...state,
      holiday: payload,
      isProcessing: false
    };
  },

  [holidayStateActions.FETCH_HOLIDAY_ERROR]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
    isProcessing: false
  }),

  [holidayStateActions.FETCH_ITINERARY_SUCCESS]: (state: IHolidayState, payload: IItineraryGroup[]): IHolidayState => {
    const holiday = state.holiday;
    if(holiday){
      holiday.itineraryGroups = payload;
    }
    return {
      ...state,
      holiday: holiday,
      isProcessing: false
    };
  },
  
  [holidayStateActions.FETCH_BOOK_PARTIES_SUCCESS]: (state: IHolidayState, payload: IBookParty[]): IHolidayState => {        
    let holidayObj = state.holiday;    
    if (holidayObj) {
      holidayObj.bookParty = [...payload];
    }       
    return {
      ...state,
      holiday: holidayObj
    };
  },
  [holidayStateActions.UPDATE_BOOK_PARTY]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
    isProcessing: true
  }),
  [holidayStateActions.UPDATE_BOOK_PARTY_ERROR]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
    isProcessing: false
  }),
  [holidayStateActions.UPDATE_BOOK_PARTY_SUCCESS]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
    isProcessing: false
  }),

  [holidayStateActions.UPDATE_BOOK_GDPR]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
  }),
  [holidayStateActions.UPDATE_BOOK_GDPR_ERROR]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
  }),
  [holidayStateActions.UPDATE_BOOK_GDPR_SUCCESS]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
  }),

  [holidayStateActions.RETRIEVE_HOLIDAY]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
  }),
  [holidayStateActions.RETRIEVE_HOLIDAY_ERROR]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
    retriveHoliday: {
      errorCode: payload.error.response.status,
      errorMessage: payload.message,
      isError: true,
    }
  }),
  [holidayStateActions.RETRIEVE_HOLIDAY_SUCCESS]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
    retriveHoliday: null,
  }),

  // [holidayStateActions.MAKE_HOLIDAY_PAYMENT]: (state: IHolidayState, payload: any): IHolidayState => ({
  //   ...state,
  //   isProcessing: true,
  //   paymentSuccess: false,
  //   paymentError: null
  // }),
  // [holidayStateActions.MAKE_HOLIDAY_PAYMENT_SUCCESS]: (state: IHolidayState, payload: any): IHolidayState => ({
  //   ...state,
  //   isProcessing: false,
  //   paymentSuccess: true
  // }),
  // [holidayStateActions.MAKE_HOLIDAY_PAYMENT_ERROR]: (state: IHolidayState, payload: any): IHolidayState => ({
  //   ...state,
  //   isProcessing: false
  // }),
  [holidayStateActions.PAYMENT_ERROR]: (state: IHolidayState, payload: any): IHolidayState => ({
    ...state,
    paymentError: payload
  }),
  [holidayStateActions.PAYMENT_ERROR_CLEAR]: (state: IHolidayState): IHolidayState => ({
    ...state,
    paymentError: null
  }),
};

const holidayReducer = (state: IHolidayState = getDefaultHolidayState(), action: IAppAction) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action.payload) : state;
};

export default holidayReducer;
