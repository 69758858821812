import React, { Fragment, useState } from 'react'
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { ButtonType } from '..';
import { getCurrencySymbol } from '../../helpers/getCurrencySymbol';
import routes from '../../routing/routes';
import { routeChange } from '../../store/session';
import Button, { ButtonHeight } from '../button';
import { DialogBox, DialogSize } from '../modal-dialog';
import { PaymentView } from '../holiday-pay';
import { Body1, Body2 } from '../typography';
import { IHoliday } from '../../models/holiday';

export interface IFooterProp {
    isLive: boolean;
    holiday: IHoliday;
}

export const Footer = ({holiday,isLive}: IFooterProp) => {
    const dispatch = useDispatch();
    const [, setBookNow] = useState(false);
    const [ showBookNowDialog, toggleBookNow] = React.useState<boolean>(false);
    const invoiceCurrency = holiday && holiday.invoice && holiday.invoice.currency ? holiday.invoice.currency.currency : '';
    const currencySymbol = getCurrencySymbol(invoiceCurrency);    
    const onAmendClick =() => { 
        const type = isLive ? "Amend" : "Requote";
        dispatch(routeChange(routes.getInTouchHolidayRequest(type, holiday.bookId.toString())));
    };
    const onBookClick =() => { 
        setBookNow(true);
        toggleBookNow(!showBookNowDialog);
    };
    const handleBookNow = () =>{
        toggleBookNow(!showBookNowDialog);     
    }
    return (
        <Fragment>
            <FooterWrapper>
                <SummaryAmountWrapper>
                    <StyledTotal>{`Total: `}
                        <NumberFormat value={holiday.invoice && holiday.invoice.invoiceTotal} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={currencySymbol}/>
                    </StyledTotal>
                    <StyledDeposit>{`Deposit required: `}
                        <NumberFormat value={holiday.invoice && holiday.invoice.deposit} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={currencySymbol}/>
                    </StyledDeposit>
                </SummaryAmountWrapper>
                <ButtonWrapper>
                    <ButtonAmend type={ButtonType.ALTERNATIVE} height={ButtonHeight.NORMAL} text= {"Request " + (isLive?"Amendment":"Requote")} onClick={onAmendClick} />
                    {isLive && <ButtonBook type={ButtonType.MAIN} height={ButtonHeight.NORMAL} text="Book this Holiday" onClick={onBookClick} />}
                </ButtonWrapper>
            </FooterWrapper>
            <DialogBox isOpen={showBookNowDialog ?? false} hide={handleBookNow} size={DialogSize.NORMAL} >
                <PaymentView holiday={holiday} hide={handleBookNow}/>
            </DialogBox>
        </Fragment>
    );
}

const StyledTotal = styled(Body1)`
font-weight: 500;
font-size: 13px;
letter-spacing: 0.7px;
`;

const StyledDeposit = styled(Body2)`
font-size: 12px;
padding-top: -10px;
letter-spacing: -1px;
color:${({ theme }) => theme.colors.primaryDark};
`;

const SummaryAmountWrapper = styled.div`
padding: 20px 20px 5px 15px;
margin-bottom: -7px;
flex-flow: column nowrap;
display: flex;
justify-content: flex-start;
width: 100%;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 30%;
    padding: 10px 20px 5px 40px;
    margin-bottom: inherit;
}
`;

const ButtonWrapper = styled.div`
padding:8px;
flex-flow: row nowrap;
display: flex;
justify-content: space-between;
width: 100%;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 70%;
    justify-content: flex-end;
}
`;

const ButtonAmend = styled(Button)`
width:300px;
margin-right:15px;
span {
    
    letter-spacing: 1px;
}

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:40%;
    span {
        
    }
}
`;

const ButtonBook= styled(Button)`
width:450px;
span {
    
}

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:40%;
    span {
        
    }
}
`;

const inAnimation = keyframes`
    0% {
      bottom: -300px;
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    100% {
      bottom: 0px;  
    }
`;

const FooterWrapper = styled.div`
background-color:${({ theme }) => theme.colors.labelLight};
border-top: solid 1px ${({ theme }) => theme.colors.labelDark};
display:flex;
justify-content: flex-end;
position: fixed;
bottom: 0;
width: 100%;
flex-flow: column nowrap;
height: 100px;
animation: ${inAnimation} 1200ms ease-in-out;

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row nowrap;
    height: 55px;
    width: 700px;
    left: 50%;
    transform: translateX(-50%);
}
@media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    left: calc(50% + 125px);
    transform: translateX(-50%);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 800px;
  }
`;