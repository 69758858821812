import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconEye = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 26 26" {...props}>
      <g transform="translate(-0.216)">
        <rect fill="none" width="26" height="26" transform="translate(0.216)" />
        <path
          fill={props.color}
          d="M11.662,15.9a12.437,12.437,0,0,1-7.131-2.231A12.549,12.549,0,0,1,0,7.951a12.53,12.53,0,0,1,23.324,0,12.549,12.549,0,0,1-4.531,5.722A12.437,12.437,0,0,1,11.662,15.9Zm0-13.253a5.3,5.3,0,1,0,5.3,5.3A5.307,5.307,0,0,0,11.662,2.651Zm0,8.482a3.181,3.181,0,1,1,3.181-3.181A3.184,3.184,0,0,1,11.662,11.133Z"
          transform="translate(1.193 4.771)"
        />
      </g>
    </Svg>
  );
});
