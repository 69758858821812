import React, { memo, useCallback, useEffect } from "react";
import styled from "styled-components";
import Body2 from "../typography/body";

const hasUpperCase = (str: string): boolean => {
  return /[A-Z]/.test(str);
};

const hasNumber = (str: string): boolean => {
  return /[0-9]/.test(str);
};

const isLongEnough = (str: string): boolean => {
  return str.length >= 8;
};

const validators = [
  {
    id: 1,
    text: "8 characters minimum",
    isValid: (str: string): boolean => isLongEnough(str),
  },
  {
    id: 2,
    text: "One uppercase character",
    isValid: (str: string): boolean => hasUpperCase(str),
  },
  {
    id: 3,
    text: "One number",
    isValid: (str: string): boolean => hasNumber(str),
  },
];

interface PasswordValidator {
  id: number;
  text: string;
  isValid: (str: string) => boolean;
}

export interface PasswordStrengthValidatorProps {
  password: string;
  onChange: (isValid: boolean) => void;
  className?:string;
}

export const PasswordStrengthValidator = memo<PasswordStrengthValidatorProps>(
  ({ password, onChange,className }): JSX.Element => {
    useEffect(() => {
      const isValid =
        hasUpperCase(password) && hasNumber(password) && isLongEnough(password);
      onChange(isValid);
    }, [password, onChange]);

    const renderItemsCallback = useCallback(
      (item: PasswordValidator) => (
        <ItemContainer key={item.id}>
          <StyledDot isValid={item.isValid(password)} />
          <StyledBody2>{item.text}</StyledBody2>
        </ItemContainer>
      ),
      [password]
    );
    return <Container className={className} >{validators.map(renderItemsCallback)}</Container>;
  }
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white}
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBody2 = styled(Body2)`
  font-size: 12px;
`;

interface StyledDotProps {
  isValid: boolean;
}

const StyledDot = styled.div<StyledDotProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ theme: { colors }, isValid }) =>
    isValid ? colors.primary : colors.accentLight};
  transition: background-color 300ms;
`;
