import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { IAppState } from '../app-state';
import { sendToast, ToastType } from '../../helpers/sendToast';
import { getInTouchActions, submitRequestSuccess, submitRequestError } from '.';

export const submitGetInTouchRequestEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, { api, endPointKeys }:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(getInTouchActions.SUBMIT_REQUEST),
        mergeMap(action => {
            var data = JSON.stringify(action.payload);
            return api.post(endPointKeys.api_travel, 'GetInTouch', data).pipe(
                map(result => result.data),
                mergeMap(() => {
                    return of(submitRequestSuccess(),  sendToast("Request Successful", ToastType.Success));
                }),
                catchError(error => {
                    const appError: IAppError = { error, message: "Failed to submit request" };
                    sendToast(appError.message, ToastType.Error);
                    return of(submitRequestError(appError));
                }))
        })
    );