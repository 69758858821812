import React from "react";
import { Link } from "react-router-dom";
import routes from "../routing/routes";
import styled from "styled-components";
import { logOut, toggleMobileMenu } from "../store/session";
import { useDispatch } from "react-redux";
import { Body2 } from "./typography";


export const SubMenu = () => {

    const dispatch = useDispatch();
    const closeMenu = () => {
        dispatch(toggleMobileMenu(false));
    }
    const logUserOut = () => {
        dispatch(logOut());
        dispatch(toggleMobileMenu(false));
    }
    return(
        <StyledNav>
            <StyledLink onClick={closeMenu} to={routes.about()}><StyledText>About</StyledText></StyledLink>
            <StyledLink onClick={closeMenu} to={routes.termsAndConditions()}><StyledText>Terms & Conditions</StyledText></StyledLink>
            <StyledLink onClick={closeMenu} to={routes.profile()}><StyledText>My Profile</StyledText></StyledLink>
            <StyledLink onClick={logUserOut} to={routes.home()}><StyledText>Log Out</StyledText></StyledLink>
        </StyledNav>
    )
}

const StyledNav = styled.nav`
position: absolute;
bottom: 1vh;
margin-left: 30px;
display: flex;
flex-flow: column nowrap;
`;

const StyledLink = styled(Link)`
list-style-type:none;
text-decoration: none;
color: ${({ theme }) => theme.colors.primary};
height: 7vh;
`;

const StyledText = styled(Body2)`
color: ${({ theme }) => theme.colors.primary};
vertical-align: middle;
`;