import { IAppAction } from "../app-action";
import { fetchApiKeysActions } from "./fetch-api-keys-actions";
const handlers = {
	[fetchApiKeysActions.FETCH_KEYS]: (state: any, payload: any) => {
		return {
			...state,
			isProcessing: true,
		};
	},
	[fetchApiKeysActions.FETCH_KEYS_SUCCESS]: (state: any, payload: any) => {
		return {
			...state,
			isProcessing: false,
			...payload,
		};
	},
};
const apiKeysReducer = (state: any = {}, action: IAppAction) => {
	console.log("action ", action);
	return handlers.hasOwnProperty(action.type)
		? handlers[action.type](state, action.payload)
		: [];
};
export default apiKeysReducer;
