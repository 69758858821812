import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonType, IconHeadset, IconCheck, Textarea } from '../components';
import Button from '../components/button';
import { GetInTouchSuccess, WebEnquiry } from '../components/get-in-touch';
import { ImageBackground } from '../components/image-background/image-background';
import { Loader } from '../components/loader';
import { SelectDropdown } from '../components/select-dropdown';
import { Headline3, Headline4, Headline5 } from '../components/typography';
import Label from '../components/typography/label';
import TextComponent from '../components/typography/text/text';
import { GetGetInTouchReasonDesc, GetInTouchOptions, getReasonByType } from '../helpers/datasource-helper';
import DictionaryReplace from '../helpers/string-helper';
import { IGetInTouch, ILookUp } from '../models';
import { IAppState } from '../store/app-state';
import { resetSuccessMessage, submitRequest } from '../store/get-in-touch';

export const GetInTouchPage = () => {
    const {type, bookingId} = useParams<{ type: string, bookingId: string }>();
    const displaySuccess: boolean = useSelector((state: IAppState) => state.getInTouch.enquirySuccess);
    const holidays = useSelector((state: IAppState) => state.holidays.holidays);
    const contact = useSelector((state: IAppState) => state.contact.contact);
    const isProcessing = useSelector((state: IAppState) => state.getInTouch.isProcessing);
    const options = GetInTouchOptions();
    const dispatch = useDispatch();
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
      
    React.useEffect(() => {
        dispatch(resetSuccessMessage());
    }, [dispatch]);

    const getSelectedType = React.useCallback( (contactR: string): ILookUp =>{
        const reason =  options.find(x=>x.title === contactR);
        return reason? reason : {id: 0, title: "Please choose.."};
    },[options]);

    const getReasonFromType = () => {
        if (type && type.length > 2) {
            const reason = getReasonByType(type).title;
            return getSelectedType(reason)
        }
        return {id: 0, title:"Please choose..."};
    }

    const getReasonDescriptionFromType = () => {
        if (type && type.length > 2) {
            const reason = getReasonByType(type).title;
            return GetGetInTouchReasonDesc(reason);
        }
        return "";
    }

    const [contactReasonLookup, setContactReasonLookup] = React.useState<ILookUp>(getReasonFromType());
    const [reasonDesc, setReasonDesc] = React.useState<string>(getReasonDescriptionFromType());
    const [query, setQuery] = React.useState<string>("");
    const [booking, setBooking] = React.useState<number>(bookingId ? Number(bookingId): 0);
    const businessUnitId = contact ? contact.businessUnitId : 1;

    let bookings: Array<ILookUp> = [];
    if (holidays) {
        bookings = [
            { id: 0, title: 'Please choose' },
            ...holidays.map((h) => ({ id: h.bookId, title: h.bookId + ' - ' + h.bookName }))
        ]
    }

    const updateContactReasonInfo = React.useCallback((newReason: string) => {
        const reasonLookup = getSelectedType(newReason)
        setContactReasonLookup(reasonLookup);
        const reasonDesc = GetGetInTouchReasonDesc(newReason);
        setReasonDesc(DictionaryReplace(reasonDesc,businessUnitId));
    },[businessUnitId, getSelectedType]);

    useEffect(() => {
        if (bookingId && Number(bookingId) > 0) {
            const holiday = holidays.find(h => h.bookId === Number(bookingId));
            if (holiday) {
                setBooking(holiday.bookId);
            }
        }
    }, [bookingId, holidays]);

    const getSelectedBooking = (): ILookUp | undefined=> {
        const holiday = holidays.find(x => x.bookId === booking);
        return holiday ? { id: holiday.bookId, title: booking + ' - ' +  holiday.bookName } : undefined;
    }

    const isWebEnquiry = () : boolean =>{
        return contactReasonLookup && contactReasonLookup.title === "Request new quote" ? true: false;
    }

    const isReasonSelected = (): boolean =>{
        return contactReasonLookup && contactReasonLookup.title !== "Please choose..." ? true: false;
    }


    const handleGetInTouchRequest = () => {
        const request: IGetInTouch = {
            emailAddress: contact.primaryEmail ? contact.primaryEmail.title : '',
            firstName: contact.firstName,
            lastName: contact.lastName,
            queryType: contactReasonLookup.title,
            query: query,
            bookId: booking
        };
        dispatch(submitRequest(request));
        updateContactReasonInfo("Please choose...");
    }

    function handleSave() {
        if (isWebEnquiry() === false) {
            handleGetInTouchRequest();
        }
        else {
            updateContactReasonInfo("Please choose...");
        }
        setBooking(0);
    }

    return (
        <React.Fragment>
            <ImageBackground url="/images/backgroundImageGetInTouch.jpg" />
            <PageWrapper>
                <StyledHeadline3>Get in Touch</StyledHeadline3>
                <PanelWrapper>
                {!isProcessing ?  <FormContainer isWebEnquiry={isReasonSelected() && isWebEnquiry() === true}>
                        {displaySuccess === true && <GetInTouchSuccess />}
                        {displaySuccess === false && <>
                            <StyledTextComponent>We have your contact details already so let’s get straight to it. How can we help? </StyledTextComponent>

                            <SelectDropdown
                                options={options}
                                defaultValue = {contactReasonLookup}
                                isClearable={false}
                                placeholder="Please choose..."
                                isSearchable={false}
                                errorMessage='Please select'
                                isValid={true}
                                label="Type of query" 
                                onChange={(e) => updateContactReasonInfo(e ? e.title : 'Please choose...')}
                            />
                           
                            {reasonDesc && reasonDesc.length > 1 && <StyledText>{reasonDesc}</StyledText>}

                            {isReasonSelected() && isWebEnquiry() === false &&
                                <SelectDropdown
                                    defaultValue={getSelectedBooking()}
                                    options={bookings}
                                    isClearable={false}
                                    placeholder="Please choose..."
                                    isSearchable={false}
                                    errorMessage='Please select'
                                    isValid={true}
                                    label="Booking reference (if applicable)"
                                    onChange={(e) => setBooking(e ? e.id : 0)}
                                />}
                                
                            {isReasonSelected() && isWebEnquiry() === false && <StyledTextArea
                                                                        label="Your Query"
                                                                        name="formName"
                                                                        onChange={e => setQuery(e)} />}

                            {isReasonSelected() && isWebEnquiry() === true && <WebEnquiry submitEnquiry={handleSave} />}

                            {isReasonSelected() && isWebEnquiry() === false && <Button type={ButtonType.MAIN} text="Send" onClick={handleSave} />}
                        </>}

                    </FormContainer>: <Loader />}

                    <ContactInfoContainer isWebEnquiry={isReasonSelected() && isWebEnquiry() === true}>
                        <Icon size={95} />
                        <StyledHeadline5>The best call you'll make today</StyledHeadline5>
                        <StyledHeadline4>020 3993 8860</StyledHeadline4>
                        <LabelText>Calls are recorded to help us help you better</LabelText>
                        <IconContainer><CheckIcon size={35} /><StyledTextWrapper>100% financial protection</StyledTextWrapper></IconContainer>
                        <IconContainer><CheckIcon size={35} /><StyledTextWrapper>24/7 emergency assistance</StyledTextWrapper></IconContainer>
                        <IconContainer><CheckIcon size={35} /><StyledTextWrapper>Firsthand knowledge</StyledTextWrapper></IconContainer>
                        <IconContainer><CheckIcon size={35} /><StyledTextWrapper>Renowned service</StyledTextWrapper></IconContainer>
                        <IconContainer><CheckIcon size={35} /><StyledTextWrapper>Award-winning tour operator</StyledTextWrapper></IconContainer>
                    </ContactInfoContainer>
                </PanelWrapper>
            </PageWrapper>
        </React.Fragment>
    );
}

const IconContainer = styled.span`
vertical-align: middle;
padding-left: 15%;
display:flex;
flex-direction: row;
margin:5px;
`;

const PageWrapper = styled.div`
display: flex;
width: 300px;
flex-flow: column nowrap;
margin: 0 auto;
@media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: calc(100% - 50px);
    max-width : 500px;
    margin:0 auto;
}
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: calc(100% - 80px);
    max-width : 800px;
    margin:0 auto;
}
`;

const PanelWrapper = styled.div`
display: flex;
flex-flow: column nowrap;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row nowrap;
}
`;

interface IFormTypeProps {
    isWebEnquiry: boolean;
}

const FormContainer = styled.div<IFormTypeProps>`
display: flex;
flex-flow: column nowrap;
width: 100%;
height: ${({ isWebEnquiry }) => (isWebEnquiry === true ? '1000px' : '700px')};
background-color: ${({ theme }) => theme.colors.white};
padding : 20px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 450px;
}
`;

const ContactInfoContainer = styled.div<IFormTypeProps>`
display: flex;
flex-flow: column nowrap;
height:600px;
background-color: ${({ theme }) => theme.colors.labelLight};
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: ${({ isWebEnquiry }) => (isWebEnquiry === true ? '1000px' : '700px')};
}
`;

const StyledHeadline3 = styled(Headline3)`
color: ${({ theme }) => theme.colors.white};
font-weight: 500;
`;

const StyledHeadline4 = styled(Headline4)`
color: ${({ theme }) => theme.colors.primaryDark};
margin: 10px;
text-align: center;
`;


const StyledHeadline5 = styled(Headline5)`
color: ${({ theme }) => theme.colors.primaryDark};
margin: 10px;
padding : 20px;
text-align: center;
`;

const StyledTextWrapper = styled(Label)`
font-size:14px;
color: ${({ theme }) => theme.colors.primary};
margin: 2px 0 0;
`;

const StyledTextComponent = styled(TextComponent)`
color: ${({ theme }) => theme.colors.primaryDark};
text-align: left;
font-size: 16px;
`;

const StyledText = styled(TextComponent)`
color: ${({ theme }) => theme.colors.primaryDark};
text-align: left;
font-size: 15px;
margin-top: 15px;
`;

const LabelText = styled(Label)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 50px;
  text-align: center;
  padding : 0px 60px;
`;

const Icon = styled(IconHeadset)`
margin: 50px auto 0;
color: ${({ theme }) => theme.colors.accentLight};
padding: 15px;
border: 4px solid;
border-radius: 50%;
`;


const CheckIcon = styled(IconCheck)`
color: ${({ theme }) => theme.colors.accentLight};
padding:5px;
position: relative;
bottom: 4px;
`;

const StyledTextArea = styled(Textarea)`
max-width: 100%;
min-width: 100%;
max-height: 200px;
resize: none;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 390px;
    min-width: 390px;
    max-height: 200px;
}
`;