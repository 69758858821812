import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconLock = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={props.color}
        d="M20,8H17V6.21a5.147,5.147,0,0,0-4.51-5.19A5.008,5.008,0,0,0,7,6V8H4V22H20Zm-8,9a2,2,0,1,1,2-2A2.006,2.006,0,0,1,12,17ZM9,8V6a3,3,0,0,1,6,0V8Z"
      />
    </Svg>
  );
});
