import { IAppAction } from '../app-action';
import { IAppError, IGetInTouch, IWebEnquiry } from '../../models';

export const getInTouchActions = {
    SUBMIT_REQUEST: 'SUBMIT_REQUEST',
    SUBMIT_REQUEST_SUCCESS: 'SUBMIT_REQUEST_SUCCESS',
    SUBMIT_REQUEST_ERROR: 'SUBMIT_REQUEST_ERROR',

    SUBMIT_ENQUIRY: 'SUBMIT_ENQUIRY',
    SUBMIT_ENQUIRY_SUCCESS: 'SUBMIT_ENQUIRY_SUCCESS',
    SUBMIT_ENQUIRY_ERROR: 'SUBMIT_ENQUIRY_ERROR',

    RESET_SUCCESS_MESSAGE : 'RESET_SUCCESS_MESSAGE'
};

export const submitRequest = (request: IGetInTouch): IAppAction => ({
    type: getInTouchActions.SUBMIT_REQUEST,
    payload: request
});

export const submitRequestError = (error: IAppError): IAppAction => ({
    type: getInTouchActions.SUBMIT_REQUEST_ERROR,
    payload: error
});

export const submitRequestSuccess = (): IAppAction => ({
    type: getInTouchActions.SUBMIT_REQUEST_SUCCESS
});

export const submitEnquiry = (request: IWebEnquiry): IAppAction => ({
    type: getInTouchActions.SUBMIT_ENQUIRY,
    payload: request
});

export const submitEnquiryError = (error: IAppError): IAppAction => ({
    type: getInTouchActions.SUBMIT_ENQUIRY_ERROR,
    payload: error
});

export const submitEnquirySuccess = (): IAppAction => ({
    type: getInTouchActions.SUBMIT_ENQUIRY_SUCCESS
});

export const resetSuccessMessage = (): IAppAction => ({
    type: getInTouchActions.RESET_SUCCESS_MESSAGE
});
