import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconEmail = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path fill={props.color} d="M22,4H2V20H22ZM20,8l-8,5L4,8V6l8,5,8-5Z" />
    </Svg>
  );
});
