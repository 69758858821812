import React, { memo } from "react";
import styled from "styled-components";
import { IconBed, IconCalendar,  IconPeople } from "../../";
import Label from "../../typography/label";

export enum InfoTileType {
    PARTY = "party",
    TARVELDATE = "traveldate",
    NIGHTS = 'nights'
}
export interface InfoIconProps {
    info: string;
    infoType: InfoTileType;
}

export const InfoIcon = memo<InfoIconProps>(
    ({
        info,
        infoType
    }): JSX.Element => {
        return (
            <Container type={infoType}>
                {infoType === InfoTileType.PARTY && <IconParty size={35}/>}
                {infoType === InfoTileType.TARVELDATE && <IconDate size={35}/>}
                {infoType === InfoTileType.NIGHTS && <IconNights size={35}/>}
                <InfoText>{info}</InfoText>
            </Container>
        );
    }
);
interface IContainerProp{
    type: InfoTileType;
}

const Container = styled.div<IContainerProp>`
display: flex;
width: 130px;
height: 100px;
flex-direction: column;
background-color: ${({ theme: { colors },type }) => type === InfoTileType.TARVELDATE ? colors.labelLight:   colors.white};
cursor: none;
`;

const IconParty = styled(IconPeople)`
margin: 13px auto;
color: ${({ theme }) => theme.colors.labelDark};
`;

const IconDate = styled(IconCalendar)`
margin: 13px auto;
color: ${({ theme }) => theme.colors.labelDark};
`;

const IconNights = styled(IconBed)`
margin: 13px auto;
color: ${({ theme }) => theme.colors.labelDark};
`;

const InfoText = styled(Label)`
font-size: 10px;
color: ${({ theme: { colors } }) => colors.primaryDark};
text-align: center;
margin: 5px;
text-transform: uppercase;
`;
