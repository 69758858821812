import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "./style.css";
import { getMonthName } from "../../helpers/date-helper";
import { GetMonthNames } from "../../helpers/datasource-helper";
import { LabelText } from "..";

export interface IDatePicker {
  selectedDate? : Date | null;
  calendarYears?: Array<number>;
  onDateSelect(date: Date): void;
  className?: string;
  startYear? :number;
  endYear? :number;
  maxDate? : Date;
  minDate? : Date;
  placeHolder : string;
  label?: string;
  isValid?: boolean;
  style?: React.CSSProperties
}

export const YearDatePicker = (props: IDatePicker) => {
  const [startDate, setStartDate] = useState(props.selectedDate);

  const setSelectedDate = (date: Date) => {
    setStartDate(date);
    props.onDateSelect(date);
  }
  const start = props?.startYear ? props.startYear:  1900;
  const to = props?.endYear ? props.endYear: new Date().getFullYear();

  const years = props?.calendarYears ? props.calendarYears : Array.from({ length: (to - start) / 1  + 1 }, (_, i) => start + (i * 1));

  const months = GetMonthNames().map(m => m.title);
 
  const ExampleCustomInput = forwardRef<HTMLInputElement,React.HTMLProps<HTMLInputElement>>((props, ref) => (
    <input readOnly={true} className="example-custom-input" onClick={props.onClick} ref={ref} value= {props.value}/>    
  ));

  return (
    <Container className={props.className} style={props.style}>
      { props.label  && <LabelContainer>{props.label && <LabelText as="label" isValid={props.isValid}>{props.label}</LabelText>}</LabelContainer> }
      <DatePicker
        dateFormat="dd/MM/yyyy" 
        maxDate={props.maxDate}
        minDate={props.minDate}
        placeholderText={props.placeHolder}
        selected={startDate ? startDate : null}
        todayButton="Select Today"
        onChange={(date: Date) => setSelectedDate(date)}
        customInput={<ExampleCustomInput/>}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          
            <ContentWrapper
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
             
              <button onClick={(e)=>{ 
                e.preventDefault();
                 decreaseMonth()
                }} 
                disabled={prevMonthButtonDisabled}>
                {"<"}
              </button>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(Number(value))}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={getMonthName(date)}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))                 
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button onClick={
                (e)=>{
                  e.preventDefault();
                  increaseMonth()
                }
                } disabled={nextMonthButtonDisabled}>
                {">"}
              </button>
            </ContentWrapper>
          )}        
      /></Container>
  );
};

const Container = styled.div`
  
  input{
    height: ${(props) => props.style?.height ? props.style.height : "50px"};
    border: 2px solid ${({ theme }) => theme.colors.labelLight};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.black};
    font-size: 13px;
    padding-right:  ${(props) => props.style?.paddingRight ? props.style.paddingRight : "20px"};
    padding-left:  ${(props) => props.style?.paddingLeft ? props.style.paddingLeft : "20px"};
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  select{
    height: 30px;
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.black};
  }  
  `

const ContentWrapper = styled.div`
margin: 10;
display: "flex";
justify-content: "center"
`;
const LabelContainer = styled.div`
  margin: 20px 0px 10px;
`;