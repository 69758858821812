import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { Observable, of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { IEpicDependency, IAppError } from "../../models";
import { IAppAction } from "../app-action";
import {
	getPaymentGatewaySessionSuccess,
	getPaymentGatewaySessionError,
	paymentStateActions,
} from "./payment-actions";
import { IAppState } from "../app-state";
import { sendToast, ToastType } from "../../helpers/sendToast";

export const getPaymentGatewaySessionEpic = (
	action$: ActionsObservable<IAppAction>,
	state$: StateObservable<IAppState>,
	{ api, endPointKeys }: IEpicDependency
): Observable<IAppAction> =>
	action$.pipe(
		ofType(paymentStateActions.GET_PAYMENT_GATEWAY_SESSION),
		mergeMap((action) => {
			var data = action.payload.form;
			let contentType: string = "application/x-www-form-urlencoded";
			return api
				.post(endPointKeys.api_payment, "Payment/GetPaymentSession", data, contentType)
				.pipe(
					map((result) => result?.data),
					mergeMap((result: any) => {
						return of(getPaymentGatewaySessionSuccess(result));
					}),
					catchError((error) => {
						const appError: IAppError = {
							error,
							message: error.response?.data?.message,
						};
						sendToast(appError.message, ToastType.Error);
						return of(getPaymentGatewaySessionError(appError));
					})
				);
		})
	);
