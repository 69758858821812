import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconArrowUp = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 46 46" {...props}>
    <g transform="translate(-1129 -1690)">
        <g transform="translate(1149.921 1694.381) rotate(90)">
            <g transform="translate(0 -7.863)">
                <rect fill="none" width="26" height="26" transform="translate(0 0.285)"/>
            </g>
            <g transform="translate(5.47 -16.579)">
                <path 
                fill={props.color} 
                d="M44.087,27.026H18.007L29.514,15.519l-1.732-1.732L13.318,28.251,27.782,42.714l1.732-1.732L18.007,29.475h26.08Z" 
                transform="translate(-13.318 -13.787)"/>
            </g>
        </g>
        <rect fill="none" width="46" height="46" transform="translate(1129 1690)"/>
    </g>
    </Svg>
  );
});
