import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconAirplaneTicket = memo<IconProps>((props) => {
  return (
    <Svg viewBox="0 0 34.375 42.188" {...props}>
      <g transform="translate(-7.813 -3.906)">
        <g transform="translate(7.813 3.906)">
          <path
            fill={props.color}
            d="M45.188,11H35.813v1.563h6.25V46.938H31.125a1.564,1.564,0,0,1-1.562-1.562H28A3.129,3.129,0,0,0,31.125,48.5H45.188a3.129,3.129,0,0,0,3.125-3.125V14.125A3.129,3.129,0,0,0,45.188,11ZM46.75,45.375a1.564,1.564,0,0,1-1.562,1.563H43.625V12.563h1.563a1.564,1.564,0,0,1,1.563,1.563Z"
            transform="translate(-13.938 -6.313)"
          />
          <path
            fill={props.color}
            d="M30.313,39.375V8.125A3.129,3.129,0,0,0,27.188,5H13.125A3.129,3.129,0,0,0,10,8.125v31.25A3.129,3.129,0,0,0,13.125,42.5H27.188A3.129,3.129,0,0,0,30.313,39.375Zm-18.75,0V8.125a1.564,1.564,0,0,1,1.563-1.562H27.188A1.564,1.564,0,0,1,28.75,8.125v31.25a1.564,1.564,0,0,1-1.562,1.563H13.125A1.564,1.564,0,0,1,11.563,39.375Z"
            transform="translate(-10 -5)"
          />
          <path
            fill={props.color}
            d="M22.031,19.781a.781.781,0,0,0-1.562,0v4.188L15,26.5V28.22l5.469-2.53v4.48l-1.562.566V32.4l2.344-.85,2.344.85V30.737l-1.562-.566v-4.48L27.5,28.22V26.5l-5.469-2.529Z"
            transform="translate(-11.094 -8.063)"
          />
          <rect
            fill={props.color}
            width="1.563"
            height="10.938"
            transform="translate(23.438 10.938)"
          />
        </g>
      </g>
    </Svg>
  );
});
