import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconTrain = memo<IconProps>((props) => {
  return ( 
    <Svg viewBox="0 0 50 50" {...props}>
        <rect fill="none" width="50" height="50"/>
        <g transform="translate(9.375 3.906)">
        <path 
        fill={props.color} 
        d="M36.049,52l.8,2.344H21.072l.8-2.344h-1.65L18.1,58.25h1.65l.8-2.344H37.375l.8,2.344h1.65L37.7,52Z" 
        transform="translate(-13.333 -15.281)"/>
        <path 
        fill={props.color} 
        d="M39.481,6.9a37.89,37.89,0,0,0-23.712,0A5.457,5.457,0,0,0,12,12.1V34.688a5.475,5.475,0,0,0,5.469,5.469H37.781a5.475,5.475,0,0,0,5.469-5.469V12.1A5.457,5.457,0,0,0,39.481,6.9Zm2.206,27.785a3.911,3.911,0,0,1-3.906,3.906H17.469a3.911,3.911,0,0,1-3.906-3.906V12.1a3.9,3.9,0,0,1,2.692-3.714A36.323,36.323,0,0,1,39,8.388,3.9,3.9,0,0,1,41.688,12.1Z" 
        transform="translate(-12 -5)"/>
        <path 
        fill={props.color} 
        d="M39.656,14H17.781a.781.781,0,0,0-.781.781v12.5a.781.781,0,0,0,.781.781H39.656a.781.781,0,0,0,.781-.781v-12.5A.781.781,0,0,0,39.656,14ZM27.938,26.5H18.563V15.563h9.375Zm10.938,0H29.5V15.563h9.375Z" 
        transform="translate(-13.094 -6.969)"/>
        <rect width="10.938" height="1.563" transform="translate(10.156 3.906)"/>
        <path 
        fill={props.color} 
        d="M21.125,36a3.125,3.125,0,1,0,3.125,3.125A3.129,3.129,0,0,0,21.125,36Zm0,4.688a1.563,1.563,0,1,1,1.563-1.562A1.564,1.564,0,0,1,21.125,40.688Z" 
        transform="translate(-13.313 -11.781)"/>
        <path 
        fill={props.color} 
        d="M41.125,36a3.125,3.125,0,1,0,3.125,3.125A3.129,3.129,0,0,0,41.125,36Zm0,4.688a1.563,1.563,0,1,1,1.563-1.562A1.564,1.564,0,0,1,41.125,40.688Z" 
        transform="translate(-17.688 -11.781)"/>
      </g>
    </Svg>
  );
});