import moment from "moment";

export const isFutureDate = (date: Date, format: string) => {
    const currentDate = moment().startOf('day');
    const isFuture: Boolean = moment(date, format).isSameOrAfter(currentDate);
    return isFuture;
};

export const getDayFromMonth = (date: Date, format: string): string => {
    return String(moment(date, format).date());
};

export const getMonthAndYear = (date: Date, format: string, outPutFormat: string): string => {
    return String(moment(date, format).format(outPutFormat));
};

export const getDateDiff = (date: Date, format: string) =>{
    const start = moment(date, "DD/MM/YYYY");
    const today = moment();
    return start.diff(today, 'days');
}

export const getMonthName = (date: Date) => {
   return moment(date.getMonth()+1, 'M').format('MMMM');
  }

export const getFormattedDate = (date: Date, format: string) =>{
    return moment(date, "DD/MM/YYYY").format(format);    
}