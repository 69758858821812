import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Headline5 } from "../typography/headline";
import { Icon, IconTypes } from "../icons/icon-component";
import { CarouselPage, PagedCarousel } from "../paged-carousel";
import { Body1 } from "../typography";
import { ButtonType } from "..";
import Button from "../button";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { signUpSocial } from "../../store/session";
import { IAppState } from "../../store/app-state";

declare interface ISignUpProps {
    onSignUpEmail: () => void;
}
export const SignUp = ({onSignUpEmail}: ISignUpProps) => {
    const dispatch = useDispatch();
    const text = "Sign up to My Scott Dunn and plan your next adventure.";
    const text2 = "Sign up to My Scott Dunn and start planning your exceptional holiday.";
    const text3 = "Sign up to My Scott Dunn and manage your dream trip.";
    const processingLoginType: string = useSelector((state: IAppState) => state.session.processingLoginType);
    const onClick = () => {
        onSignUpEmail();
    }
    // const onFacebookLogin = (e: any) => {
    //     // const email = e.email;
    //     // const fullName = e.name;
    //     // const facebookId = e.id;
    //     const accessToken = e.accessToken;
    //     // const signedRequest = e.signedRequest;
    //     dispatch(signUpSocial('Facebook', accessToken)) ;
    // }

    const onGoogleLogin = (e: any) => {
        const accessToken = e.accessToken;
        dispatch(signUpSocial('Google', accessToken)) ;
    }
      
    const onGoogleLoginFailure = (e: any) => {}

    const { colors } = useContext(ThemeContext);
    const isProcessing = false;
    return (
    <Container>
    <PagedCarousel autoplay autoplayTime={2500}>
        <CarouselPage key={1}>
            <ScrollingAdvert title="Plan your next adventure" text={text} icon="IconIsland" color={colors.primary}/>
        </CarouselPage>
        <CarouselPage key={2}>
            <ScrollingAdvert title="Book your dream holiday" text={text2} icon="IconAirplaneTicket" color={colors.primary}/>
        </CarouselPage>
        <CarouselPage key={3}>
            <ScrollingAdvert title="Manage your booking" text={text3} icon="IconConcierge" color={colors.primary}/>
        </CarouselPage>
    </PagedCarousel>
    <StyledButton
        type={ButtonType.ICON}
        text="Sign up with email"
        onClick={onClick}
        processing={isProcessing && processingLoginType === 'Email'}
        leftIcon={IconTypes.IconEmail}
        color={colors.white}
    />

 {/* <FacebookLogin
  appId="2852427318350058"
  autoLoad={false}
  fields="name,email,firstName,lastName"
  callback={onFacebookLogin}
  render={(renderProps: any) => (
    <StyledButton
    type={ButtonType.ICON}
    text="Sign up with Facebook"
    onClick={renderProps.onClick}
    processing={isProcessing && processingLoginType === 'Facebook'}
    leftIcon={IconTypes.IconEmail}
    color={colors.white}
/> 
  )}
/> */}
            <GoogleLogin
                clientId="481440991538-v64lnamg5ats650dlb9rckk2jsr0887t.apps.googleusercontent.com"
                autoLoad={false}
                onSuccess={onGoogleLogin}
                onFailure={onGoogleLoginFailure}
                isSignedIn={false}
                scope='openid email profile'
                render={(renderProps: any) => (
                  <StyledButton
                    type={ButtonType.ICON}
                    text="Sign up with Google"
                    onClick={renderProps.onClick}
                    processing={isProcessing && processingLoginType === 'Google'}
                    leftIcon={IconTypes.IconEmail}
                    color={colors.white}
                  />)}
              />
    </Container>
  )};

declare interface IScrollingAdvertProps {
    title: string;
    text: string;
    icon: string;
    color: string;
}

const ScrollingAdvert = ({title, text, icon, color}: IScrollingAdvertProps) =>
<AdvertContainer>
    <IconInCircle icon={icon} color={color}/>
    <StyledH5>{title}</StyledH5>
    <StyledBody1>{text}</StyledBody1>
</AdvertContainer>

interface IIconInCircle {
    icon: string;
    color: string;
}

const IconInCircle = ({icon, color}: IIconInCircle) => {
    return(
        <Circle className="circle"><Icon name={icon} size={50} color={color}/></Circle>
    );
}

const Container = styled.div`
display: flex;
flex-flow: column nowrap;
align-items: center;
justify-content: center;
overflow: hidden;
width: 100%;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 350px;
}
`;

const AdvertContainer = styled.div`
height: 150px;
display: flex;
flex-flow: column nowrap;
align-items: center;
width: 100%;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 350px;
}
`;

const Circle = styled.div`
margin-top: 8px;
display: inline-block;
height: 94px;
border-radius: 110px;
border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
padding: 0.5em 0.6em;
position: relative; 
`;

const StyledH5 = styled(Headline5)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  font-size: 25px;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 25px;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  margin-top: 25px;
`;

const StyledBody1 = styled(Body1)`
color: ${({ theme }) => theme.colors.primary};
font-size: 12px;
text-align: center;
width: 100%;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 350px;
}
word-wrap: normal;
`;