import React, { useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import { login, loginSocialMedia, routeChange, userAlreadyExists } from "../../store/session/session-actions";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "../../helpers/useWindowSize";
import { Input, InputTypes } from "../input";
import { Headline1 } from "../typography/headline";
import Button, { ButtonHeight, ButtonType } from "../button";
import Tab from "../tab";
import ForgotPassword from "./forgot-password";
import EmailAlreadyExist from "./email-already-exist";
import { useQueryParams } from "../../helpers/useQueryParams";
import NewPassword from "./new-password";
import routes from "../../routing/routes";
import { LinkButton } from "../link-button";
import { IAppState } from "../../store/app-state";
import { SignUp } from "./sign-up";
import { SignUpEmail } from "./sign-up-email";
import { IconTypes } from "../icons/icon-component";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from "react-google-login";

export const Login = () => {
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const resetLogin: boolean = useSelector(
    (state: IAppState) => state.session.resetLogin
  );
  const isProcessing: boolean = useSelector(
    (state: IAppState) => state.session.isProcessing
  );
  const isUserAlreadyExist: boolean = useSelector(
    (state: IAppState) => state.session.isUserAlreadyExist
  );
  const processingLoginType: string = useSelector((state: IAppState) => state.session.processingLoginType);
  const [showForgotPassword, setShowForgotPassword] = React.useState<boolean>(false);
  const [showCreateNewPassword, setShowCreateNewPassword] = React.useState<boolean>(false);
  const [showSignUpEmail, setShowSignUpEmail] = React.useState<boolean>(false);
  const [hasSubmittedLogin, setHasSubmittedLogin] = React.useState<boolean>(false);
  const [showCreateNewPasswordWhenExist, setShowCreateNewPasswordWhenExist] = React.useState<boolean>(false);
  const { breakpoints, colors } = useContext(ThemeContext);
  const [tabIdx, setTabIdx] = React.useState<number>(0);
  const dispatch = useDispatch();
  const size = useWindowSize();
  const isDesktop = size.width >= breakpoints.tablet;

  const queryStringEmail = useQueryParams().get("un");
  const queryStringResetCode = useQueryParams().get("code");

  useEffect(() => {
    if (queryStringEmail) {
      setEmail(queryStringEmail);
    }
  }, [queryStringEmail]);

  useEffect(() => {
    if (resetLogin) {
      setShowCreateNewPassword(false);
      setShowForgotPassword(false);
      setShowSignUpEmail(false);
    }
  }, [resetLogin]);

  useEffect(() => {
    if (queryStringResetCode) {
      setShowCreateNewPassword(true);
    }
  }, [queryStringResetCode]);

  const isValidEmail = () => {
    return email.indexOf("@") > -1;
  };

  const isValidPasswordLength = () => {
    return password.length > 6;
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onLogin();
  };

  const onLogin = () => {
    setHasSubmittedLogin(true);
    if (!isValidEmail()) return;
    if (!isValidPasswordLength()) return;
    dispatch(login(email, password));
  };

  //   const onFacebookLogin = (e: any) => {
  //     // const email = e.email;
  //     const accessToken = e.accessToken;
  //     dispatch(loginSocialMedia('Facebook', accessToken)) ;
  // }

  const onGoogleLogin = (e: any) => {
    const accessToken = e.accessToken;
    dispatch(loginSocialMedia('Google', accessToken));
  }

  const onGoogleLoginFailure = (e: any) => {
  }

  const onSignUpEmail = () => {
    setShowSignUpEmail(true);
  }

  const onCancel = () => {
    setShowCreateNewPassword(false);
    setShowCreateNewPasswordWhenExist(false)
    dispatch(routeChange(routes.home()));
  };

  const changeTab = (idx: number) => {
    setTabIdx(idx);
    setShowCreateNewPassword(false);
    setShowForgotPassword(false);
    setShowSignUpEmail(false);
    setShowCreateNewPasswordWhenExist(false)
  }

  return (
    <React.Fragment>
      {isDesktop ? (
        <DesktopImageArea>
          <DesktopLogo>
            <img
              height="50px"
              src={window.location.origin + "/images/msd_logo.svg"}
              alt="My Scott Dunn"
            />
          </DesktopLogo>
          <StyledH1>Start your adventure...</StyledH1>
        </DesktopImageArea>
      ) : (
        <MobileImageArea>
          <MobileLogo>
            <img
              height="40px"
              src={window.location.origin + "/images/msd_logo.svg"}
              alt="My Scott Dunn"
            />
          </MobileLogo>
        </MobileImageArea>
      )}
      <LoginArea>
        <LoginContainer>
          {!showCreateNewPassword && (
            <StyledTab
              initialIndex={tabIdx}
              elements={["Log In", "Sign Up"]}
              color={colors.white}
              size={18}
              onChange={(idx: number) => changeTab(idx)}
            />
          )}
          {!showCreateNewPassword && !showForgotPassword && !showSignUpEmail && tabIdx === 0 && (
            <>
              <Input
                placeholder="Email"
                type={InputTypes.TEXT}
                isValid={!(hasSubmittedLogin && !isValidEmail())}
                errorMessage={"Please enter a valid email address"}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                autoFocus
              />
              <Input
                placeholder="Password"
                type={InputTypes.PASSWORD}
                value={password}
                maxLength={25}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <LinkButton onClick={() => setShowForgotPassword(true)}>
                Forgotten your password?
              </LinkButton>
              <Button
                type={ButtonType.MAIN}
                height={ButtonHeight.TALL}
                text="Log in"
                onClick={onClick}
                processing={isProcessing && processingLoginType === 'Email'}
              />
              {/* <FacebookLogin
                appId="2852427318350058"
                autoLoad={false}
                fields="name,email,firstName,lastName"
                callback={onFacebookLogin}
                render={(renderProps: any) => (
                  <StyledButton
                    type={ButtonType.ICON}
                    text="Log in with Facebook"
                    onClick={renderProps.onClick}
                    processing={isProcessing && processingLoginType === 'Facebook'}
                    leftIcon={IconTypes.IconEmail}
                    color={colors.white}
                  />)}
              /> */}
              <GoogleLogin
                clientId="481440991538-v64lnamg5ats650dlb9rckk2jsr0887t.apps.googleusercontent.com"
                autoLoad={false}
                onSuccess={onGoogleLogin}
                onFailure={onGoogleLoginFailure}
                scope='openid email profile'
                isSignedIn={false}
                render={(renderProps: any) => (
                  <Button
                    type={ButtonType.ICON}
                    text="Log in with Google"
                    onClick={renderProps.onClick}
                    processing={isProcessing && processingLoginType === 'Google'}
                    leftIcon={IconTypes.IconEmail}
                    color={colors.white}
                  />)}
              />

            </>
          )}
          {!showCreateNewPassword && !showForgotPassword && !showSignUpEmail && tabIdx === 1 && !isUserAlreadyExist &&
            <SignUp onSignUpEmail={onSignUpEmail} />
          }
          {!showCreateNewPassword && !showForgotPassword && tabIdx === 1 && showSignUpEmail && !isUserAlreadyExist &&
            <SignUpEmail />
          }
          {tabIdx === 1 && isUserAlreadyExist && <EmailAlreadyExist onNewPassword={() => {
            setShowForgotPassword(true);
            setShowCreateNewPasswordWhenExist(true);
            dispatch(userAlreadyExists(false));
          }
          } onReset={() => {
            setTabIdx(0);
            setShowForgotPassword(true);
            setShowCreateNewPasswordWhenExist(false);
            dispatch(userAlreadyExists(false));
          }} />}
          {showForgotPassword && <ForgotPassword
            originalEmail={email}
            headerMessage={showCreateNewPasswordWhenExist ? "Let's create a password for you" : ""}
            messageText={showCreateNewPasswordWhenExist ? "Enter the email address you gave us when you contacted us and we'll email you a link to set up your password straight away." : ""}
            btnText={showCreateNewPasswordWhenExist ? "Create Password" : ""}
          />}
          {showCreateNewPassword && (
            <NewPassword
              code={queryStringResetCode ? queryStringResetCode : ""}
              cancel={onCancel}
            />
          )}
        </LoginContainer>
      </LoginArea>
    </React.Fragment>
  );
};

const StyledTab = styled(Tab)`
  ul > li > button > span {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: 700;
  }
`;

// const StyledButton = styled(Button)`
//   margin-top: 25px;
// `;

const DesktopImageArea = styled.div`
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100vh;

  &::before {
    content: "";
    background-image: url("/images/backgroundImageLogin.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

const MobileImageArea = styled.div`
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 200px;
  margin-bottom: -94px;

  &::before {
    content: "";
    background-image: url("/images/backgroundImageLogin.jpg");
    background-repeat: no-repeat;
    background-position: 55% 80%;
    background-size: cover;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`;

const DesktopLogo = styled.div`
  position: absolute;
  top: 80px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    left: 50px;
    top: 50px;

    img {
      height: 50px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    img {
      height: 55px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    left: 80px;
    top: 80px;

    img {
      height: 75px;
    }
  }
`;

const MobileLogo = styled.div`
  position: absolute;
  top: 68px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
`;

const StyledH1 = styled(Headline1)`
  position: absolute;
  bottom: 20px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    left: 50px;
    bottom: 50px;
    max-width: 400px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    max-width: 450px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    left: 80px;
    bottom: 80px;
    max-width: 600px;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  justify-content: start;
  & > * {
    margin-bottom: 20px;
  }
  margin: 0 auto;
  width: 300px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 350px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-width: 350px;
  }
`;

const LoginArea = styled.div`
  width: 100%;
  padding: 30px;
  flex-grow: 0;
  height: 80vh;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 410px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 500px;
    min-width: 500px;
    padding-top: 70px;
  }
`;
