import { combineReducers } from 'redux';
import sessionReducer from './session/session-reducer';
import { routerReducer } from 'react-router-redux';
import contactReducer from './contact/contact-reducer';
import holidayReducer from './holiday/holiday-reducer';
import referenceDataReducer from './reference-data/reference-data-state-reducer';
import getInTouchReducer from './get-in-touch/get-in-touch-reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import paymentReducer from './payment/payment-reducer';
import apiKeysReducer from './fetch-api-keys/fetch-api-keys-reducer';

const sessionPersistConfig = {
  key: 'session',
  storage: storage,
  blacklist: ['isMobileMenuOpen','resetLogin','isProcessing','updatePasswordSuccess','processingLoginType']
}

export const reducers = {
    session: sessionReducer,
    contact: contactReducer,
    holidays: holidayReducer,
    referenceData: referenceDataReducer,
    getInTouch: getInTouchReducer,
    payment: paymentReducer,
    apiKeys: apiKeysReducer
  };

 export const appReducer = 
  combineReducers({
  ...reducers,
  session: persistReducer(sessionPersistConfig, sessionReducer),//override session to exclude all but appUser being persisted
  router: routerReducer
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT_SUCCESS' || action.type === 'LOGOUT_ERROR') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

