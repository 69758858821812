import * as React from "react";
import styled from "styled-components";
import { IAppUser } from "../models";
import { useSelector } from "react-redux";
import { IAppState } from "../store/app-state";
import { Headline3 } from "../components/typography/headline/headline";
import TextComponent from "../components/typography/text/text";
import { ImageBackground } from "../components/image-background/image-background";
import DashboardButton from "../components/dashboard-button";
import routes from "../routing/routes";
import { Icon, IconTypes } from "../components/icons/icon-component";
//import { fetchKeys } from "../store/fetch-api-keys";
import { SDPDashboardImageBackground } from "../components/image-background/image-sdp-background";
import { getFormattedDate } from "../helpers/date-helper";

export const DashboardPage = () => {
    const appUser: IAppUser | null = useSelector((state: IAppState) => state.session.appUser);
    const [hideNotification, setHideNotification] = React.useState(true)
    const message = useSelector((state: IAppState)=> state?.session?.appUser?.futureTravelVoucherMessage)

  const Title = () => {
    return (
      <>
        <StyledHeadline3>{`Welcome ${appUser?.firstName},`}</StyledHeadline3>
        <StyledTextComponent>
          Your place to book, manage & discover your next adventure.
        </StyledTextComponent>
        {appUser?.isSDP && appUser.isSDP === true && (
          <SDPWarpper>
            <SDPStyledTextComponent>
              Membership No :{" "}
              {appUser.membershipNumber === "0"
                ? "N/A"
                : appUser.membershipNumber}
            </SDPStyledTextComponent>
            <SDPStyledTextComponent>
              Membership Tier : {appUser.tierLevel}
            </SDPStyledTextComponent>
            <SDPStyledTextComponent>
              Relationship Manager : {appUser.loyaltyPlanner}
            </SDPStyledTextComponent>
            <SDPStyledTextComponent>
              Membership Expiry Date :{" "}
              {getFormattedDate(
                new Date(appUser.membershipExpiryDate),
                "DD MMM yyyy"
              )}
            </SDPStyledTextComponent>
          </SDPWarpper>
        )}

        {/* {NotificationWrapper !== "" ? <NotificationWrapper> <Text>{message}</Text> <CloseIcon onClick={()=>setHideNotification((prev)=>!prev)}> <Icon name={IconTypes.IconClose} size={20}></Icon></CloseIcon> </NotificationWrapper> : ""}  */}
        {hideNotification && message !== "" && (
          <NotificationWrapper>
            <Text>{message}</Text>{" "}
            <CloseIcon onClick={() => setHideNotification((prev) => !prev)}>
              {" "}
              <Icon name={IconTypes.IconClose} size={20}></Icon>
            </CloseIcon>{" "}
          </NotificationWrapper>
        )}
        {/* {hideNotification ? "" : <NotificationWrapper> <Text>{message}{"   bgsdfgsdgsdjflngsdfngngndfgnsdjfngjdsnfjgldfsjgnlsdgnjsdngfsd jnngjkgfdskgnjsngkfsnjglfsdjngnsdfgndslngdsngdsjngkjdngdkngksdfngksdfngdksflgnsdfngdsngdsjfgnslgnjjsdngjfgnsfngiurengnsengunengiungiufgnngnfsdiugnile"}</Text> <CloseIcon onClick={()=>setHideNotification((prev)=>!prev)}> <Icon name={IconTypes.IconClose} size={20}></Icon></CloseIcon> </NotificationWrapper>} */}
      </>
    );
  };

  return (
    <DashboardWrapper>
      {appUser?.isSDP ? (
        <SDPDashboardImageBackground url="/images/SDPBackgroundImageDashboard.jpg" />
      ) : (
        <ImageBackground url="/images/backgroundImageDashboard.jpg" />
      )}
      <ContentLayout>
        <Title />
      </ContentLayout>
      <ButtonsLayout>
        <StyledDashboardButton
          label="My Quotes"
          url={routes.quotes()}
          backgroundImage={appUser?.isSDP ?"/images/quotes-sdp@2x.jpg":"/images/quotes@2x.png"}
          icon={IconTypes.IconEmailStroke}
        />
        <StyledDashboardButton
          label="My Bookings"
          url={routes.bookings()}
          backgroundImage={appUser?.isSDP ?"/images/bookings-sdp@2x.jpg":"/images/bookings@2x.png"}
          icon={IconTypes.IconAirplaneTicket}
        />
        <StyledDashboardButton
          label="Party Details"
          url={routes.guests()}
          backgroundImage={appUser?.isSDP ?"/images/guests-sdp@2x.jpg":"/images/guests@2x.png"}
          icon={IconTypes.IconFemaleUser}
        />
        <StyledDashboardButton
          label="Get in Touch"
          url={routes.getInTouch()}
          backgroundImage={appUser?.isSDP ?"/images/getInTouch-sdp@2x.jpg":"/images/getInTouch@2x.png"}
          icon={IconTypes.IconHeadset}
        />
      </ButtonsLayout>
    </DashboardWrapper>
  );
};

const ContentLayout = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 280px;
  padding-left: 5px;
  margin: 0px auto 10px auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    padding-left: 10px;
    width: 340px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 700px;
    padding-left: 10px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 800px;
    padding-left: 0px;
  }
`;

const ButtonsLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 280px;
  max-width: 500px;
  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 340px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 700px;
    max-width: inherit;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 800px;
    margin-top: 40px;
  }
  margin: 0 auto;
`;

const NotificationWrapper = styled.div`
background: transparent;
display: flex;
justify-content: space-between;
margin-top: 20px;
height: auto; 
padding: 5px;
margin-right: 10px;
// width: auto;
// min-width: 600px;
background: ${({ theme }) => theme.colors.primaryDark}};
color:${({ theme }) => theme.colors.white}};
font-size: 14px;
align-items: center;
// word-wrap: break-word;
// width: 680px;
width: auto;

@media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 320px;
}

@media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 270px;
}
`;

const Text = styled.span`
color:${({ theme }) => theme.colors.white}};
// width: 780px;
word-wrap: break-word;

@media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 250px;
}

@media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 240px;
}
`;

// const MaintenanceText = styled.span`
// color:#CC5500;
// // width: 780px;
// word-wrap: break-word;

// @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
//     width: 250px;
// }

// @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
//     width: 240px;
// }
// `;


// const MaintenanceNotificationWrapper = styled.div`
// display: flex;
// justify-content: space-between;
// margin-top: 20px;
// height: auto; 
// padding: 5px;
// margin-right: 10px;
// // width: auto;
// // min-width: 600px;
// background: #F2D2BD;
// color:${({theme})=>theme.colors.white}};
// font-size: 14px;
// align-items: center;
// // word-wrap: break-word;
// // width: 680px;
// width: auto;

// @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
//     width: 320px;
// }

// @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
//     width: 270px;
// }
// `;

const CloseIcon = styled.div`
  text-align: center;
  cursor: pointer;

  > svg {
    position: relative;
    top: 19px;
    left: 5px;
    scale: 3;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    position: relative;
  }
`;

const StyledDashboardButton = styled(DashboardButton)`
  flex: 0 1 calc(50%-30px);
`;

const StyledHeadline3 = styled(Headline3)`
  color: ${({ theme }) => theme.colors.white};
`;

const StyledTextComponent = styled(TextComponent)`
  color: ${({ theme }) => theme.colors.white};
`;

const SDPWarpper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  padding-top: 15px;
`;

const SDPStyledTextComponent = styled(TextComponent)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  display: block;
`;

const DashboardWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  font-size: 12px;
`;
