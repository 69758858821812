import React, { memo } from "react";
import { IconProps } from "./interface";
import { Svg } from "./Svg";

export const IconMultiModel = memo<IconProps>((props) => {
  return (
    <Svg width="50.72" height="50.75" viewBox="0 0 50.72 50.75">
      <path fill="none" stroke={props.color} stroke-miterlimit="10" stroke-width= "1.25px" d="M44.79,31.19l3.99-5.17c.98-1.26,1.47-2.33,1.47-4,0-3.47-2.8-6.29-6.26-6.29s-6.26,2.81-6.26,6.29c0,1.67.5,2.74,1.47,4l3.99,5.16c.73,1.06.86.96,1.59,0h0ZM11.46,15.81c4.01,0,7.27,3.27,7.27,7.3s-3.25,7.3-7.27,7.3-7.26-3.27-7.26-7.3,3.25-7.3,7.26-7.3h0ZM12.85,39.14l6.98-9.04c1.71-2.21,2.57-4.08,2.57-6.99,0-6.07-4.9-10.99-10.94-10.99S.51,17.04.51,23.11c0,2.92.87,4.78,2.58,7l6.97,9.03c1.28,1.85,1.5,1.67,2.78,0h0ZM35.88,31.95l-6.73,8.47h-15.35l4.95-6.43h7.67l6.73-6.73h5.33l3.65,4.69h-6.24ZM43.99,18.42c1.99,0,3.6,1.62,3.6,3.61s-1.61,3.61-3.6,3.61-3.6-1.62-3.6-3.61,1.61-3.61,3.6-3.61Z"/>
    </Svg>
  );
});
