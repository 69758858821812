import { ICountry } from "../../models";

export interface IReferenceDataState{
    countries: Array<ICountry>;
}

const defaultState: IReferenceDataState ={
 countries : []
};

export const getDefaultReferenceDataState = (options?: any) => {
    return {
      ...defaultState,
      ...options
    };
};