export const fetchApiKeysActions = {
	FETCH_KEYS: "FETCH_KEYS",
	FETCH_KEYS_SUCCESS: "FETCH_KEYS_SUCCESS",
	FETCH_KEYS_ERROR: "FETCH_KEYS_ERROR",
};
export const fetchKeys = () => {
	return {
		type: fetchApiKeysActions.FETCH_KEYS,
		payload: null,
	};
};
export const fetchKeysSuccess = (data: any) => {
	return {
		type: fetchApiKeysActions.FETCH_KEYS_SUCCESS,
		payload: data,
	};
};
export const fetchKeysError = (error: any) => {
	return {
		type: fetchApiKeysActions.FETCH_KEYS_ERROR,
	};
};
