import React from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../routing/routes";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { toggleMobileMenu } from "../store/session";
import { IconAirplaneTicket, IconDashboard, IconEmailStroke, IconFemaleUser, IconHeadset } from "./icons";
import { Body1 } from "./typography";

interface ILinkWithSelectedStateProps {
    url: string;
    children?: any;
    onClick?: ()=> void;
}

export const LinkWithSelectedState = ({url, children, onClick}: ILinkWithSelectedStateProps) => {
    const location = useLocation();
    const isActive = location.pathname === url;
    const classN = isActive ? 'is-active' : '';
    return (
        <Link to={url} className={classN} onClick={onClick}>{children}</Link>
    );
}

export const MainMenu = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const active = (url: string) => {
        return location.pathname === url;
    }
    const closeMenu = () => {
        dispatch(toggleMobileMenu(false));
    }
    return(
    <StyledNav>   
        <LinkContainer onClick={closeMenu}><VerticalLine active={active(routes.home())}/><LinkWithSelectedState url={routes.home()}><IconContainer><IconDashboard size={25}/></IconContainer><StyledText>DASHBOARD</StyledText></LinkWithSelectedState></LinkContainer>
        <LinkContainer onClick={closeMenu}><VerticalLine active={active(routes.quotes())}/><LinkWithSelectedState url={routes.quotes()}><IconContainer><IconEmailStroke size={25}/></IconContainer><StyledText>MY QUOTES</StyledText></LinkWithSelectedState></LinkContainer>
        <LinkContainer onClick={closeMenu}><VerticalLine active={active(routes.bookings())}/><LinkWithSelectedState url={routes.bookings()}><IconContainer><IconAirplaneTicket size={25}/></IconContainer><StyledText>MY BOOKINGS</StyledText></LinkWithSelectedState></LinkContainer>
        <LinkContainer onClick={closeMenu}><VerticalLine active={active(routes.guests())}/><LinkWithSelectedState url={routes.guests()}><IconContainer><IconFemaleUser size={25}/></IconContainer><StyledText>PARTY DETAILS</StyledText></LinkWithSelectedState></LinkContainer>
        <LinkContainer onClick={closeMenu}><VerticalLine active={active(routes.getInTouch())}/><LinkWithSelectedState url={routes.getInTouch()}><IconContainer><IconHeadset size={25}/></IconContainer><StyledText>GET IN TOUCH</StyledText></LinkWithSelectedState></LinkContainer>
    </StyledNav>
    )
}

const StyledNav = styled.nav`
margin-left: 30px;
margin-top: 6vh;
&.a {
text-decoration: none;
}
`;

const IconContainer = styled.span`
vertical-align: middle;
`;

const LinkContainer = styled.li`
list-style-type:none;
text-decoration: none;
height: 8vh;
position: relative;
${IconContainer} svg { 
    color: ${({ theme }) => theme.colors.primary};
}
.is-active ${IconContainer} svg {  
    color: ${({ theme }) => theme.colors.accent};
}
&:hover ${IconContainer} svg { 
    color: ${({ theme }) => theme.colors.accent};
}
`;

interface VerticalLineProps {
    active: boolean;
  }
  
export const VerticalLine = styled.div<VerticalLineProps>`
    position: absolute;
    left: -40px;
    top: -7px;
    width: ${({ active }) => active ? 3:0}px;
    background-color: ${({ theme }) => theme.colors.accentLight};
    height: 40px;
`;

const StyledText = styled(Body1)`
    color: ${({ theme }) => theme.colors.white};
    margin-left: 25px;
    vertical-align: middle;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.07em;
    text-decoration: none;
`;