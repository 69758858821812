import React from 'react'
import NumberFormat from 'react-number-format';
import styled, { keyframes } from 'styled-components';
import { useDispatch } from "react-redux";
import { ButtonType } from '../../';
import { getFormattedDate } from '../../../helpers/date-helper';
import { getCurrencySymbol } from '../../../helpers/getCurrencySymbol';
import { IHoliday } from '../../../models';
import Button, { ButtonHeight } from '../../button';
import { PaymentView } from '../../holiday-pay';
import { DialogBox, DialogSize } from '../../modal-dialog';
import { Body1, Body2 } from '../../typography';
import { InfoIcon, InfoTileType } from '../footer-info-icons';
import { paymentResultsClear } from "../../../store/payment";
import { fetchHoliday } from "../../../store/holiday";

export interface IBookingFooterProp {
    holiday: IHoliday;
    bookId: number;
    currency: string;
    total: number;
    party: number;
    travelDate: Date;
    nights: string;
}

export const BookingFooter = ({holiday, bookId, currency, total, party, travelDate, nights }: IBookingFooterProp) => {
    const dispatch = useDispatch();
    const currencySymbol = getCurrencySymbol(currency);

    const isNotFullyPaid = holiday && holiday.invoice && holiday.invoice.balance > 0;
    const [ showPaymentDialog, setShowPaymentDialog] = React.useState<boolean>(false);


    const onPayClick = () => {
        setShowPaymentDialog(true);
     };
    const partyText = `${party} guests`;
    const dateText = getFormattedDate(travelDate,'DD MMM yyyy')  
    const nightsText = `${nights} nights`;

    const handlePay = () =>{
        dispatch(fetchHoliday(holiday.bookId));
        dispatch(paymentResultsClear());
        setShowPaymentDialog(!showPaymentDialog);     
    }

    return (
        <>
        <FooterWrapper>
            <InfoIconWrapper>
                <InfoIcon info={partyText} infoType={InfoTileType.PARTY} />
                <InfoIcon info={dateText} infoType={InfoTileType.TARVELDATE} />
                <InfoIcon info={nightsText} infoType={InfoTileType.NIGHTS} />
            </InfoIconWrapper>
            <SummaryAmountWrapper>
                <StyledTotal>{`Total: `}
                    <NumberFormat value={total} thousandSeparator decimalScale={2} fixedDecimalScale displayType="text" prefix={currencySymbol} />
                </StyledTotal>
                <StyledReference>
                    {`Reference: `} {bookId}
                </StyledReference>
            </SummaryAmountWrapper>
            <ButtonWrapper>
                {isNotFullyPaid && <ButtonBook type={ButtonType.MAIN} height={ButtonHeight.NORMAL} text="Make Payment" onClick={onPayClick} />}
            </ButtonWrapper>
        </FooterWrapper>
            <DialogBox isOpen={showPaymentDialog ?? false} hide={handlePay} size={DialogSize.NORMAL} noPadding={true}>
                <PaymentView holiday={holiday} hide={handlePay}/>
            </DialogBox>
         </>
    );
}

const StyledTotal = styled(Body1)`
font-weight: 500;
font-size: 13px;
letter-spacing: 0.7px;
`;

const StyledReference = styled(Body2)`
font-size: 11px;
letter-spacing: -1px;
color:${({ theme }) => theme.colors.primarylDark};
`;

const InfoIconWrapper = styled.div`
display: none;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
}
cursor: pointer;
`;

const SummaryAmountWrapper = styled.div`
flex-flow: column nowrap;
display: flex;
justify-content: flex-start;
width: 100%;
padding: 25px 0px 25px 30px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 30%;
    padding: 30px 0px 0px 80px;
}
`;

const ButtonWrapper = styled.div`
flex-flow: row;
display: flex;
justify-content: space-between;
width: 60%;
padding: 25px 10px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 50%;
    justify-content: flex-end;
    padding: 30px 20px 20px 0px;
}
`;

const ButtonBook = styled(Button)`
width: 450px;
span {    
}
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width:80%;
    span {        
    }
}
`;

const inAnimation = keyframes`
    0% {
      bottom: -300px;
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    100% {
      bottom: 0px;  
    }
`;

const FooterWrapper = styled.div`
background-color:${({ theme }) => theme.colors.labelLight};
border-top: solid 1px ${({ theme }) => theme.colors.labelDark};
display:flex;
justify-content: flex-end;
position: fixed;
bottom: 0;
width: 100%;
height: 100px;
animation: ${inAnimation} 1200ms ease-in-out;

@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-flow: row nowrap;
    height: 100px;
    width: 700px;
    left: 50%;
    transform: translateX(-50%);
}
@media (min-width: ${({ theme }) => theme.breakpoints.laptop}px) {
    left: calc(50% + 125px);
    transform: translateX(-50%);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 800px;
  }
`;