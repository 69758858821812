import React from 'react'
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { getDayFromMonth, getMonthAndYear } from '../../../helpers/date-helper';
import { GetCloudinaryImage } from '../../../helpers/getCloudinaryImages';
import {  IHolidaySummary } from '../../../models';
import routes from '../../../routing/routes';
import { IconAddNew, QuoteTile, QuoteTileBadgeTypes } from '../..';
import Label from '../../typography/label';
import { useDispatch, useSelector } from 'react-redux';
import { routeChange } from '../../../store/session';
import { deleteRetrieveHoliday } from '../../../store/holiday/holiday-actions';
import { IAppState } from '../../../store/app-state';
import { DialogBox, DialogSize } from '../../modal-dialog';
import { DeleteBookingDialog } from '../../delete-booking/delete-booking-dialog';

interface IBookingTileProps {
    holidays: IHolidaySummary[];
    badgeType: QuoteTileBadgeTypes;
    isLiveHoliday?: boolean;
    isQuote: boolean;
    showRequestNewQuote?: boolean;
}



export const HolidayTiles = (props: IBookingTileProps) => {
    const dispatch = useDispatch()
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [selectedBooking, setSelectedBooking] =React.useState<number>();
    // const contactId : number = useSelector((state:IAppState)=> state.contact.contact?.contactId);
    const contactId : string = useSelector((state:IAppState)=> state.contact.contact?.contactId);
    const onClickNewQuote = () => {
        dispatch(routeChange(routes.getInTouchHolidayRequest('NewQuote', '')));
    }

    const onDeleteRetrieveBooking = (bookId: number) => {
        setSelectedBooking(bookId);
        setShowDeleteModal(true);
    }

    const onClose = () => {
        setSelectedBooking(undefined);
        setShowDeleteModal(false);
    }

    const onDelete = () => {
        dispatch(deleteRetrieveHoliday(contactId, selectedBooking || 0))
        setSelectedBooking(undefined);
        setShowDeleteModal(false);
    }



    const history = useHistory();
    return (
        <QuoteTileContainer>
            {props.holidays.map((book, i) =>
                <QuoteContainer key={'container' + i}>
                    <QuoteTile
                        key={'holiday' + i}
                        title={book.bookName}
                        imageUrl={book.primaryCountryImageId ? GetCloudinaryImage(book.primaryCountryImageId, false, 235, 210):"/images/backgroundImageLogin.jpg"}
                        location={book.primaryCountry}
                        consultantName={book.consultantName}
                        consultantImageUrl={book.consultantImageId ? GetCloudinaryImage(book.consultantImageId, false, 40, 40) : ''}
                        bookId={book.bookId}
                        badgeType={props.badgeType}
                        day={getDayFromMonth(book.startDate, "DD/MM/YYYY")}
                        monthAndYear={getMonthAndYear(book.startDate, "DD/MM/YYYY", "MMM YYYY")}
                        onClick={() => props.isQuote ? history.push(routes.quote(book.bookId.toString())) : history.push(routes.booking(book.bookId.toString()))}
                        isLive={props.isLiveHoliday}
                        isRetrieved={book.isRetrieved}
                        onDeleteRetrieveBooking={onDeleteRetrieveBooking}
                    />
                </QuoteContainer>)}
                {props.showRequestNewQuote && props.showRequestNewQuote === true &&  <NewQuoteContainer onClick={onClickNewQuote}> <IconNew size={50} /> <InfoText>Request new quote</InfoText></NewQuoteContainer>}
                {showDeleteModal &&  <DialogBox isOpen={showDeleteModal} hide={onClose} size={DialogSize.SMALL}>              
                    <DeleteBookingDialog isQuote onDelete={onDelete} OnClose={onClose}/>
                </DialogBox>} 
        </QuoteTileContainer>
    )
};

const QuoteTileContainer = styled.div`
display: flex;
flex-flow: column nowrap;
justify-content: flex-start;

@media (min-width: ${({ theme }) => theme.breakpoints.tablet + 20}px) {
    flex-flow: row wrap;
}
`;

const QuoteContainer = styled.div`
width: calc(100% - 10px);
margin-left: 5px;
margin-bottom: 10px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 250px;
    max-width: 250px;   
    margin-right: 10px;    
}
`;



const NewQuoteContainer = styled.div`
position: relative;
display: flex;
min-width: 185px;
flex-direction: column;
overflow: hidden;
margin-right: 5px;
margin-left:5px;
background-color:${({ theme }) => theme.colors.primaryDarkTransparent};
cursor: pointer;
height: 160px;
width: calc(100% - 5px);
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    height: 362px;   
    width: 250px;
}
@media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 419px;   
}
`;

const IconNew = styled(IconAddNew)`
margin: 25px auto;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 130px auto;
}
color: white;
border-radius: 50%;
border: 1px solid transparent;
background-color:${({ theme }) => theme.colors.primary};
`;

const InfoText = styled(Label)`
font-size: 12px;
font-weight: 500;
color:  white;
text-align: center;
text-transform: uppercase;
margin-top: 5px;
@media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-top: -95px;
}
`;