import {ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import { IEpicDependency, IAppError } from '../../models';
import { IAppAction } from '../app-action';
import { holidayStateActions, fetchHolidaysSuccess, fetchHolidaysError } from './holiday-actions';
import { IAppState } from '../app-state';

export const fetchHolidaysEpic = (action$: ActionsObservable<IAppAction>, state$: StateObservable<IAppState>, {api, endPointKeys}:
    IEpicDependency): Observable<IAppAction> => action$.pipe(
        ofType(holidayStateActions.FETCH_HOLIDAYS),
        mergeMap((action) => {
            const contactId: number = action.payload;
            return api.get(endPointKeys.api_travel, `Booking/GetBookingByContact?ContactId=${contactId}`).pipe(
                map(res => res.data),
                map((m : any) => fetchHolidaysSuccess(m)),
                catchError((error) => {
                    const appError : IAppError = { error: error, message: 'Failed to retrieve holidays'};
                    return of(fetchHolidaysError(appError))
                }))
        })
    )