import React from "react";
import styled from "styled-components";
import { signUpEmail } from "../../store/session/session-actions";
import { useDispatch, useSelector } from "react-redux";
import { Input, InputTypes } from "../input";
import Button, { ButtonHeight, ButtonType } from "../button";
import { IAppState } from "../../store/app-state";
import { Checkbox, Link, PasswordStrengthValidator } from "..";
//import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Body2 } from "../typography";
import { DialogBox, DialogSize } from '../modal-dialog';
import PrivacyStatement from "./privacy-statement";
import ReCaptcha from "react-google-recaptcha";

export const SignUpEmail = () => {
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  // const [password2, setPassword2] = React.useState<string>("");
  const [captchaToken, setCaptchaToken] = React.useState<string>("");
  const [termsAccepted, setTermsAccepted] = React.useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = React.useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = React.useState<boolean>(false);
  const [showPrivacyStatement, setShowPrivacyStatement] = React.useState<boolean>(false);

  const isProcessing: boolean = useSelector(
    (state: IAppState) => state.session.isProcessing
  );

  const dispatch = useDispatch();

  const isValidEmail = () => {
    return email.indexOf("@") > -1;
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSignUp();
  };

  const onSignUp = () => {
    setHasSubmitted(true);
    if (!firstName) return;
    if (!lastName) return;
    if (!isValidEmail()) return;
    if (!isValidPassword) return;
    if (!captchaToken) return;
    if(!termsAccepted) return;
    dispatch(signUpEmail(firstName, lastName, email, password, captchaToken, termsAccepted ));
  };

  const onChange = (token: string | null) => {
    if(token){
      setCaptchaToken(token);
    }
  }

  const onHidePrivacyStatement = () => {
    setShowPrivacyStatement(false);
  }

  return (
        <LoginContainer>
            <Input
              label ={(!hasSubmitted || firstName.length > 0) === true? "" : "Please enter a first name"}
                placeholder="First Name"
                type={InputTypes.TEXT}
                isValid={!hasSubmitted || firstName.length > 0}
                value={firstName}
                onChange={(e: any) => setFirstName(e.target.value)}
                autoFocus
              />
            <Input
                label ={(!hasSubmitted || lastName.length > 0) === true? "" : "Please enter a last name"}
                placeholder="Last Name"
                type={InputTypes.TEXT}
                isValid={!hasSubmitted || lastName.length > 0}
               
                value={lastName}
                onChange={(e: any) => setLastName(e.target.value)}
              />
              <Input
               label ={(!(hasSubmitted && !isValidEmail())) === true? "" : "Please enter a valid email address"}
                placeholder="Email address"
                type={InputTypes.EMAIL}
                isValid={!(hasSubmitted && !isValidEmail())}
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <Input
              label ={(!(hasSubmitted && !isValidPassword)) === true? "" : "Please enter a password that matches the criteria below"}
                placeholder="Create password"
                type={InputTypes.PASSWORD}
                isValid={!(hasSubmitted && !isValidPassword)}               
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <PasswordStrengthValidator password={password} onChange={setIsValidPassword}/>                
                <ReCaptcha
            theme="light"
            sitekey="6LdxtRMcAAAAAMTHU3xuxoMcHnDpu6MPke-6W8QH"
            onChange={onChange}
          /> 
              
              <CheckboxContainer>
                <Checkbox isChecked={termsAccepted} onClick={(e) => setTermsAccepted(e)}></Checkbox>
                <StyledBody2>I have read and accepted the <StyledLink url="https://www.scottdunn.com/about/legalities/privacy-policy" target="_blank">privacy statement</StyledLink></StyledBody2>
              </CheckboxContainer>
              {hasSubmitted && !termsAccepted && <PrivacyMessage>Please accept the privacy statement</PrivacyMessage>}
              <Button
                type={ButtonType.MAIN}
                height={ButtonHeight.TALL}
                text="Sign up"
                onClick={onClick}
                processing={isProcessing}
              />
              {showPrivacyStatement &&
                <DialogBox isOpen={showPrivacyStatement} hide={onHidePrivacyStatement} size={DialogSize.NORMAL}>              
                    <PrivacyStatement/>
                </DialogBox>}
        </LoginContainer>
  );
};

const LoginContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  justify-content: start;
  & > * {
    margin-bottom: 20px;
  }
  margin: 0 auto;
  width: 300px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 350px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-width: 350px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const StyledBody2 = styled(Body2)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  padding-left: 40px;
`;

// const StyledLinkButton = styled(LinkButton)` this doesn't work!
// `;

const StyledLink = styled(props => <Link {...props} />)`
color: ${({ theme }) => theme.colors.white};
font-size: 12px;
text-decoration: underline;
`;
const PrivacyMessage = styled.div`
color: ${({ theme }) => theme.colors.accent};
`;